import {
  useAddCryptoWalletMutation,
  useAddDepositToWalletMutation,
  useConnectCryptoWalletMutation,
  useGetCryptoWalletsMutation,
  useLazyCryptoWalletsBillingQuery,
  useSubstractDepositFromWalletMutation,
  useLazyCryptoWalletsStatisticsQuery,
  useAddBalanceToWalletMutation,
  useAddFrozenToWalletMutation,
  useSubstractBalanceFromWalletMutation,
  useSubstractFrozenFromWalletMutation,
  useActiveWalletMutation,
  useActiveDepositMutation,
  useChangeCryptoWalletMutation
} from "../../Store/Builder/unitrade.api";
import {
  setAddDepositToWallet,
  setCryptoWalletBilling,
  setCryptoWalletBillingDepos,
  setCryptoWallets,
  setLoader,
  setMerchantWalletBilling,
  setCryptoWalletStatistics,
  setSubstractDepositFromWallet,
  setAddBalanceToWallet,
  setAddFrozenToWallet,
  setSubstractBalanceFromWallet,
  setSubstractFrozenFromWallet,
} from "../../Store/Slice/unitradeSlice";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const CryptoWalletsApi = () => {
  const parameters = useParams();
  const dispatch = useDispatch();
  const [apiGetCryptoWallets] = useGetCryptoWalletsMutation();
  const [apiAddDepositToWallet] = useAddDepositToWalletMutation();
  const [apiAddBalanceToWallet] = useAddBalanceToWalletMutation();
  const [apiAddFrozenToWallet] = useAddFrozenToWalletMutation();
  const [apiSubstractDepositFromWallet] =
    useSubstractDepositFromWalletMutation();
  const [apiSubstractBalanceFromWallet] =
    useSubstractBalanceFromWalletMutation();
  const [apiSubstractFrozenFromWallet] = useSubstractFrozenFromWalletMutation();
  const [apiAddCryptoWallet] = useAddCryptoWalletMutation();
  const [apiCryptoWalletsBilling] = useLazyCryptoWalletsBillingQuery();
  const [apiCryptoWalletsStatistics] = useLazyCryptoWalletsStatisticsQuery();
  const [apiConnectCryptoWallet] = useConnectCryptoWalletMutation();
  const [apiActiveWallet] = useActiveWalletMutation();
  const [apiActiveDeposit] = useActiveDepositMutation();
  const [apiChangeCryptoWallet] = useChangeCryptoWalletMutation();
  const [limitCount, setLimitCount] = useState("100");
  const location = useLocation();

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);

    // if (lastCreated.current !== null) params.append('last_created_at', lastCreated.current);
    return params;
  };
  const getData = (params, setIsOnline) => {
    dispatch(setLoader(true));
    apiGetCryptoWallets(params)
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWallets(res));
        if(location?.pathname === '/crypto-wallets') {
          setIsOnline(res?.is_wallet);
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getCryptoWalletsBilling = (params) => {
    dispatch(setLoader(true));
    apiCryptoWalletsBilling(params)
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWalletBilling(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getCryptoWalletsStatistics = (body) => {
    dispatch(setLoader(true));
    apiCryptoWalletsStatistics(body)
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWalletStatistics(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addDepositToWallet = (body) => {
    dispatch(setLoader(true));
    apiAddDepositToWallet(body)
      .unwrap()
      .then((res) => {
        // dispatch(setAddDepositToWallet(res));
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id });
        // getCryptoWalletsStatistics({ wallet_id: res?.id });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addBalanceToWallet = (body) => {
    dispatch(setLoader(true));
    apiAddBalanceToWallet(body)
      .unwrap()
      .then((res) => {
        // dispatch(setAddBalanceToWallet(res));
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id });
        // getCryptoWalletsStatistics({ wallet_id: res?.id });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addFrozenToWallet = (body) => {
    dispatch(setLoader(true));
    apiAddFrozenToWallet(body)
      .unwrap()
      .then((res) => {
        // dispatch(setAddFrozenToWallet(res));
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id });
        // getCryptoWalletsStatistics({ wallet_id: res?.id });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const substractDepositFromWallet = (body) => {
    dispatch(setLoader(true));
    apiSubstractDepositFromWallet(body)
      .unwrap()
      .then((res) => {
        // dispatch(setSubstractDepositFromWallet(res));
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id });
        // getCryptoWalletsStatistics({ wallet_id: res?.id });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const substractBalanceFromWallet = (body) => {
    dispatch(setLoader(true));
    apiSubstractBalanceFromWallet(body)
      .unwrap()
      .then((res) => {
        // dispatch(setSubstractBalanceFromWallet(res));
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id });
        // getCryptoWalletsStatistics({ wallet_id: res?.id });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const substractFrozenFromWallet = (body) => {
    dispatch(setLoader(true));
    apiSubstractFrozenFromWallet(body)
      .unwrap()
      .then((res) => {
        // dispatch(setSubstractFrozenFromWallet(res));
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id });
        // getCryptoWalletsStatistics({ wallet_id: res?.id });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addCryptoWallet = () => {
    dispatch(setLoader(true));
    apiAddCryptoWallet()
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        getData({ wallet_id: res?.id, limit: formatParams() });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const connectCryptoWallet = (body, closeModal, getWallet) => {
    dispatch(setLoader(true));
    apiConnectCryptoWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setLoader(false));
        if(location?.pathname === '/profile') {
          getWallet()
        }
        showToast("Крипто кошелек успешно добавлен", "success");
        closeModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const activeWallet = (body, setIsOnline) => {
    dispatch(setLoader(true));
    apiActiveWallet(body)
      .unwrap()
      .then(() => {
        getData(
          {
            wallet_id: parameters?.id,
          },
          setIsOnline
        );
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const activeDeposit = (body, setIsOnline) => {
    dispatch(setLoader(true));
    apiActiveDeposit(body)
      .unwrap()
      .then(() => {
        getData(
          {
            wallet_id: parameters?.id,
          },
          setIsOnline
        );
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const changeCryptoWallet = (body, closeCreateCryptoWalletModal) => {
    dispatch(setLoader(true));
    apiChangeCryptoWallet(body)
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWallets(res));
        closeCreateCryptoWalletModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    addDepositToWallet,
    substractDepositFromWallet,
    addCryptoWallet,
    getCryptoWalletsBilling,
    connectCryptoWallet,
    getCryptoWalletsStatistics,
    addBalanceToWallet,
    addFrozenToWallet,
    substractBalanceFromWallet,
    substractFrozenFromWallet,
    activeWallet,
    activeDeposit,
    changeCryptoWallet
  };
};

export default CryptoWalletsApi;
