import Header from "../../Components/Header";
import { useEffect, useRef, useState } from "react";
import ApiOrders from "../../Global/Api/orders.api";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelect } from "../../UI/Select";
import moment from "moment/moment";

import { setRejectOrdersEmpty } from "../../Store/Slice/unitradeSlice";
import Button from "../../UI/Button";
import SearchComponent from "../../Components/Search";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import "rsuite/Toggle/styles/index.css";
import ApiUsers from "../../Global/Api/users.api";
import { useDebounce } from "../../Global/Hooks/useDebounce";
import { Link } from "react-router-dom";
import ApiCurrencies from "../../Global/Api/currencies.api";

import "./index.scss";

export default function RejectOrders() {
  const ordersApi = ApiOrders();
  const usersApi = ApiUsers();
  const currenciesApi = ApiCurrencies();
  const dispatch = useDispatch();
  const limitCount = 100;
  const [languageState, setLanguageState] = useState(false);
  const lastId = useRef(null);
  const lastDateTime = useRef(null);
  const [search, setSearch] = useState("");
  const firstLoad = useRef(false);
  const [value, setValue] = useState(null);
  const [usersMerchantSelect, setUsersMerchantSelect] = useState(null);
  const [bonusSelect, setBonusSelect] = useState(null);
  const [clientId, setClientId] = useState("");
  const [requisiteValue, setRequisiteValue] = useState("");
  const [categorySelect, setCategorySelect] = useState(null);
  const [requisiteTypeSelect, setRequisiteTypeSelect] = useState(null);
  const [currencySelect, setcurrencySelect] = useState(null);

  const clearClients = () => {
    dispatch(setRejectOrdersEmpty([]));
  };

  const debouncedSearchIdValue = useDebounce(clientId, 300, clearClients);
  const debouncedSearchRequisiteValue = useDebounce(
    requisiteValue,
    300,
    clearClients
  );

  const bonusData = [
    {
      value: true,
      label: "Межбанк",
    },
    {
      value: false,
      label: "Не межбанк",
    },
  ];
  const categoryData = [
    {
      value: "p2p",
      label: "P2P",
    },
    {
      value: "c2c",
      label: "C2C",
    },
  ];
  const requisiteTypes = [
    {
      value: "card",
      label: "Card",
    },
    {
      value: "phone",
      label: "Phone",
    },
    {
      value: "transfer",
      label: "Transfer",
    },
    {
      value: "wallet",
      label: "Wallet",
    },
  ];

  const { rejectOrders, language, usersMerchant, currencies } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );
  const [tab, setTab] = useState(false);

  useEffect(() => {
    const formattedParamsUsersMerchant = formatParamsUsersMerchant();
    usersApi.getData(formattedParamsUsersMerchant, "merchant");
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      ordersApi.getRejectOrders(formattedParams);
    }
  }, [search]);

  useEffect(() => {
    dispatch(setRejectOrdersEmpty([]));
    lastId.current = null;
    lastDateTime.current = null;
  }, [
    value,
    categorySelect,
    bonusSelect,
    usersMerchantSelect,
    debouncedSearchIdValue,
    debouncedSearchRequisiteValue,
    requisiteTypeSelect,
    currencySelect,
  ]);

  useEffect(() => {
    const formattedParams = formatParams();
    ordersApi.getRejectOrders(formattedParams);
    firstLoad.current = true;
  }, [
    tab,
    value,
    categorySelect,
    bonusSelect,
    usersMerchantSelect,
    debouncedSearchIdValue,
    debouncedSearchRequisiteValue,
    requisiteTypeSelect,
    currencySelect,
  ]);

  useEffect(() => {
    setLanguageState(localStorage.getItem("localization"));
  }, [localStorage.getItem("localization")]);

  const formatParamsUsersMerchant = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "merchant_server");
    return params;
  };

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("order_type", !tab ? "buy" : "sell");
    params.append('pagination_order', 'start');
    if (value !== null)
      params.append(
        "date_from",
        moment(value[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (value !== null)
      params.append(
        "date_to",
        moment(value[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (categorySelect !== null)
      params.append("order_category", categorySelect.value);
    if (requisiteTypeSelect !== null)
      params.append("requisite_type", requisiteTypeSelect.value);
    if (usersMerchantSelect !== null)
      params.append("merchant_id", usersMerchantSelect.value);
    if (bonusSelect !== null) params.append("bonus_order", bonusSelect.value);
    if (lastId.current !== null)
      params.append("last_created_at", lastId.current);
    if (clientId !== "") params.append("client_id", clientId);
    if (currencySelect !== null)
      params.append("currency", currencySelect.label);
    if (requisiteValue !== "") params.append("requisite", requisiteValue);
    return params;
  };

  const loadMore = () => {
    lastId.current = rejectOrders[rejectOrders.length - 1]?.created_at;
    const formattedParams = formatParams();
    ordersApi.getRejectOrders(formattedParams);
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const searchSendData = () => {
    const body = {
      order_id: search,
    };
    ordersApi.getRejectOrderSearch(body);
  };

  const renderOrders = () => {
    return rejectOrders?.map((order) => (
      <tr key={order?.id}>
        <td>{order?.transaction_id}</td>
        <td>{order?.type === "sell" ? "Покупка" : "Продажа"}</td>
        <td>{order?.order_category}</td>
        <td>{order?.bonus_order ? "Межбанк" : "Не межбанк"}</td>
        <td>{order?.requisite_type}</td>
        <td>{order?.client_id}</td>
        <td>{order?.requisite}</td>
        <td>{order?.order_amount}</td>
        <td>{order?.currency}</td>
        <td>{order?.bank_id}</td>
        <td>
          <Link
            onClick={() => {
              localStorage.setItem("user_id", order?.merchant_id);
            }}
            target="_blank"
            to={`/user/${order?.merchant_id}`}
          >
            {usersMerchant.find((user) => user.id === order.merchant_id)
              ?.notice || order.merchant_id}
          </Link>
        </td>
        <td>
          {moment.utc(order?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
      </tr>
    ));
  };

  const handleSearchId = (searchTerm) => {
    setClientId(searchTerm);
  };
  const handleSearchRequisite = (searchTerm) => {
    setRequisiteValue(searchTerm);
  };

  return (
    <div className="orders container">
      <Header title={"Оклоненные Заявки"} />
      <div className="orders__inner">
        <div className="orders__selects">
          <div className="orders__select">
            <CustomSelect
              options={categoryData}
              placeholder={"Выберите категорию"}
              selected={categorySelect}
              handleSelect={(e) => {
                setCategorySelect(e ?? null);
                dispatch(setRejectOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={requisiteTypes}
              placeholder={"Выберите тип реквизитов"}
              selected={requisiteTypeSelect}
              handleSelect={(e) => {
                setRequisiteTypeSelect(e ?? null);
                dispatch(setRejectOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>

          <div className="orders__select">
            <CustomSelect
              options={usersMerchant?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите мерчанта"}
              selected={usersMerchantSelect}
              handleSelect={(e) => {
                setUsersMerchantSelect(e ?? null);
                dispatch(setRejectOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={bonusData}
              placeholder={"Выберите тип межбанка"}
              selected={bonusSelect}
              handleSelect={(e) => {
                setBonusSelect(e ?? null);
                dispatch(setRejectOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__search">
            <SearchComponent
              searchTerm={search}
              onSearch={handleSearch}
              placeholder={"Введите id заявки"}
            />
          </div>
          <div className="users__top-btn">
            <Button
              text={"Найти заяку"}
              type={"accept"}
              onClick={() => {
                searchSendData();
              }}
              topPadding={10}
              bottomPadding={10}
            />
          </div>
        </div>
        <div className="orders__top">
          <div className="orders__tabs orders__tabs_mw">
            <div
              onClick={() => {
                if (tab) {
                  lastId.current = null;
                  lastDateTime.current = null;
                  dispatch(setRejectOrdersEmpty([]));
                }
                setTab(false);
              }}
              className={`orders__tab ${!tab ? "orders__tab_active" : ""}`}
            >
              <p>
                {languageState === "false"
                  ? language?.ads?.sell_ru
                  : language?.ads?.sell_en}
              </p>
            </div>
            <div
              onClick={() => {
                if (!tab) {
                  lastId.current = null;
                  lastDateTime.current = null;
                  dispatch(setRejectOrdersEmpty([]));
                }
                setTab(true);
              }}
              className={`orders__tab ${tab ? "orders__tab_active" : ""}`}
            >
              <p>
                {languageState === "false"
                  ? language?.ads?.buy_ru
                  : language?.ads?.buy_en}
              </p>
            </div>
            <div className="orders__input orders__input_w">
              <SearchComponent
                searchTerm={clientId}
                onSearch={handleSearchId}
                placeholder={"Поиск по ID клиента"}
              />
            </div>
            <div className="orders__input orders__input_w">
              <SearchComponent
                searchTerm={requisiteValue}
                onSearch={handleSearchRequisite}
                placeholder={"Введите реквизиты"}
              />
            </div>
            <div className="orders__input_w">
              <DateRangePicker onChange={setValue} format="dd.MM.yyyy HH:mm" />
            </div>
            <div className="orders__select">
              <CustomSelect
                options={currencies.map((currency) => {
                  return {
                    value: currency.id,
                    label: currency.currency,
                  };
                })}
                placeholder={"Выберите валюту"}
                selected={currencySelect}
                id="currency"
                handleSelect={(e) => {
                  setcurrencySelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          </div>
          <div className={`users__top-inner`}></div>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID танзанции</th>
              <th>Тип</th>
              <th>Категория</th>
              <th>Межбанк/ Не межбанк</th>
              <th>Тип реквизитов</th>
              <th>ID клиента</th>
              <th>Реквизиты</th>
              <th>Сумма заявки</th>
              <th>Валюта</th>
              <th>ID банка</th>
              <th>Мерчант</th>
              <th>Время создания</th>
            </tr>
          </thead>
          <tbody>{renderOrders()}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
