import React, {useEffect} from "react";
import axios from "../axios";
import Header from "../../../Components/Header";
import useInputHandler from "../../../Global/Hooks/inputHandler";
import Input from "../../../UI/Input";
import Button from "../../../UI/Button";
import showToast from "../../../Global/Hooks/toast";
import { setAccessTokenCookie, setRefreshTokenCookie } from "../authUtils";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';

import './index.scss';


export default function Login() {
    const navigate = useNavigate()
    const tokenField = useInputHandler("");
    const codeField = useInputHandler("");
    const profileData = JSON.parse(localStorage.getItem("profileData"));

    useEffect(() => {
        if (Cookies.get('refresh_token') && profileData?.role === 'superadmin') {
            navigate("/receipt-service/users");
        }
        if (Cookies.get('refresh_token') && profileData?.role === 'arbitr') {
            navigate("/receipt-service/receipts");
        }
    }, []);

    const isButtonDisabled = !(
        tokenField.value.length >= 3 && codeField.value.length === 6
    );

    const sendData = (event) => {
        event.preventDefault();
        const body = {
            login: tokenField.value,
            password: codeField.value
        }
        axios.post('/api/v1/auth/', body)
        .then((res) => {
            setAccessTokenCookie(res?.data?.access_token)
            setRefreshTokenCookie(res?.data?.refresh_token)
            if(profileData?.role === 'superadmin') {
                navigate('/receipt-service/users')
            } else {
                navigate('/receipt-service/receipts')
            }
            
        })
        .catch(error => {
            showToast(error?.response?.data?.message, "error");
        })
    }

    return (
        <div className="receiptLogin container">
            <Header title={"Сервис чеков"} />
            <div className="receiptLogin__wrapper">
            <form onSubmit={sendData} className="login__form">
                        <h1 className="login__title">{'Войти'}</h1>
                        <div className="login__input">
                            <Input
                              label={'Токен'}
                              placeholder="Введите токен для входа"
                              type="text"
                              id="token_field"
                              {...tokenField}
                              maxLength={71}
                            />
                        </div>
                        <div className="login__input mb-0">
                            <Input
                              label={"Код authenticator"}
                              placeholder="Введите код"
                              type="text"
                              id="code_field"
                              {...codeField}
                            />
                        </div>
                        <div className="login__btn">
                            <Button
                              text={"Войти"}
                              onClick={sendData}
                              topPadding={16}
                              disabled={isButtonDisabled}
                              bottomPadding={16}
                              type='accept'
                            />
                        </div>
                    </form>
            </div>
        </div>
    )
}

