import Header from "../../Components/Header";
import './index.scss'
import ApiBankBins from "../../Global/Api/bankBins.api";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import {CustomSelect} from "../../UI/Select";
import ApiPaymentMethods from "../../Global/Api/paymentMethods.api";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import Checkbox from "../../UI/Checkbox";
import SearchComponent from "../../Components/Search";

export default function BankBins() {
  const bankBinsApi = ApiBankBins();
  const paymentMethodsApi = ApiPaymentMethods();
  const [limitCount, setLimitCount] = useState(100);
  const [offSet, setOffSet] = useState(0);
  const [limitCountPayments, setLimitCountPayments] = useState(100000);
  const lastCreated = useRef(null);
  const [createModal, setCreateModal] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [bankBinInfo, setBankBinInfo] = useState(null);
  const isButtonDisabled = !(selectedMethod !== null);
  const bin = useInputHandler("");
  const [search, setSearch] = useState("");
  const firstLoad = useRef(false)

  const {bankBins, paymentMethods} = useSelector((state) => state.rootReducer.unitradeSlice);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('offset', offSet);
    return params
  }

  const formatParamsPayments = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCountPayments);
    params.append('offset', 0);
    return params
  }

  useEffect(() => {
    const formattedParams = formatParams();
    if(search === '' && firstLoad.current === true) {
      bankBinsApi.getData(formattedParams)
    }
  }, [search])

  useEffect(() => {
    const formattedParams = formatParams();
    const formattedParamsPayments = formatParamsPayments();
    bankBinsApi.getData(formattedParams)
    paymentMethodsApi.getData(formattedParamsPayments)
    firstLoad.current = true
  }, [])

  useEffect(() => {
    if(offSet !== 0) {
      const formattedParams = formatParams();
      bankBinsApi.getData(formattedParams)
    }
  }, [offSet])

  const loadMore = () => {
    setOffSet(offSet + 100)
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const openCreateModal = (bbin) => {
    setBankBinInfo(bbin)
    setCreateModal(true)
  }

  const closeCreateModal = () => {
    setBankBinInfo(null)
    setCreateModal(false)
    bin.setChange('')
  }

  const openDeleteModal = (bbin) => {
    setBankBinInfo(bbin)
    setDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setBankBinInfo(null)
    setDeleteModal(false)
  }

  const openEditModal = (bbin) => {
    setBankBinInfo(bbin)
    setEditModal(true)
    bin.setChange(bbin?.bin === null ? '' : bbin?.bin)
    setSelectedMethod({value: bbin?.payment_method_id, label: bbin?.payment_method?.bank_name})
  }

  const closeEditModal = () => {
    setBankBinInfo(null)
    setEditModal(false)
    bin.setChange('')
  }

  const createSendData = () => {
    const body = {
      bin: bin.value,
      payment_method_id: selectedMethod.value
    }
    bankBinsApi.createBankBin(body, closeCreateModal)
  }

  const searchSendData = () => {
    const body = {
      bin_id: search
    }
    bankBinsApi.getDataSearch(body)
  }

  const deleteSendData = () => {
    const body = {
      bin_id: bankBinInfo?.id
    }
    bankBinsApi.deleteBankBin(body, closeDeleteModal)
  }

  const editSendData = () => {
    const body = {
      bin_id: bankBinInfo?.id,
      bin: bin.value,
      payment_method_id: selectedMethod.value
    }
    bankBinsApi.editBankBin(body, closeEditModal)
  }

  const renderBankBinsData = () => {
    return bankBins.map((bbin, index) => {
      return (
        <tr key={index}>
          <td>{bbin?.bin}</td>
          <td>{moment.utc(bbin?.created_at).local().format('DD-MM-YYYY', 'HH:mm:ss')}</td>
          <td>{bbin?.payment_method?.bank_id}</td>
          <td>{bbin?.payment_method?.bank_name}</td>
          <td>{bbin?.payment_method?.country}</td>
          <td>
            <div
              onClick={() => {
                openEditModal(bbin)
              }}
              className="users__btn"
            >
              <img src="/assets/icons/edit-ad.svg" alt=""/>
            </div>
          </td>
          <td>
            <div
              onClick={() => {
                openDeleteModal(bbin)
              }}
              className="currencies__btn"
            >
              <img src="/assets/icons/trash-ad.svg" alt=""/>
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="bank-bins container">
      {createModal && (
        <Popup
          title={'Создать bank bin'}
          closePopup={closeCreateModal}>
          <div className="popup-select">
            <p>Выберите банк</p>
            <CustomSelect
              options={paymentMethods.map((payment) => {
                return {
                  value: payment.id,
                  label: payment.bank_name
                }
              })}
              selected={selectedMethod}
              id="paymentMethod"
              handleSelect={(e) => {
                setSelectedMethod(e ?? null)
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__input">
            <Input
              label={"Bin"}
              placeholder="123456"
              type="text"
              id="bin"
              {...bin}
              onlyNumber={true}
              maxLength={6}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={'Отмена'}
                type='light'
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={isButtonDisabled}
                bottomPadding={10}
                type='accept'
                onClick={createSendData}
              />
            </div>
          </div>
        </Popup>
      )}

      {editModal && (
        <Popup
          title={'Изменить способ оплаты'}
          closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Bin"}
                placeholder="bin"
                type="text"
                id="bin"
                {...bin}
              />
            </div>
            <div className="popup__inputs">
              <div className="popup-select">
                <p>Выберите валюту</p>
                <CustomSelect
                  options={paymentMethods.map((payment) => {
                    return {
                      value: payment.id,
                      label: payment.bank_name,
                    };
                  })}
                  selected={selectedMethod}
                  id="currency"
                  handleSelect={(e) => {
                    setSelectedMethod(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={'Отмена'}
                type='light'
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type='accept'
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}


      {deleteModal && (
        <Popup
          title={'Удалить bank bin'}
          closePopup={closeDeleteModal}>
          <p
            className="delete-text">Вы уверены что хотите удалить bank bin?</p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={'Отмена'}
                type='light'
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type='remove'
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}


      <Header title={"Bank Bins"}/>
      <div className="paymentMethods__top">
        <div className="paymentMethods__top-btn">
          <Button
            text={'Создать Bank bin'}
            type={'accept'}
            onClick={() => {
              openCreateModal()
            }}
            topPadding={10}
            bottomPadding={10}
          />
        </div>
        <div className='users__top-inner users__top-inner_ml'>
          <div className="users__top-search">
            <SearchComponent
              searchTerm={search}
              onSearch={handleSearch}
              placeholder={'Введите bin id'}
            />
          </div>
          <div className="users__top-btn">
            <Button
              text={'Найти bin'}
              type={'accept'}
              onClick={() => {
                searchSendData()
              }}
              topPadding={10}
              bottomPadding={10}
            />
          </div>
        </div>
      </div>
      <table>
        <thead>
        <tr>
          <th>Bin</th>
          <th>Дата создания</th>
          <th>Банк ID</th>
          <th>Банк</th>
          <th>Страна</th>
        </tr>
        </thead>
        <tbody>
        {renderBankBinsData()}
        </tbody>
      </table>
      <div className="loadMore">
        <p onClick={() => {loadMore()}}>
          Загрузить еще...
        </p>
      </div>
    </div>
  )
}
