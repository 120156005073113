import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import {
  setDeleteAd,
  setEditAd,
  setEditAdLimit,
  setLoader,
  setSelectedBanksClear,
  setToggleAd,
} from "../../Store/Slice/unitradeSlice";
import {
  useDeleteAnnouncementMutation,
  useEditAnnouncementMutation,
  useAddMinLimitAdMutation,
  useSubMinLimitAdMutation,
  useAddMaxLimitAdMutation,
  useSubMaxLimitAdMutation,
  useEditMaxLimitPerHourMutation
} from "../../Store/Builder/unitrade.api";
import useGetAds from "./getAds.api";

const useAds = () => {
  const dispatch = useDispatch();
  const [editAd] = useEditAnnouncementMutation();
  const [deleteAd] = useDeleteAnnouncementMutation();
  const [apiAddMinLimitAd] = useAddMinLimitAdMutation();
  const [apiSubMinLimitAd] = useSubMinLimitAdMutation();
  const [apiAddMaxLimitAd] = useAddMaxLimitAdMutation();
  const [apiSubMaxLimitAd] = useSubMaxLimitAdMutation();
  const [editMaxLimitPerHour] = useEditMaxLimitPerHourMutation();

  const getAds = useGetAds();

  const editedAd = (data, closeModal, infoAction) => {
    editAd(data)
      .unwrap()
      .then((res) => {
        if (infoAction?.action === "toggle") {
          dispatch(setToggleAd(data?.id));
        }
        if (infoAction?.action === "edit") {
          dispatch(setEditAd(res));
          showToast("Объявление успешно редактировано", "success");
        }
        closeModal();
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editedMaxLimitPerHour = (data, closeEditMaxOrderAmountModal) => {
    editMaxLimitPerHour(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAdLimit(res))
        closeEditMaxOrderAmountModal()
        showToast("Макс. объем за час успешно изменен", "success");
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const addMinLimitAd = (data, closeModal) => {
    apiAddMinLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAd(res));
        showToast("Лимит успешно изменен", "success");
        closeModal();
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addMaxLimitAd = (data, closeModal) => {
    apiAddMaxLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAd(res));
        showToast("Лимит успешно изменен", "success");
        closeModal();
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const subMinLimitAd = (data, closeModal) => {
    apiSubMinLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAd(res));
        showToast("Лимит успешно изменен", "success");
        closeModal();
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const subMaxLimitAd = (data, closeModal) => {
    apiSubMaxLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAd(res));
        showToast("Лимит успешно изменен", "success");
        closeModal();
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const deletedAd = (data, closeDeleteModal, tab, tabService) => {
    deleteAd(data)
      .unwrap()
      .then(() => {
        dispatch(setDeleteAd(data?.ad_id));
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append("limit", 50);
          params.append("ad_type", tab ? "sell" : "buy");
          params.append("ad_subtype", tabService ? "service" : "simple");
          return params;
        };
        getAds.get(formatParams());
        showToast("Объявление успешно удалено", "success");
        closeDeleteModal();
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    editedAd,
    deletedAd,
    addMinLimitAd,
    addMaxLimitAd,
    subMinLimitAd,
    subMaxLimitAd,
    editedMaxLimitPerHour
  };
};

export default useAds;
