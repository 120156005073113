import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import { CustomSelect } from "../../UI/Select";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import ApiPaymentMethods from "../../Global/Api/paymentMethods.api";
import showToast from "../../Global/Hooks/toast";
import Button from "../../UI/Button";
import "./index.scss";

export default function CreateOrder() {
  const paymentMethodsApi = ApiPaymentMethods();
  const [typeSelected, setTypeSelected] = useState(null);
  const [requisiteSelected, setRequisiteSelected] = useState(null);
  const cardNumber = useInputHandler("");
  const transferNumber = useInputHandler("");
  const clientId = useInputHandler("");
  const amountOrder = useInputHandler("");
  const timeOrder = useInputHandler("");
  const authCode = useInputHandler("");
  const [value, setValue] = useState();
  const [bankSelect, setBankSelect] = useState(null);
  const [limitCountPayments, setLimitCountPayments] = useState(10000);
  const banksData = useSelector(
    (state) => state.rootReducer.unitradeSlice.paymentMethods
  );
  const typeData = [
    {
      label: "Продажа",
      value: "buy",
    },
    {
      label: "Покупка",
      value: "sell",
    },
  ];
  const requisiteData = [
    {
      label: "Карта",
      value: "card",
    },
    {
      label: "СБП",
      value: "phone",
    },
    {
      label: "Счет",
      value: "transfer",
    },
  ];

  console.log(bankSelect);

  useEffect(() => {
    const formattedParams = formatParamsPayments();
    paymentMethodsApi.getData(formattedParams);
  }, []);

  const formatParamsPayments = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCountPayments);
    params.append("offset", 0);
    return params;
  };

  const loginMerchant = () => {
    let bodyData = {
      login: "noRfPh6ty9fL5rUewchmKEHy1i43qx",
      password: authCode.value,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/merchant/auth/sign-in/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("merchant_token", data?.access_token);
        showToast("Успешно авторизован", "success");
      })
      .catch(() => {
        showToast('Login failed', "error");
      });
  };

  const createOrder = () => {
    const randomID = Math.floor(Math.random() * 10000000) + 1; // Генерация случайного ID
    let bodyData;
    if (requisiteSelected.value === "card") {
      bodyData = {
        type: typeSelected.value,
        client_id: clientId.value,
        requisite: cardNumber.value,
        requisite_type: requisiteSelected.value,
        amount: amountOrder.value * 100,
        currency: bankSelect.currency.currency,
        transaction_id: `${randomID}`,
        bank_id: bankSelect.bank_id,
        minute_end_time: Number(timeOrder.value),
      };
    }
    if (requisiteSelected.value === "phone") {
      bodyData = {
        type: typeSelected.value,
        client_id: clientId.value,
        requisite: value,
        requisite_type: requisiteSelected.value,
        amount: amountOrder.value * 100,
        currency: bankSelect.currency.currency,
        transaction_id: `${randomID}`,
        bank_id: bankSelect.bank_id,
        minute_end_time: Number(timeOrder.value),
      };
    }
    if (requisiteSelected.value === "transfer") {
      bodyData = {
        type: typeSelected.value,
        client_id: clientId.value,
        requisite: transferNumber.value,
        requisite_type: requisiteSelected.value,
        amount: amountOrder.value * 100,
        currency: bankSelect.currency.currency,
        transaction_id: `${randomID}`,
        bank_id: bankSelect.bank_id,
        minute_end_time: Number(timeOrder.value),
      };
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/merchant/orders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("merchant_token")}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        showToast("Заявка успешно создана", "success");
      })
      .catch((error) => {
        showToast(error?.data?.message ?? error?.data?.message, "error");
      });
  };

  return (
    <div className="createOrder container">
      <Header title={"Создать заявку"} />
      <div className="createOrder__top">
        <div className="popup__input">
          <Input
            label={"Код аутентификации"}
            placeholder="Введите код аутентификации"
            type="text"
            id="authCode"
            {...authCode}
            onlyNumber={true}
            maxLength={6}
          />
        </div>
        <div className="statistics__btn">
          <Button
            text={"Залогиниться"}
            type={"accept"}
            disabled={authCode.value?.length < 6}
            onClick={() => {
              loginMerchant();
            }}
            topPadding={12}
            bottomPadding={12}
          />
        </div>
      </div>
      <div className="createOrder__form">
        <div className="popup-select">
          <CustomSelect
            options={typeData}
            selected={typeSelected}
            placeholder={"Выберите тип заявки"}
            handleSelect={(e) => {
              setTypeSelected(e ?? null);
            }}
          />
        </div>
        <div className="popup-select">
          <CustomSelect
            options={requisiteData}
            selected={requisiteSelected}
            placeholder={"Выберите тип реквизитов"}
            handleSelect={(e) => {
              setRequisiteSelected(e ?? null);
            }}
          />
        </div>
        {requisiteSelected?.value === "card" && (
          <div className="popup__input">
            <Input
              label={"Номер карты"}
              placeholder="Введите номер карты"
              type="text"
              id="cardNumber"
              {...cardNumber}
              onlyNumber={true}
              maxLength={16}
            />
          </div>
        )}
        {requisiteSelected?.value === "transfer" && (
          <div className="popup__input">
            <Input
              label={"Номер счета"}
              placeholder="Введите номер счета"
              type="text"
              id="transferNumber"
              {...transferNumber}
              onlyNumber={true}
              maxLength={20}
            />
          </div>
        )}
        {requisiteSelected?.value === "phone" && (
          <div className="popup__input">
            <PhoneInput
              placeholder="Введите номер телефона"
              value={value}
              onChange={setValue}
              international
            />
          </div>
        )}
        <div className="popup__input">
          <Input
            label={"ID клиента"}
            placeholder="Введите ID клиента"
            type="text"
            id="clientId"
            {...clientId}
            onlyNumber={true}
            maxLength={20}
          />
        </div>
        <div className="popup-select">
          <CustomSelect
            options={banksData}
            selected={bankSelect}
            placeholder={"Выберите банк"}
            handleSelect={(e) => {
              setBankSelect(e ?? null);
            }}
            isClearable={true}
            isSearch={true}
          />
        </div>
        <div className="popup__input">
          <Input
            label={"Сумма"}
            placeholder="Введите сумму заявки"
            type="text"
            id="amountOrder"
            {...amountOrder}
            onlyNumber={true}
            maxLength={20}
          />
        </div>
        <div className="popup__input">
          <Input
            label={"Срок жизни заявки в минутах"}
            placeholder="Срок жизни заявки в минутах"
            type="text"
            id="timeOrder"
            {...timeOrder}
            onlyNumber={true}
            maxLength={20}
          />
        </div>
        <div className="statistics__btn">
          <Button
            text={"Создать заявку"}
            type={"accept"}
            onClick={() => {
              createOrder();
            }}
            topPadding={10}
            bottomPadding={10}
          />
        </div>
      </div>
    </div>
  );
}
