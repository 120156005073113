import React, { useEffect, useRef, useState } from "react";
import Ad from "../../Components/Ad";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import { CustomSelect } from "../../UI/Select";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBanksClear } from "../../Store/Slice/unitradeSlice";
import useGetAds from "../../Global/Api/getAds.api";
import useAds from "../../Global/Api/ads.api";
import Header from "../../Components/Header";
import PhoneInput from "react-phone-number-input";
import ApiPaymentMethods from "../../Global/Api/paymentMethods.api";
import "react-phone-number-input/style.css";
import "./index.scss";
import { setSellAdsEmpty } from "../../Store/Slice/unitradeSlice";
import ApiUsers from "../../Global/Api/users.api";
import ApiCurrencies from "../../Global/Api/currencies.api";
import SearchComponent from "../../Components/Search";
import { useDebounce } from "../../Global/Hooks/useDebounce";

export default function Ads() {
  const dispatch = useDispatch();
  const usersApi = ApiUsers();
  const currenciesApi = ApiCurrencies();
  const [tab, setTab] = useState(false);
  const [tabService, setTabService] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addMinLimitModal, setAddMinLimitModal] = useState(false);
  const [addMinLimitNumber, setAddMinLimitNumber] = useState(null);
  const [subMinLimitModal, setSubMinLimitModal] = useState(false);
  const [subMinLimitNumber, setSubMinLimitNumber] = useState(null);
  const [addMaxLimitModal, setAddMaxLimitModal] = useState(false);
  const [addMaxLimitNumber, setAddMaxLimitNumber] = useState(null);
  const [subMaxLimitModal, setSubMaxLimitModal] = useState(false);
  const [subMaxLimitNumber, setSubMaxLimitNumber] = useState(null);
  const cardNumber = useInputHandler("");
  const cardName = useInputHandler("");
  const minLimit = useInputHandler("");
  const maxLimit = useInputHandler("");
  const maxOrderAmount = useInputHandler("");
  const maxOrderAmountPerHour = useInputHandler("");
  const requisite = useInputHandler("");
  const cvv = useInputHandler("");
  const mm = useInputHandler("");
  const yy = useInputHandler("");
  const [value, setValue] = useState();
  const [typePayment, setTypePayment] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [comment, setComment] = useState("");
  const lastCreated = useRef(null);
  const [requisiteValue, setRequisiteValue] = useState("");
  const [limitCount, setLimitCount] = useState(100);
  const [limitCountPayments, setLimitCountPayments] = useState(10000);
  const [usersSelect, setUsersSelect] = useState(null);
  const [categorySelect, setCategorySelect] = useState(null);
  const [requisiteSelect, setRequisiteSelect] = useState(null);
  const [currencySelect, setcurrencySelect] = useState(null);
  const [editMaxOrderAmountPerHourModal, setEditMaxOrderAmountPerHourModal] =
    useState(false);
  const statusData = [
    {
      label: "Активные",
      value: true,
    },
    {
      label: "Неактивные",
      value: false,
    },
  ];
  const categoryData = [
    {
      label: "P2P",
      value: "p2p",
    },
    {
      label: "C2C",
      value: "c2c",
    },
  ];
  const requisiteData = [
    {
      label: "Карта",
      value: "card",
    },
    {
      label: "СБП",
      value: "phone",
    },
    {
      label: "Счета",
      value: "transfer",
    },
  ];
  const [statusSelect, setStatusSelect] = useState(null);
  const getAds = useGetAds();
  const createAd = useAds();
  const [search, setSearch] = useState("");
  const firstLoad = useRef(false);

  const typeData = [
    {
      label: "Sell",
      value: "sell",
    },
    {
      label: "Buy",
      value: "buy",
    },
  ];
  const recvDataRu = [
    {
      label: "Номер карты",
      value: "card_number",
    },
    {
      label: "Номер мобильного телефона",
      value: "phone_number",
    },
    {
      label: "Номер счета",
      value: "requisite",
    },
    {
      label: "С2С",
      value: "c2c",
    },
  ];
  const recvDataEn = [
    {
      label: "Card number",
      value: "card_number",
    },
    {
      label: "Mobile phone number",
      value: "phone_number",
    },
    {
      label: "Account number",
      value: "requisite",
    },
    {
      label: "С2С",
      value: "c2c",
    },
  ];
  const interbanksData = [
    {
      label: "Да",
      value: true,
    },
    {
      label: "Нет",
      value: false,
    },
  ];
  const [interbankSelect, setInterbankSelect] = useState(null);
  const [typeSelect, setTypeSelect] = useState(null);
  const [bankSelect, setBankSelect] = useState(null);
  const [recvSelect, setRecvSelect] = useState(null);
  const [adInfo, setAdInfo] = useState(null);
  const [languageState, setLanguageState] = useState(false);
  const [deviseSelect, setDeviseSelect] = useState(null);
  const paymentMethodsApi = ApiPaymentMethods();

  const banksData = useSelector(
    (state) => state.rootReducer.unitradeSlice.paymentMethods
  );
  const profileData = JSON.parse(localStorage.getItem("profileData"));

  const sellData = useSelector(
    (state) => state.rootReducer.unitradeSlice.announcements
  );
  const { users, fullTraders } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  let serviceUsers = fullTraders;

  const selectedBanks = useSelector(
    (state) => state.rootReducer.unitradeSlice.selectedBanks
  );

  const currencies = useSelector(
    (state) => state.rootReducer.unitradeSlice.currencies
  );

  const isButtonDisabled = !(
    (
      (cardNumber?.value?.length === 16 || value?.length > 6) &&
      cardName?.value?.length > 2 &&
      minLimit?.value?.length > 0 &&
      maxLimit?.value?.length > 0 &&
      typeSelect !== null &&
      bankSelect !== null
    )
    // (cardNumber?.value?.length === 16 || value?.length > 6) && cardName?.value?.length > 2 && minLimit?.value?.length > 0 && maxLimit?.value?.length > 0 && (typeSelect !== null)
  );

  const isButtonDisabledBuy = !(
    minLimit?.value?.length > 0 &&
    maxLimit?.value?.length > 0 &&
    typeSelect !== null &&
    bankSelect !== null
  );

  const language = useSelector(
    (state) => state.rootReducer.unitradeSlice.language
  );

  const handleRequisiteValue = (searchTerm) => {
    setRequisiteValue(searchTerm);
  };

  const clearAds = () => {
    dispatch(setSellAdsEmpty([]));
  };

  const debouncedReqisiteValue = useDebounce(requisiteValue, 300, clearAds);

  const formatParamsPayment = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("offset", 0);
    return params;
  };

  useEffect(() => {
    const formattedParamsUsers = formatParamsUsers();
    usersApi.getFullTraders(formattedParamsUsers);
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      getAds.get(formattedParams);
      // dispatch(setSelectedBanksClear([]));
    }
  }, [
    search,
    tab,
    tabService,
    usersSelect,
    statusSelect,
    requisiteSelect,
    debouncedReqisiteValue,
    categorySelect,
    currencySelect,
  ]);

  useEffect(() => {
    const formattedParams = formatParamsPayments();
    if (profileData?.role === "superadmin") {
      paymentMethodsApi.getData(formattedParams);
    }
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    getAds.get(formattedParams);
    // dispatch(setSelectedBanksClear([]));
    firstLoad.current = true;
  }, []);

  useEffect(() => {
    setLanguageState(localStorage.getItem("localization"));
  }, [localStorage.getItem("localization")]);

  useEffect(() => {
    if (recvSelect === null) {
      setValue("");
    }
  }, [recvSelect]);

  useEffect(() => {
    if (adInfo === null) {
      cardNumber.setChange("");
      cardName.setChange("");
      minLimit.setChange("");
      maxLimit.setChange("");
      maxOrderAmount.setChange("");
      maxOrderAmountPerHour.setChange("");
      requisite.setChange("");
      cvv.setChange("");
      mm.setChange("");
      yy.setChange("");
      setBankSelect(null);
      setComment("");
      setRecvSelect(null);
      setValue("");
      setTypePayment("");
      setDeviseSelect(null);
      setIsActive(false);
      setInterbankSelect(null);
    }
  }, [typeSelect]);

  const searchSendData = () => {
    const body = {
      ad_id: search,
    };
    getAds.getSearch(body);
  };

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("ad_type", tab ? "sell" : "buy");
    params.append("ad_subtype", tabService ? "service" : "simple");
    if (requisiteValue !== "") params.append("requisite", requisiteValue.replace(/\s+/g, ''));
    if (usersSelect !== null) params.append("trader_id", usersSelect.value);
    if (statusSelect !== null) params.append("is_active", statusSelect.value);
    if (categorySelect !== null)
      params.append("ad_category", categorySelect.value);
    if (requisiteSelect !== null)
      params.append("requisite_type", requisiteSelect.value);
    if (currencySelect !== null)
      params.append("currency_id", currencySelect.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    return params;
  };

  const formatParamsPayments = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCountPayments);
    params.append("offset", 0);
    return params;
  };

  const loadMore = () => {
    lastCreated.current = sellData[sellData.length - 1]?.created_at;
    const formattedParams = formatParams();
    getAds.get(formattedParams);
  };

  const openModal = () => {
    setModal(true);
  };

  const deleteAd = (item) => {
    setDeleteModal(true);
    setAdInfo(item);
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setAdInfo(null);
  };

  const editAd = (item) => {
    // const formattedParamsPayment = formatParamsPayment();
    // paymentMethodsApi.getData(formattedParamsPayment);
    openModal();
    setAdInfo(item);
    cardNumber.setChange(item?.card_number);
    cardName.setChange(item?.card_holder);
    minLimit.setChange(Math.round(item?.min_amount));
    maxLimit.setChange(Math.round(item?.max_amount));
    maxOrderAmount.setChange(Math.round(item?.max_order_amount));
    maxOrderAmountPerHour.setChange(
      Math.round(item?.max_order_amount_per_hour)
    );
    cvv.setChange(item?.cvv);
    mm.setChange(item?.month);
    yy.setChange(item?.year);
    setValue(item?.phone_number);
    requisite.setChange(item?.requisite);
    setBankSelect(
      banksData?.filter((el) => el?.id === item?.payment_method?.id)
    );
    // if(item?.device_id !== null) {
    //     setDeviseSelect(devisesData?.filter(el => el?.id === item?.device_id))
    // }
    if (item?.is_interbank !== null) {
      setInterbankSelect(
        interbanksData?.filter((el) => el?.value === item?.is_interbank)
      );
    }
    if (item?.type === "buy") {
      setTypeSelect(typeData?.filter((el) => el?.value === "sell"));
    } else {
      setTypeSelect(typeData?.filter((el) => el?.value === "buy"));
    }
    setComment(item?.comment);
    setIsActive(item?.is_active);
    if (
      item?.card_number !== null &&
      item?.phone_number === null &&
      languageState === "false"
    ) {
      setTypePayment("card_number");
      setRecvSelect(recvDataRu?.filter((el) => el?.value === "card_number"));
    }
    if (
      item?.card_number !== null &&
      item?.phone_number === null &&
      languageState === "true"
    ) {
      setTypePayment("card_number");
      setRecvSelect(recvDataEn?.filter((el) => el?.value === "card_number"));
    }
    if (
      item?.phone_number !== null &&
      item?.card_number === null &&
      languageState === "false"
    ) {
      setTypePayment("phone_number");
      setRecvSelect(recvDataRu?.filter((el) => el?.value === "phone_number"));
    }
    if (
      item?.phone_number !== null &&
      item?.card_number === null &&
      languageState === "true"
    ) {
      setTypePayment("phone_number");
      setRecvSelect(recvDataEn?.filter((el) => el?.value === "phone_number"));
    }
    if (item?.year !== null && item?.month !== null && item?.cvv !== null) {
      setRecvSelect(recvDataRu?.filter((el) => el?.value === "c2c"));
    }
    if (item?.year !== null && item?.month !== null && item?.cvv !== null) {
      setRecvSelect(recvDataEn?.filter((el) => el?.value === "c2c"));
    }
    if (item?.requisite !== null && languageState === "false") {
      setTypePayment("requisite");
      setRecvSelect(recvDataRu?.filter((el) => el?.value === "requisite"));
    }
    if (item?.requisite !== null && languageState === "true") {
      setTypePayment("requisite");
      setRecvSelect(recvDataEn?.filter((el) => el?.value === "requisite"));
    }
  };

  const closeModal = () => {
    setModal(false);
    setAdInfo(null);
    cardNumber.setChange("");
    cardName.setChange("");
    minLimit.setChange("");
    maxLimit.setChange("");
    maxOrderAmount.setChange("");
    maxOrderAmountPerHour.setChange("");
    cvv.setChange("");
    mm.setChange("");
    requisite.setChange("");
    yy.setChange("");
    setBankSelect(null);
    setTypeSelect(null);
    setComment("");
    setRecvSelect(null);
    setValue("");
    // dispatch(setSelectedBanksClear([]))
    setTypePayment("");
    setIsActive(false);
    setDeviseSelect(null);
    setInterbankSelect(null);
  };

  const editSendData = () => {
    console.log(typePayment);
    const isCardNumberPayment = typePayment === "card_number";
    const cardHolderValue = cardName?.value?.length > 0 ? cardName.value : null;

    const body = {
      id: adInfo?.id,
      type: adInfo?.type,
      ad_category: adInfo.ad_category,
      card_holder: cardHolderValue,
      card_number: isCardNumberPayment ? cardNumber.value : null,
      cvv: typePayment === "c2c" ? cvv.value : null,
      month: typePayment === "c2c" ? mm.value : null,
      year: typePayment === "c2c" ? yy.value : null,
      phone_number: isCardNumberPayment ? null : value,
      requisite: typePayment === "requisite" ? requisite.value : null,
      requisite_type:
        typePayment === "card_number" || typePayment === "c2c"
          ? "card"
          : typePayment === "phone_number"
          ? "phone"
          : "transfer",
      device_id: adInfo?.device_id,
      comment: comment,
      payment_method_id: bankSelect[0]?.id,
      currency_id: bankSelect[0]?.currency?.id,
      is_active: isActive,
      is_interbank: adInfo?.type === "sell" ? interbankSelect.value : true,
      max_order_amount:
        maxOrderAmount?.value !== "" ? maxOrderAmount?.value : maxLimit?.value,
    };

    createAd.editedAd(body, closeModal, { action: "edit" });
  };

  const deleteSendData = () => {
    createAd.deletedAd(
      {
        ad_id: adInfo?.id,
      },
      closeDeleteModal,
      tab,
      tabService
    );
  };

  const renderSellAds = () => {
    if (search !== "") {
      if (sellData?.length === 0) {
        return (
          <div className="orders__empty orders__empty_height">
            <img src="/assets/images/ads-empty.svg" alt="ads-empty" />
            <p>
              {languageState === "false"
                ? language?.empty?.ads_search_empty_ru
                : language?.empty?.ads_search_empty_en}
            </p>
          </div>
        );
      } else {
        return sellData?.map((item) => (
          <Ad
            editAd={editAd}
            deleteAd={deleteAd}
            typePayment={typePayment}
            closeModal={closeModal}
            openAddMinLimitModal={openAddMinLimitModal}
            openSubMinLimitModal={openSubMinLimitModal}
            openAddMaxLimitModal={openAddMaxLimitModal}
            openSubMaxLimitModal={openSubMaxLimitModal}
            id={item?.id}
            item={item}
            key={item?.id}
          />
        ));
      }
    } else {
      if (sellData?.length === 0) {
        return (
          <div className="orders__empty orders__empty_height">
            <img src="/assets/images/ads-empty.svg" alt="ads-empty" />
            <p>
              {languageState === "false"
                ? language?.empty?.ads_empty_ru
                : language?.empty?.ads_empty_en}
            </p>
          </div>
        );
      } else {
        return sellData?.map((item) => (
          <Ad
            editAd={editAd}
            deleteAd={deleteAd}
            typePayment={typePayment}
            closeModal={closeModal}
            openAddMinLimitModal={openAddMinLimitModal}
            openSubMinLimitModal={openSubMinLimitModal}
            openAddMaxLimitModal={openAddMaxLimitModal}
            openSubMaxLimitModal={openSubMaxLimitModal}
            openEditMaxOrderAmountModal={openEditMaxOrderAmountModal}
            id={item?.id}
            item={item}
            key={item?.id}
          />
        ));
      }
    }
  };

  const openEditMaxOrderAmountModal = (item) => {
    setAdInfo(item);
    maxOrderAmountPerHour.setChange(
      Math.round(item?.max_order_amount_per_hour)
    );
    setEditMaxOrderAmountPerHourModal(true);
  };

  const closeEditMaxOrderAmountModal = () => {
    setAdInfo(null);
    maxOrderAmountPerHour.setChange("");
    setEditMaxOrderAmountPerHourModal(false);
  };

  const closeAddMinLimitModal = () => {
    setAdInfo(null);
    setAddMinLimitModal(false);
    setAddMinLimitNumber(null);
  };

  const closeSubMinLimitModal = () => {
    setAdInfo(null);
    setSubMinLimitModal(false);
    setSubMinLimitNumber(null);
  };

  const closeAddMaxLimitModal = () => {
    setAdInfo(null);
    setAddMaxLimitModal(false);
    setAddMaxLimitNumber(null);
  };

  const closeSubMaxLimitModal = () => {
    setAdInfo(null);
    setSubMaxLimitModal(false);
    setSubMaxLimitNumber(null);
  };

  const openAddMinLimitModal = (item) => {
    setAdInfo(item);
    setAddMinLimitModal(true);
  };

  const openSubMinLimitModal = (item) => {
    setAdInfo(item);
    setSubMinLimitModal(true);
  };

  const openAddMaxLimitModal = (item) => {
    setAdInfo(item);
    setAddMaxLimitModal(true);
  };

  const openSubMaxLimitModal = (item) => {
    setAdInfo(item);
    setSubMaxLimitModal(true);
  };

  const addMinLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: addMinLimitNumber,
    };
    createAd.addMinLimitAd(body, closeAddMinLimitModal);
  };

  const subMinLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: subMinLimitNumber,
    };
    createAd.subMinLimitAd(body, closeSubMinLimitModal);
  };

  const addMaxLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: addMaxLimitNumber,
    };
    createAd.addMaxLimitAd(body, closeAddMaxLimitModal);
  };

  const subMaxLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: subMaxLimitNumber,
    };
    createAd.subMaxLimitAd(body, closeSubMaxLimitModal);
  };

  const editMaxAmountPerHour = () => {
    const data = {
      ad_id: adInfo?.id,
      amount:
        maxOrderAmountPerHour?.value !== ""
          ? Number(maxOrderAmountPerHour?.value)
          : Number(maxLimit?.value),
    };
    createAd.editedMaxLimitPerHour(data, closeEditMaxOrderAmountModal);
  };

  return (
    <div className="ads container">
      <Header
        title={
          languageState === "false"
            ? language?.nav_bar?.ads_ru
            : language?.nav_bar?.ads_en
        }
      />
      {deleteModal && (
        <Popup
          title={
            languageState === "false"
              ? language?.ads?.deleted_ad_ru
              : language?.ads?.deleted_ad_en
          }
          closePopup={closeDeleteModal}
        >
          <p className="ads__popup-text">
            {languageState === "false"
              ? language?.ads?.delete_text_ru
              : language?.ads?.delete_text_en}
          </p>
          <div className="ads__popup-btns">
            <div className="ads__popup-btn">
              <Button
                text={
                  languageState === "false"
                    ? language?.ads?.cancel_ru
                    : language?.ads?.cancel_en
                }
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="ads__popup-btn">
              <Button
                text={
                  languageState === "false"
                    ? language?.ads?.delete_ru
                    : language?.ads?.delete_en
                }
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {modal && (
        <Popup
          closePopup={closeModal}
          title={
            adInfo
              ? languageState === "false"
                ? language?.ads?.edit_ad_ru
                : language?.ads?.edit_ad_en
              : languageState === "false"
              ? language?.ads?.create_ad_ru
              : language?.ads?.create_ad_en
          }
        >
          <div className="ads__popup">
            <div className="ads__popup-select">
              <p>
                {languageState === "false"
                  ? language?.ads?.type_ru
                  : language?.ads?.type_en}
              </p>
              <CustomSelect
                disabled={adInfo !== null}
                options={typeData}
                selected={typeSelect}
                handleSelect={(e) => {
                  setTypeSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="ads__popup-select">
              <p>
                {languageState === "false"
                  ? language?.ads?.payment_ru
                  : language?.ads?.payment_en}
              </p>
              <CustomSelect
                disabled={adInfo !== null}
                options={banksData}
                selected={bankSelect}
                handleSelect={(e) => {
                  setBankSelect(e ?? null);
                }}
                isClearable={true}
                isSearch={true}
              />
            </div>
            {typeSelect?.value !== "buy" && adInfo?.type !== "sell" && (
              <div className="ads__popup-select">
                {/*<p>{languageState === 'false' ? language?.ads?.payment_ru : language?.ads?.payment_en}</p>*/}
                <p>Платежные реквизиты</p>
                <CustomSelect
                  options={languageState === "false" ? recvDataRu : recvDataEn}
                  selected={recvSelect}
                  handleSelect={(e) => {
                    setRecvSelect(e ?? null);
                    if (e !== null) {
                      setTypePayment(e.value);
                    } else {
                      setTypePayment("");
                    }
                  }}
                  isClearable={false}
                />
              </div>
            )}
            {typeSelect?.value !== "buy" && (
              <div className="ads__popup-inputs">
                {typePayment === "phone_number" && (
                  <div className="ads__input">
                    <p>Номер мобильного телефона</p>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={value}
                      onChange={setValue}
                      international
                    />
                  </div>
                )}
                {typePayment === "requisite" && (
                  <div className="ads__input">
                    <Input
                      label={"Номер счета"}
                      placeholder={"Введите номер счета"}
                      type="text"
                      id="requisite"
                      {...requisite}
                      onlyNumber={true}
                    />
                  </div>
                )}
                {(typePayment === "card_number" || typePayment === "c2c") && (
                  <div className="ads__input">
                    <Input
                      label={
                        languageState === "false"
                          ? language?.ads?.card_number_ru
                          : language?.ads?.card_number_en
                      }
                      placeholder={
                        languageState === "false"
                          ? language?.ads?.enter_card_number_ru
                          : language?.ads?.enter_card_number_en
                      }
                      type="text"
                      id="card_number"
                      {...cardNumber}
                      onlyNumber={true}
                      maxLength={16}
                    />
                  </div>
                )}
                {typeSelect?.value !== "buy" && adInfo?.type !== "sell" && (
                  <div
                    className={`ads__input ${
                      recvSelect === null ? "ads__input_100" : ""
                    }`}
                  >
                    <Input
                      label={
                        languageState === "false"
                          ? language?.ads?.card_holder_ru
                          : language?.ads?.card_holder_en
                      }
                      placeholder={
                        languageState === "false"
                          ? language?.ads?.enter_card_holder_ru
                          : language?.ads?.enter_card_holder_en
                      }
                      type="text"
                      id="card_name"
                      {...cardName}
                    />
                  </div>
                )}
              </div>
            )}
            {typeSelect?.value !== "buy" &&
              (recvSelect?.[0]?.value === "c2c" ||
                recvSelect?.value === "c2c") && (
                <div className="ads__popup-inputs">
                  <div className="ads__popup-inputsDF">
                    <div className="ads__input">
                      <Input
                        label={"MM"}
                        placeholder={"01"}
                        type="text"
                        id="mm"
                        {...mm}
                        onlyNumber={true}
                        maxLength={2}
                      />
                    </div>
                    <p>/</p>
                    <div className="ads__input">
                      <Input
                        label={"YY"}
                        placeholder={"01"}
                        type="text"
                        id="yy"
                        {...yy}
                        onlyNumber={true}
                        maxLength={2}
                      />
                    </div>
                  </div>
                  <div className="ads__input">
                    <Input
                      label={"CVV"}
                      placeholder={"123"}
                      type="text"
                      id="cvv"
                      {...cvv}
                      onlyNumber={true}
                      maxLength={3}
                    />
                  </div>
                </div>
              )}
            {adInfo === null && (
              <div className="ads__popup-inputs">
                <div className="ads__input">
                  <Input
                    label={
                      languageState === "false"
                        ? language?.ads?.min_limit_ru
                        : language?.ads?.min_limit_en
                    }
                    placeholder={
                      languageState === "false"
                        ? language?.ads?.enter_min_limit_ru
                        : language?.ads?.enter_min_limit_en
                    }
                    type="text"
                    id="min_limit"
                    {...minLimit}
                    onlyNumber={true}
                    maxLength={10}
                  />
                </div>
                <div className="ads__popup-separate"></div>
                <div className="ads__input">
                  <Input
                    label={
                      languageState === "false"
                        ? language?.ads?.max_limit_ru
                        : language?.ads?.max_limit_en
                    }
                    placeholder={
                      languageState === "false"
                        ? language?.ads?.enter_max_limit_ru
                        : language?.ads?.enter_max_limit_en
                    }
                    type="text"
                    id="max_limit"
                    {...maxLimit}
                    onlyNumber={true}
                  />
                </div>
              </div>
            )}
            <div className="ads__popup-inputs">
              <div className="ads__input w-100">
                <Input
                  label={"Макс. сумма заявки"}
                  placeholder={"Введите макс. сумму заявки"}
                  type="text"
                  id="max_order_amount"
                  {...maxOrderAmount}
                  onlyNumber={true}
                />
              </div>
            </div>
            {/*{typeSelect?.value !== 'buy' && (*/}
            {/*    <div className="ads__popup-select">*/}
            {/*        /!*<p>{languageState === 'false' ? language?.ads?.payment_ru : language?.ads?.payment_en}</p>*!/*/}
            {/*        <p>Выберите девайс</p>*/}
            {/*        <CustomSelect*/}
            {/*            options={null}*/}
            {/*            selected={deviseSelect}*/}
            {/*            handleSelect={(e) => {*/}
            {/*                setDeviseSelect(e ?? null)*/}
            {/*            }}*/}
            {/*            isClearable={true}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}
            {(typeSelect?.[0]?.value === "buy" ||
              typeSelect?.value === "buy") && (
              <div className="ads__popup-select">
                <p>Межбанк</p>
                <CustomSelect
                  options={interbanksData}
                  selected={interbankSelect}
                  handleSelect={(e) => {
                    setInterbankSelect(e ?? null);
                  }}
                  isClearable={true}
                  isSearch={false}
                />
              </div>
            )}
            <div className="ads__popup-textarea">
              <textarea
                value={comment}
                placeholder={
                  languageState === "false"
                    ? language?.ads?.enter_comment_ru
                    : language?.ads?.enter_comment_en
                }
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                maxLength={50}
              />
            </div>
            <div className="ads__popup-btns">
              <div className="ads__popup-btn">
                <Button
                  text={
                    languageState === "false"
                      ? language?.ads?.cancel_ru
                      : language?.ads?.cancel_en
                  }
                  type="light"
                  topPadding={10}
                  onClick={closeModal}
                  bottomPadding={10}
                />
              </div>
              <div className="ads__popup-btn">
                <Button
                  disabled={
                    adInfo !== null && typeSelect?.value !== "buy"
                      ? false
                      : adInfo === null && typeSelect?.value !== "buy"
                      ? isButtonDisabled
                      : isButtonDisabledBuy
                  }
                  key={cardNumber.value}
                  type="accept"
                  text={
                    languageState === "false"
                      ? language?.ads?.save_ru
                      : language?.ads?.save_en
                  }
                  onClick={() => {
                    if (adInfo) {
                      editSendData();
                    }
                  }}
                  topPadding={10}
                  bottomPadding={10}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {editMaxOrderAmountPerHourModal && (
        <Popup
          title={"Изменить макс. объем за час"}
          closePopup={closeEditMaxOrderAmountModal}
        >
          <div className="add-limit__inner mb-10 mt-10">
            <Input
              placeholder="Макс. объем за час"
              type="text"
              label="Макс. объем за час"
              id="max_order_amount_per_hour"
              {...maxOrderAmountPerHour}
              onlyNumber={true}
            />
          </div>
          <Button
            disabled={maxOrderAmountPerHour?.value !== "" ? false : true}
            text={"Сохранить"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={editMaxAmountPerHour}
          />
        </Popup>
      )}
      {addMinLimitModal && (
        <Popup
          title={"Добавить минимальный лимит"}
          closePopup={closeAddMinLimitModal}
        >
          <div className="add-deposit__inner mt8 mb16">
            <Input
              label="Минимальный лимит"
              placeholder="Введите число"
              type="number"
              id="add"
              value={addMinLimitNumber}
              handleChange={(e) => setAddMinLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={addMinLimitNumber !== null ? false : true}
            text={"Добавить"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={addMinLimit}
          />
        </Popup>
      )}
      {subMinLimitModal && (
        <Popup
          title={"Вычесть минимальный лимит"}
          closePopup={closeSubMinLimitModal}
        >
          <div className="add-deposit__inner mt8 mb16">
            <Input
              label="Минимальный лимит"
              placeholder="Введите число"
              type="number"
              id="add"
              value={subMinLimitNumber}
              handleChange={(e) => setSubMinLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={subMinLimitNumber !== null ? false : true}
            text={"Вычесть"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={subMinLimit}
          />
        </Popup>
      )}
      {addMaxLimitModal && (
        <Popup
          title={"Добавить максимальный лимит"}
          closePopup={closeAddMaxLimitModal}
        >
          <div className="add-deposit__inner mt8 mb16">
            <Input
              label="Максимальный лимит"
              placeholder="Введите число"
              type="number"
              id="add"
              value={addMaxLimitNumber}
              handleChange={(e) => setAddMaxLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={addMaxLimitNumber !== null ? false : true}
            text={"Добавить"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={addMaxLimit}
          />
        </Popup>
      )}
      {subMaxLimitModal && (
        <Popup
          title={"Вычесть максимальный лимит"}
          closePopup={closeSubMaxLimitModal}
        >
          <div className="add-deposit__inner mt8">
            <Input
              label="Максимальный лимит"
              placeholder="Введите число"
              type="number"
              id="add"
              value={subMaxLimitNumber}
              handleChange={(e) => setSubMaxLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={subMaxLimitNumber?.length > 0 ? false : true}
            text={"Вычесть"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={subMaxLimit}
          />
        </Popup>
      )}
      {/*<div className="ads__top-search">*/}
      {/*    <SearchComponent*/}
      {/*        searchTerm={search}*/}
      {/*        onSearch={handleSearch}*/}
      {/*        placeholder={languageState === 'false' ? language?.general?.search_ru : language?.general?.search_en}*/}
      {/*        text={languageState === 'false' ? `Найдено ${search !== '' ? sellData?.length : sellData?.length} объявлениe(й)` : `${search !== '' ? sellData?.length : sellData?.length} ads found`}*/}
      {/*    />*/}
      {/*</div>*/}
      {/*<div key={selectedBanks} className="ads__btns mobile-time ads__btns-mobile">*/}
      {/*    <div className="ads__btn">*/}
      {/*        <Button*/}
      {/*            text={languageState === 'false' ? language?.ads?.create_ad_ru : language?.ads?.create_ad_en}*/}
      {/*            topPadding={6}*/}
      {/*            type='accept'*/}
      {/*            bottomPadding={6}*/}
      {/*            bottomPadding={6}*/}
      {/*            onClick={openModal}*/}
      {/*            imglink='/assets/icons/plus.svg'*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="orders__tabs orders__tabs_service">
        <div
          onClick={() => {
            if (tabService) {
              lastCreated.current = null;
              dispatch(setSellAdsEmpty([]));
            }
            setTabService(false);
          }}
          className={`orders__tab ${!tabService ? "orders__tab_active" : ""}`}
        >
          <p>Простые</p>
        </div>
        <div
          onClick={() => {
            if (!tabService) {
              lastCreated.current = null;
              dispatch(setSellAdsEmpty([]));
            }
            setTabService(true);
          }}
          className={`orders__tab ${tabService ? "orders__tab_active" : ""}`}
        >
          <p>Сервисные</p>
        </div>
      </div>
      <div className="users__top-inner ads__top_width">
        <div className="orders__tabs ads__tabs_width">
          <div
            onClick={() => {
              if (tab) {
                lastCreated.current = null;
                dispatch(setSellAdsEmpty([]));
              }
              setTab(false);
            }}
            className={`orders__tab ${!tab ? "orders__tab_active" : ""}`}
          >
            <p>
              {languageState === "false"
                ? language?.ads?.sell_ru
                : language?.ads?.sell_en}
            </p>
          </div>
          <div
            onClick={() => {
              if (!tab) {
                lastCreated.current = null;
                dispatch(setSellAdsEmpty([]));
              }
              setTab(true);
            }}
            className={`orders__tab ${tab ? "orders__tab_active" : ""}`}
          >
            <p>
              {languageState === "false"
                ? language?.ads?.buy_ru
                : language?.ads?.buy_en}
            </p>
          </div>
          <div className="orders__select ads__select_width">
            {!tabService ? (
              <CustomSelect
                options={fullTraders
                  ?.filter((item) => !item?.is_service)
                  ?.map((user) => {
                    return {
                      value: user.id,
                      label: user.notice !== null ? user.notice : user.login,
                    };
                  })}
                placeholder={"Выберите трейдера"}
                selected={usersSelect}
                handleSelect={(e) => {
                  setUsersSelect(e ?? null);
                  dispatch(setSellAdsEmpty([]));
                }}
                isClearable={true}
              />
            ) : (
              <CustomSelect
                options={serviceUsers
                  ?.filter((item) => item?.is_service === true)
                  ?.map((user) => {
                    return {
                      value: user.id,
                      label: user.notice !== null ? user.notice : user.login,
                    };
                  })}
                placeholder={"Выберите трейдера"}
                selected={usersSelect}
                handleSelect={(e) => {
                  setUsersSelect(e ?? null);
                  dispatch(setSellAdsEmpty([]));
                }}
                isClearable={true}
              />
            )}
          </div>
          <div className="orders__select ads__select_width">
            <CustomSelect
              options={statusData}
              placeholder={"Выберите статус"}
              selected={statusSelect}
              handleSelect={(e) => {
                setStatusSelect(e ?? null);
                dispatch(setSellAdsEmpty([]));
              }}
              isClearable={true}
              isSearchable={false}
            />
          </div>
          <div className="orders__select ads__select_width">
            <CustomSelect
              options={categoryData}
              placeholder={"Выберите категорию"}
              selected={categorySelect}
              handleSelect={(e) => {
                setCategorySelect(e ?? null);
                dispatch(setSellAdsEmpty([]));
              }}
              isClearable={true}
              isSearchable={false}
            />
          </div>
          <div className="orders__input w-200">
            <SearchComponent
              searchTerm={requisiteValue}
              onSearch={handleRequisiteValue}
              placeholder={"Реквизиты объявления"}
            />
          </div>
          <div className="orders__select ads__select_width">
            <CustomSelect
              options={requisiteData}
              placeholder={"Выберите тип реквизитов"}
              selected={requisiteSelect}
              handleSelect={(e) => {
                setRequisiteSelect(e ?? null);
                dispatch(setSellAdsEmpty([]));
              }}
              isClearable={true}
              isSearchable={false}
            />
          </div>
          <div className="orders__select ads__select_width">
            <CustomSelect
              options={currencies.map((currency) => {
                return {
                  value: currency.id,
                  label: currency.currency,
                };
              })}
              placeholder={"Выберите валюту"}
              selected={currencySelect}
              handleSelect={(e) => {
                setcurrencySelect(e ?? null);
                dispatch(setSellAdsEmpty([]));
              }}
              isClearable={true}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      <div className="ads__list">{renderSellAds()}</div>
      <div className="loadMore">
        <p
          onClick={() => {
            loadMore();
          }}
        >
          Загрузить еще...
        </p>
      </div>
    </div>
  );
}
