import React, { useEffect, useState } from "react";
import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {setLanguage, setLocalization, setNavBar, setTheme} from "../../Store/Slice/unitradeSlice";
import {useLocation} from "react-router-dom";

export default function Header({title}) {
  const location = useLocation()
  const dispatch = useDispatch();
  const navBar = useSelector(
    (state) => state.rootReducer.unitradeSlice.navBar
  );
  const theme = useSelector(
    (state) => state.rootReducer.unitradeSlice.theme
  );
  const [languageState, setLanguageState] = useState(false)

  useEffect(() => {
    if((localStorage.getItem('localization') == null || localStorage.getItem('localization') === 'false')) {
      localStorage.setItem('localization', 'false')
    }
  }, [])

  useEffect(() => {
    if(window.innerWidth <= 500) {
      dispatch(setNavBar(false))
    }
  }, [location])

  useEffect(() => {
    setLanguageState(localStorage.getItem('localization'))
  }, [localStorage.getItem('localization')])

  return (
    <div className="header">
      <div className="container">
        <div className="header__inner">
            <div className="header__left">
              {location?.pathname === '/login' && (
                <p className='header__logo'>UnitPay</p>
              )}
              {location?.pathname !== '/login' && (
              <div onClick={() => {
                dispatch(setNavBar(!navBar))
                if(window.innerWidth <= 500) {
                  document.querySelector('.nav__wrapper-right').style.display = 'none'
                }
              }} className={`header__btn ${navBar ? 'header__btn_active' : ''}`}>
                <div className="header__btn-line"></div>
                <div className="header__btn-line header__btn-line_small"></div>
                <div className="header__btn-line"></div>
              </div>
              )}
              <p key={title} className={`header__title ${theme ? 'header__title_dark' : ''}`}>{title}</p>
            </div>
          <div className="header__right">
            <div onClick={() => {
              dispatch(setTheme(!theme))
              localStorage.setItem('theme', localStorage.getItem('them') === 'true' ? 'false' : 'true')
            }}
                 className="header__theme-btn">
              <img className={`header__theme-img ${!theme ? 'header__theme-img_active' : ''}`} src="/assets/icons/solnce.svg" alt=""/>
              <img className={`header__theme-img ${theme ? 'header__theme-img_active moon' : ''}`} src="/assets/icons/moon.svg" alt=""/>
            </div>
              <div onClick={() => {
                dispatch(setLocalization(languageState === 'true' ? 'false' : 'true'))
                dispatch(setLanguage({
                  general: {
                    search_ru: 'Поиск...',
                    login_ru: 'Войти',
                    token_ru: 'Токен',
                    code_ru: 'Код authenticator',
                    total_orders_ru: 'выполненных заявок:',
                    total_amount_ru: 'общий оборот в',
                    filter_ru: 'Фильтр',
                    search_id_ru: 'Поиск по id',
                    search_amount_ru: 'Поиск по сумме',
                    search_card_ru: 'Поиск по номеру карты',
                    search_date_ru: 'Поиск по дате',
                    search_status_ru: 'Поиск по статусу',
                    recalculation_amount_ru: 'Введите сумму перерасчета',
                    recalculation_amount_label_ru: 'Сумма перерасчета',
                    total_orders_en: 'of orders completed:',
                    total_amount_en: 'total turnover in',
                    search_id_en: 'Search by id',
                    search_amount_en: 'Search by amount',
                    search_card_en: 'Search by card number',
                    search_date_en: 'Search by date',
                    search_status_en: 'Search by status',
                    recalculation_amount_en: 'Enter recalculation amount',
                    recalculation_amount_label_en: 'Recalculation amount',
                    filter_en: 'Filter',
                    search_en: 'Search...',
                    login_en: 'Login in',
                    token_en: 'Token',
                    code_en: 'Authenticator code',
                  },
                  empty: {
                    sell_empty_ru: 'У вас нет активных заявок',
                      sell_search_empty_ru: 'Заявок не найдено',
                      appeal_empty_ru: 'У вас нет активных апелляций',
                      appeal_search_empty_ru: 'Апелляций не найдено',
                      history_empty_ru: 'У вас нет заявок в истории',
                      history_search_empty_ru: '',
                      ads_empty_ru: 'У вас нет добавленных объявлений',
                      ads_search_empty_ru: 'Объявлений не найдено',
                      sell_empty_en: `You don't have any active orders`,
                      sell_search_empty_en: 'No orders found',
                      appeal_empty_en: `You don't have any active appeals`,
                      appeal_search_empty_en: 'No appeals found',
                      history_empty_en: `You don't have any applications in your history`,
                      history_search_empty_en: '',
                      ads_empty_en: `You don't have any ads added`,
                      ads_search_empty_en: 'No ads found',
                  },
                  nav_bar: {
                    profile_ru: 'Профиль',
                    sell_ru: 'Продажа USDT',
                    buy_ru: 'Покупка USDT',
                    history_ru: 'История заявок',
                    appeal_ru: 'Аппеляции',
                    ads_ru: 'Объявления',
                    automatic_ru: 'Автоматика',
                    settings_ru: 'Настройки',
                    wallet_ru: 'Кошелек',
                    details_ru: 'Детали заявки',
                    logout_ru: 'Выйти',
                    profile_en: 'Profile',
                    sell_en: 'Sell USDT',
                    buy_en: 'Buy USDT',
                    history_en: 'Orders history',
                    appeal_en: 'Appeals',
                    ads_en: 'Ads',
                    automatic_en: 'Automation',
                    settings_en: 'Settings',
                    wallet_en: 'Сurrencies',
                    details_en: 'Order details',
                    logout_en: 'Log Out',
                  },
                  order: {
                    requisites_ru: 'Реквизиты',
                      sender_ru: 'Отправитель',
                      receive_details_ru: 'К получению',
                      receive_ru: 'Получаем',
                      receive_or_sending_ru: 'Получаем/Отправляем',
                      sending_details_ru: 'К отправлению',
                      sending_ru: 'Отправляем',
                      recipient_ru: 'Получатель',
                      recipient_or_sender_ru: 'получатель/отправитель',
                      created_date_ru: 'Дата создания',
                      completed_date_ru: 'Дата завершения',
                      actions_ru: 'Действие',
                      accept_order_ru: 'Подтвердить заявку',
                      accept_order_text_ru: 'Подтвердите что вы перевели средства',
                      accept_sell_order_ru: 'Принять заявку',
                      accept_sell_order_text_ru: 'Вы уверены что хотите принять заявку?',
                      accept_sell_order_btn_ru: 'Принять',
                      accept_order_btn_ru: 'Подтвердить',
                      cancel_order_ru: 'Отменить заявку',
                      cancel_order_text_ru: 'Вы уверены что хотите отменить заявку?',
                      cancel_order_btn_ru: 'Отменить заявку',
                      recalculation_order_ru: 'Перерасчет',
                      recalculation_order_text_ru: 'Вы уверены что хотите сделать перерасчет?',
                      recalculation_order_btn_ru: 'Перерасчитать',
                      file_order_text_ru: 'Прикрепить (чек, выписку...)',
                      files_details_text_ru: 'Прикрепленные файлы',
                      files_empty_text_ru: 'Нет прикрепленных файлов',
                      status_ru: 'Статус',
                      rating_ru: 'Рейтинг',
                      requisites_en: 'Requisites',
                      sender_en: 'Sender',
                      receive_en: 'Receive',
                      sending_en: 'Sending',
                      recipient_en: 'Recipient',
                      recipient_or_sender_en: 'Recipient/\nSender',
                      receive_or_sending_en: 'Receive/\nSending',
                      created_date_en: 'Created date',
                      completed_date_en: 'Completed date',
                      actions_en: 'Actions',
                      accept_order_en: 'Confirm order',
                      accept_order_text_en: 'Confirm that you have transferred funds',
                      accept_sell_order_en: 'Accepted order',
                      accept_sell_order_text_en: 'Are you sure you want to accept the order?',
                      accept_sell_order_btn_en: 'Accept',
                      accept_order_btn_en: 'Confirm',
                      cancel_order_en: 'Rejected order',
                      cancel_order_text_en: 'Are you sure you want to cancel the order?',
                      cancel_order_btn_en: 'Reject order',
                      recalculation_order_en: 'Recalculation',
                      recalculation_order_text_en: 'Are you sure you want to recalculate?',
                      recalculation_order_btn_en: 'Recalculate',
                      file_order_text_en: 'Attach (receipt, statement...)',
                      status_en: 'Status',
                      rating_en: 'Rating',
                      files_details_text_en: 'Attached files',
                      files_empty_text_en: 'No attached files',
                  },
                  ads: {
                    sell_ru: 'Продажа',
                    buy_ru: 'Покупка',
                    create_ad_ru: 'Создать объявление',
                    fee_ru: 'Ставка',
                    make_ads_ru: 'Вкл/Выкл объявления',
                    edit_ad_ru: 'Редактировать объявление',
                    type_ru: 'Тип',
                    payment_ru: 'Способ оплаты',
                    card_number_ru: 'Номер карты',
                    client_card_number_ru: 'Номер карты клиента',
                    card_holder_ru: 'ФИО владельца карты',
                    max_limit_ru: 'Max лимит',
                    min_limit_ru: 'Min лимит',
                    enter_card_number_ru: 'Введите номер карты',
                    enter_card_holder_ru: 'Введите ФИО',
                    enter_max_limit_ru: 'Введите max лимит',
                    enter_min_limit_ru: 'Введите min лимит',
                    enter_comment_ru: 'Введите комментарий к объявлению',
                    save_ru: 'Сохранить',
                    cancel_ru: 'Отменить',
                    delete_ru: 'Удалить',
                    deleted_ad_ru: 'Удалить объявление',
                    deleted_devise_ru: 'Удалить девайс',
                    delete_text_ru: 'Вы уверены что хотите удалить объявление?',
                    delete_text_devise_ru: 'Вы уверены что хотите удалить девайс?',
                    sell_en: 'Sell',
                    buy_en: 'Buy',
                    create_ad_en: 'Created ad',
                    fee_en: 'Bet',
                    make_ads_en: 'On/Off ads',
                    edit_ad_en: 'Edited ad',
                    type_en: 'Type',
                    payment_en: 'Payment method',
                    card_number_en: 'Card number',
                    client_card_number_en: 'Client curd number',
                    card_holder_en: 'Card holder',
                    max_limit_en: 'Max limit',
                    min_limit_en: 'Min limit',
                    enter_card_number_en: 'Enter card number',
                    enter_card_holder_en: 'Enter card holder',
                    enter_max_limit_en: 'Enter max limit',
                    enter_min_limit_en: 'Enter min limit',
                    enter_comment_en: 'Enter a comment to the ad',
                    save_en: 'Save',
                    cancel_en: 'Cancel',
                    delete_en: 'Delete',
                    deleted_ad_en: 'Deleted ad',
                    deleted_devise_en: 'Deleted devise',
                    delete_text_en: 'Are you sure you want to delete the ad?',
                    delete_text__devise_en: 'Are you sure you want to delete the devise?'
                  },
                }))
                localStorage.setItem('localization', localStorage.getItem('localization') === 'true' ? 'false' : 'true')
                setLanguageState(!languageState)
            }} className="header__theme-btn">
              <img className={`header__theme-img ${languageState === 'false'  ? 'header__theme-img_active' : ''}`} src="/assets/icons/ru.svg" alt="ru"/>
              <img className={`header__theme-img ${languageState === 'true' ? 'header__theme-img_active moon' : ''}`} src="/assets/icons/en.svg" alt="en"/>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
