import { useEffect, useState, useRef } from "react";
import Header from "../../Components/Header";
import { CustomSelect } from "../../UI/Select";
import Button from "../../UI/Button";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css?version="1"';
import ApiCurrencies from "../../Global/Api/currencies.api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DateRangePicker from "rsuite/DateRangePicker";
import Toggle from "rsuite/Toggle";
import ApiStatistics from "../../Global/Api/statistics.api";
import ApiUsers from "../../Global/Api/users.api";
import { setOrdersEmpty } from "../../Store/Slice/unitradeSlice";
import CryptoWalletsApi from "../../Global/Api/cryptoWallets.api";
import SearchComponent from "../../Components/Search";
import { setStatisticsOrders } from "../../Store/Slice/unitradeSlice";
import "./index.scss";

export default function Statistics() {
  const dispatch = useDispatch();
  const statisticsApi = ApiStatistics();
  const usersApi = ApiUsers();
  const [statusSelect, setStatusSelect] = useState(null);
  const [typeSelect, setTypeSelect] = useState(null);
  const [typeSelectTwo, setTypeSelectTwo] = useState(null);
  const [typeSelectTri, setTypeSelectTri] = useState(null);
  const currenciesApi = ApiCurrencies();
  const [currencySelect, setcurrencySelect] = useState(null);
  const [currencySelectStatistic, setcurrencySelectStatistic] = useState(null);
  const [currencySelectTri, setcurrencySelectTri] = useState(null);
  const [value, setValue] = useState(null);
  const [valueTwo, setValueTwo] = useState(null);
  const [valueCancel, setValueCancel] = useState(null);
  const [valueTri, setValueTri] = useState(null);
  const [checked, setChecked] = useState(false);
  const [checkedMobile, setCheckedMobile] = useState(false);
  const [timeSelect, setTimeSelect] = useState(null);
  const [usersSelect, setUsersSelect] = useState(null);
  const [usersSelectTwo, setUsersSelectTwo] = useState(null);
  const [avgTypeSelect, setAvgTypeSelect] = useState(null);
  const apiCryptoWallets = CryptoWalletsApi();
  const [tab, setTab] = useState(false);
  const [bonusSelect, setBonusSelect] = useState(null);
  const [bonusSelectTri, setBonusSelectTri] = useState(null);
  const [rejectSelect, setRejectSelect] = useState(null);
  const [usersMerchantSelect, setUsersMerchantSelect] = useState(null);
  const [usersMerchantSelectTri, setUsersMerchantSelectTri] = useState(null);
  const [callbackFlagSelect, setCallbackFlagSelect] = useState(null);
  const [warningMessageSelect, setWarningMessageSelect] = useState(null);
  const [categorySelect, setCategorySelect] = useState(null);
  const [requisiteTypeSelect, setRequisiteTypeSelect] = useState(null);
  const [clientId, setClientId] = useState("");
  const [requisiteValue, setRequisiteValue] = useState("");
  const [tabService, setTabService] = useState(false);
  const [usersSelectService, setUsersSelectService] = useState(null);
  const [usersSelectCancel, setUsersSelectCancel] = useState(null);
  const [requisiteSelect, setRequisiteSelect] = useState(null);
  const [currencySelectAvgUsdt, setCurrencySelectAvgUsdt] = useState(null);
  const [usersSelectAvgUsdt, setUsersSelectAvgUsdt] = useState(null);
  const [valueAvgUsdt, setValueAvgUsdt] = useState(null);


  const requisiteData = [
    {
      value: "card",
      label: "По картам",
    },
    {
      value: "phone",
      label: "По СБП",
    },
    {
      value: "transfer",
      label: "По счетам",
    },
  ];

  const callbackFlagData = [
    {
      value: "not_sent",
      label: "без кб",
    },
    {
      value: "successfully",
      label: "с кб",
    },
    {
      value: "unsuccessfully",
      label: "ошибка с кб",
    },
  ];
  const warningMessageData = [
    {
      value: true,
      label: "Да",
    },
    {
      value: false,
      label: "Нет",
    },
  ];
  const bonusData = [
    {
      value: true,
      label: "Межбанк",
    },
    {
      value: false,
      label: "Не межбанк",
    },
  ];
  const rejectData = [
    {
      label: "Клиент указал неверный банк",
      value: "Клиент указал неверный банк",
    },
    {
      label: "Карта заблокирована",
      value: "Карта заблокирована",
    },
    {
      label: "Высокий риск",
      value: "Высокий риск",
    },
  ];
  const [timeData, setTimeData] = useState([
    {
      value: "created",
      label: "Created",
    },
    {
      value: "updated",
      label: "Updated",
    },
    {
      value: "completed",
      label: "Completed",
    },
  ]);
  const categoryData = [
    {
      value: "p2p",
      label: "P2P",
    },
    {
      value: "c2c",
      label: "C2C",
    },
  ];
  const requisiteTypes = [
    {
      value: "card",
      label: "Card",
    },
    {
      value: "phone",
      label: "Phone",
    },
    {
      value: "transfer",
      label: "Transfer",
    },
    {
      value: "wallet",
      label: "Wallet",
    },
  ];
  const {
    currencies,
    statisticData,
    fullTraders,
    cryptoWallet,
    statisticsOrders,
    avgCompleted,
    cancelPercent,
    statisticsRejectOrders,
    tradersKPI,
    tradersKPIArray,
    usersMerchant,
    avgUsdt,
  } = useSelector((state) => state.rootReducer.unitradeSlice);
  const users = fullTraders?.filter((item) => item?.is_service === false);
  const serviceTraders = fullTraders?.filter(
    (item) => item?.is_service === true
  );
  const [statusData, setStatusData] = useState([
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "success",
      label: "Success",
    },
    {
      value: "cancel",
      label: "Cancel",
    },
    {
      value: "appeal",
      label: "Appeal",
    },
  ]);
  const [typeData, setTypeData] = useState([
    {
      value: "buy",
      label: "Buy",
    },
    {
      value: "sell",
      label: "Sell",
    },
  ]);
  const avgTypes = [
    {
      value: "normal",
      label: "Normal",
    },
    {
      value: "appeal",
      label: "Appeal",
    },
  ];
  useEffect(() => {
    const formattedParamsUsers = formatParamsUsers();
    currenciesApi.getData();
    usersApi.getFullTraders(formattedParamsUsers);
    const formattedParamsUsersMerchant = formatParamsUsersMerchant();
    usersApi.getData(formattedParamsUsersMerchant, "merchant");
  }, []);

  console.log('statisticData', statisticData);
  const formatParamsUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    return params;
  };

  const formatParamsUsersMerchant = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "merchant_server");
    return params;
  };

  const formatParams = () => {
    const params = new URLSearchParams();
    if (value !== null)
      params.append(
        "date_from",
        moment(value[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (value !== null)
      params.append(
        "date_to",
        moment(value[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (bonusSelect !== null) params.append("bonus_order", bonusSelect.value);
    if (statusSelect !== null) params.append("order_stage", statusSelect.value);
    if (typeSelect !== null) params.append("order_type", typeSelect.value);
    if (timeSelect !== null) params.append("time_filer", timeSelect.value);
    if (usersMerchantSelect !== null)
      params.append("merchant_id", usersMerchantSelect.value);
    if (warningMessageSelect !== null)
      params.append("warning_message", warningMessageSelect.value);
    if (callbackFlagSelect !== null)
      params.append("callback_flag", callbackFlagSelect.value);
    if (requisiteSelect !== null)
      params.append("requisite_type", requisiteSelect.value);
    if (currencySelect !== null)
      params.append("currency_id", currencySelect.value);
    if (usersSelect !== null) params.append("trader_id", usersSelect.value);
    return params;
  };

  const formatParamsCancelPercent = () => {
    const params = new URLSearchParams();
    if (valueCancel !== null)
      params.append(
        "date_from",
        moment(valueCancel[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (valueCancel !== null)
      params.append(
        "date_to",
        moment(valueCancel[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (rejectSelect !== null) params.append("reason", rejectSelect.value);
    if (typeSelectTwo !== null)
      params.append("order_type", typeSelectTwo.value);
    if (usersSelectCancel !== null)
      params.append("trader_id", usersSelectCancel.value);
    return params;
  };

  const formatParamsAvg = () => {
    const params = new URLSearchParams();
    if (valueTwo !== null)
      params.append(
        "date_from",
        moment(valueTwo[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (valueTwo !== null)
      params.append(
        "date_to",
        moment(valueTwo[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (avgTypeSelect !== null) params.append("avg_type", avgTypeSelect.value);
    if (usersSelectService !== null)
      params.append("trader_id", usersSelectService.value);
    return params;
  };

  const formatParamsStatistics = () => {
    const params = new URLSearchParams();
    if (usersSelectTwo !== null)
      params.append("trader_id", usersSelectTwo.value);
    return params;
  };

  const formatParamsStatisticsService = () => {
    const params = new URLSearchParams();
    if (usersSelectTwo !== null)
      params.append("trader_id", usersSelectTwo.value);
    if (currencySelectStatistic !== null)
      params.append("currency_id", currencySelectStatistic.value);
    return params;
  };

  const formatParamsReject = () => {
    const params = new URLSearchParams();
    if (valueTri !== null)
      params.append(
        "date_from",
        moment(valueTri[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (valueTri !== null)
      params.append(
        "date_to",
        moment(valueTri[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (typeSelectTri !== null)
      params.append("order_type", typeSelectTri.value);
    if (categorySelect !== null)
      params.append("order_category", categorySelect.value);
    if (requisiteTypeSelect !== null)
      params.append("requisite_type", requisiteTypeSelect.value);
    if (bonusSelectTri !== null)
      params.append("bonus_order", bonusSelectTri.value);
    if (requisiteValue !== "") params.append("requisite", requisiteValue);
    if (clientId !== "") params.append("client_id", clientId);
    if (usersMerchantSelectTri !== null)
      params.append("merchant_id", usersMerchantSelectTri.value);
    if (currencySelectTri !== null)
      params.append("currency", currencySelectTri.label);
    return params;
  };

  const formatParamsAvgUsdt = () => {
    const params = new URLSearchParams();
    if (valueAvgUsdt !== null)
      params.append(
        "date_from",
        moment(valueAvgUsdt[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (valueAvgUsdt !== null)
      params.append(
        "date_to",
        moment(valueAvgUsdt[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (usersSelectAvgUsdt !== null)
      params.append("trader_id", usersSelectAvgUsdt.value);
    if (currencySelectAvgUsdt !== null)
      params.append("currency_id", currencySelectAvgUsdt.value);
    return params;
  };

  const comeStatistic = () => {
    const formattedParams = formatParams();
    statisticsApi.getData(formattedParams);
    if (usersSelect !== null && !usersSelect?.is_service) {
      const body = {
        wallet_id: usersSelect.value,
      };

      apiCryptoWallets.getData(body);
    }
  };

  const comeStatisticOrders = () => {
    const formattedParamsStatistics = formatParamsStatistics();
    statisticsApi.getStatisticsOrders(formattedParamsStatistics);
  };

  const comeStatisticOrdersService = () => {
    const formattedParamsStatistics = formatParamsStatisticsService();
    statisticsApi.getStatisticsServiceOrders(formattedParamsStatistics);
  };

  const comeStatisticAvg = () => {
    const formattedParamsAvg = formatParamsAvg();
    statisticsApi.getAvgCompleted(formattedParamsAvg);
  };

  const comeStatisticCancel = () => {
    const formattedParamsCancelPercent = formatParamsCancelPercent();
    statisticsApi.getCancelPercent(formattedParamsCancelPercent);
  };

  const comeStatisticReject = () => {
    const formattedParamsReject = formatParamsReject();
    statisticsApi.getRejectOrdersStatistics(formattedParamsReject);
  };

  const handleSearchId = (searchTerm) => {
    setClientId(searchTerm);
  };
  const handleSearchRequisite = (searchTerm) => {
    setRequisiteValue(searchTerm);
  };

  const comeStatisticAvgUsdt = () => {
    const formattedParamsAvgUsdt = formatParamsAvgUsdt();
    statisticsApi.getAvgUsdt(formattedParamsAvgUsdt)
  }

  return (
    <div className="statistics container">
      <Header title={"Статистика"} />
      <div className="orders__select">
        <div className="popup-select orders__head-select">
          <CustomSelect
            options={requisiteData}
            placeholder={"Выберите тип реквизитов"}
            selected={requisiteSelect}
            handleSelect={(e) => {
              setRequisiteSelect(e ?? null);
              dispatch(setOrdersEmpty([]));
            }}
            isClearable={true}
          />
        </div>
      </div>
      <div className="orders__selects">
        <div className="orders__select">
          <CustomSelect
            options={timeData}
            placeholder={"Выберите тип времени"}
            selected={timeSelect}
            handleSelect={(e) => {
              setTimeSelect(e ?? null);
            }}
            isClearable={true}
          />
        </div>
        <div className="orders__select">
          <CustomSelect
            options={fullTraders?.map((user) => {
              return {
                value: user.id,
                label: user.notice !== null ? user.notice : user.login,
                is_service: user?.is_service,
              };
            })}
            placeholder={"Выберите трейдера"}
            selected={usersSelect}
            handleSelect={(e) => {
              setUsersSelect(e ?? null);
              dispatch(setOrdersEmpty([]));
            }}
            isClearable={true}
          />
        </div>
        <div className="orders__select">
          <CustomSelect
            options={usersMerchant?.map((user) => {
              return {
                value: user.id,
                label: user.notice !== null ? user.notice : user.login,
              };
            })}
            placeholder={"Выберите мерчанта"}
            selected={usersMerchantSelect}
            handleSelect={(e) => {
              setUsersMerchantSelect(e ?? null);
              dispatch(setOrdersEmpty([]));
            }}
            isClearable={true}
          />
        </div>
        <div className="orders__select">
          <CustomSelect
            options={warningMessageData}
            placeholder={"Warning message"}
            selected={warningMessageSelect}
            handleSelect={(e) => {
              setWarningMessageSelect(e ?? null);
              dispatch(setOrdersEmpty([]));
            }}
            isClearable={true}
          />
        </div>
        <div className="orders__select">
          <CustomSelect
            options={callbackFlagData}
            placeholder={"Выберите тип кб"}
            selected={callbackFlagSelect}
            handleSelect={(e) => {
              setCallbackFlagSelect(e ?? null);
              dispatch(setOrdersEmpty([]));
            }}
            isClearable={true}
          />
        </div>
        <div className="orders__select">
          <CustomSelect
            options={bonusData}
            placeholder={"Выберите тип межбанка"}
            selected={bonusSelect}
            handleSelect={(e) => {
              setBonusSelect(e ?? null);
              dispatch(setOrdersEmpty([]));
            }}
            isClearable={true}
          />
        </div>
      </div>
      <div className="statistics__top">
        <div className="statistics__select">
          <CustomSelect
            options={typeData}
            placeholder={"Выберите тип"}
            selected={typeSelect}
            handleSelect={(e) => {
              setTypeSelect(e ?? null);
            }}
            isClearable={true}
          />
        </div>
        <div className="statistics__select">
          <CustomSelect
            options={statusData}
            placeholder={"Выберите статус"}
            selected={statusSelect}
            handleSelect={(e) => {
              setStatusSelect(e ?? null);
            }}
            isClearable={true}
          />
        </div>
        <DateRangePicker onChange={setValue} format="dd.MM.yyyy HH:mm" />
        <div className="statistics__select">
          <CustomSelect
            options={currencies.map((currency) => {
              return {
                value: currency.id,
                label: currency.currency,
              };
            })}
            placeholder={"Выберите валюту"}
            selected={currencySelect}
            id="currency"
            handleSelect={(e) => {
              setcurrencySelect(e ?? null);
            }}
            isClearable={true}
          />
        </div>
        <div className="statistics__btn">
          <Button
            text={"Получить статистику"}
            type={"accept"}
            disabled={currencySelect === null ? true : false}
            onClick={() => {
              comeStatistic();
            }}
            topPadding={10}
            bottomPadding={10}
          />
        </div>
      </div>
      <div className="statistics__inner">
        <p className="statistics__text">
          Количество ордеров:{" "}
          {statisticData === null ? 0 : statisticData?.count_orders}
        </p>
        <p className="statistics__text">
          Общая сумма:{" "}
          {statisticData === null ? 0 : statisticData?.common_amount}
        </p>
        <p className="statistics__text">
          Комиссионные трейдеров:{" "}
          {statisticData === null ? 0 : statisticData?.common_trader_amount}
        </p>
        <p className="statistics__text">
          Комиссионные агентов мерчантов:{" "}
          {statisticData === null
            ? 0
            : statisticData?.common_agent_merchant_amount}
        </p>
        <p className="statistics__text">
          Комиссионные агентов трейдеров:{" "}
          {statisticData === null
            ? 0
            : statisticData?.common_agent_trader_amount}
        </p>
        <p className="statistics__text">
          Комиссионные системы:{" "}
          {statisticData === null
            ? 0
            : statisticData?.common_system_amount || '-'}
        </p>
        {cryptoWallet !== null && usersSelect !== null && (
          <div className="statistics__inner">
            <h2>Информация о кошельке трейдера</h2>
            <p className="statistics__text">
              Депозит трейдера: {cryptoWallet?.deposit}
            </p>
            <p className="statistics__text">
              Баланс трейдера: {cryptoWallet?.balance}
            </p>
            <p className="statistics__text">
              Замороженный баланс трейдера: {cryptoWallet?.frozen_balance}
            </p>
          </div>
        )}
        <hr />
        <div className="statistics__inner mt-40">
          <h2 className="mb-20">Статистика ордеров по трейдеру</h2>
          <div className="orders__tabs orders__tabs_service">
            <div
              onClick={() => {
                setcurrencySelectStatistic(null);
                setUsersSelectTwo(null);
                dispatch(setStatisticsOrders(null));
                setTabService(false);
              }}
              className={`orders__tab ${
                !tabService ? "orders__tab_active" : ""
              }`}
            >
              <p>Трейдеры</p>
            </div>
            <div
              onClick={() => {
                setcurrencySelectStatistic(null);
                setUsersSelectTwo(null);
                dispatch(setStatisticsOrders(null));
                setTabService(true);
              }}
              className={`orders__tab ${
                tabService ? "orders__tab_active" : ""
              }`}
            >
              <p>Сервисные трейдеры</p>
            </div>
          </div>
          <div className="statistics__top">
            <div className="statistics__select">
              <CustomSelect
                options={
                  !tabService
                    ? users?.map((user) => {
                        return {
                          value: user.id,
                          label:
                            user.notice !== null ? user.notice : user.login,
                        };
                      })
                    : serviceTraders?.map((user) => {
                        return {
                          value: user.id,
                          label:
                            user.notice !== null ? user.notice : user.login,
                        };
                      })
                }
                placeholder={"Выберите трейдера"}
                selected={usersSelectTwo}
                handleSelect={(e) => {
                  setUsersSelectTwo(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            {tabService && (
              <div className="statistics__select">
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  placeholder={"Выберите валюту"}
                  selected={currencySelectStatistic}
                  id="currency"
                  handleSelect={(e) => {
                    setcurrencySelectStatistic(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            )}
            <div className="statistics__btn">
              <Button
                text={"Получить статистику"}
                type={"accept"}
                disabled={
                  (!tabService && usersSelectTwo) === null
                    ? true
                    : tabService &&
                      (usersSelectTwo === null ||
                        currencySelectStatistic === null)
                    ? true
                    : false
                }
                onClick={() => {
                  if (tabService) {
                    comeStatisticOrdersService();
                  } else {
                    comeStatisticOrders();
                  }
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          </div>
          <p className="statistics__text mt-24">
            Количество ордеров на ввод:{" "}
            {statisticsOrders?.count_orders_buy
              ? statisticsOrders?.count_orders_buy
              : 0}
          </p>
          <p className="statistics__text">
            Количество ордеров на вывод:{" "}
            {statisticsOrders?.count_orders_sell
              ? statisticsOrders?.count_orders_sell
              : 0}
          </p>
          <p className="statistics__text">
            Сумма ордеров на ввод:{" "}
            {statisticsOrders?.common_amount_buy
              ? statisticsOrders?.common_amount_buy
              : 0}
          </p>
          <p className="statistics__text">
            Сумма ордеров на вывод:{" "}
            {statisticsOrders?.common_amount_sell
              ? statisticsOrders?.common_amount_sell
              : 0}
          </p>
          <p className="statistics__text">
            Валюта:{" "}
            {statisticsOrders?.currency?.code
              ? statisticsOrders?.currency?.code
              : "--- ---"}
          </p>
        </div>
        <hr />
        <div className="statistics__inner mt-40">
          <h2 className="mb-20">
            Статистика среднего времени выполнения заявок
          </h2>
          <div className="statistics__top">
            <div className="statistics__select">
              <CustomSelect
                options={fullTraders?.map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
                placeholder={"Выберите трейдера"}
                selected={usersSelectService}
                handleSelect={(e) => {
                  setUsersSelectService(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <DateRangePicker onChange={setValueTwo} format="dd.MM.yyyy HH:mm" />
            <div className="statistics__select">
              <CustomSelect
                options={avgTypes}
                placeholder={"Выберите тип"}
                selected={avgTypeSelect}
                handleSelect={(e) => {
                  setAvgTypeSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__btn">
              <Button
                text={"Получить статистику"}
                type={"accept"}
                onClick={() => {
                  comeStatisticAvg();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          </div>
          <p className="statistics__text mt-20">
            Среднее время выполнения заявок:{" "}
            {avgCompleted?.avg_time ? avgCompleted?.avg_time : 0}
          </p>
        </div>
        <hr />
        <div className="statistics__inner mt-40">
          <h2 className="mb-20">Статистика среднего курса USDT</h2>
          <div className="statistics__top">
            <div className="statistics__select">
              <CustomSelect
                options={currencies.map((currency) => {
                  return {
                    value: currency.id,
                    label: currency.currency,
                  };
                })}
                placeholder={"Выберите валюту"}
                selected={currencySelectAvgUsdt}
                id="currencyAvgUsdt"
                handleSelect={(e) => {
                  setCurrencySelectAvgUsdt(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__select">
              <CustomSelect
                options={fullTraders?.map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
                placeholder={"Выберите трейдера"}
                selected={usersSelectAvgUsdt}
                handleSelect={(e) => {
                  setUsersSelectAvgUsdt(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <DateRangePicker onChange={setValueAvgUsdt} format="dd.MM.yyyy HH:mm" />
            <div className="statistics__btn">
              <Button
                text={"Получить статистику"}
                type={"accept"}
                disabled={currencySelectAvgUsdt === null ? true : false}
                onClick={() => {
                  comeStatisticAvgUsdt();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          </div>
          <p className="statistics__text mt-20">
            Средний курс USDT:{" "}
            {avgUsdt?.avg_usdt_price ? avgUsdt?.avg_usdt_price : 0}
          </p>
        </div>
        <hr />
        <div className="statistics__inner mt-40">
          <h2 className="mb-20">Статистика по отменам</h2>
          <div className="statistics__top">
            <div className="statistics__select">
              <CustomSelect
                options={typeData}
                placeholder={"Выберите тип"}
                selected={typeSelectTwo}
                handleSelect={(e) => {
                  setTypeSelectTwo(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__select">
              <CustomSelect
                options={fullTraders?.map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
                placeholder={"Выберите трейдера"}
                selected={usersSelectCancel}
                handleSelect={(e) => {
                  setUsersSelectCancel(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <DateRangePicker
              onChange={setValueCancel}
              format="dd.MM.yyyy HH:mm"
            />
            <div className="statistics__select">
              <CustomSelect
                options={rejectData}
                placeholder={"Причина отмены"}
                selected={rejectSelect}
                handleSelect={(e) => {
                  setRejectSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__btn">
              <Button
                text={"Получить статистику"}
                type={"accept"}
                disabled={typeSelectTwo === null ? true : false}
                onClick={() => {
                  comeStatisticCancel();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          </div>
          <p className="statistics__text mt-20">
            Общее количесво отмененных заяков:{" "}
            {cancelPercent?.all_orders_count
              ? cancelPercent?.all_orders_count
              : 0}
          </p>
          <p className="statistics__text">
            Процент авто-отмен:{" "}
            {cancelPercent?.auto_cancel ? cancelPercent?.auto_cancel : 0} %
          </p>
          <p className="statistics__text">
            Процент отмен трейдером:{" "}
            {cancelPercent?.trader_cancel ? cancelPercent?.trader_cancel : 0} %
          </p>
        </div>
        <hr />
        <div className="statistics__inner mt-40">
          <h2>Статистика по отклоненным заявкам</h2>
          <div className="statistics__top">
            <div className="statistics__select">
              <CustomSelect
                options={typeData}
                placeholder={"Выберите тип"}
                selected={typeSelectTri}
                handleSelect={(e) => {
                  setTypeSelectTri(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__select">
              <CustomSelect
                options={categoryData}
                placeholder={"Выберите категорию"}
                selected={categorySelect}
                handleSelect={(e) => {
                  setCategorySelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__select">
              <CustomSelect
                options={requisiteTypes}
                placeholder={"Выберите тип реквизитов"}
                selected={requisiteTypeSelect}
                handleSelect={(e) => {
                  setRequisiteTypeSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__select">
              <CustomSelect
                options={usersMerchant?.map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
                placeholder={"Выберите мерчанта"}
                selected={usersMerchantSelectTri}
                handleSelect={(e) => {
                  setUsersMerchantSelectTri(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__select">
              <CustomSelect
                options={bonusData}
                placeholder={"Выберите тип межбанка"}
                selected={bonusSelectTri}
                handleSelect={(e) => {
                  setBonusSelectTri(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          </div>
          <div className="statistics__top">
            <div className="orders__input orders__input_w">
              <SearchComponent
                searchTerm={clientId}
                onSearch={handleSearchId}
                placeholder={"Введите ID клиента"}
              />
            </div>
            <div className="orders__input orders__input_w">
              <SearchComponent
                searchTerm={requisiteValue}
                onSearch={handleSearchRequisite}
                placeholder={"Введите реквизиты"}
              />
            </div>
            <DateRangePicker onChange={setValueTri} format="dd.MM.yyyy HH:mm" />
            <div className="statistics__select">
              <CustomSelect
                options={currencies.map((currency) => {
                  return {
                    value: currency.id,
                    label: currency.currency,
                  };
                })}
                placeholder={"Выберите валюту"}
                selected={currencySelectTri}
                id="currency"
                handleSelect={(e) => {
                  setcurrencySelectTri(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="statistics__btn">
              <Button
                text={"Получить статистику"}
                type={"accept"}
                onClick={() => {
                  comeStatisticReject();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          </div>
          <p className="statistics__text mt-24">
            Количество отклоненных заявок:{" "}
            {statisticsRejectOrders?.count_rejected_orders
              ? statisticsRejectOrders?.count_rejected_orders
              : 0}
          </p>
          <p className="statistics__text">
            Сумма отклоненных заявок:{" "}
            {statisticsRejectOrders?.common_amount
              ? statisticsRejectOrders?.common_amount
              : 0}
          </p>
        </div>
      </div>
    </div>
  );
}
