import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import axios from './axios';

export const setAccessTokenCookie = (token) => {
  if (!token) return;

  const decodedToken = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);
  const remainingTimeInSeconds = decodedToken.exp - currentTime;
  const sevenDaysInSeconds = 7 * 24 * 60 * 60;
  const expiresInSeconds = Math.min(remainingTimeInSeconds, sevenDaysInSeconds);
  const expiresInDays = expiresInSeconds / (60 * 60 * 24);

  Cookies.set('access_token', token, { expires: expiresInDays });
};

export const setRefreshTokenCookie = (token) => {
  if (!token) return;

  const decodedToken = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);
  const remainingTimeInSeconds = decodedToken.exp - currentTime;
  const sevenDaysInSeconds = 7 * 24 * 60 * 60;
  const expiresInSeconds = Math.min(remainingTimeInSeconds, sevenDaysInSeconds);
  const expiresInDays = expiresInSeconds / (60 * 60 * 24);

  Cookies.set('refresh_token', token, { expires: expiresInDays });
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = Cookies.get('refresh_token');

    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axios.post(`/api/v1/auth/refresh-token`, {
      refresh_token: refreshToken
    });

    const { access_token } = response.data;

    // Сохраняем новый токен в куки
    setAccessTokenCookie(access_token)

    return access_token;
  } catch (error) {
    console.error('Failed to refresh access token:', error);
    throw error;
  }
};
