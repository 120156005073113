import './index.scss'
import {useEffect, useState} from "react";

export default function Status({status}) {
  const [languageState, setLanguageState] = useState(false)

  useEffect(() => {
    setLanguageState(localStorage.getItem('localization'))
  }, [localStorage.getItem('localization')])

  const getStatus = (status) => {
    if(status === 'sell') {
      return 'SELL'
    }
    else if(status === 'buy') {
      return 'BUY'
    }
    else if(status === 'pending') {
      if(languageState === 'false') {
        return 'В обработке'
      } else {
        return  'Processing'
      }
    }
    else if(status === 'success') {
      if(languageState === 'false') {
        return 'Завершен'
      } else {
        return  'Completed'
      }
    }
    else if(status === 'cancel-arbitr') {
      if(languageState === 'false') {
        return 'Отменен'
      } else {
        return  'cancel'
      }
    }
    else if(status === 'cancel-auto') {
      if(languageState === 'false') {
        return 'Отменен'
      } else {
        return  'cancel'
      }
    }
    else if(status === 'cancel') {
      if(languageState === 'false') {
        return 'Отменен'
      } else {
        return  'cancel'
      }
    }
    else if(status === 'appeal') {
      if(languageState === 'false') {
        return 'Апелляция'
      } else {
        return  'Appeal'
      }
    }
    else if(status === 'appeal_success') {
      if(languageState === 'false') {
        return 'Апелляция'
      } else {
        return  'Appeal'
      }
    }
    else if(status === 'automatic') {
      if(languageState === 'false') {
        return 'Автоманика'
      } else {
        return  'Automatic'
      }
    }
    else if(status === 'recalculation') {
      if(languageState === 'false') {
        return 'Перерасчет'
      } else {
        return  'Recalculation'
      }
      return 'Recalculated'
    }
    else {
      return status
    }
  }

  return (
    <div className={`status status_${status}`}>
      <p className={`status__text status__text_${status}`}>{getStatus(status)}</p>
    </div>
  )
}
