const authMiddleware = () => (next) => async (action) => {
    const { error, payload } = action;
  
    if (error) {
      if (payload?.status === 401) {
        localStorage.removeItem('refresh_token_admin')
        localStorage.removeItem('unitrade_admin_auth')
        localStorage.removeItem("token_expiration");
        localStorage.removeItem("profileData");
        setTimeout(() => {
          localStorage.removeItem('unitrade_admin_auth')
          localStorage.removeItem("token_expiration");
          window.location.href = 'https://service.unitpay.io/login'
        }, 800)
      }
    }
  
    return next(action);
  };
  
  export default authMiddleware;
  