import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../Components/Header";
import ApiStatistics from "../../Global/Api/statistics.api";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";

import "./index.scss";
import { useParams } from "react-router-dom";

export default function TraderKPI() {
  const statisticsApi = ApiStatistics();
  const limitCount = 100;
  const lastId = useRef(null);
  const parametrs = useParams();
  const [searchParams] = useSearchParams();
  const { traderKPIStatistic, traderKPIStatisticArray } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  console.log(parametrs);

  useEffect(() => {
    if (searchParams.get("is_service") === "service") {
      statisticsApi.getTraderServiceKPIStatistic({
        trader_id: parametrs?.id,
        currency_id: searchParams.get("currency"),
        limit: limitCount,
      });
    } else {
      statisticsApi.getTraderKPIStatistic({
        trader_id: parametrs?.id,
        limit: limitCount,
      });
    }
  }, []);

  const loadMore = () => {
    lastId.current =
      traderKPIStatisticArray[traderKPIStatisticArray.length - 1]?.created_at;
    statisticsApi.getTraderKPIStatistic({
      trader_id: parametrs?.id,
      limit: limitCount,
      last_created_at: lastId.current,
    });
  };

  const renderDayKPI = () => {
    return traderKPIStatisticArray?.map((item) => (
      <tr key={item?.id}>
        <td>{moment(item?.created_at).format("DD.MM.YY, HH:mm:ss")}</td>
        <td>{item?.total_buy_orders}</td>
        <td>{item?.total_sell_orders}</td>
        <td>
          {item?.total_success_buy_orders} (
          {isNaN(
            100 -
              ((item?.total_buy_orders - item?.total_success_buy_orders) /
                item?.total_buy_orders) *
                100
          ) || item?.total_buy_orders - item?.total_success_buy_orders < 0
            ? 0
            : (
                100 -
                ((item?.total_buy_orders - item?.total_success_buy_orders) /
                  item?.total_buy_orders) *
                  100
              ).toFixed(2)}
          %)
        </td>
        <td>
          {item?.total_success_sell_orders} (
          {isNaN(
            100 -
              ((item?.total_sell_orders - item?.total_success_sell_orders) /
                item?.total_sell_orders) *
                100
          ) || item?.total_sell_orders - item?.total_success_sell_orders < 0
            ? 0
            : (
                100 -
                ((item?.total_sell_orders - item?.total_success_sell_orders) /
                  item?.total_sell_orders) *
                  100
              ).toFixed(2)}
          %)
        </td>
        <td>{item?.total_cancel_buy_orders}</td>
        <td>{item?.total_cancel_sell_orders}</td>
        <td>{item?.total_appeal_buy_orders}</td>
        <td>{item?.total_appeal_sell_orders}</td>
      </tr>
    ));
  };

  return (
    <div className="traderKPI container">
      <Header title={`KPI по трейдеру ${traderKPIStatistic?.notice}`} />
      <div className="traderKPI__inner">
        <h2>Итоговая статистика</h2>
        <div className="traderKPI__table">
          <table>
            <thead>
              <tr>
                {searchParams.get("is_service") !== "service" && (
                  <th>Рейтинг</th>
                )}
                {searchParams.get("is_service") !== "service" && (
                  <th>Приоритет</th>
                )}
                <th>Кол-во на ввод</th>
                <th>Кол-во на вывод</th>
                <th>Кол-во на ввод в успехе</th>
                <th>Кол-во на вывод в успехе</th>
                <th>Кол-во на ввод в отмене</th>
                <th>Кол-во на вывод в отмене</th>
                <th>Кол-во апелляций на ввод</th>
                <th>Кол-во апелляций на вывод</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {searchParams.get("is_service") !== "service" && (
                  <td>{traderKPIStatistic?.trader_data?.rang}</td>
                )}
                {searchParams.get("is_service") !== "service" && (
                  <td>{traderKPIStatistic?.trader_data?.priority}</td>
                )}

                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_buy_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_sell_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_success_buy_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_success_sell_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_cancel_buy_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_cancel_sell_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_appeal_buy_orders
                  }
                </td>
                <td>
                  {
                    traderKPIStatistic?.final_total_statistics
                      ?.final_total_appeal_sell_orders
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="traderKPI__table">
          <h2 className="mt-20">Статистика по дням</h2>
          <table>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Кол-во на ввод</th>
                <th>Кол-во на вывод</th>
                <th>Кол-во на ввод в успехе</th>
                <th>Кол-во на вывод в успехе</th>
                <th>Кол-во на ввод в отмене</th>
                <th>Кол-во на вывод в отмене</th>
                <th>Кол-во апелляций на ввод</th>
                <th>Кол-во апелляций на вывод</th>
              </tr>
            </thead>
            <tbody>{renderDayKPI()}</tbody>
          </table>
        </div>
        {traderKPIStatisticArray?.length > 0 && (
          <div className="loadMore">
            <p
              onClick={() => {
                loadMore();
              }}
            >
              Загрузить еще...
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
