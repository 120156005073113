import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setSelectedBanks } from "../../Store/Slice/unitradeSlice";
import { useLocation } from "react-router-dom";
import useAds from "../../Global/Api/ads.api";
import Status from "../Status";
import "./index.scss";

export default function Ad({
  id,
  editAd,
  deleteAd,
  item,
  typePayment,
  closeModal,
  openAddMinLimitModal,
  openSubMinLimitModal,
  openAddMaxLimitModal,
  openSubMaxLimitModal,
  openEditMaxOrderAmountModal
}) {
  const createAd = useAds();

  return (
    <div key={id} className="ad">
      <div className={`ad__left-check`}>
        <img src="/assets/icons/check-ad.svg" alt="checked" />
      </div>
      <div className={`ad__inner`}>
        <div className="ad__flex">
          <div className={`ad__left`}>
            <div
              onClick={() => {
                let body;

                body = {
                  id: item?.id,
                  type: item?.type,
                  ad_category: item?.ad_category,
                  card_holder: item?.card_holder,
                  card_number: item?.card_number,
                  phone_number: item?.phone_number,
                  device_id: item?.device_id,
                  comment: item?.comment,
                  requisite: item?.requisite,
                  requisite_type:
                    item?.card_number !== null &&
                    item?.phone_number === null &&
                    item?.requisite === null
                      ? "card"
                      : item?.card_number === null &&
                        item?.phone_number !== null &&
                        item?.requisite === null
                      ? "phone"
                      : "transfer",
                  payment_method_id: item?.payment_method_id,
                  currency_id: item?.currency_id,
                  is_active: !item?.is_active,
                  is_interbank: item?.is_interbank,
                  max_order_amount: item?.max_order_amount,
                };

                createAd.editedAd(body, closeModal, { action: "toggle" });
              }}
              className="ad__left-btn"
            >
              {!item?.is_active ? (
                <img src="/assets/icons/play.svg" alt="play" />
              ) : (
                <img src="/assets/icons/pause.svg" alt="play" />
              )}
            </div>
            <div className="ad__separator"></div>
            <div className="ad__left-info">
              <div className="ad__left-infoField">
                <span>
                  <img src="/assets/icons/user.svg" alt="user" />
                </span>
                <p>{item?.card_holder}</p>
                <Status
                  status={
                    item?.type === "buy"
                      ? "sell" + ` ${item?.ad_category}`
                      : "buy" +
                        ` ${item?.ad_category}` +
                        `${item?.is_interbank === true ? " Межбанк" : ""}`
                  }
                />
              </div>
              <div className="ad__left-infoField">
                {(item?.card_number !== null || item?.requisite !== null) && (
                  <span>
                    <img src="/assets/icons/card.svg" alt="card" />
                  </span>
                )}
                {item?.phone_number !== null && (
                  <span>
                    <img src="/assets/icons/phone.svg" alt="phone" />
                  </span>
                )}
                {item?.card_number !== null && (
                  <p>{item?.card_number?.replace(/(.{4})/g, "$1 ")}</p>
                )}
                {item?.phone_number !== null && <p>{item?.phone_number}</p>}
                {item?.requisite !== null && <p>{item?.requisite}</p>}
                <div className="mobile-time color-green">
                  <Status status={item?.payment_method.bank_name} />
                </div>
              </div>
            </div>
            {window.innerWidth >= 500 && (
              <div className="ad__left-comment">
                <p className="">
                  <span>Владелец: </span>{" "}
                  {item?.user?.notice?.length > 0
                    ? item?.user?.notice
                    : item?.user?.login}
                </p>
                {item?.device_id !== null && (
                  <p>
                    <span>Девайс: </span> {item?.device_id}
                  </p>
                )}
                {item?.user?.trader_data?.rang !== null && (
                  <p>
                    <span>Рейтинг: </span> {item?.user?.trader_data?.rang}
                  </p>
                )}
                {item?.user?.trader_data?.priority !== null && (
                  <p>
                    <span>Приоритет: </span> {item?.user?.trader_data?.priority}
                  </p>
                )}
                {item?.is_interbank !== null && (
                  <p>
                    <span>Межбанк: </span>{" "}
                    {item?.is_interbank ? "Межбанк" : "Не межбанк"}
                  </p>
                )}
                {item?.user?.trader_data?.input_fee !== null && (
                  <p>
                    <span>Ввод: </span> {item?.user?.trader_data?.input_fee}%
                  </p>
                )}
                {item?.user?.trader_data?.output_fee !== null && (
                  <p>
                    <span>Вывод: </span> {item?.user?.trader_data?.output_fee}%
                  </p>
                )}
                <p>
                  <span>Активность: </span>{" "}
                  {item?.user?.type_active === "active"
                    ? "Активен"
                    : item?.user?.type_active === "paused"
                    ? "На паузе"
                    : "Не активен"}
                </p>
              </div>
            )}
            {window.innerWidth >= 500 && (
              <div className="ad__left-comment">
                {item?.user?.is_banned !== null && (
                  <p>
                    <span>Блок: </span>{" "}
                    {item?.user?.is_banned ? "Заблокирован" : "Разблокирован"}
                  </p>
                )}
                <p>
                  <span>Активность: </span>{" "}
                  {item?.user?.type_active === "active"
                    ? "Активен"
                    : item?.user?.type_active === "paused"
                    ? "На паузе"
                    : "Не активен"}
                </p>
                {item?.user?.crypto_wallet?.is_deposit !== null && (
                  <p>
                    <span>Депозит: </span>{" "}
                    {item?.user?.crypto_wallet?.is_deposit ? "Есть" : "Нет"}
                  </p>
                )}
                {item?.user?.crypto_wallet?.deposit !== null && (
                  <p>
                    <span>Сумма депозита: </span>{" "}
                    {item?.user?.crypto_wallet?.deposit}
                  </p>
                )}
                {item?.user?.trader_data?.default_min_amount_ads !== null && (
                  <p>
                    <span>Мин. лимит: </span>{" "}
                    {item?.user?.trader_data?.default_min_amount_ads}
                  </p>
                )}
                {item?.user?.trader_data?.bonus_input_fee !== null && (
                  <p>
                    <span>Бонусный ввод: </span>{" "}
                    {item?.user?.trader_data?.bonus_input_fee}%
                  </p>
                )}
                {item?.user?.trader_data?.bonus_output_fee !== null && (
                  <p>
                    <span>Бонусный вывод: </span>{" "}
                    {item?.user?.trader_data?.bonus_output_fee}%
                  </p>
                )}
              </div>
            )}
          </div>
          {window.innerWidth <= 500 && (
            <div className="ad__left-comment ad__left-comment_mobile">
              <p className="">
                <span>Владелец: </span>{" "}
                {item?.user?.notice?.length > 0
                  ? item?.user?.notice
                  : item?.user?.login}
              </p>
              {item?.device_id !== null && (
                <p>
                  <span>Девайс: </span> {item?.device_id}
                </p>
              )}
              {item?.user?.trader_data?.rang !== null && (
                <p>
                  <span>Рейтинг: </span> {item?.user?.trader_data?.rang}
                </p>
              )}
              {item?.user?.trader_data?.priority !== null && (
                <p>
                  <span>Приоритет: </span> {item?.user?.trader_data?.priority}
                </p>
              )}
              {item?.is_interbank !== null && (
                <p>
                  <span>Межбанк: </span>{" "}
                  {item?.is_interbank ? "Межбанк" : "Не межбанк"}
                </p>
              )}
              {item?.user?.trader_data?.input_fee !== null && (
                <p>
                  <span>Ввод: </span> {item?.user?.trader_data?.input_fee}%
                </p>
              )}
              {item?.user?.trader_data?.output_fee !== null && (
                <p>
                  <span>Вывод: </span> {item?.user?.trader_data?.output_fee}%
                </p>
              )}
              <p>
                <span>Активность: </span>{" "}
                {item?.user?.type_active === "active"
                  ? "Активен"
                  : item?.user?.type_active === "paused"
                  ? "На паузе"
                  : "Не активен"}
              </p>
            </div>
          )}
          {window.innerWidth <= 500 && (
            <div className="ad__left-comment ad__left-comment_mobile">
              {item?.user?.is_banned !== null && (
                <p>
                  <span>Блок: </span>{" "}
                  {item?.user?.is_banned ? "Заблокирован" : "Разблокирован"}
                </p>
              )}
              <p>
                <span>Активность: </span>{" "}
                {item?.user?.type_active === "active"
                  ? "Активен"
                  : item?.user?.type_active === "paused"
                  ? "На паузе"
                  : "Не активен"}
              </p>
              {item?.user?.crypto_wallet?.is_deposit !== null && (
                <p>
                  <span>Депозит: </span>{" "}
                  {item?.user?.crypto_wallet?.is_deposit ? "Есть" : "Нет"}
                </p>
              )}
              {item?.user?.crypto_wallet?.deposit !== null && (
                <p>
                  <span>Сумма депозита: </span>{" "}
                  {item?.user?.crypto_wallet?.deposit}
                </p>
              )}
              {item?.user?.trader_data?.default_min_amount_ads !== null && (
                <p>
                  <span>Мин. лимит: </span>{" "}
                  {item?.user?.trader_data?.default_min_amount_ads}
                </p>
              )}
              {item?.user?.trader_data?.bonus_input_fee !== null && (
                <p>
                  <span>Бонусный ввод: </span>{" "}
                  {item?.user?.trader_data?.bonus_input_fee}%
                </p>
              )}
              {item?.user?.trader_data?.bonus_output_fee !== null && (
                <p>
                  <span>Бонусный вывод: </span>{" "}
                  {item?.user?.trader_data?.bonus_output_fee}%
                </p>
              )}
            </div>
          )}
          <div className="ad__right">
            <div className="desktop-time">
              <Status status={item?.payment_method?.bank_name} />
            </div>
            <div className="ad__stats">
              <div className="ad__stat">
                  <img
                    onClick={() => {
                      openAddMinLimitModal(item);
                    }}
                    src="/assets/icons/plus-limit.svg"
                    alt="plus"
                  />
                <p className="ad__stat-text">
                  {Number(item?.min_amount).toFixed(2)?.toLocaleString("en-US")}{" "}
                  {item?.currency?.currency}
                </p>
                <p className="ad__stat-title">MIN LIMIT</p>
                  <img
                    onClick={() => {
                      openSubMinLimitModal(item);
                    }}
                    src="/assets/icons/minus-limit.svg"
                    alt="minus"
                  />
              </div>
              <div className="ad__separator"></div>
              <div className="ad__stat">
                  <img
                    onClick={() => {
                      openAddMaxLimitModal(item);
                    }}
                    src="/assets/icons/plus-limit.svg"
                    alt="plus"
                  />
                <p className="ad__stat-text">
                  {Number(item?.max_amount).toFixed(2)?.toLocaleString("en-US")}{" "}
                  {item?.currency?.currency}
                </p>
                <p className="ad__stat-title">MAX LIMIT</p>
                  <img
                    onClick={() => {
                      openSubMaxLimitModal(item);
                    }}
                    src="/assets/icons/minus-limit.svg"
                    alt="minus"
                  />
              </div>
              <div className="ad__separator"></div>
              <div className="ad__stat">
                <p className="ad__stat-text">
                  {Number(item?.max_order_amount)
                    .toFixed(2)
                    ?.toLocaleString("en-US")}{" "}
                  {item?.currency?.currency}
                </p>
                <p className="ad__stat-title">MAX ORDER AMOUNT</p>
              </div>
              <div className="ad__separator"></div>
              <div className="ad__stat">
                  <div className="empty_img"></div>
                <p className="ad__stat-text">
                  {Number(item?.max_order_amount_per_hour)
                    .toFixed(2)
                    ?.toLocaleString("en-US")}{" "}
                  {item?.currency?.currency}
                </p>
                <p className="ad__stat-title">MAX ORDER AMOUNT PER HOUR</p>
                  <img
                    onClick={() => {
                      openEditMaxOrderAmountModal(item);
                    }}
                    src="/assets/icons/editAdAmount.svg"
                    alt="edit"
                  />
                
              </div>
            </div>
              <div className="ad__btns mobile-time">
                <div
                  onClick={() => {
                    editAd(item);
                  }}
                  className="ad__btn ad__btn-edit"
                >
                  <img src="/assets/icons/edit-ad.svg" alt="" />
                </div>
                <div
                  onClick={() => {
                    deleteAd(item);
                  }}
                  className="ad__btn ad__btn-delete"
                >
                  <img src="/assets/icons/trash-ad.svg" alt="" />
                </div>
              </div>
          </div>
        </div>
          <div className="ad__btns desktop-time">
            <div
              onClick={() => {
                editAd(item);
              }}
              className="ad__btn ad__btn-edit"
            >
              <img src="/assets/icons/edit-ad.svg" alt="" />
            </div>
            <div
              onClick={() => {
                deleteAd(item);
              }}
              className="ad__btn ad__btn-delete"
            >
              <img src="/assets/icons/trash-ad.svg" alt="" />
            </div>
          </div>
      </div>
    </div>
  );
}
