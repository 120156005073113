import { useEffect, useState, useRef } from "react";
import Header from "../../Components/Header";
import ApiProfile from "../../Global/Api/profile.api";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../UI/Button";
import useInputHandler from "../../Global/Hooks/inputHandler";
import Input from "../../UI/Input";
import Popup from "../../Components/Popup";
import CryptoWalletsApi from "../../Global/Api/cryptoWallets.api";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { setCryptoWalletBillingEmpty } from "../../Store/Slice/unitradeSlice";
import { CustomSelect } from "../../UI/Select";

export default function Profile() {
  const dispatch = useDispatch();
  const apiProfile = ApiProfile();
  const [generateFaModal, setGenerateFaModal] = useState(false);
  const [createAdminModal, setCreateAdminModal] = useState(false);
  const [switchAdminModal, setSwitchAdminModal] = useState(false);
  const newFaKey = useInputHandler("");
  const limitCount = 100;
  const repeatNewFaKey = useInputHandler("");
  const ownerFaKey = useInputHandler("");
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const [addWalletModal, setAddWalletModal] = useState(false);
  const cryptoAddress = useInputHandler("");
  const cryptoKey = useInputHandler("");
  const [addWebWallet, setAddWebWallet] = useState(false);
  const apiCryptoWallets = CryptoWalletsApi();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const lastCreated = useRef(null);
  const lastCreatedBilling = useRef(null);
  const lastCreatedUsers = useRef(null);
  const [infoUser, setInfoUser] = useState(null);
  const login = useInputHandler("");
  const channelId = useInputHandler("");
  const telegramId = useInputHandler("");
  const notice = useInputHandler("");
  let bodyFilter;

  const typeData = [
    {
      value: "web3",
      label: "WEB3",
    },
    {
      value: "order",
      label: "Order",
    },
    {
      value: "fee",
      label: "Fee",
    },
  ];

  const {
    faKeyDataProfile,
    profileBilling,
    cryptoWalletBilling,
    superadminsData,
  } = useSelector((state) => state.rootReducer.unitradeSlice);

  let data;

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    if (lastCreatedUsers.current !== null)
      params.append("last_created_at", lastCreatedUsers.current);
    return params;
  };

  useEffect(() => {
    const formattedParams = formatParams();
    apiProfile.getProfileBilling();
    apiProfile.getSuperadmins(formattedParams);
  }, []);

  useEffect(() => {
    const formattedParams = formatParamsBilling();
    apiCryptoWallets.getCryptoWalletsBilling(formattedParams);
  }, [selectedTransaction])

  useEffect(() => {
    if (faKeyDataProfile !== null) {
      newFaKey.setChange(faKeyDataProfile?.fa_key);
      repeatNewFaKey.setChange(faKeyDataProfile?.fa_key);
    }
  }, [faKeyDataProfile]);

  const formatParamsBilling = () => {
    const params = new URLSearchParams();
    params.append("limit", 200);
    params.append("wallet_id", profileData?.id);
    if (selectedTransaction !== null)
      params.append("transaction_type", selectedTransaction.value);
    if (lastCreatedBilling.current !== null)
      params.append("last_created_at", lastCreatedBilling.current);
    return params;
  };

  const openGenerateFaKeyModal = () => {
    setGenerateFaModal(true);
  };

  const closeGenerateFaKeyModal = () => {
    setGenerateFaModal(false);
    newFaKey.setChange("");
    repeatNewFaKey.setChange("");
    ownerFaKey.setChange("");
  };

  const openCreateAdminModal = () => {
    setCreateAdminModal(true);
  };

  const closeCreateAdminModal = () => {
    setCreateAdminModal(false);
    ownerFaKey.setChange("");
  };

  const openSwitchAdminModal = (user) => {
    setInfoUser(user);
    setSwitchAdminModal(true);
  };

  const closeSwitchAdminModal = () => {
    setSwitchAdminModal(false);
    setInfoUser(null);
    ownerFaKey.setChange("");
  };

  const sendData = () => {
    const body = {
      new_fa_key: newFaKey.value,
      new_fa_key_again: repeatNewFaKey.value,
      owner_password: ownerFaKey.value,
    };
    apiProfile.editFaKeyUser(body, closeGenerateFaKeyModal);
  };

  const generateKey = () => {
    apiProfile.generateFaKey();
  };

  const addWallet = () => {
    apiCryptoWallets.addCryptoWallet();
    setAddWalletModal(false);
  };

  const openAddWalletModal = () => {
    setAddWalletModal(true);
  };

  const closeAddWalletModal = () => {
    setAddWalletModal(false);
  };

  const closeAddWebWallet = () => {
    setAddWebWallet(false);
    cryptoKey.setChange("");
    cryptoAddress.setChange("");
  };

  const createWebWallet = () => {
    const body = {
      crypto_address: cryptoAddress.value,
      crypto_key: cryptoKey.value,
    };
    apiCryptoWallets.connectCryptoWallet(body, closeAddWebWallet, getWallet);
  };

  const openAddWebWallet = () => {
    setAddWebWallet(true);
  };

  const getWallet = () => {
    apiProfile.getProfileBilling();
  };

  const renderBilling = () => {
    return cryptoWalletBilling?.map((billing) => (
      <tr key={billing?.id}>
        <td>
          <NavLink
            onClick={() => {
              localStorage.setItem("order_id", billing?.order_id);
            }}
            to={`/order/${billing?.order_id}`}
            className="user__btn"
          >
            {billing?.order_id}
          </NavLink>
        </td>
        <td>{billing?.currency_type}</td>
        <td>{billing?.operation_type}</td>
        <td>{billing?.transaction_type}</td>
        <td>{billing?.balance}</td>
        <td>{billing?.frozen_balance}</td>
        <td>{billing?.deposit}</td>
        <td>
          {moment.utc(billing?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
      </tr>
    ));
  };

  const renderSuperadmins = () => {
    return superadminsData?.map((user) => (
      <tr key={user?.id}>
        <td>{user?.notice !== null ? user?.notice : user?.id}</td>
        <td>{user?.login}</td>
        <td>{user?.telegram_id}</td>
        <td>{user?.channel_id}</td>
        <td>{user?.is_banned === true ? "Заблокирован" : "Разблокирован"}</td>
        <td>
          {user?.type_active === "active"
            ? "Активен"
            : user?.type_active === "paused"
            ? "На паузе"
            : "Неактивен"}
        </td>
        <td className="switch_btn">
          <div
            onClick={() => {
              openSwitchAdminModal(user);
            }}
            className="table__btn"
          >
            <div>Сменить аккаунт</div>
          </div>
        </td>
        <td>
          <div className="table__btn">
            <NavLink target="_blank" to={`/profile/${user?.id}`} className="">
              <img src="/assets/icons/eye-icon.svg" alt="" />
            </NavLink>
          </div>
        </td>
      </tr>
    ));
  };

  const loadMore = () => {
    lastCreatedBilling.current =
      cryptoWalletBilling[cryptoWalletBilling.length - 1]?.created_at;
    const formattedParams = formatParams();
    apiCryptoWallets.getCryptoWalletsBilling(formattedParams);
  };

  const loadMoreUsers = () => {
    lastCreatedUsers.current =
      superadminsData[superadminsData.length - 1]?.created_at;
    const formattedParams = formatParams();
    apiProfile.getSuperadmins(formattedParams);
  };

  const createSuperadmin = () => {
    const body = {
      owner_password: ownerFaKey.value,
    };
    apiProfile.createSuperadmin(body, closeCreateAdminModal);
  };

  const openEditModal = (user) => {
    setInfoUser(user);
    setEditModal(true);
    login.setChange(user?.login === null ? "" : user?.login);
    notice.setChange(user?.notice === "" ? "" : user?.notice);
    channelId.setChange(user?.channel_id === null ? "" : user?.channel_id);
    telegramId.setChange(user?.telegram_id === null ? "" : user?.telegram_id);
  };

  const closeEditModal = () => {
    setInfoUser(null);
    setEditModal(false);
    login.setChange("");
    channelId.setChange("");
    telegramId.setChange("");
    notice.setChange("");
  };

  const editSendData = () => {
    const body = {
      login: login.value,
      channel_id: channelId.value !== "" ? channelId.value : null,
      telegram_id: telegramId.value !== "" ? telegramId.value : null,
      notice: notice.value,
    };

    apiProfile.editSuperadmin(body, closeEditModal);
  };

  const switchSuperadmin = () => {
    const body = {
      admin_id: infoUser?.id,
      owner_password: ownerFaKey.value,
    };
    apiProfile.switchSuperadmin(body, closeSwitchAdminModal);
  };

  return (
    <div className="profile container">
      {addWebWallet && (
        <Popup title={profileBilling?.crypto_address === null ||
          profileBilling?.crypto_address === "" ? "Добавить крипто кошелек" : 'Изменить крипто кошелек'} closePopup={closeAddWebWallet}>
          <div className="addWebWallet__inner">
            <div className="addWebWallet__inner-input">
              <Input
                label="Крипто адрес"
                placeholder="Введите адрес"
                type="text"
                id="crypto_address"
                {...cryptoAddress}
              />
            </div>
            <div className="addWebWallet__inner-input">
              <Input
                label="Крипто ключ"
                placeholder="Введите адрес"
                type="text"
                id="crypto_key"
                {...cryptoKey}
              />
            </div>
          </div>
          <div className="ads__popup-btns">
            <div className="ads__popup-btn">
              <Button
                text="Отмена"
                type="light"
                onClick={closeAddWebWallet}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="ads__popup-btn">
              <Button
                text="Добавить"
                disabled={
                  cryptoAddress.value?.length >= 3 &&
                  cryptoKey.value?.length >= 3
                    ? false
                    : true
                }
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createWebWallet}
              />
            </div>
          </div>
        </Popup>
      )}
      {addWalletModal && (
        <Popup
          title={"Вы уверены что хотите создать кошелек?"}
          closePopup={closeAddWalletModal}
        >
          <div className="add-deposit__inner">
            <Button
              text={"Создать"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addWallet}
            />
          </div>
        </Popup>
      )}
      {generateFaModal && (
        <Popup title={"Изменить 2fa ключ"} closePopup={closeGenerateFaKeyModal}>
          <div className="popup__form">
            <div>
              <Button
                text={"Сгенерировать новый fa ключ"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={generateKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Новый fa ключ"}
                placeholder=""
                type="text"
                id="newFaKey"
                {...newFaKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Повторите новый fa ключ"}
                type="text"
                placeholder=""
                id="repeatNewFaKey"
                {...repeatNewFaKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Введите свой fa ключ"}
                type="text"
                placeholder=""
                onlyNumber={true}
                maxLength={6}
                id="ownerFaKey"
                {...ownerFaKey}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeGenerateFaKeyModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={
                  ownerFaKey.value?.length === 6 &&
                  newFaKey.value === "" &&
                  repeatNewFaKey.value === ""
                    ? true
                    : false
                }
                bottomPadding={10}
                type="accept"
                onClick={sendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {createAdminModal && (
        <Popup
          title={"Создать суперпользователя"}
          closePopup={closeCreateAdminModal}
        >
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Введите свой fa ключ"}
                type="text"
                placeholder=""
                onlyNumber={true}
                maxLength={6}
                id="ownerFaKey"
                {...ownerFaKey}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeGenerateFaKeyModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={ownerFaKey.value?.length !== 6 ? true : false}
                bottomPadding={10}
                type="accept"
                onClick={createSuperadmin}
              />
            </div>
          </div>
        </Popup>
      )}
      {switchAdminModal && (
        <Popup
          title={"Переключиться на другого пользователя"}
          closePopup={closeSwitchAdminModal}
        >
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Введите свой fa ключ"}
                type="text"
                placeholder=""
                onlyNumber={true}
                maxLength={6}
                id="ownerFaKey"
                {...ownerFaKey}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeSwitchAdminModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={ownerFaKey.value?.length !== 6 ? true : false}
                bottomPadding={10}
                type="accept"
                onClick={switchSuperadmin}
              />
            </div>
          </div>
        </Popup>
      )}
      {editModal && (
        <Popup title={"Изменить пользователя"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Логин"}
                  placeholder="Введите логин"
                  type="text"
                  id="login"
                  {...login}
                  maxLength={3}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Notice"}
                  placeholder="Введите notice"
                  type="text"
                  id="notice"
                  {...notice}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Chanel id"}
                  placeholder="Введите id"
                  type="text"
                  id="channelId"
                  {...channelId}
                  onlyNumber={true}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Telegram id"}
                  placeholder="Введите id"
                  type="text"
                  id="telegramId"
                  {...telegramId}
                  onlyNumber={true}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title="Профиль" />
      <div className="profile__inner">
        <div className="profile__top">
          <div className="profile__top-btns">
            <div className="editFa__btn">
              <Button
                text={"Изменить fa ключ"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={openGenerateFaKeyModal}
              />
            </div>
            <div className="editFa__btn">
              <Button
                text={"Создать суперпользователя"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={openCreateAdminModal}
              />
            </div>
            {profileBilling?.crypto_address === null ||
            profileBilling?.crypto_address === "" ? (
              <div className="editFa__btn">
                <Button
                  text={"Добавить крипто кошелек"}
                  topPadding={10}
                  bottomPadding={10}
                  type="accept"
                  onClick={openAddWebWallet}
                />
              </div>
            ) : (
              <div className="editFa__btn">
                <Button
                  text={"Изменить крипто кошелек"}
                  topPadding={10}
                  bottomPadding={10}
                  type="accept"
                  onClick={openAddWebWallet}
                />
              </div>
            )}
          </div>
        </div>
        <div className="profile__table mt-10">
          <h2>Суперадминистраторы</h2>
          <table>
            <thead>
              <tr>
                <th>Название</th>
                <th>Логин</th>
                <th>Telegram id</th>
                <th>Channel id</th>
                <th>Блокировка</th>
                <th>Активность</th>
              </tr>
            </thead>
            <tbody>{renderSuperadmins()}</tbody>
          </table>
          <div className="loadMore">
            <p
              onClick={() => {
                loadMoreUsers();
              }}
            >
              Загрузить еще...
            </p>
          </div>
        </div>
        <div className="profile__table profile__table_mt">
          <h2>
            Суперадминистарор: $
            {profileData?.notice ? profileData?.notice : profileData?.id}
          </h2>
          <table>
            <thead>
              <tr>
                <th>Название</th>
                <th>Логин</th>
                <th>Telegram id</th>
                <th>Channel id</th>
                <th>Активность</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {profileData?.notice ? profileData?.notice : profileData?.id}
                </td>
                <td>{profileData?.login}</td>
                <td>{profileData?.telegram_id}</td>
                <td>{profileData?.channel_id}</td>
                <td>
                  {profileData?.type_active === "active"
                    ? "Активен"
                    : profileData?.type_active === "paused"
                    ? "На паузе"
                    : "Неактивен"}
                </td>
                <td>
                  <div
                    onClick={() => {
                      openEditModal(profileData);
                    }}
                    className="table__btn"
                  >
                    <img src="/assets/icons/edit-ad.svg" alt="" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {profileBilling !== null && (
          <div className="profile__crypto-wallet">
            <h2>Крипто кошелек</h2>
            <table>
              <thead>
                <tr>
                  <th>Адрес</th>
                  <th>Депозит</th>
                  <th>Баланс</th>
                  <th>Замороженный баланс</th>
                  <th>QR код</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{profileBilling?.crypto_address}</td>
                  <td>{profileBilling?.deposit}</td>
                  <td>{profileBilling?.balance}</td>
                  <td>{profileBilling?.frozen_balance}</td>
                  <td className="qr_code">
                    <img src={profileBilling?.qr_code_path} alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="cryptoWalletBilling">
          <h2 className="crypto-wallets__title">
            Billings
            <div className="crypto-wallets__select">
              <CustomSelect
                options={typeData}
                placeholder={"Выберите тип транзакции"}
                selected={selectedTransaction}
                handleSelect={(e) => {
                  dispatch(setCryptoWalletBillingEmpty([]));
                  setSelectedTransaction(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          </h2>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Currency Type</th>
                <th>Operation Type</th>
                <th>Transaction Type</th>
                <th>Balance</th>
                <th>Frozen Balance</th>
                <th>Deposit</th>
                <th>Created At</th>
              </tr>
            </thead>
            {cryptoWalletBilling?.length > 0 && (
              <tbody>{renderBilling()}</tbody>
            )}
          </table>
          <div className="loadMore">
            <p
              onClick={() => {
                loadMore();
              }}
            >
              Загрузить еще...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
