import React from "react";

import "./index.scss";
import Button from "../../UI/Button";
import { useNavigate } from "react-router-dom";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div className="page-404">
      <img src="/assets/images/404page.svg" alt="404" />
      <span>Ooops! Page not found</span>
      <div className="page-404__btn">
        <Button
          text="Go back"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
}
