import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Header from "../../Components/Header";
import { CustomSelect } from "../../UI/Select";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import moment from "moment/moment";
import Popup from "../../Components/Popup";
import Button from "../../UI/Button";

import "./index.scss";
import ApiReports from "../../Global/Api/reports.api";
import { Link } from "react-router-dom";

export default function Reports() {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTimeField, setSelectedTimeField] = useState(null);
  const [date, setDate] = useState(null);
  const [dateCreated, setDateCreated] = useState(null);
  const [createModal, setCreateModal] = useState(null);
  const { reports } = useSelector((state) => state.rootReducer.unitradeSlice);
  const reportsApi = ApiReports();
  const lastCreated = useRef(null);
  const limitCount = 100;
  const timeFieldData = [
    {
      value: "created",
      label: "Created",
    },
    {
      value: "updated",
      label: "Updated",
    },
    {
      value: "completed",
      label: "Completed",
    },
  ];
  const statusData = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "progress",
      label: "Progress",
    },
    {
      value: "finished",
      label: "Finished",
    },
  ];

  useEffect(() => {
    const formattedParams = formatParams();
    reportsApi.getReports(formattedParams);
  }, [selectedStatus, date]);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    if (date !== null)
      params.append(
        "datetime_from",
        moment(date[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (date !== null)
      params.append(
        "datetime_to",
        moment(date[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (selectedStatus !== null)
      params.append("report_status", selectedStatus.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const loadMore = () => {
    lastCreated.current = reports[reports.length - 1]?.created_at;
    const formattedParams = formatParams();
    reportsApi.getReports(formattedParams);
  };

  const renderReports = () => {
    return reports?.map((item) => (
      <tr key={item?.id}>
        <td>{item?.id}</td>
        <td>{item?.status}</td>
        <td>{item?.time_field}</td>
        <td>{moment(item?.datetime_from).format("DD.MM.YY, HH:mm:ss")}</td>
        <td>{moment(item?.datetime_to).format("DD.MM.YY, HH:mm:ss")}</td>
        <td>{moment.utc(item?.created_at).local().format("DD.MM.YY, HH:mm:ss")}</td>
        <td>{moment.utc(item?.updated_at).local().format("DD.MM.YY, HH:mm:ss")}</td>
        {item?.report_url?.includes("https://") ? (
          <td>
            <Link className="report_url" to={item?.report_url} target="_blank">
              {item?.report_url}
            </Link>
          </td>
        ) : (
          <td
            onClick={() => {
              reportsApi.getReportURL({ report_id: item?.id });
            }}
            className="report_url"
          >
            Получить ссылку
          </td>
        )}
      </tr>
    ));
  };

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const closeCreateModal = () => {
    setCreateModal(false);
    setSelectedTimeField(null);
    setDateCreated(null);
  };

  const createReport = () => {
    const body = {
      datetime_from: moment(dateCreated?.[0].toString()).format("YYYY-MM-DDTHH:mm"),
      datetime_to: moment(dateCreated?.[1].toString()).format("YYYY-MM-DDTHH:mm"),
      time_field: selectedTimeField.value,
    };
    reportsApi.createReport(body, closeCreateModal);
  };

  return (
    <div className="reports container">
      {createModal && (
        <Popup title={"Создать репорт"} closePopup={closeCreateModal}>
          <div className="reports__select-created">
            <CustomSelect
              options={timeFieldData}
              placeholder={"Выберите тип времени"}
              selected={selectedTimeField}
              handleSelect={(e) => {
                setSelectedTimeField(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="reports__select-created">
            <DateRangePicker
              onChange={setDateCreated}
              format="dd.MM.yyyy HH:mm"
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Создать"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createReport}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Репорты"} />
      <div className="reports__top">
        <div className="reports__top-inner">
          <div className="reports__select">
            <CustomSelect
              options={statusData}
              placeholder={"Выберите статус"}
              selected={selectedStatus}
              handleSelect={(e) => {
                setSelectedStatus(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <DateRangePicker onChange={setDate} format="dd.MM.yyyy HH:mm" />
        </div>
        <div className="reports__top-btn">
          <Button
            text={"Создать репорт"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={openCreateModal}
          />
        </div>
      </div>
      <div className="reports__table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Статус</th>
              <th>Time Field</th>
              <th>Дата от</th>
              <th>Дата до</th>
              <th>Время создания</th>
              <th>Время обновления</th>
              <th>Получить ссылку на скачивание</th>
            </tr>
          </thead>
          <tbody>{renderReports()}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
