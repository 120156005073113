import { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiMerchantWallets from "../../Global/Api/merchantWallets.api";
import Button from "../../UI/Button";
import useInputHandler from "../../Global/Hooks/inputHandler";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import { CustomSelect } from "../../UI/Select";
import ApiCurrencies from "../../Global/Api/currencies.api";
import { NavLink } from "react-router-dom";
import moment from "moment/moment";
import { setMerchantWalletBillingEmpty } from "../../Store/Slice/unitradeSlice";
import "./index.scss";

export default function MerchantWalletDetails() {
  const parameters = useParams();
  const dispatch = useDispatch();
  const merchantApi = ApiMerchantWallets();
  const currenciesApi = ApiCurrencies();
  const navigate = useNavigate();
  const balance = useInputHandler("");
  const inputFee = useInputHandler("");
  const outputFee = useInputHandler("");
  const bonusInputFee = useInputHandler("");
  const bonusOutputFee = useInputHandler("");
  const [editModal, setEditModal] = useState(false);
  const [currencySelect, setcurrencySelect] = useState(null);
  const [limitCount, setLimit] = useState(100);
  const lastCreated = useRef(null);
  const [addModal, setAddModal] = useState(false);
  const [addNumber, setAddNumber] = useState(null);
  const [subModal, setSubModal] = useState(false);
  const [subNumber, setSubNumber] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const typeData = [
    {
      value: "web3",
      label: "WEB3",
    },
    {
      value: "order",
      label: "Order",
    },
    {
      value: "fee",
      label: "Fee",
    },
  ];

  const {
    userWallet,
    currencies,
    merchantWalletBilling,
    merchantWalletStatistics,
  } = useSelector((state) => state.rootReducer.unitradeSlice);

  useEffect(() => {
    merchantApi.getMerchantWalletsByWalletId(parameters?.id);
    merchantApi.getMerchantWalletsStatistics({
      wallet_id: parameters?.id,
    });
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    merchantApi.getMerchantWalletsBilling(formattedParams);
  }, [selectedTransaction])

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("wallet_id", parameters?.id);
    if (selectedTransaction !== null)
      params.append("transaction_type", selectedTransaction.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const loadMore = () => {
    const formattedParams = formatParams();
    lastCreated.current = merchantWalletBilling[merchantWalletBilling?.length - 1]?.created_at
    merchantApi.getMerchantWalletsBilling(formattedParams);
  };

  const openEditModal = (payment) => {
    setEditModal(true);
    console.log(userWallet);
    inputFee.setChange(
      userWallet?.inputFee === null ? "" : userWallet?.input_fee
    );
    outputFee.setChange(
      userWallet?.outputFee === null ? "" : userWallet?.output_fee
    );
    bonusInputFee.setChange(
      userWallet?.inputFee === null ? "" : userWallet?.bonus_input_fee
    );
    bonusOutputFee.setChange(
      userWallet?.outputFee === null ? "" : userWallet?.bonus_output_fee
    );
    setcurrencySelect({
      value: userWallet?.currency_id,
      label: userWallet?.currency?.currency,
    });
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const editSendData = () => {
    const body = {
      input_fee: inputFee.value,
      output_fee: outputFee.value,
      bonus_input_fee: bonusInputFee.value,
      bonus_output_fee: bonusOutputFee.value,
      currency_id: currencySelect.value,
      wallet_id: userWallet?.id,
    };
    merchantApi.editedMerchantWallets(body, closeEditModal);
  };

  const renderBilling = () => {
    return merchantWalletBilling?.map((billing) => (
      <tr key={billing?.id}>
        <td>
          <NavLink
            onClick={() => {
              localStorage.setItem("order_id", billing?.order_id);
            }}
            to={`/order/${billing?.order_id}`}
            className="user__btn"
          >
            {billing?.order_id}
          </NavLink>
        </td>
        <td>{billing?.currency_type}</td>
        <td>{billing?.operation_type}</td>
        <td>{billing?.transaction_type}</td>
        <td>{billing?.balance}</td>
        <td>{billing?.frozen_balance}</td>
        <td>{billing?.deposit}</td>
        <td>{billing?.merchant_input_fee}</td>
        <td>{billing?.merchant_output_fee}</td>
        <td>{billing?.trader_input_fee}</td>
        <td>{billing?.trader_output_fee}</td>
        <td>{billing?.agent_fee}</td>
        <td>
          {moment.utc(billing?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
      </tr>
    ));
  };

  const openAddModal = () => {
    setAddModal(true);
  };

  const closeAddModal = () => {
    setAddModal(false);
    setAddNumber("");
  };

  const openSubModal = () => {
    setSubModal(true);
  };

  const closeSubModal = () => {
    setSubModal(false);
    setSubNumber("");
  };

  const addDeposit = () => {
    const formattedParams = formatParams();
    const body = {
      wallet_id: userWallet?.id,
      balance: addNumber,
    };
    merchantApi.addDepositMerchantWallets(body, closeAddModal, formattedParams);
  };

  const subDeposit = () => {
    const formattedParams = formatParams();
    const body = {
      wallet_id: userWallet?.id,
      balance: subNumber,
    };
    merchantApi.subDepositMerchantWallets(body, closeSubModal, formattedParams);
  };

  return (
    <div className="walletDetails container">
      {editModal && (
        <Popup title={"Редактировать кошелек"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Input fee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="inputFee"
                  {...inputFee}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"OutputFee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="outputFee"
                  {...outputFee}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Bonus input fee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="bonusInputFee"
                  {...bonusInputFee}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Bonus output fee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="bonusOutputFee"
                  {...bonusOutputFee}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup-select">
                <p>Выберите валюту</p>
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  selected={currencySelect}
                  id="currency"
                  handleSelect={(e) => {
                    setcurrencySelect(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {addModal && (
        <Popup title={"Добавить депозит"} closePopup={closeAddModal}>
          <div className="add-deposit__inner">
            <Input
              label="Введите сумму"
              placeholder="Введите сумму"
              type="number"
              id="add"
              value={addNumber}
              handleChange={(e) => setAddNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addDeposit}
            />
          </div>
        </Popup>
      )}
      {subModal && (
        <Popup title={"Вычесть депозит"} closePopup={closeSubModal}>
          <div className="add-deposit__inner">
            <Input
              label="Введите сумму"
              placeholder="Введите сумму"
              type="number"
              id="sub"
              value={subNumber}
              handleChange={(e) => setSubNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subDeposit}
            />
          </div>
        </Popup>
      )}
      <Header title={"Детали кошелька"} />
      <div className="walletDetails__inner">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="userDetails__backBtn"
        >
          <img src="/assets/icons/back-arrow.svg" alt="" />
        </div>
        <div className="walletDetails__df">
          <div className="walletDetails__btn">
            <Button
              text={"Добавить депозит"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="accept"
              onClick={openAddModal}
            />
          </div>
          <div className="walletDetails__btn">
            <Button
              text={"Вычесть депозит"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="primary"
              onClick={openSubModal}
            />
          </div>
          <div className="walletDetails__edit-btn">
            <Button
              text="Редактировать"
              onClick={openEditModal}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
            />
          </div>
        </div>
        <table>
          <tr>
            <th>ID мерчанта</th>
            <td>{userWallet?.user_id}</td>
          </tr>
          <tr>
            <th>ID кошелька мерчанта</th>
            <td>{userWallet?.id}</td>
          </tr>
          <tr>
            <th>Баланс</th>
            <td>{userWallet?.balance}</td>
          </tr>
          <tr>
            <th>Комиссия на ввод</th>
            <td>{userWallet?.input_fee}</td>
          </tr>
          <tr>
            <th>Комиссия на вывод</th>
            <td>{userWallet?.output_fee}</td>
          </tr>
          <tr>
            <th>Бонусная комиссия на ввод</th>
            <td>{userWallet?.bonus_input_fee}</td>
          </tr>
          <tr>
            <th>Бонусная комиссия на вывод</th>
            <td>{userWallet?.bonus_output_fee}</td>
          </tr>
          <tr>
            <th>ID валюты</th>
            <td>{userWallet?.currency?.id}</td>
          </tr>
          <tr>
            <th>Валюта</th>
            <td>{userWallet?.currency?.currency}</td>
          </tr>
          <tr>
            <th>Код валюты</th>
            <td>{userWallet?.currency?.code}</td>
          </tr>

          <tr>
            <th>Курс</th>
            <td>{userWallet?.currency?.usdt_price}</td>
          </tr>
        </table>
      </div>

      <p className="common_balance">
        Common balance: {merchantWalletStatistics?.common_balance}
      </p>
        <div className="cryptoWalletBilling w-100">
          <h2 className="crypto-wallets__title mt-20">
            Биллинг по кошельку
            <div className="crypto-wallets__select">
              <CustomSelect
                options={typeData}
                placeholder={"Выберите тип транзакции"}
                selected={selectedTransaction}
                handleSelect={(e) => {
                  dispatch(setMerchantWalletBillingEmpty([]))
                  setSelectedTransaction(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          </h2>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Currency Type</th>
                <th>Operation Type</th>
                <th>Transaction Type</th>
                <th>Balance</th>
                <th>Frozen Balance</th>
                <th>Deposit</th>
                <th>Merchant Input Fee</th>
                <th>Merchant Output Fee</th>
                <th>Trader Input Fee</th>
                <th>Trader Output Fee</th>
                <th>Agent Fee</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>{renderBilling()}</tbody>
          </table>
          {merchantWalletBilling[merchantWalletBilling.length - 1]
            ?.transaction_type !== "web3" && (
            <div className="loadMore">
              <p
                onClick={() => {
                  loadMore();
                }}
              >
                Загрузить еще...
              </p>
            </div>
          )}
        </div>
    </div>
  );
}
