import { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";
import ApiClients from "../../Global/Api/clients.api";
import moment from "moment";
import Button from "../../UI/Button";
import { CustomSelect } from "../../UI/Select";
import Popup from "../../Components/Popup";
import { setClientsEmpty } from "../../Store/Slice/unitradeSlice";
import ApiUsers from "../../Global/Api/users.api";
import SearchComponent from "../../Components/Search";
import { useDebounce } from "../../Global/Hooks/useDebounce";

import "./index.scss";

export default function Clients() {
  const dispatch = useDispatch();
  const usersApi = ApiUsers();
  const useClients = ApiClients();
  const limitCount = 100;
  const lastCreated = useRef(null);
  const [infoClient, setInfoClient] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [banedSelect, setBanedSelect] = useState(null);
  const [rangSelect, setRangSelect] = useState(null);
  const [clientId, setClientId] = useState("");

  const { clients, usersMerchant } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );
  const [usersMerchantSelect, setUsersMerchantSelect] = useState(null);
  const [bannedSelect, setBannedSelect] = useState(null);

  const clearClients = () => {
    dispatch(setClientsEmpty([]));
  };

  const debouncedSearchIdValue = useDebounce(clientId, 300, clearClients);

  const rangs = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
  ];

  const baned = [
    {
      label: "Заблокировать",
      value: true,
    },
    {
      label: "Разблокировать",
      value: false,
    },
  ];

  const banedTypes = [
    {
      label: "Заблокированные",
      value: true,
    },
    {
      label: "Разблокированные",
      value: false,
    },
  ];

  useEffect(() => {
    const formattedParamsUsersMerchant = formatParamsUsersMerchant();
    usersApi.getData(formattedParamsUsersMerchant, "merchant");
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    useClients.getClients(formattedParams);
  }, [debouncedSearchIdValue, usersMerchantSelect, bannedSelect, rangSelect]);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    if (clientId !== "") params.append("client_id", clientId);
    if (usersMerchantSelect !== null)
      params.append("merchant_id", usersMerchantSelect.value);
    if (bannedSelect !== null) params.append("is_banned", bannedSelect.value);
    if (rangSelect !== null) params.append("client_rang", rangSelect.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsUsersMerchant = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "merchant_server");
    return params;
  };

  const loadMore = () => {
    lastCreated.current = clients[clients.length - 1]?.created_at;
    const formattedParams = formatParams();
    useClients.getClients(formattedParams);
  };

  const renderClients = useMemo(() => {
    return clients?.map((item) => (
      <tr key={item?.id}>
        <td>
          {moment.utc(item?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
        <td>{item?.client_id}</td>
        <td>
          <Link
            onClick={() => {
              localStorage.setItem("user_id", item?.merchant_id);
            }}
            target="_blank"
            to={`/user/${item?.merchant_id}`}
          >
            {item?.merchant_id}
          </Link>
        </td>
        <td>{item?.rang}</td>
        <td>{item?.success_order}</td>
        <td>{item?.is_banned === true ? "Заблокирован" : "Разброкирован"}</td>
        <td>
          <div
            onClick={() => {
              openEditModal(item);
            }}
            className="users__btn"
          >
            <img src="/assets/icons/edit-ad.svg" alt="" />
          </div>
        </td>
      </tr>
    ));
  }, [clients]);

  const openEditModal = (item) => {
    setInfoClient(item);
    setEditModal(true);
    setBanedSelect({
      value: item?.is_banned,
      label: item?.is_banned ? "Заблокировать" : "Разблокировать",
    });
  };

  const editSendData = () => {
    const body = {
      id: infoClient?.id,
    };
    useClients.banClient(body, closeEditModal);
  };

  const closeEditModal = () => {
    setInfoClient(null);
    setEditModal(false);
    setBanedSelect(null);
  };

  const handleSearchId = (searchTerm) => {
    setClientId(searchTerm);
  };

  return (
    <div className="clients container">
      {editModal && (
        <Popup title={"Блокировка клиента"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup-select mt-10">
                <p>Заблокировать/Разблокировать</p>
                <CustomSelect
                  options={baned}
                  isClearable={false}
                  selected={banedSelect}
                  handleSelect={(e) => {
                    setBanedSelect(e ?? null);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Клиенты"} />
      <div className="clients__inner">
        <div className="clients__top">
          <div className="clients__select">
            <CustomSelect
              options={usersMerchant?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите мерчанта"}
              selected={usersMerchantSelect}
              handleSelect={(e) => {
                setUsersMerchantSelect(e ?? null);
                dispatch(setClientsEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="clients__select">
            <CustomSelect
              options={banedTypes}
              placeholder={"Выберите статус"}
              selected={bannedSelect}
              handleSelect={(e) => {
                setBannedSelect(e ?? null);
                dispatch(setClientsEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="clients__select">
            <CustomSelect
              options={rangs}
              placeholder={"Выберите рейтинг"}
              selected={rangSelect}
              handleSelect={(e) => {
                setRangSelect(e ?? null);
                dispatch(setClientsEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="clients__input">
            <SearchComponent
              searchTerm={clientId}
              onSearch={handleSearchId}
              placeholder={"Поиск по ID клиента"}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Создано</th>
              <th>ID Клиента</th>
              <th>ID Мерчанта</th>
              <th>Рейтинг</th>
              <th>Кол-во успешных заявок</th>
              <th>Блокировка</th>
            </tr>
          </thead>
          <tbody>{renderClients}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
