import Header from "../../Components/Header";
import {useEffect, useRef, useState} from "react";
import ApiOrders from "../../Global/Api/orders.api";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import ApiSmsMessages from "../../Global/Api/smsMessages.api";
import { setSmsMessagesSearch } from "../../Store/Slice/unitradeSlice";
import { Link } from "react-router-dom";
import './index.scss'

export default function OrderDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userApi = ApiOrders();
  const {orderData, orderInfoData} = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );
  const message = useRef(null)
  const smsMessagesApi = ApiSmsMessages();
  const {smsMessages} = useSelector((state) => state.rootReducer.unitradeSlice)

  useEffect(() => {
    userApi.getOrderBillingDetails({order_id: localStorage.getItem('order_id')})
    userApi.getOrderDetails({order_id: localStorage.getItem('order_id')})
  }, [])

  const renderBilling = () => {
    return orderData?.order_billings?.map(billing => (
      <tr key={billing?.id}>
        <td>{billing?.order_stage}</td>
        <td>{billing?.order_substage}</td>
        <td>{billing?.order_amount}</td>
        <td>
          <p onClick={() => {
          message.current = billing?.sms_message_id
          if(message.current !== null) {
            searchSendData()
          }
        }} className="orderBilling__sms">{billing?.sms_message_id}</p>
          </td>
        <td>{moment.utc(billing?.created_at).local().format('DD.MM.YY, HH:mm:ss')}</td>
        <td>{billing?.updated_by}</td>
      </tr>
    ))
  }

  const searchSendData = () => {
    const body = {
      message_id: message.current
    }
    smsMessagesApi.getSmsSearch(body)
  }

  const renderSmsMessagesData = () => {
    return smsMessages.map((sms, index) => {
      return (
        <tr key={index}>
          <td>{moment.utc(sms?.created_at).local().format('DD-MM-YYYY')}</td>
          <td>{sms?.balance ? sms?.balance : 0}</td>
          <td>{sms?.android_version}</td>
          <td>{sms?.device_name}</td>
          <td>{sms?.bank_title}</td>
          <td>{sms?.package}</td>
          <td>{sms?.message}</td>
          <td>{sms?.order_amount}</td>
        </tr>
      )
    })
  }

  console.log('orderInfoData', orderInfoData);
  console.log('orderInfoData?.admin_receipts.receipt_id', orderInfoData?.admin_receipts?.receipt_id);

  return (
    <div className='orderDetails container'>
      <Header title={'Детали заявки'}/>
      <div className="orderDetails__inner">
        <div onClick={() => {
          dispatch(setSmsMessagesSearch([]))
          navigate(-1);
        }} className='userDetails__backBtn'>
          <img src="/assets/icons/back-arrow.svg" alt=""/>
        </div>
        <div className="order__details">
        <h2 className='crypto-wallets__title'>Billings</h2>
            <table>
          <thead>
          <tr>
            <th>Order Stage</th>
            <th>Order Substage</th>
            <th>Order Amount</th>
            <th>Sms Message Id</th>
            <th>Created At</th>
            <th>Updated By</th>
          </tr>
          </thead>
          <tbody>
            {renderBilling()}
          </tbody>
        </table>
        </div>
        {smsMessages.length > 0 && (
          <div className="orderDetailsSms">
          <h2 className='crypto-wallets__title'>SMS message</h2>
          <table>
          <thead>
          <tr>
            <th>Дата создания</th>
            <th>Баланс</th>
            <th>Версия андроида</th>
            <th>Название устройства</th>
            <th>Банк</th>
            <th>Package</th>
            <th>СМС</th>
            <th>Сумма заказа</th>
          </tr>
          </thead>
          <tbody>
          {renderSmsMessagesData()}
          </tbody>
        </table>
          </div>
        )}
        <div className="orderDetails__inner-data">
          <div className="orderDetails__box">
            <h2>Order details</h2>
            <div className="orderDetails__box-inner">
              <div className="orderDetails__box-left">
                <p className="orderDetails__title">ID</p>
                <p className="orderDetails__title">Type</p>
                <p className="orderDetails__title">Order Category</p>
                <p className="orderDetails__title">Requisite Type</p>
                <p className="orderDetails__title">Order Stage</p>
                <p className="orderDetails__title">Order Substage</p>
                <p className="orderDetails__title">Transaction Id</p>
                <p className="orderDetails__title">Internal Id</p>
                <p className="orderDetails__title">External Id</p>
                <p className="orderDetails__title">Card Number</p>
                <p className="orderDetails__title">Phone Number</p>
                <p className="orderDetails__title">Requisite Number</p>
                <p className="orderDetails__title">Client Id</p>
                <p className="orderDetails__title">Bank Id</p>
                <p className="orderDetails__title">Reason</p>
                <p className="orderDetails__title">Callback Result</p>
                <p className="orderDetails__title">Callback Message</p>
                {orderInfoData?.admin_receipt_url && (
                  <p className="orderDetails__title">Admin Receipt Url</p>
                )}
                <p className="orderDetails__title">Usdt Price</p>
                <p className="orderDetails__title">System Fee</p>
                <p className="orderDetails__title">Trader Fee</p>
                <p className="orderDetails__title">Merchant Fee</p>
                <p className="orderDetails__title">Agent Trader Fee</p>
                <p className="orderDetails__title">Agent Merchant Fee</p>
                <p className="orderDetails__title">Order Amount</p>
                <p className="orderDetails__title">System Amount</p>
                <p className="orderDetails__title">Trader Amount</p>
                <p className="orderDetails__title">Merchant Amount</p>
                <p className="orderDetails__title">Agent Trader Amount</p>
                <p className="orderDetails__title">Agent Merchant Amount</p>
                {orderInfoData?.receipt_url && <p className="orderDetails__title">Receipt Url</p>}
                {orderInfoData?.admin_receipts && <p className="orderDetails__title">Чеки прикрепленные администратором</p>}
                <p className="orderDetails__title">Payment Method Id</p>
                <p className="orderDetails__title">Merchant Id</p>
                <p className="orderDetails__title">Trader Id</p>
                <p className="orderDetails__title">Created At</p>
                <p className="orderDetails__title">Updated At</p>
                <p className="orderDetails__title">Completed At</p>
              </div>
              <div className="orderDetails__box-right orderDetails__box-right_width">
                <p>{orderInfoData?.id ? orderInfoData?.id : '--- --- ---'}</p>
                <p>{orderInfoData?.type ? orderInfoData?.type : '--- --- ---'}</p>
                <p>{orderInfoData?.order_category ? orderInfoData?.order_category : '--- --- ---'}</p>
                <p>{orderInfoData?.requisite_type ? orderInfoData?.requisite_type : '--- --- ---'}</p>
                <p>{orderInfoData?.order_stage ? orderInfoData?.order_stage : '--- --- ---'}</p>
                <p>{orderInfoData?.order_substage ? orderInfoData?.order_substage : '--- --- ---'}</p>
                <p>{orderInfoData?.transaction_id ? orderInfoData?.transaction_id : '--- --- ---'}</p>
                <p>{orderInfoData?.internal_id ? orderInfoData?.transaction_id : '--- --- ---'}</p>
                <p>{orderInfoData?.external_id ? orderInfoData?.transaction_id : '--- --- ---'}</p>
                <p>{orderInfoData?.card_number ? orderInfoData?.card_number : '--- --- ---'}</p>
                <p>{orderInfoData?.phone_number ? orderInfoData?.phone_number : '--- --- ---'}</p>
                <p>{orderInfoData?.requisite ? orderInfoData?.requisite : '--- --- ---'}</p>
                <p>{orderInfoData?.client_id ? orderInfoData?.client_id : '--- --- ---'}</p>
                <p>{orderInfoData?.bank_id ? orderInfoData?.bank_id : '--- --- ---'}</p>
                <p>{orderInfoData?.reason ? orderInfoData?.reason : '--- --- ---'}</p>
                <p>{orderInfoData?.callback_result ? 'true' : orderInfoData?.callback_result === false ? 'false' : '--- --- ---'}</p>
                <p>{orderInfoData?.callback_message !== null ? orderInfoData?.callback_message : '--- --- ---'}</p>
                {orderInfoData?.admin_receipt_url && <a href={orderInfoData?.admin_receipt_url} target="_blank">Смотреть чек</a>}
                <p>{orderInfoData?.usdt_price ? orderInfoData?.usdt_price : '--- --- ---'}</p>
                <p>{orderInfoData?.system_fee ? orderInfoData?.system_fee : '--- --- ---'}</p>
                <p>{orderInfoData?.trader_fee ? orderInfoData?.trader_fee : '--- --- ---'}</p>
                <p>{orderInfoData?.merchant_fee ? orderInfoData?.merchant_fee : '--- --- ---'}</p>
                <p>{orderInfoData?.agent_trader_fee ? orderInfoData?.agent_trader_fee : '--- --- ---'}</p>
                <p>{orderInfoData?.agent_merchant_fee ? orderInfoData?.agent_merchant_fee : '--- --- ---'}</p>
                <p>{orderInfoData?.order_amount ? orderInfoData?.order_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.system_amount ? orderInfoData?.system_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.trader_amount ? orderInfoData?.trader_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.merchant_amount ? orderInfoData?.merchant_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.agent_trader_amount ? orderInfoData?.agent_trader_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.agent_merchant_amount ? orderInfoData?.agent_merchant_amount : '--- --- ---'}</p>
                {orderInfoData?.receipt_url && <a href={orderInfoData?.receipt_url} target="_blank">Смотреть чек</a>}
                <div className="order__details-images">
                  {orderInfoData?.admin_receipts &&  orderInfoData?.admin_receipts.map((receipt, index) => {
                    return (
                        <a className="order__details-border" href={receipt.receipt_id} target="_blank">Смотреть чек</a>
                    )
                  })}
                </div>
                <p>{orderInfoData?.payment_method_id ? orderInfoData?.payment_method_id : '--- --- ---'}</p>
                <p>{orderInfoData?.merchant_id ? orderInfoData?.merchant_id : '--- --- ---'}</p>
                <p>{orderInfoData?.trader_id ? orderInfoData?.trader_id : '--- --- ---'}</p>
                <p>{orderInfoData?.created_at ? moment.utc(orderInfoData?.created_at).local().format('DD.MM.YY, HH:mm:ss') : '--- --- ---'}</p>
                <p>{orderInfoData?.updated_at ? moment.utc(orderInfoData?.updated_at).local().format('DD.MM.YY, HH:mm:ss') : '--- --- ---'}</p>
                <p>{orderInfoData?.completed_at ? moment.utc(orderInfoData?.completed_at).local().format('DD.MM.YY, HH:mm:ss') : '--- --- ---'}</p>
              </div>
            </div>
          </div>
          <div className="orderDetails__box">
            <h2>Ad Data</h2>
            <div className="orderDetails__box-inner">
              <div className="orderDetails__box-left">
                <p className="orderDetails__title">ID</p>
                <p className="orderDetails__title">Type</p>
                <p className="orderDetails__title">Requisite Type</p>
                <p className="orderDetails__title">Min Amount</p>
                <p className="orderDetails__title">Max Amount</p>
                <p className="orderDetails__title">Card Holder</p>
                <p className="orderDetails__title">Card Number</p>
                <p className="orderDetails__title">Month</p>
                <p className="orderDetails__title">Year</p>
                <p className="orderDetails__title">CVV</p>
                <p className="orderDetails__title">Phone Number</p>
                <p className="orderDetails__title">Requisite Number</p>
                <p className="orderDetails__title">Device Id</p>
                <p className="orderDetails__title">Comment</p>
                <p className="orderDetails__title">Is Active</p>
                <p className="orderDetails__title">Currency Id</p>
                <p className="orderDetails__title">Payment Method Id</p>
                <p className="orderDetails__title">User Id</p>
                <p className="orderDetails__title">Created At</p>
                <p className="orderDetails__title">Updated At</p>
                <p className="orderDetails__title">Country</p>
                <p className="orderDetails__title">Bank Name</p>
                <p className="orderDetails__title">Bank Id</p>
              </div>
              <div className="orderDetails__box-right orderDetails__box-right_width">
                <p>{orderInfoData?.ad_data?.id ? orderInfoData?.ad_data?.id : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.type ? orderInfoData?.ad_data?.type : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.requisite_type ? orderInfoData?.ad_data?.requisite_type : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.min_amount ? orderInfoData?.ad_data?.min_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.max_amount ? orderInfoData?.ad_data?.max_amount : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.card_holder ? orderInfoData?.ad_data?.card_holder : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.card_number ? orderInfoData?.ad_data?.card_number : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.month ? orderInfoData?.ad_data?.month : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.year ? orderInfoData?.ad_data?.year : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.cvv ? orderInfoData?.ad_data?.cvv : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.phone_number ? orderInfoData?.ad_data?.phone_number : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.requisite ? orderInfoData?.ad_data?.requisite : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.device_id ? orderInfoData?.ad_data?.device_id : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.comment ? orderInfoData?.ad_data?.comment : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.is_active ? 'true' : 'false'}</p>
                <p>{orderInfoData?.ad_data?.currency_id ? orderInfoData?.ad_data?.currency_id : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.payment_method_id ? orderInfoData?.ad_data?.payment_method_id : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.user_id ? orderInfoData?.ad_data?.user_id : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.created_at ? moment.utc(orderInfoData?.ad_data?.created_at).local().format('DD.MM.YY, HH:mm:ss') : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.updated_at ? moment.utc(orderInfoData?.ad_data?.updated_at).local().format('DD.MM.YY, HH:mm:ss') : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.country ? orderInfoData?.ad_data?.country : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.payment_method?.bank_name ? orderInfoData?.ad_data?.payment_method?.bank_name : '--- --- ---'}</p>
                <p>{orderInfoData?.ad_data?.payment_method?.bank_id ? orderInfoData?.ad_data?.payment_method?.bank_id : '--- --- ---'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
