import React, { useEffect, useState, useRef } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import ApiServiceTraderCredentials from "../../Global/Api/serviceTraderCredentials.api";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import { NavLink } from "react-router-dom";
import { CustomSelect } from "../../UI/Select";
import useInputHandler from "../../Global/Hooks/inputHandler";
import { Link } from "react-router-dom";

import "./index.scss";
import { setServiceTraderWalletBillingEmpty } from "../../Store/Slice/unitradeSlice";

export default function ServiceTraderWallet() {
  const serviceCredentialsApi = ApiServiceTraderCredentials();
  const navigate = useNavigate();
  const parameters = useParams();
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [addFrozenModal, setAddFrozenModal] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [subBalanceModal, setSubBalanceModal] = useState(false);
  const [subFrozenModal, setSubFrozenModal] = useState(false);
  const [addNumber, setAddNumber] = useState(null);
  const [addBalanceNumber, setAddBalanceNumber] = useState(null);
  const [addFrozenNumber, setAddFrozenNumber] = useState(null);
  const [subNumber, setSubNumber] = useState(null);
  const [subBalanceNumber, setSubBalanceNumber] = useState(null);
  const [subFrozenNumber, setSubFrozenNumber] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [addLimitModal, setAddLimitModal] = useState(false);
  const [subLimitModal, setSubLimitModal] = useState(false);
  const [addLimitOutputModal, setAddLimitOutputModal] = useState(false);
  const [subLimitOutputModal, setSubLimitOutputModal] = useState(false);
  const inputLimit = useInputHandler("");
  const subInputLimit = useInputHandler("");
  const outputLimit = useInputHandler("");
  const subOutputLimit = useInputHandler("");
  const limitCount = 200;
  const lastCreated = useRef(null);
  const [editModal, setEditModal] = useState(false);
  const dailyInputLimit = useInputHandler("");
  const dailyOutputLimit = useInputHandler("");
  const bonusInputFee = useInputHandler("");
  const bonusOutputFee = useInputHandler("");
  const inputFee = useInputHandler("");
  const outputFee = useInputHandler("");
  const typeData = [
    {
      value: "web3",
      label: "WEB3",
    },
    {
      value: "order",
      label: "Order",
    },
    {
      value: "fee",
      label: "Fee",
    },
  ];

  const {
    serviceTraderWalletDetails,
    serviceTraderWalletBilling,
    serviceTraderWalletStatistics,
    serviceTraderData,
  } = useSelector((state) => state.rootReducer.unitradeSlice);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("wallet_id", parameters?.id);
    if (selectedTransaction !== null)
      params.append("transaction_type", selectedTransaction.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsEdited = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("wallet_id", parameters?.id);
    if (selectedTransaction !== null)
      params.append("transaction_type", selectedTransaction.value);
    return params;
  };

  useEffect(() => {
    serviceCredentialsApi.getWalletDetails({ wallet_id: parameters?.id });
    serviceCredentialsApi.getStatisticWallet({ wallet_id: parameters?.id });
    serviceCredentialsApi.getServiceTraderData({
      service_trader_data_id: parameters?.id,
    });
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    serviceCredentialsApi.getBillingWallet(formattedParams);
  }, [selectedTransaction]);

  const addDeposit = () => {
    const formattedParams = formatParamsEdited();
    const body = {
      wallet_id: parameters?.id,
      amount: addNumber,
    };
    serviceCredentialsApi.addDepositToWallet(body, formattedParams);
    setAddModal(false);
  };

  const addBalance = () => {
    const formattedParams = formatParamsEdited();
    const body = {
      wallet_id: parameters?.id,
      amount: addBalanceNumber,
    };
    serviceCredentialsApi.addBalanceToWallet(body, formattedParams);
    setAddBalanceModal(false);
  };

  const addFrozenBalance = () => {
    const formattedParams = formatParamsEdited();
    const body = {
      wallet_id: parameters?.id,
      amount: addFrozenNumber,
    };
    serviceCredentialsApi.addFrozenToWallet(body, formattedParams);
    setAddFrozenModal(false);
  };

  const subDeposit = () => {
    const formattedParams = formatParamsEdited();
    const body = {
      wallet_id: parameters?.id,
      amount: subNumber,
    };
    serviceCredentialsApi.substractDepositFromWallet(body, formattedParams);
    setSubModal(false);
  };

  const subBalance = () => {
    const formattedParams = formatParamsEdited();
    const body = {
      wallet_id: parameters?.id,
      amount: subBalanceNumber,
    };
    serviceCredentialsApi.substractBalanceFromWallet(body, formattedParams);
    setSubBalanceModal(false);
  };

  const subFrozen = () => {
    const formattedParams = formatParamsEdited();
    const body = {
      wallet_id: parameters?.id,
      amount: subFrozenNumber,
    };
    serviceCredentialsApi.substractFrozenFromWallet(body, formattedParams);
    setSubFrozenModal(false);
  };

  const openAddModal = () => {
    setAddModal(true);
  };

  const openAddBalanceModal = () => {
    setAddBalanceModal(true);
  };

  const openAddFrozenModal = () => {
    setAddFrozenModal(true);
  };
  const closeAddModal = () => {
    setAddModal(false);
  };

  const closeAddBalanceModal = () => {
    setAddBalanceModal(false);
  };

  const closeAddFrozenModal = () => {
    setAddFrozenModal(false);
  };

  const openSubModal = () => {
    setSubModal(true);
  };

  const openSubBalanceModal = () => {
    setSubBalanceModal(true);
  };

  const openSubFrozenModal = () => {
    setSubFrozenModal(true);
  };

  const closeSubModal = () => {
    setSubModal(false);
  };

  const closeSubBalanceModal = () => {
    setSubBalanceModal(false);
  };

  const closeSubFrozenModal = () => {
    setSubFrozenModal(false);
  };

  const loadMore = () => {
    lastCreated.current =
      serviceTraderWalletBilling[
        serviceTraderWalletBilling.length - 1
      ]?.created_at;
    const formattedParams = formatParams();
    serviceCredentialsApi.getBillingWallet(formattedParams);
  };

  const renderBilling = () => {
    return serviceTraderWalletBilling?.map((billing) =>
      selectedTransaction?.value === "fee" ? (
        <tr key={billing?.id}>
          <td>{billing?.transaction_type}</td>
          <td>{billing?.trader_bonus_input_fee}</td>
          <td>{billing?.trader_bonus_output_fee}</td>
          <td>{billing?.trader_input_fee}</td>
          <td>{billing?.trader_output_fee}</td>
          <td>
            {moment
              .utc(billing?.created_at)
              .local()
              .format("DD.MM.YY, HH:mm:ss")}
          </td>
        </tr>
      ) : (
        <tr key={billing?.id}>
          <td>
            <NavLink
              onClick={() => {
                localStorage.setItem("order_id", billing?.order_id);
              }}
              to={`/order/${billing?.order_id}`}
              className="user__btn"
            >
              {billing?.order_id}
            </NavLink>
          </td>
          <td>{billing?.currency_type}</td>
          <td>{billing?.operation_type}</td>
          <td>{billing?.transaction_type}</td>
          <td>{billing?.balance}</td>
          <td>{billing?.frozen_balance}</td>
          <td>{billing?.deposit}</td>
          <td>
            {moment
              .utc(billing?.created_at)
              .local()
              .format("DD.MM.YY, HH:mm:ss")}
          </td>
        </tr>
      )
    );
  };

  const closeEditModal = () => {
    setEditModal(false);
    inputFee.setChange("");
    outputFee.setChange("");
    bonusInputFee.setChange("");
    bonusOutputFee.setChange("");
    dailyInputLimit.setChange("");
    dailyOutputLimit.setChange("");
  };

  const openInputLimit = () => {
    setAddLimitModal(true);
  };

  const openSubInputLimit = () => {
    setSubLimitModal(true);
  };

  const openOutputLimit = () => {
    setAddLimitOutputModal(true);
  };

  const openSubOutputLimit = () => {
    setSubLimitOutputModal(true);
  };

  const closeAddLimitModal = () => {
    setAddLimitModal(false);
    inputLimit.setChange("");
  };

  const closeSubLimitModal = () => {
    setSubLimitModal(false);
    subInputLimit.setChange("");
  };

  const closeAddLimitOutputModal = () => {
    setAddLimitOutputModal(false);
    outputLimit.setChange("");
  };

  const closeSubLimitOutputModal = () => {
    setSubLimitOutputModal(false);
    subOutputLimit.setChange("");
  };

  const addLimit = () => {
    const body = {
      service_trader_data_id: parameters?.id,
      input_limit: inputLimit.value,
    };
    serviceCredentialsApi.addInputLimitServiceTrader(body, closeAddLimitModal);
  };

  const subLimit = () => {
    const body = {
      service_trader_data_id: parameters?.id,
      input_limit: subInputLimit.value,
    };
    serviceCredentialsApi.subInputLimitServiceTrader(body, closeSubLimitModal);
  };

  const addOutputLimit = () => {
    const body = {
      service_trader_data_id: parameters?.id,
      output_limit: outputLimit.value,
    };
    serviceCredentialsApi.addOutputLimitServiceTrader(
      body,
      closeAddLimitOutputModal
    );
  };

  const subOutputLimitAction = () => {
    const body = {
      service_trader_data_id: parameters?.id,
      output_limit: subOutputLimit.value,
    };
    serviceCredentialsApi.subOututLimitServiceTrader(
      body,
      closeSubLimitOutputModal
    );
  };

  const openEditModal = () => {
    setEditModal(true);
    inputFee.setChange(
      serviceTraderData?.input_fee === null ? "" : serviceTraderData?.input_fee
    );
    outputFee.setChange(
      serviceTraderData?.output_fee === null
        ? ""
        : serviceTraderData?.output_fee
    );
    bonusInputFee.setChange(
      serviceTraderData?.bonus_input_fee === null
        ? ""
        : serviceTraderData?.bonus_input_fee
    );
    bonusOutputFee.setChange(
      serviceTraderData?.bonus_output_fee === null
        ? ""
        : serviceTraderData?.bonus_output_fee
    );
    dailyInputLimit.setChange(
      serviceTraderData?.daily_input_limit === null
        ? ""
        : serviceTraderData?.daily_input_limit
    );
    dailyOutputLimit.setChange(
      serviceTraderData?.daily_output_limit === null
        ? ""
        : serviceTraderData?.daily_output_limit
    );
  };

  const editSendData = () => {
    const body = {
      service_trader_data_id: parameters?.id,
      input_fee: inputFee.value,
      output_fee: outputFee.value,
      bonus_input_fee: bonusInputFee.value,
      bonus_output_fee: bonusOutputFee.value,
      daily_input_limit: dailyInputLimit.value,
      daily_output_limit: dailyOutputLimit.value,
    };
    serviceCredentialsApi.editServiceTraderData(body, closeEditModal);
  };

  return (
    <div className="serviceTraderWallet container">
      {addModal && (
        <Popup title={"Добавить депозит"} closePopup={closeAddModal}>
          <div className="add-deposit__inner">
            <Input
              label="Добавить депозит"
              placeholder="Введите число"
              type="number"
              id="add"
              value={addNumber}
              handleChange={(e) => setAddNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addDeposit}
            />
          </div>
        </Popup>
      )}
      {addBalanceModal && (
        <Popup title={"Добавить баланс"} closePopup={closeAddBalanceModal}>
          <div className="add-deposit__inner">
            <Input
              label="Добавить баланс"
              placeholder="Введите число"
              type="number"
              id="add"
              value={addBalanceNumber}
              handleChange={(e) => setAddBalanceNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addBalance}
            />
          </div>
        </Popup>
      )}
      {addFrozenModal && (
        <Popup
          title={"Добавить замороженный баланс"}
          closePopup={closeAddFrozenModal}
        >
          <div className="add-deposit__inner">
            <Input
              label="Добавить замороженный баланс"
              placeholder="Введите число"
              type="number"
              id="add"
              value={addFrozenNumber}
              handleChange={(e) => setAddFrozenNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addFrozenBalance}
            />
          </div>
        </Popup>
      )}
      {subModal && (
        <Popup title={"Вычесть депозит"} closePopup={closeSubModal}>
          <div className="add-deposit__inner">
            <Input
              label="Вычесть депозит"
              placeholder="Введите число"
              type="number"
              id="sub"
              value={subNumber}
              handleChange={(e) => setSubNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subDeposit}
            />
          </div>
        </Popup>
      )}
      {subBalanceModal && (
        <Popup title={"Вычесть баланс"} closePopup={closeSubBalanceModal}>
          <div className="add-deposit__inner">
            <Input
              label="Вычесть баланс"
              placeholder="Введите число"
              type="number"
              id="sub"
              value={subBalanceNumber}
              handleChange={(e) => setSubBalanceNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subBalance}
            />
          </div>
        </Popup>
      )}
      {subFrozenModal && (
        <Popup
          title={"Вычесть замороженный баланс"}
          closePopup={closeSubFrozenModal}
        >
          <div className="add-deposit__inner">
            <Input
              label="Вычесть замороженный баланс"
              placeholder="Введите число"
              type="number"
              id="sub"
              value={subFrozenNumber}
              handleChange={(e) => setSubFrozenNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subFrozen}
            />
          </div>
        </Popup>
      )}
      {addLimitModal && (
        <Popup title={"Добавить лимит на ввод"} closePopup={closeAddLimitModal}>
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на ввод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={inputLimit.value}
              handleChange={(e) => inputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addLimit}
            />
          </div>
        </Popup>
      )}
      {addLimitOutputModal && (
        <Popup
          title={"Добавить лимит на вывод"}
          closePopup={closeAddLimitOutputModal}
        >
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на вывод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={outputLimit.value}
              handleChange={(e) => outputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addOutputLimit}
            />
          </div>
        </Popup>
      )}
      {subLimitOutputModal && (
        <Popup
          title={"Вычесть лимит на вывод"}
          closePopup={closeSubLimitOutputModal}
        >
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на вывод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={subOutputLimit.value}
              handleChange={(e) => subOutputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subOutputLimitAction}
            />
          </div>
        </Popup>
      )}
      {subLimitModal && (
        <Popup title={"Вычесть лимит на ввод"} closePopup={closeSubLimitModal}>
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на ввод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={subInputLimit.value}
              handleChange={(e) => subInputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subLimit}
            />
          </div>
        </Popup>
      )}
      {editModal && (
        <Popup title={"Изменить данные трейдера"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Дневной лимит ввода"}
                  placeholder="Введите лимит ввода"
                  type="text"
                  onlyNumber={true}
                  id="dailyInputLimit"
                  {...dailyInputLimit}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Дневной лимит вывода"}
                  placeholder="Введите лимит вывода"
                  type="text"
                  onlyNumber={true}
                  id="dailyOutputLimit"
                  {...dailyOutputLimit}
                />
              </div>
            </div>
            <div className="popup__input">
              <Input
                label={"Input fee"}
                placeholder="Введите Input fee"
                type="text"
                onlyNumber={true}
                id="inputFee"
                {...inputFee}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Output fee"}
                placeholder="Введите outputFee"
                type="text"
                onlyNumber={true}
                id="outputFee"
                {...outputFee}
              />
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Bonus input fee"}
                  placeholder="Введите . . ."
                  type="text"
                  onlyNumber={true}
                  id="bonusInputFee"
                  {...bonusInputFee}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Bonus output fee"}
                  placeholder="Введите . . ."
                  type="text"
                  onlyNumber={true}
                  id="bonusOutputFee"
                  {...bonusOutputFee}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Кошелек сервисного трейдера"} />
      <div className="serviceTraderWallet__inner">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="userDetails__backBtn"
        >
          <img src="/assets/icons/back-arrow.svg" alt="" />
        </div>
        <div className="serviceTraderWallet__btns">
          <div className="crypto-wallets__deposit-btn">
            <Button
              className="crypto-wallets__add-btn"
              text={"Добавить депозит"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="accept"
              onClick={openAddModal}
            />
          </div>
          <div className="crypto-wallets__deposit-btn">
            <Button
              className="crypto-wallets__add-btn"
              text={"Добавить баланс"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="accept"
              onClick={openAddBalanceModal}
            />
          </div>
          <div className="crypto-wallets__deposit-btn">
            <Button
              className="crypto-wallets__add-btn"
              text={"Добавить замороженный баланс"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="accept"
              onClick={openAddFrozenModal}
            />
          </div>
          <div className="crypto-wallets__deposit-btn">
            <Button
              text={"Вычесть депозит"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="primary"
              onClick={openSubModal}
            />
          </div>
          <div className="crypto-wallets__deposit-btn">
            <Button
              text={"Вычесть баланс"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="primary"
              onClick={openSubBalanceModal}
            />
          </div>
          <div className="crypto-wallets__deposit-btn">
            <Button
              text={"Вычесть замороженный баланс"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={5}
              rightPadding={5}
              type="primary"
              onClick={openSubFrozenModal}
            />
          </div>
        </div>
        <div className="userDetails__table">
          <table>
            <thead>
              <tr>
                <th>Мерчант</th>
                <th>Валюта</th>
                <th>Баланс</th>
                <th>Депозит</th>
                <th>Замороженный баланс</th>
                <th>Дата создания</th>
                <th>Дата изменения</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link target="_blank" to={`/user/${serviceTraderWalletDetails?.merchant?.id}`}>
                    {serviceTraderWalletDetails?.merchant?.notice
                      ? serviceTraderWalletDetails?.merchant?.notice
                      : serviceTraderWalletDetails?.merchant?.id}
                  </Link>
                </td>
                <td>{serviceTraderWalletDetails?.currency?.currency}</td>
                <td>{serviceTraderWalletDetails?.balance}</td>
                <td>{serviceTraderWalletDetails?.deposit}</td>
                <td>{serviceTraderWalletDetails?.frozen_balance}</td>
                <td>
                  {moment
                    .utc(serviceTraderWalletDetails?.created_at)
                    .local()
                    .format("DD.MM.YY, HH:mm:ss")}
                </td>
                <td>
                  {serviceTraderWalletDetails?.updated_at
                    ? moment
                        .utc(serviceTraderWalletDetails?.updated_at)
                        .local()
                        .format("DD.MM.YY, HH:mm:ss")
                    : "--- --- ---"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 className="crypto-wallets__title">Данные трейдера</h2>
        <div className="traders-data__top-right">
          <div className="editFa__btn">
            <Button
              text={"Изменить данные трейдера"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={openEditModal}
            />
          </div>
          <div className="traders-data__btn">
            <Button
              text={"Добавить лимит на ввод"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={4}
              rightPadding={4}
              type="accept"
              onClick={openInputLimit}
            />
          </div>
          <div className="traders-data__btn">
            <Button
              text={"Добавить лимит на вывод"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={4}
              rightPadding={4}
              type="accept"
              onClick={openOutputLimit}
            />
          </div>
          <div className="traders-data__btn">
            <Button
              text={"Вычесть лимит на ввод"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={4}
              rightPadding={4}
              onClick={openSubInputLimit}
            />
          </div>
          <div className="traders-data__btn">
            <Button
              text={"Вычесть лимит на вывод"}
              topPadding={10}
              bottomPadding={10}
              leftPadding={4}
              rightPadding={4}
              onClick={openSubOutputLimit}
            />
          </div>
        </div>
        <table className="serviceTraderWallet__data">
          <tr>
            <th>Комиссия на ввод</th>
            <td>{serviceTraderData?.input_fee}</td>
          </tr>
          <tr>
            <th>Комиссия на вывод</th>
            <td>{serviceTraderData?.output_fee}</td>
          </tr>
          <tr>
            <th>Комиссия на ввод бонус</th>
            <td>{serviceTraderData?.bonus_input_fee}</td>
          </tr>
          <tr>
            <th>Комиссия на вывод бонус</th>
            <td>{serviceTraderData?.bonus_output_fee}</td>
          </tr>
          <tr>
            <th>Дневной лимит на ввод</th>
            <td>{serviceTraderData?.daily_input_limit}</td>
          </tr>
          <tr>
            <th>Дневной лимит на вывод</th>
            <td>{serviceTraderData?.daily_output_limit}</td>
          </tr>
          <tr>
            <th>Остаток лимита на ввод</th>
            <td>{serviceTraderData?.input_limit}</td>
          </tr>
          <tr>
            <th>Остаток лимита на вывод</th>
            <td>{serviceTraderData?.output_limit}</td>
          </tr>
          <tr>
            <th>Кол-во заявок на ввод</th>
            <td>{serviceTraderData?.total_buy_orders}</td>
          </tr>
          <tr>
            <th>Кол-во заявок на вывод</th>
            <td>{serviceTraderData?.total_sell_orders}</td>
          </tr>
          <tr>
            <th>Кол-во успешных заявок на ввод</th>
            <td>{serviceTraderData?.total_success_buy_orders}</td>
          </tr>
          <tr>
            <th>Кол-во успешных заявок на вывод</th>
            <td>{serviceTraderData?.total_success_sell_orders}</td>
          </tr>
          <tr>
            <th>Кол-во отмененных заявок на ввод</th>
            <td>{serviceTraderData?.total_cancel_buy_orders}</td>
          </tr>
          <tr>
            <th>Кол-во отмененных заявок на вывод</th>
            <td>{serviceTraderData?.total_cancel_sell_orders}</td>
          </tr>
          <tr>
            <th>Кол-во апелляций на ввод</th>
            <td>{serviceTraderData?.total_appeal_buy_orders}</td>
          </tr>
          <tr>
            <th>Кол-во апелляций на вывод</th>
            <td>{serviceTraderData?.total_appeal_sell_orders}</td>
          </tr>
        </table>
        {serviceTraderWalletStatistics !== null && (
          <div className="cryptoWalletBilling">
            <h2 className="crypto-wallets__title">Статистика</h2>
            <table>
              <thead>
                <tr>
                  <th>Common Balance</th>
                  <th>Common Deposit</th>
                  <th>Common Frozen Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{serviceTraderWalletStatistics?.common_balance}</td>
                  <td>{serviceTraderWalletStatistics?.common_deposit}</td>
                  <td>
                    {serviceTraderWalletStatistics?.common_frozen_balance}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <h2 className="crypto-wallets__title">
          Биллинг по кошельку
          <div className="crypto-wallets__select">
            <CustomSelect
              options={typeData}
              placeholder={"Выберите тип транзакции"}
              selected={selectedTransaction}
              handleSelect={(e) => {
                dispatch(setServiceTraderWalletBillingEmpty([]));
                setSelectedTransaction(e ?? null);
              }}
              isClearable={true}
            />
          </div>
        </h2>

        <table>
          <thead>
            {selectedTransaction?.value !== "fee" ? (
              <tr>
                <th>Order ID</th>
                <th>Currency Type</th>
                <th>Operation Type</th>
                <th>Transaction Type</th>
                <th>Balance</th>
                <th>Frozen Balance</th>
                <th>Deposit</th>
                <th>Created At</th>
              </tr>
            ) : (
              <tr>
                <th>Transaction Type</th>
                <th>Trader bonus input fee</th>
                <th>Trader bonus output fee</th>
                <th>Trader input fee</th>
                <th>Trader output fee</th>
                <th>Created At</th>
              </tr>
            )}
          </thead>
          {serviceTraderWalletBilling?.length > 0 && (
            <tbody>{renderBilling()}</tbody>
          )}
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
