import React from 'react'
import './index.scss'

export default function Popup({title, children, closePopup}) {

  return (
      <div className="popup">
        <div className="popup__content">
          <div className="popup__content-head">
            <p className="popup__title">{title}</p>
            <div onClick={closePopup} className="popup__close"><img src='/assets/icons/close.svg' alt=""/></div>
          </div>
          {children}
        </div>
        <div onClick={closePopup} className="popup__bg"></div>
      </div>
  )
}
