import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useGetClientsMutation, useBanClientMutation } from "../../Store/Builder/unitrade.api";
import { setLoader, setClients, setClient } from "../../Store/Slice/unitradeSlice";

const ApiClients = () => {
  const dispatch = useDispatch();
  const [apiGetClients] = useGetClientsMutation();
  const [apiBanClient] = useBanClientMutation();

  const getClients = (params) => {
    dispatch(setLoader(true));

    apiGetClients(params)
      .unwrap()
      .then((res) => {
        dispatch(setClients(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const banClient = (params, closeEditModal) => {
    dispatch(setLoader(true));

    apiBanClient(params)
      .unwrap()
      .then((res) => {
        dispatch(setClient(res));
        closeEditModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getClients,
    banClient
  };
};

export default ApiClients;
