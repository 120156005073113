import {
  setAvgCompleted,
  setCancelPercent,
  setLoader,
  setStatisticData,
  setStatisticsOrders,
  setTradersKPICommonStatistics,
  setTradersKPIArray,
  setTraderKPIStatistic,
  setTraderKPIStatisticArray,
  setStatisticRejectOrders,
  setAvgUsdt
} from "../../Store/Slice/unitradeSlice";
import {
  useAvgCompletedMutation,
  useCancelPercentMutation,
  useGetStatisticsMutation,
  useStatisticsOrdersMutation,
  useTradersKPIStatisticsMutation,
  useTradersKPIMutation,
  useTraderKPIStatisticMutation,
  useRejectOrderStatisticsMutation,
  useServiceTradersKPIStatisticsMutation,
  useServiceTradersKPIMutation,
  useStatisticsServiceOrdersMutation,
  useTraderServiceKPIStatisticMutation,
  useGetAvgUsdtStatisticsMutation
} from "../../Store/Builder/unitrade.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";

const ApiStatistics = () => {
  const dispatch = useDispatch();
  const [apiGetStatistic] = useGetStatisticsMutation();
  const [apiGetStatisticsOrders] = useStatisticsOrdersMutation();
  const [apiGetStatisticsServiceOrders] = useStatisticsServiceOrdersMutation();
  const [apiGetAvgComleted] = useAvgCompletedMutation();
  const [apiGetCancelPercent] = useCancelPercentMutation();
  const [apiGetTradersKPICommonStatistics] = useTradersKPIStatisticsMutation();
  const [apiGetServiceTradersKPICommonStatistics] =
    useServiceTradersKPIStatisticsMutation();
  const [apiGetTradersKPI] = useTradersKPIMutation();
  const [apiGetServiceTradersKPI] = useServiceTradersKPIMutation();
  const [apiGetTraderKPIStatistic] = useTraderKPIStatisticMutation();
  const [apiGetTraderServiceKPIStatistic] = useTraderServiceKPIStatisticMutation();
  const [apiGetRejectsOrdersStatistic] = useRejectOrderStatisticsMutation();
  const [apiGetAvgUsdtStatistic] = useGetAvgUsdtStatisticsMutation();

  const getData = (params) => {
    dispatch(setLoader(true));
    apiGetStatistic(params)
      .unwrap()
      .then((res) => {
        dispatch(setStatisticData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getRejectOrdersStatistics = (params) => {
    dispatch(setLoader(true));
    apiGetRejectsOrdersStatistic(params)
      .unwrap()
      .then((res) => {
        dispatch(setStatisticRejectOrders(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getStatisticsOrders = (params) => {
    dispatch(setLoader(true));
    apiGetStatisticsOrders(params)
      .unwrap()
      .then((res) => {
        dispatch(setStatisticsOrders(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getStatisticsServiceOrders = (params) => {
    dispatch(setLoader(true));
    apiGetStatisticsServiceOrders(params)
      .unwrap()
      .then((res) => {
        dispatch(setStatisticsOrders(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getAvgCompleted = (params) => {
    dispatch(setLoader(true));
    apiGetAvgComleted(params)
      .unwrap()
      .then((res) => {
        dispatch(setAvgCompleted(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getCancelPercent = (params) => {
    dispatch(setLoader(true));
    apiGetCancelPercent(params)
      .unwrap()
      .then((res) => {
        dispatch(setCancelPercent(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getTradersKPICommonStatistics = (body) => {
    dispatch(setLoader(true));
    apiGetTradersKPICommonStatistics(body)
      .unwrap()
      .then((res) => {
        dispatch(setTradersKPICommonStatistics(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getServiceTradersKPICommonStatistics = (body) => {
    dispatch(setLoader(true));
    apiGetServiceTradersKPICommonStatistics(body)
      .unwrap()
      .then((res) => {
        dispatch(setTradersKPICommonStatistics(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getTradersKPI = (body) => {
    dispatch(setLoader(true));
    apiGetTradersKPI(body)
      .unwrap()
      .then((res) => {
        dispatch(setTradersKPIArray(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getServiceTradersKPI = (body) => {
    dispatch(setLoader(true));
    apiGetServiceTradersKPI(body)
      .unwrap()
      .then((res) => {
        dispatch(setTradersKPIArray(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getTraderKPIStatistic = (body) => {
    dispatch(setLoader(true));
    apiGetTraderKPIStatistic(body)
      .unwrap()
      .then((res) => {
        dispatch(setTraderKPIStatistic(res));
        dispatch(setTraderKPIStatisticArray(res?.list_traders_kpi));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getTraderServiceKPIStatistic = (body) => {
    console.log(body);
    dispatch(setLoader(true));
    apiGetTraderServiceKPIStatistic(body)
      .unwrap()
      .then((res) => {
        dispatch(setTraderKPIStatistic(res));
        dispatch(setTraderKPIStatisticArray(res?.list_traders_kpi));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getAvgUsdt = (params) => {
    console.log(params);
    dispatch(setLoader(true));
    apiGetAvgUsdtStatistic(params)
      .unwrap()
      .then((res) => {
        dispatch(setAvgUsdt(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    getStatisticsOrders,
    getAvgCompleted,
    getCancelPercent,
    getTradersKPICommonStatistics,
    getTraderServiceKPIStatistic,
    getTradersKPI,
    getTraderKPIStatistic,
    getRejectOrdersStatistics,
    getServiceTradersKPICommonStatistics,
    getServiceTradersKPI,
    getStatisticsServiceOrders,
    getAvgUsdt,
  };
};

export default ApiStatistics;
