import {
  setLoader,
  setPaymentsMethods,
  setSmsHistory,
  setSmsHistorySearch,
  setSmsMessages
} from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";
import {useDispatch} from "react-redux";
import {
  useGetPaymentMethodsMutation,
  useGetSmsHistoryMutation,
  useGetSmsHistorySearchMutation
} from "../../Store/Builder/unitrade.api";

const ApiSmsHistory = () => {
  const dispatch = useDispatch();
  const [apiGetSmsHistory] = useGetSmsHistoryMutation();
  const [apiGetSmsHistorySearch] = useGetSmsHistorySearchMutation()
  const getData = (params, action) => {
    dispatch(setLoader(true))
    apiGetSmsHistory(params)
      .unwrap()
      .then((res) => {
        dispatch(setSmsHistory(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getSmsSearch = (body) => {
    dispatch(setLoader(true))
    apiGetSmsHistorySearch(body)
      .unwrap()
      .then((res) => {
        let array = []
        array.push(res)
        dispatch(setSmsHistorySearch(array))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    getSmsSearch
  }
}

export default ApiSmsHistory;
