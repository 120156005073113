import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import ApiStatistics from "../../Global/Api/statistics.api";
import { CustomSelect } from "../../UI/Select";
import ApiCurrencies from "../../Global/Api/currencies.api";
import { setTradersKPIArrayEmpty } from "../../Store/Slice/unitradeSlice";
import { Link } from "react-router-dom";

import "./index.scss";

export default function KPI() {
  const dispatch = useDispatch();
  const limitCount = 100;
  const statisticsApi = ApiStatistics();
  const lastId = useRef(null);
  const [tabService, setTabService] = useState(false);
  const currenciesApi = ApiCurrencies();
  const [currencySelect, setcurrencySelect] = useState(null);
  const { tradersKPICommonStatistics, tradersKPIArray, currencies } =
    useSelector((state) => state.rootReducer.unitradeSlice);

  useEffect(() => {
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    dispatch(setTradersKPIArrayEmpty([]));
    if (currencySelect !== null && !tabService) {
      statisticsApi.getTradersKPICommonStatistics({
        currency_id: currencySelect?.value,
      });
      statisticsApi.getTradersKPI({
        currency_id: currencySelect?.value,
        limit: limitCount,
      });
    }
    if (currencySelect !== null && tabService) {
      statisticsApi.getServiceTradersKPICommonStatistics({
        currency_id: currencySelect?.value,
      });
      statisticsApi.getServiceTradersKPI({
        currency_id: currencySelect?.value,
        limit: limitCount,
      });
    }
  }, [currencySelect, tabService]);

  const loadMore = () => {
    lastId.current = tradersKPIArray[tradersKPIArray.length - 1]?.created_at;
    statisticsApi.getTradersKPI({
      currency_id: currencySelect?.value,
      limit: limitCount,
      last_created_at: lastId.current,
    });
  };

  const renderTraders = () => {
    // Сортируем массив tradersKPIArray по процентам
    const sortedTraders = tradersKPIArray.slice().sort((item1, item2) => {
      const percent1 =
        isNaN(item1?.total_buy_orders) ||
        isNaN(item1?.total_success_buy_orders)
          ? NaN
          : 100 -
            Math.abs(
              (item1?.total_buy_orders -
                item1?.total_success_buy_orders) /
                item1?.total_buy_orders
            ) *
              100;

      const percent2 =
        isNaN(item2?.total_buy_orders) ||
        isNaN(item2?.total_success_buy_orders)
          ? NaN
          : 100 -
            Math.abs(
              (item2?.total_buy_orders -
                item2?.total_success_buy_orders) /
                item2?.total_buy_orders
            ) *
              100;

      // Проверяем, являются ли значения NaN
      if (isNaN(percent1) && isNaN(percent2)) {
        return 0; // Если оба значения NaN, сохраняем текущий порядок
      } else if (isNaN(percent1)) {
        return 1; // Если только percent1 NaN, он должен идти после percent2
      } else if (isNaN(percent2)) {
        return -1; // Если только percent2 NaN, он должен идти после percent1
      } else {
        return percent2 - percent1; // Сортировка по убыванию непустых процентов
      }
    });

    return sortedTraders.map((item, index) => (
      <tr
        className={`${
          item?.is_banned === false && item?.type_active !== "active"
            ? "is_active"
            : item?.is_banned === true && item?.type_active !== "active"
            ? "is_banned"
            : item?.is_banned === true && item?.type_active === "active"
            ? "is_banned"
            : "transparent_trader"
        }`}
        key={index}
      >
        <td>
          <Link
            target="_blank"
            to={`/trader-kpi/${item?.id}${
              tabService ? `?is_service=service` : ""
            }`}
          >
            {item?.notice !== null ? item?.notice : item?.id}
          </Link>
        </td>
        <td>{item?.rang}</td>
        <td>{item?.priority}</td>
        <td>{item?.total_buy_orders}</td>
        <td>{item?.total_sell_orders}</td>
        <td>
          {item?.total_success_buy_orders} (
          {isNaN(
            100 -
              ((item?.total_buy_orders -
                item?.total_success_buy_orders) /
                item?.total_buy_orders) *
                100
          ) ||
          item?.total_buy_orders -
            item?.total_success_buy_orders <
            0
            ? 0
            : (
                100 -
                ((item?.total_buy_orders -
                  item?.total_success_buy_orders) /
                  item?.total_buy_orders) *
                  100
              ).toFixed(2)}
          %)
        </td>
        <td>
          {item?.total_success_sell_orders} (
          {isNaN(
            100 -
              ((item?.total_sell_orders -
                item?.total_success_sell_orders) /
                item?.total_sell_orders) *
                100
          ) ||
          item?.total_sell_orders -
            item?.total_success_sell_orders <
            0
            ? 0
            : (
                100 -
                ((item?.total_sell_orders -
                  item?.total_success_sell_orders) /
                  item?.total_sell_orders) *
                  100
              ).toFixed(2)}
          %)
        </td>
        <td>{item?.total_cancel_buy_orders}</td>
        <td>{item?.total_cancel_sell_orders}</td>
        <td>{item?.total_appeal_buy_orders}</td>
        <td>{item?.total_appeal_sell_orders}</td>
        <td>{item?.input_limit}</td>
        <td>{item?.output_limit}</td>
        <td>
          {!tabService
            ? item?.deposit
            : item?.service_wallet?.deposit}
        </td>
        {!tabService && <td>{item?.crypto_address}</td>}
      </tr>
    ));
  };

  const renderServiceTraders = () => {
    // Сортируем массив tradersKPIArray по процентам
    const sortedTraders = tradersKPIArray.slice().sort((item1, item2) => {
      const percent1 =
        isNaN(item1?.total_buy_orders) ||
        isNaN(item1?.total_success_buy_orders)
          ? NaN
          : 100 -
            Math.abs(
              (item1?.total_buy_orders -
                item1?.total_success_buy_orders) /
                item1?.total_buy_orders
            ) *
              100;

      const percent2 =
        isNaN(item2?.total_buy_orders) ||
        isNaN(item2?.total_success_buy_orders)
          ? NaN
          : 100 -
            Math.abs(
              (item2?.total_buy_orders -
                item2?.total_success_buy_orders) /
                item2?.total_buy_orders
            ) *
              100;

      // Проверяем, являются ли значения NaN
      if (isNaN(percent1) && isNaN(percent2)) {
        return 0; // Если оба значения NaN, сохраняем текущий порядок
      } else if (isNaN(percent1)) {
        return 1; // Если только percent1 NaN, он должен идти после percent2
      } else if (isNaN(percent2)) {
        return -1; // Если только percent2 NaN, он должен идти после percent1
      } else {
        return percent2 - percent1; // Сортировка по убыванию непустых процентов
      }
    });

    return sortedTraders.map((item, index) => (
      <tr
        key={index}
      >
        <td>
          <Link
            target="_blank"
            to={`/trader-kpi/${item?.id}?currency=${currencySelect.value}${
              tabService ? `&is_service=service` : ""
            }`}
          >
            {item?.notice !== null ? item?.notice : item?.id}
          </Link>
        </td>
        <td>{item?.balance}</td>
        <td>{item?.deposit}</td>
        <td>{item?.frozen_balance}</td>
        <td>{item?.total_buy_orders}</td>
        <td>{item?.total_sell_orders}</td>
        <td>
          {item?.total_success_buy_orders} (
          {isNaN(
            100 -
              ((item?.total_buy_orders -
                item?.total_success_buy_orders) /
                item?.total_buy_orders) *
                100
          ) ||
          item?.total_buy_orders -
            item?.total_success_buy_orders <
            0
            ? 0
            : (
                100 -
                ((item?.total_buy_orders -
                  item?.total_success_buy_orders) /
                  item?.total_buy_orders) *
                  100
              ).toFixed(2)}
          %)
        </td>
        <td>
          {item?.total_success_sell_orders} (
          {isNaN(
            100 -
              ((item?.total_sell_orders -
                item?.total_success_sell_orders) /
                item?.total_sell_orders) *
                100
          ) ||
          item?.total_sell_orders -
            item?.total_success_sell_orders <
            0
            ? 0
            : (
                100 -
                ((item?.total_sell_orders -
                  item?.total_success_sell_orders) /
                  item?.total_sell_orders) *
                  100
              ).toFixed(2)}
          %)
        </td>
        <td>{item?.total_cancel_buy_orders}</td>
        <td>{item?.total_cancel_sell_orders}</td>
        <td>{item?.total_appeal_buy_orders}</td>
        <td>{item?.total_appeal_sell_orders}</td>
        <td>{item?.input_limit}</td>
        <td>{item?.output_limit}</td>
      </tr>
    ));
  };


  return (
    <div className="kpi container">
      <Header title={"KPI"} />
      <div className="kpi__inner">
        <div className="orders__tabs orders__tabs_service">
          <div
            onClick={() => {
              if (tabService) {
                lastId.current = null;
              }
              setTabService(false);
            }}
            className={`orders__tab ${!tabService ? "orders__tab_active" : ""}`}
          >
            <p>KPI</p>
          </div>
          <div
            onClick={() => {
              if (!tabService) {
                lastId.current = null;
              }
              setTabService(true);
            }}
            className={`orders__tab ${tabService ? "orders__tab_active" : ""}`}
          >
            <p>KPI Сервисных трейдеров</p>
          </div>
        </div>
        <div className="statistics__inner">
          <div className="popup-select mtb-12">
            <CustomSelect
              options={currencies.map((currency) => {
                return {
                  value: currency.id,
                  label: currency.currency,
                };
              })}
              placeholder={"Выберите валюту"}
              selected={currencySelect}
              id="currency"
              handleSelect={(e) => {
                setcurrencySelect(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <p className="statistics__text">
            Баланс: {tradersKPICommonStatistics?.total_balance ?? 0}{" "}
            {currencySelect?.label ?? ""}
          </p>
          <p className="statistics__text">
            Депозит: {tradersKPICommonStatistics?.total_deposit ?? 0}{" "}
            {currencySelect?.label ?? ""}
          </p>
          <p className="statistics__text">
            Замороженый баланс:{" "}
            {tradersKPICommonStatistics?.total_frozen_balance ?? 0}{" "}
            {currencySelect?.label ?? ""}
          </p>
          <div className="statistics__table">
            <table>
              <thead>
                <tr>
                  <th>Название</th>
                  {!tabService && <th>Рейтинг</th>}
                  {!tabService && <th>Приоритет</th>}
                  {tabService && <th>Баланс</th>}
                  {tabService && <th>Депозит</th>}
                  {tabService && <th>Замороженный баланс</th>}
                  <th>Кол-во на ввод</th>
                  <th>Кол-во на вывод</th>
                  <th>Кол-во на ввод в успехе</th>
                  <th>Кол-во на вывод в успехе</th>
                  <th>Кол-во на ввод в отмене</th>
                  <th>Кол-во на вывод в отмене</th>
                  <th>Кол-во апелляций на ввод</th>
                  <th>Кол-во апелляций на вывод</th>
                  <th>Лимит на ввод</th>
                  <th>Лимит на вывод</th>
                  {!tabService && <th>Депозит</th>}
                  {!tabService && <th>Крипто адрес</th>}
                </tr>
              </thead>
              <tbody>{!tabService ? renderTraders() : renderServiceTraders()}</tbody>
            </table>
          </div>
          {tradersKPIArray?.length > 0 && (
            <div className="loadMore">
              <p
                onClick={() => {
                  loadMore();
                }}
              >
                Загрузить еще...
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
