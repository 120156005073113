import {
  setLoader,
  setDevices,
  setDevicesPage,
  setDevicesDetails
} from "../../Store/Slice/unitradeSlice";
import {
  useGetDevicesMutation,
  useGetDeviceMutation,
} from "../../Store/Builder/unitrade.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useLocation } from "react-router-dom";

const ApiDevices = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [apiGetDevices] = useGetDevicesMutation();
  const [apiGetDevice] = useGetDeviceMutation();

  const getDevices = (params) => {
    dispatch(setLoader(true));
    apiGetDevices(params)
      .unwrap()
      .then((res) => {
        if (location?.pathname === "/devices") {
          dispatch(setDevicesPage(res));
        } else {
          dispatch(setDevices(res));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getDevice = (params) => {
    dispatch(setLoader(true));
    apiGetDevice(params)
      .unwrap()
      .then((res) => {
        dispatch(setDevicesDetails(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getDevices,
    getDevice,
  };
};

export default ApiDevices;
