import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { unitradeApi } from "./Builder/unitrade.api";
import unitradeSlice from "./Slice/unitradeSlice";
import apiMiddleware from "./apiMiddleware";

const rootReducer = combineReducers({
  unitradeSlice,
});

export const store = configureStore({
  reducer: {
    [unitradeApi.reducerPath]: unitradeApi.reducer,
    rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(unitradeApi.middleware)
      .concat(apiMiddleware)
});
