import { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import { useSelector } from "react-redux";
import ApiCurrencies from "../../Global/Api/currencies.api";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import moment from "moment";

import "./index.scss";
import { useLocation } from "react-router-dom";

export default function Currencies() {
  const location = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [infoCurrency, setInfoCurrency] = useState(null);
  const currencyValue = useInputHandler("");
  const code = useInputHandler("");
  const currenciesApi = ApiCurrencies();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const { currencies } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );
  const isButtonDisabled = !(
    currencyValue.value.length >= 3 && code.value.length >= 2
  );

  useEffect(() => {
    if (location?.pathname === "/") {
      currenciesApi.getData();
    }
  }, []);

  const openDeleteModal = (currency) => {
    setInfoCurrency(currency);
    setDeleteModal(true);
  };

  const openCreateModal = (currency) => {
    setInfoCurrency(currency);
    if (currency !== null) {
      currencyValue.setChange(currency?.currency);
      code.setChange(currency?.code);
    }
    setCreateModal(true);
  };

  const closeDeleteModal = () => {
    setInfoCurrency(null);
    setDeleteModal(false);
  };

  const closeCreateModal = () => {
    setInfoCurrency(null);
    currencyValue.setChange("");
    code.setChange("");
    setCreateModal(false);
  };

  const deleteSendData = () => {
    const body = {
      currency_id: infoCurrency?.id,
    };
    currenciesApi.deletedCurrency(body, closeDeleteModal);
  };

  const sendData = () => {
    let body;
    if (infoCurrency === null) {
      body = {
        currency: currencyValue.value,
        code: code.value,
      };
      currenciesApi.createCurrency(body, closeCreateModal);
    } else {
      body = {
        id: infoCurrency?.id,
        currency: currencyValue.value,
        code: code.value,
      };
      currenciesApi.editedCurrency(body, closeCreateModal);
    }
  };

  const renderCurrenciesData = () => {
    return currencies?.map((currency, index) => (
      <tr key={currency?.id}>
        <td>{index + 1}</td>
        <td>{currency?.code}</td>
        <td>{currency?.currency}</td>
        <td>{currency?.usdt_price}</td>

        <td className="currencies__actions">
          <div>
            {moment
              .utc(currency?.created_at)
              .local()
              .format("DD-MM-YYYY", "HH:mm:ss")}
          </div>
          {profileData?.role === "superadmin" && (
            <div className="currencies__btns">
              <div
                onClick={() => {
                  openCreateModal(currency);
                }}
                className="currencies__btn"
              >
                <img src="/assets/icons/edit-ad.svg" alt="" />
              </div>
              <div
                onClick={() => {
                  openDeleteModal(currency);
                }}
                className="currencies__btn"
              >
                <img src="/assets/icons/trash-ad.svg" alt="" />
              </div>
            </div>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className="currencies container">
      {createModal && (
        <Popup
          title={infoCurrency === null ? "Создать валюту" : "Изменить валюту"}
          closePopup={closeCreateModal}
        >
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Валюта"}
                placeholder="RUB"
                type="text"
                id="currency"
                {...currencyValue}
                maxLength={7}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Код"}
                placeholder="RU"
                type="text"
                id="code"
                {...code}
                maxLength={5}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                disabled={isButtonDisabled}
                type="accept"
                onClick={sendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {deleteModal && (
        <Popup title={"Удалить валюту"} closePopup={closeDeleteModal}>
          <p className="delete-text">Вы уверены что хотите удалить валюту?</p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Валюты"} />
      <div className="currencies__inner">
        <div className="currencies__top">
          {profileData?.role === "superadmin" && (
            <div className="currencies__top-btn">
              <Button
                text={"Создать валюту"}
                type={"accept"}
                onClick={() => {
                  openCreateModal(null);
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th>Номер</th>
              <th>Код</th>
              <th>Валюта</th>
              <th>USDT price</th>
              <th>Дата создания</th>
            </tr>
          </thead>
          <tbody>{renderCurrenciesData()}</tbody>
        </table>
      </div>
    </div>
  );
}
