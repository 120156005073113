import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie';

export default function PrivateRouteReceiptService() {

  const auth = Cookies.get('refresh_token');

  return auth ? <Outlet /> : <Navigate to="/receipt-service/login" />;
}
