import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import ApiUsers from "../../Global/Api/users.api";
import Toggle from "rsuite/Toggle";
import { setOrdersEmpty } from "../../Store/Slice/unitradeSlice";
import ApiOrders from "../../Global/Api/orders.api";
import Popup from "../../Components/Popup";
import Button from "../../UI/Button";
import { CustomSelect } from "../../UI/Select";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";

import "rsuite/Toggle/styles/index.css";
import "./index.scss";

export default function ShaitanMachine() {
  const dispatch = useDispatch();
  const usersApi = ApiUsers();
  const ordersApi = ApiOrders();
  const { users, orders } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );
  const [checked, setChecked] = useState(false);
  const [checkedMobile, setCheckedMobile] = useState(false);
  const [redefineModal, setRedefineModal] = useState(false);
  const [usersSelectRedefine, setUsersSelectRedefine] = useState(null);
  const [requisiteSelect, setRequisiteSelect] = useState(null);

  const requisiteData = [
    {
      value: "card",
      label: "По картам",
    },
    {
      value: "phone",
      label: "По СБП",
    },
    {
      value: "transfer",
      label: "По счетам",
    },
  ];

  const count = useInputHandler("");

  useEffect(() => {
    const formattedParamsUsers = formatParamsUsers();
    usersApi.getData(formattedParamsUsers, "shaitan", requisiteSelect);
  }, [requisiteSelect]);

  const formatParamsUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "trader");
    return params;
  };

  const openRedefineModal = (user) => {
    const formatParams = () => {
      const params = new URLSearchParams();
      params.append("limit", 50000);
      params.append("order_type", "sell");
      params.append("time_filer", "created");
      params.append("order_status", "pending");
      if (requisiteSelect !== null)
        params.append("requisite_type", requisiteSelect.value);
      params.append("trader_id", user?.id);
      return params;
    };
    const formattedParams = formatParams();
    ordersApi.getData(formattedParams);
    setRedefineModal(true);
  };

  const closeRedefineModal = () => {
    setRedefineModal(false);
    dispatch(setOrdersEmpty([]));
  };

  const renderUsersData = () => {
    const filteredUsers = users?.filter(
      (user) => !user?.notice?.startsWith("afk")
    );

    return filteredUsers?.map((user) => (
      <tr
        className={`${
          user?.is_banned === false && user?.type_active !== "active"
            ? "is_active"
            : user?.is_banned === true && user?.type_active !== "active"
            ? "is_banned"
            : user?.is_banned === true && user?.type_active === "active"
            ? "is_banned"
            : "transparent_trader"
        }`}
        key={user?.id}
      >
        <td>{user?.notice ? user?.notice : user?.id}</td>
        <td>
          {user?.type_active === "active"
            ? "Активен"
            : user?.type_active === "paused"
            ? "На паузе"
            : "Неактивен"}
        </td>
        <td>{user?.orders_count}</td>
        <td>{user?.orders_amount}</td>
        <td
          onClick={() => {
            openRedefineModal(user);
          }}
          className="order__btn"
        >
          <img src="/assets/icons/sendRedefine.svg" alt="" />
        </td>
      </tr>
    ));
  };

  const redefineSendData = () => {
    for (let i = 0; i < count.value; i++) {
      const body = {
        order_id: orders[i]?.id,
        trader_id: usersSelectRedefine.value,
      };
      ordersApi.redefineTraderOrderShaitan(body, closeRedefineModal);
    }
  };

  return (
    <div className="shaitan container">
      {redefineModal && (
        <Popup title={"Перенаправить заявки"} closePopup={closeRedefineModal}>
          <div className="popup__input">
            <Input
              label={"Кол-во заявок"}
              placeholder="Введите кол-во заявок"
              type="text"
              id="count"
              {...count}
              onlyNumber={true}
            />
          </div>
          <div className="popup__select mt-10">
            <CustomSelect
              options={users
                .filter((user) => user.type_active === "active")
                .map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
              placeholder={"Выберите трейдера"}
              selected={usersSelectRedefine}
              handleSelect={(e) => {
                setUsersSelectRedefine(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeRedefineModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Перенаправить заявки"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={redefineSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Шайтан машина"} />
      <div className="shaitan__inner">
        <div className="orders__select">
          <div className="popup-select orders__head-select">
            <CustomSelect
              options={requisiteData}
              placeholder={"Выберите тип реквизитов"}
              selected={requisiteSelect}
              handleSelect={(e) => {
                setRequisiteSelect(e ?? null);
              }}
              isClearable={true}
            />
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Активность</th>
            <th>Кол-во заявок</th>
            <th>Сумма заявок</th>
          </tr>
        </thead>
        <tbody>{renderUsersData()}</tbody>
      </table>
    </div>
  );
}
