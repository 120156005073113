import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiDevices from "../../Global/Api/devices.api";

import "./index.scss";

export default function DeviceDetails() {
  const navigate = useNavigate();
  const { device } = useSelector((state) => state.rootReducer.unitradeSlice);
  const useDevices = ApiDevices();

  useEffect(() => {
    useDevices.getDevice({ device_id: localStorage.getItem("deviceId") });
  }, []);

  return (
    <div className="devices">
      <div
        onClick={() => {
          navigate(-1);
        }}
        className="userDetails__backBtn"
      >
        <img src="/assets/icons/back-arrow.svg" alt="" />
      </div>
      <div className="devices__table">
        <table>
          <thead>
            <tr>
              <th>Тип Устройства</th>
              <th>Имя</th>
              <th>Версия Андроида</th>
              <th>Ключ Устройства</th>
              <th>SIM SLOT</th>
              <th>Номер Телефона</th>
              <th>ID Пользователя</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{device?.device_type}</td>
              <td>{device?.name}</td>
              <td>{device?.android_version}</td>
              <td>{device?.device_key}</td>
              <td>{device?.sim_slot}</td>
              <td>{device?.phone_number}</td>
              <td>
                <Link
                  onClick={() => {
                    localStorage.setItem("user_id", device?.user_id);
                  }}
                  to={`/user/${device?.user_id}`}
                >
                  {device?.user_id}
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
