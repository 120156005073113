import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import { useDispatch, useSelector } from "react-redux";
import ApiPaymentMethods from "../../Global/Api/paymentMethods.api";
import { CustomSelect } from "../../UI/Select";
import ApiCurrencies from "../../Global/Api/currencies.api";

import "./index.scss";
import { useParams, useNavigate } from "react-router-dom";
import ApiBankLinks from "../../Global/Api/bankLinks.api";
import { setAvailableBanks } from "../../Store/Slice/unitradeSlice";

export default function BankLinks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currenciesApi = ApiCurrencies();
  const apiBankLinks = ApiBankLinks();
  const paymentMethodsApi = ApiPaymentMethods();
  const parameters = useParams();
  const [currencySelect, setCurrencySelect] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const limitCountPayments = 100000;
  const [paymentMethod, sePaymentMethod] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [selectBuyCard, setSelectBuyCard] = useState(null);
  const [selectBuyPhone, setSelectBuyPhone] = useState(null);
  const [selectBuyCheck, setSelectBuyCheck] = useState(null);
  const [selectSellPhone, setSelectSellPhone] = useState(null);
  const [selectSellCard, setSelectSellCard] = useState(null);
  const [selectSellCheck, setSelectSellCheck] = useState(null);
  const typeActiveData = [
    {
      label: "Активен",
      value: true,
    },
    {
      label: "Не активен",
      value: false,
    },
  ];

  const { paymentMethods, availableBanks, currencies } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  useEffect(() => {
    const formattedParamsPayments = formatParamsPayments();
    paymentMethodsApi.getData(formattedParamsPayments);
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    apiBankLinks.getAvailableBanks(formattedParams);
  }, [currencySelect]);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("user_id", parameters?.id);
    if (currencySelect !== null)
      params.append("currency_id", currencySelect.value);
    return params;
  };

  const formatParamsPayments = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCountPayments);
    params.append("offset", 0);
    return params;
  };

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const closeCreateModal = () => {
    setCreateModal(false);
    setSelectedMethod(null);
  };

  const createSendData = () => {
    const body = {
      user_id: parameters?.id,
      payment_method_id: selectedMethod.value,
    };
    apiBankLinks.linkAvailableBank(body, formatParams, closeCreateModal);
  };

  const openDeleteModal = (link) => {
    sePaymentMethod(link);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    sePaymentMethod(null);
    setDeleteModal(false);
  };

  const openEditModal = (link) => {
    sePaymentMethod(link);
    setSelectBuyCard(
      typeActiveData?.find((el) => el?.value === link?.is_buy_card)
    );
    setSelectBuyPhone(
      typeActiveData?.find((el) => el?.value === link?.is_buy_phone)
    );
    setSelectBuyCheck(
      typeActiveData?.find((el) => el?.value === link?.is_buy_check)
    );
    setSelectSellPhone(
      typeActiveData?.find((el) => el?.value === link?.is_sell_phone)
    );
    setSelectSellCard(
      typeActiveData?.find((el) => el?.value === link?.is_sell_card)
    );
    setSelectSellCheck(
      typeActiveData?.find((el) => el?.value === link?.is_sell_check)
    );
    setEditModal(true);
  };

  const closeEditModal = () => {
    sePaymentMethod(null);
    setEditModal(false);
    setSelectBuyCard(null);
    setSelectBuyPhone(null);
    setSelectSellPhone(null);
    setSelectSellCard(null);
  };

  const renderBanks = () => {
    return availableBanks?.map((item) => (
      <tr>
        <td>{item?.payment_method?.bank_name}</td>
        <td>{item?.payment_method?.is_active ? "Активен" : "Не активен"}</td>
        <td>{item?.payment_method?.currency?.currency}</td>
        <td>{item?.is_buy_card ? "Активен" : "Не активен"}</td>
        <td>{item?.is_sell_card ? "Активен" : "Не активен"}</td>
        <td>{item?.is_buy_phone ? "Активен" : "Не активен"}</td>
        <td>{item?.is_sell_phone ? "Активен" : "Не активен"}</td>
        <td>{item?.is_buy_check ? "Активен" : "Не активен"}</td>
        <td>{item?.is_sell_check ? "Активен" : "Не активен"}</td>
        <td>{item?.payment_method?.currency?.usdt_price}</td>
        <td>
          <div
            onClick={() => {
              openEditModal(item);
            }}
            className="users__btn"
          >
            <img src="/assets/icons/edit-ad.svg" alt="" />
          </div>
        </td>
        <td>
          <div
            onClick={() => {
              openDeleteModal(item);
            }}
            className="users__btn"
          >
            <img src="/assets/icons/trash-ad.svg" alt="" />
          </div>
        </td>
      </tr>
    ));
  };

  const deleteSendData = () => {
    const body = {
      available_bank_id: paymentMethod.id,
    };
    apiBankLinks.deleteAvailableBank(body, formatParams, closeDeleteModal);
  };

  const editSendData = () => {
    const body = {
      available_bank_id: paymentMethod.id,
      is_buy_card: selectBuyCard.value,
      is_buy_phone: selectBuyPhone.value,
      is_buy_check: selectBuyCheck.value,
      is_sell_card: selectSellCard.value,
      is_sell_phone: selectSellPhone.value,
      is_sell_check: selectSellCheck.value,
    };
    apiBankLinks.editeAvailableBank(body, formatParams, closeEditModal);
  };

  return (
    <div className="bankLinks container">
      {createModal && (
        <Popup title={"Привязать банк"} closePopup={closeCreateModal}>
          <div className="popup-select mt-10">
            <p>Выберите банк</p>
            <CustomSelect
              options={paymentMethods.map((payment) => {
                return {
                  value: payment.id,
                  label: payment.bank_name,
                };
              })}
              selected={selectedMethod}
              id="paymentMethod"
              handleSelect={(e) => {
                setSelectedMethod(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                disabled={selectedMethod === null ? true : false}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {deleteModal && (
        <Popup title={"Удалить привязанный банк"} closePopup={closeDeleteModal}>
          <p className="delete-text">
            Вы уверены что хотите удалить привязанный банк?
          </p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {editModal && (
        <Popup title={"Настройки трафика"} closePopup={closeEditModal}>
          <div className="popup-select mt-10">
            <p>Ввод по картам</p>
            <CustomSelect
              options={typeActiveData}
              selected={selectBuyCard}
              id="buyCard"
              handleSelect={(e) => {
                setSelectBuyCard(e ?? null);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="popup-select mt-10">
            <p>Ввод по СБП</p>
            <CustomSelect
              options={typeActiveData}
              selected={selectBuyPhone}
              id="buyPhone"
              handleSelect={(e) => {
                setSelectBuyPhone(e ?? null);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="popup-select mt-10">
            <p>Ввод по счету</p>
            <CustomSelect
              options={typeActiveData}
              selected={selectBuyCheck}
              id="buyPhone"
              handleSelect={(e) => {
                setSelectBuyCheck(e ?? null);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="popup-select mt-10">
            <p>Вывод по картам</p>
            <CustomSelect
              options={typeActiveData}
              selected={selectSellCard}
              id="sellCard"
              handleSelect={(e) => {
                setSelectSellCard(e ?? null);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="popup-select mt-10">
            <p>Вывод по СБП</p>
            <CustomSelect
              options={typeActiveData}
              selected={selectSellPhone}
              id="sellPhone"
              handleSelect={(e) => {
                setSelectSellPhone(e ?? null);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="popup-select mt-10">
            <p>Вывод по счету</p>
            <CustomSelect
              options={typeActiveData}
              selected={selectSellCheck}
              id="sellPhone"
              handleSelect={(e) => {
                setSelectSellCheck(e ?? null);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Подключенные банки"} />
      <div className="bankLinks__inner">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="userDetails__backBtn"
        >
          <img src="/assets/icons/back-arrow.svg" alt="" />
        </div>
        <div className="bankLinks__top">
          <div className="bankLinks__select">
            <CustomSelect
              options={currencies.map((currency) => {
                return {
                  value: currency.id,
                  label: currency.currency,
                };
              })}
              placeholder={"Выберите валюту"}
              selected={currencySelect}
              id="currency"
              handleSelect={(e) => {
                setCurrencySelect(e ?? null);
                dispatch(setAvailableBanks([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="bankLinks__btn">
            <Button
              text={"Привязать банк"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={openCreateModal}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Название банка</th>
              <th>Активность</th>
              <th>Валюта</th>
              <th>Ввод по картам</th>
              <th>Вывод по картам</th>
              <th>Ввод по СБП</th>
              <th>Вывод по СБП</th>
              <th>Ввод по счету</th>
              <th>Вывод по счету</th>
              <th>Курс USDT</th>
            </tr>
          </thead>
          <tbody>{renderBanks()}</tbody>
        </table>
      </div>
    </div>
  );
}
