import Input from "../../UI/Input";
import Button from "../../UI/Button";
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./index.scss"
import useInputHandler from "../../Global/Hooks/inputHandler";
import useOnboarding from "../../Global/Api/onboarding.api";
import Header from "../../Components/Header";
import {useSelector} from "react-redux";

export default function Login({languageState, setLanguageState}) {
    const navigate = useNavigate()
    const tokenField = useInputHandler("");
    const codeField = useInputHandler("");
    const Onboarding = useOnboarding();

    const language = useSelector(
      (state) => state.rootReducer.unitradeSlice.language
    );

    useEffect(() => {
        setLanguageState(localStorage.getItem('localization'))
    }, [localStorage.getItem('localization')])

    useEffect(() => {
        if (localStorage.getItem("unitrade_admin_auth")?.length > 0) {
            navigate("/");
        }
    }, []);
    const isButtonDisabled = !(
        tokenField.value.length >= 3 && codeField.value.length === 6
    );

    const sendData = (event) => {
        event.preventDefault();
        Onboarding.loginHandle({
            'login': tokenField.value,
            'password': codeField.value
        })
    }

    return (
        <>
            <Header />
            <div className="login">
                <div className="login__box">
                    <form onSubmit={sendData} className="login__form">
                        <h1 className="login__title">{languageState === 'false' ? language?.general?.login_ru : language?.general?.login_en}</h1>
                        <div className="login__input">
                            <Input
                              label={languageState === 'false' ? language?.general?.token_ru : language?.general?.token_en}
                              placeholder="Введите токен для входа"
                              type="text"
                              id="token_field"
                              {...tokenField}
                              maxLength={71}
                            />
                        </div>
                        <div className="login__input mb-0">
                            <Input
                              label={languageState === 'false' ? language?.general?.code_ru : language?.general?.code_en}
                              placeholder="Введите код"
                              type="text"
                              id="code_field"
                              {...codeField}
                            />
                        </div>
                        <div className="login__btn">
                            <Button
                              text={languageState === 'false' ? language?.general?.login_ru : language?.general?.login_en}
                              onClick={sendData}
                              topPadding={16}
                              disabled={isButtonDisabled}
                              bottomPadding={16}
                              type='accept'
                            />
                        </div>
                    </form>
                    <div className="login__right">
                        <div className="login__right-img">
                            <img src="/assets/icons/login-image.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
