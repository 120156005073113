import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import NavBar from "./Components/NavBar";
import Page404 from "./Screens/404page";
import PrivateRoute from "./Components/PrivateRoute";
import PrivateRouteReceiptService from "./Components/PrivateRouteReceiptService";
import Login from "./Screens/Login";
import Loader from "./UI/Loader";
import Currencies from "./Screens/Сurrencies";
import Users from "./Screens/Users";
import Home from "./Screens/ReceiptService/Home";

import "react-toastify/dist/ReactToastify.css";
import "./Global/app.scss";
import UserDetails from "./Screens/UserDetails";
import PaymentMethods from "./Screens/PaymentMethods";
import BankBins from "./Screens/BankBins";
import SmsMessages from "./Screens/SmsMessages";
import SmsHistory from "./Screens/SmsHistory";
import Orders from "./Screens/Orders";
import OrderDetails from "./Screens/OrderDetails";
import Announcements from "./Screens/Announcements";
import MerchantWalletDetails from "./Screens/MerchantWalletDetails";
import {
  setUserFilter,
  setSmsHistorySearch,
  setProfileDataEmpty,
  setStatisticData,
  setSmsMessagesSearch,
  setDevicesEmpty,
  setTradersKPICommonStatistics,
  setTradersKPIArrayEmpty,
  setClientsEmpty,
} from "./Store/Slice/unitradeSlice";
import Profile from "./Screens/Profile";
import Statistics from "./Screens/Statistics";
import Devices from "./Screens/Devices";
import DeviceDetails from "./Screens/DeviceDetails";
import KPI from "./Screens/KPI";
import Reports from "./Screens/Reports";
import TraderKPI from "./Screens/TraderKPI";
import MerchantLinks from "./Screens/MerchantLinks";
import ShaitanMachine from "./Screens/ShaitanMachine";
import Clients from "./Screens/Clients";
import ProfileInfo from "./Screens/ProfileInfo";
import RejectOrders from "./Screens/RejectOrders";
import ServiceTraderWallet from "./Screens/ServiceTraderWallet";
import Transactions from "./Screens/Transactions";
import BankLinks from "./Screens/BankLinks";
import CreateOrder from "./Screens/CreateOrder";
import LoginReceipt from "./Screens/ReceiptService/Login";

export const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [languageState, setLanguageState] = useState(false);

  const { loader, navBar, theme } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  useEffect(() => {
    dispatch(setUserFilter([]));
    dispatch(setTradersKPICommonStatistics(null));
    dispatch(setTradersKPIArrayEmpty([]));
    dispatch(setClientsEmpty([]));
    if (location?.pathname !== "/sms-history") {
      dispatch(setSmsHistorySearch([]));
    }
    if (location?.pathname !== "/sms-messages") {
      dispatch(setSmsMessagesSearch([]));
    }
    dispatch(setStatisticData(null));
    if (location?.pathname === "/login") {
      dispatch(setProfileDataEmpty(null));
    }
    if (location?.pathname === "/devices") {
      dispatch(setDevicesEmpty([]));
    }
  }, [location]);

  const navbarDisabled = () => {
    if (location?.pathname === "/login") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {loader && <Loader />}
      <ToastContainer position="top-right" autoClose={5000} />
      <div
        className={
          localStorage.getItem("unitrade_admin_auth") && navbarDisabled()
            ? `nav__wrapper`
            : ""
        }
      >
        {localStorage.getItem("unitrade_admin_auth") && navbarDisabled() && (
          <NavBar />
        )}
        <div
          className={
            navbarDisabled()
              ? `nav__wrapper-right ${theme ? "nav__wrapper-right_dark" : ""} ${
                  !navBar ? "nav__wrapper-right_np" : ""
                } ${
                  !localStorage.getItem("unitrade_admin_auth")
                    ? "nav__wrapper-right_none"
                    : ""
                }`
              : ""
          }
        >
          <Routes>
            <Route
              path="/login"
              element={
                <Login
                  languageState={languageState}
                  setLanguageState={setLanguageState}
                />
              }
            />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Currencies />} />
            </Route>
            <Route path="/users" element={<PrivateRoute />}>
              <Route path="/users" element={<Users />} />
            </Route>
            <Route path="/shaitan-machine" element={<PrivateRoute />}>
              <Route path="/shaitan-machine" element={<ShaitanMachine />} />
            </Route>
            <Route path="/user/:id" element={<PrivateRoute />}>
              <Route path="/user/:id" element={<UserDetails />} />
            </Route>
            <Route path="/merchant-wallets/:id" element={<PrivateRoute />}>
              <Route
                path="/merchant-wallets/:id"
                element={<MerchantWalletDetails />}
              />
            </Route>
            <Route path="/payment-methods" element={<PrivateRoute />}>
              <Route path="/payment-methods" element={<PaymentMethods />} />
            </Route>
            <Route path="/bank-bins" element={<PrivateRoute />}>
              <Route path="/bank-bins" element={<BankBins />} />
            </Route>
            <Route path="/sms-messages" element={<PrivateRoute />}>
              <Route path="/sms-messages" element={<SmsMessages />} />
            </Route>
            <Route path="/sms-history" element={<PrivateRoute />}>
              <Route path="/sms-history" element={<SmsHistory />} />
            </Route>
            <Route path="/announcements" element={<PrivateRoute />}>
              <Route path="/announcements" element={<Announcements />} />
            </Route>
            <Route path="/orders" element={<PrivateRoute />}>
              <Route path="/orders" element={<Orders />} />
            </Route>
            <Route path="/order/:id" element={<PrivateRoute />}>
              <Route path="/order/:id" element={<OrderDetails />} />
            </Route>
            <Route path="/reject-orders" element={<PrivateRoute />}>
              <Route path="/reject-orders" element={<RejectOrders />} />
            </Route>
            <Route path="/profile" element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="/profile/:id" element={<PrivateRoute />}>
              <Route path="/profile/:id" element={<ProfileInfo />} />
            </Route>
            <Route path="/statistics" element={<PrivateRoute />}>
              <Route path="/statistics" element={<Statistics />} />
            </Route>
            <Route path="/kpi" element={<PrivateRoute />}>
              <Route path="/kpi" element={<KPI />} />
            </Route>
            <Route path="/trader-kpi/:id" element={<PrivateRoute />}>
              <Route path="/trader-kpi/:id" element={<TraderKPI />} />
            </Route>
            <Route path="/links-merchant-trader" element={<PrivateRoute />}>
              <Route
                path="/links-merchant-trader"
                element={<MerchantLinks />}
              />
            </Route>
            <Route path="/reports" element={<PrivateRoute />}>
              <Route path="/reports" element={<Reports />} />
            </Route>
            <Route path="/devices" element={<PrivateRoute />}>
              <Route path="/devices" element={<Devices />} />
            </Route>
            <Route path="/devices/:id" element={<PrivateRoute />}>
              <Route path="/devices/:id" element={<DeviceDetails />} />
            </Route>
            <Route path="/clients" element={<PrivateRoute />}>
              <Route path="/clients" element={<Clients />} />
            </Route>
            <Route path="/service-trader/wallet/:id" element={<PrivateRoute />}>
              <Route
                path="/service-trader/wallet/:id"
                element={<ServiceTraderWallet />}
              />
            </Route>
            <Route path="/transactions" element={<PrivateRoute />}>
              <Route path="/transactions" element={<Transactions />} />
            </Route>
            <Route path="/bank-links/:id" element={<PrivateRoute />}>
              <Route path="/bank-links/:id" element={<BankLinks />} />
            </Route>
            <Route path="/create-order" element={<PrivateRoute />}>
              <Route path="/create-order" element={<CreateOrder />} />
            </Route>
            <Route path="/receipt-service/login" element={<PrivateRoute />}>
              <Route path="/receipt-service/login" element={<LoginReceipt />} />
            </Route>
            <Route path="/receipt-service/" element={<PrivateRouteReceiptService />}>
              <Route path="/receipt-service/*" element={<Home />} />
            </Route>
            <Route path="/*" element={<Page404 />}></Route>
          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;
