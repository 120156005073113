import { useDispatch } from "react-redux";
import {
  useAddInputLimitToTraderMutation,
  useAddOutputLimitToTraderMutation,
  useEditTraderDataMutation,
  useGetTradersDataMutation,
  useSubstractInputLimitToTraderMutation,
  useSubstractOutputLimitToTraderMutation,
} from "../../Store/Builder/unitrade.api";
import { setLoader, setTradersData } from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";
import ApiUsers from "./users.api";
import { useParams } from "react-router-dom";

const TradersDataApi = () => {
  const dispatch = useDispatch();
  const parameters = useParams();
  const userApi = ApiUsers();
  const [apiGetTradersData] = useGetTradersDataMutation();
  const [apiEditTraderData] = useEditTraderDataMutation();
  const [apiAddInputLimitToTrader] = useAddInputLimitToTraderMutation();
  const [apiAddOutputLimitToTrader] = useAddOutputLimitToTraderMutation();
  const [apiSubstractInputLimitToTrader] =
    useSubstractInputLimitToTraderMutation();
  const [apiSubstractOutputLimitToTrader] =
    useSubstractOutputLimitToTraderMutation();

  const getTradersData = (params) => {
    dispatch(setLoader(true));
    apiGetTradersData(params)
      .unwrap()
      .then((res) => {
        dispatch(setTradersData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editTraderData = (data, closeModal, setIsOnline) => {
    dispatch(setLoader(true));
    apiEditTraderData(data)
      .unwrap()
      .then(() => {
        userApi.getDataUser(parameters?.id, setIsOnline);
        showToast("Данные трейдера успешно изменены", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addInputLimitToTrader = (body, closeAddLimitModal, setIsOnline) => {
    dispatch(setLoader(true));
    apiAddInputLimitToTrader(body)
      .unwrap()
      .then(() => {
        userApi.getDataUser(parameters?.id, setIsOnline);
        showToast("Лимиты успешно изменены", "success");
        closeAddLimitModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addOutputLimitToTrader = (body, closeSubLimitModal, setIsOnline) => {
    dispatch(setLoader(true));
    apiAddOutputLimitToTrader(body)
      .unwrap()
      .then(() => {
        userApi.getDataUser(parameters?.id, setIsOnline);
        showToast("Лимиты успешно изменены", "success");
        closeSubLimitModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const subInputLimitToTrader = (
    body,
    closeAddLimitOutputModal,
    setIsOnline
  ) => {
    dispatch(setLoader(true));
    apiSubstractInputLimitToTrader(body)
      .unwrap()
      .then(() => {
        userApi.getDataUser(parameters?.id, setIsOnline);
        showToast("Лимиты успешно изменены", "success");
        closeAddLimitOutputModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const subOutputLimitToTrader = (
    body,
    closeSubLimitOutputModal,
    setIsOnline
  ) => {
    dispatch(setLoader(true));
    apiSubstractOutputLimitToTrader(body)
      .unwrap()
      .then(() => {
        userApi.getDataUser(parameters?.id, setIsOnline);
        showToast("Лимиты успешно изменены", "success");
        closeSubLimitOutputModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getTradersData,
    editTraderData,
    addInputLimitToTrader,
    addOutputLimitToTrader,
    subInputLimitToTrader,
    subOutputLimitToTrader,
  };
};

export default TradersDataApi;
