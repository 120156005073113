import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import ApiPaymentMethods from "../../Global/Api/paymentMethods.api";
import { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import moment from "moment/moment";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import { CustomSelect } from "../../UI/Select";
import ApiCurrencies from "../../Global/Api/currencies.api";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import SearchComponent from "../../Components/Search";
import { setPaymentsMethodsAdsSearch } from "../../Store/Slice/unitradeSlice";
import { useDebounce } from "../../Global/Hooks/useDebounce";

export default function PaymentMethods() {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const paymentMethodsApi = ApiPaymentMethods();
  const currenciesApi = ApiCurrencies();
  const dispatch = useDispatch();
  const [limitCount, setLimitCount] = useState(100);
  const [currencySelect, setcurrencySelect] = useState(null);
  const [currencySelectTwo, setcurrencySelectTwo] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const isButtonDisabled = !(currencySelect !== null);
  const [search, setSearch] = useState("");
  const [bankID, setBankID] = useState("");
  const firstLoad = useRef(false);
  const [offSet, setOffSet] = useState(0);
  const [statusSelected, setStatusSelected] = useState(null);
  const statusData = [
    {
      label: "Активен",
      value: true,
    },
    {
      label: "Не активен",
      value: false,
    },
  ];

  const country = useInputHandler("");
  const bank = useInputHandler("");
  const bankId = useInputHandler("");

  const { paymentMethods, currencies } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  const clearPayments = () => {
    dispatch(setPaymentsMethodsAdsSearch([]));
  };

  const debouncedBankID = useDebounce(bankID, 300, clearPayments);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("offset", offSet);
    if (bankID !== "") params.append("bank_id", bankID.replace(/\s+/g, ""));
    if (currencySelectTwo !== null)
      params.append("currency_id", currencySelectTwo.value);
    return params;
  };

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      paymentMethodsApi.getData(formattedParams);
    }
  }, [search, debouncedBankID]);

  useEffect(() => {
    const formattedParams = formatParams();
    paymentMethodsApi.getData(formattedParams);
  }, [currencySelectTwo]);

  useEffect(() => {
    currenciesApi.getData();
    firstLoad.current = true;
  }, []);

  useEffect(() => {
    if (offSet !== 0) {
      const formattedParams = formatParams();
      paymentMethodsApi.getData(formattedParams);
    }
  }, [offSet]);

  const loadMore = () => {
    setOffSet(offSet + 100);
  };

  const openCreateModal = (payment) => {
    setPaymentInfo(payment);
    setCreateModal(true);
  };

  const closeCreateModal = () => {
    setPaymentInfo(null);
    setStatusSelected(null);
    setCreateModal(false);
    country.setChange("");
    bank.setChange("");
    bankId.setChange("");
  };

  const openDeleteModal = (payment) => {
    setPaymentInfo(payment);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setPaymentInfo(null);
    setDeleteModal(false);
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const handlebankID = (searchTerm) => {
    setBankID(searchTerm);
  };

  const searchSendData = () => {
    const body = {
      payment_id: search,
    };
    paymentMethodsApi.getPaymentSearch(body);
  };

  const openEditModal = (payment) => {
    setPaymentInfo(payment);
    setEditModal(true);
    country.setChange(payment?.country === null ? "" : payment?.country);
    bank.setChange(payment?.bank_name === null ? "" : payment?.bank_name);
    bankId.setChange(payment?.bank_id === null ? "" : payment?.bank_id);
    setcurrencySelect({
      value: payment?.currency_id,
      label: payment?.currency?.currency,
    });
    setStatusSelected({
      value: payment?.is_active,
      label: payment?.is_active ? "Активен" : "Не активен",
    });
  };

  const closeEditModal = () => {
    setPaymentInfo(null);
    setEditModal(false);
    country.setChange("");
    bank.setChange("");
    bankId.setChange("");
    setStatusSelected(null);
  };

  const createSendData = () => {
    const body = {
      country: country.value,
      bank_name: bank.value,
      bank_id: bankId.value,
      is_active: statusSelected !== null ? statusSelected.value : true,
      currency_id: currencySelect.value,
    };
    paymentMethodsApi.createPaymentMethod(body, closeCreateModal);
  };

  const deleteSendData = () => {
    const body = {
      payment_id: paymentInfo?.id,
    };
    paymentMethodsApi.deletePaymentMethod(body, closeDeleteModal);
  };

  const editSendData = () => {
    const body = {
      payment_id: paymentInfo?.id,
      country: country.value,
      bank_name: bank.value,
      bank_id: bankId.value,
      is_active: statusSelected !== null ? statusSelected.value : true,
      currency_id: currencySelect.value,
    };
    paymentMethodsApi.editPaymentMethod(body, closeEditModal);
  };

  const renderPaymentMethodsData = () => {
    return paymentMethods?.map((paymentMethod, index) => {
      return (
        <tr key={index}>
          <td>{paymentMethod?.id}</td>
          <td>{paymentMethod?.country}</td>
          <td>{paymentMethod?.bank_name}</td>
          <td>{paymentMethod?.bank_id}</td>
          <td>{paymentMethod?.is_active ? "Активен" : "Не активен"}</td>
          <td>
            {moment
              .utc(paymentMethod?.created_at)
              .local()
              .format("DD-MM-YYYY", "HH:mm:ss")}
          </td>
          <td>{paymentMethod?.currency?.currency}</td>
          <td>{paymentMethod?.currency?.code}</td>
          <td className="paymentMethods__actions">
            <div>{paymentMethod?.currency?.usdt_price}</div>
            {profileData?.role === "superadmin" && (
              <div className="paymentMethods__btns">
                <div
                  onClick={() => {
                    openEditModal(paymentMethod);
                  }}
                  className="users__btn"
                >
                  <img src="/assets/icons/edit-ad.svg" alt="" />
                </div>
                <div
                  onClick={() => {
                    openDeleteModal(paymentMethod);
                  }}
                  className="currencies__btn"
                >
                  <img src="/assets/icons/trash-ad.svg" alt="" />
                </div>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="paymentMethods container">
      {createModal && (
        <Popup title={"Создать способ оплаты"} closePopup={closeCreateModal}>
          <div className="popup-select mt-10">
            <p>Выберите валюту</p>
            <CustomSelect
              options={currencies.map((currency) => {
                return {
                  value: currency.id,
                  label: currency.currency,
                };
              })}
              selected={currencySelect}
              id="currency"
              handleSelect={(e) => {
                setcurrencySelect(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__input">
            <Input
              label={"Страна"}
              placeholder="Russia"
              type="text"
              id="country"
              {...country}
            />
          </div>
          <div className="popup__inputs">
            <div className="popup__input">
              <Input
                label={"Банк"}
                placeholder="Tinkoff"
                type="text"
                id="bank"
                {...bank}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Банк ID"}
                placeholder="123456789"
                type="text"
                id="bankId"
                {...bankId}
              />
            </div>
          </div>
          <div className="popup-select mt-10">
            <p>Выберите статус</p>
            <CustomSelect
              options={statusData}
              selected={statusSelected}
              id="status"
              handleSelect={(e) => {
                setStatusSelected(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={isButtonDisabled}
                bottomPadding={10}
                type="accept"
                onClick={createSendData}
              />
            </div>
          </div>
        </Popup>
      )}

      {editModal && (
        <Popup title={"Изменить способ оплаты"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup-select mt-10">
              <p>Выберите валюту</p>
              <CustomSelect
                options={currencies.map((currency) => {
                  return {
                    value: currency.id,
                    label: currency.currency,
                  };
                })}
                selected={currencySelect}
                id="currency"
                handleSelect={(e) => {
                  setcurrencySelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Страна"}
                placeholder="Введите страну"
                type="text"
                id="country"
                {...country}
              />
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Банк"}
                  placeholder="Введите название Банка"
                  type="text"
                  id="bank"
                  {...bank}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Банк id"}
                  placeholder="Введите id"
                  type="text"
                  id="bankId"
                  {...bankId}
                />
              </div>
            </div>
            <div className="popup-select mt-10">
              <p>Выберите статус</p>
              <CustomSelect
                options={statusData}
                selected={statusSelected}
                id="status"
                handleSelect={(e) => {
                  setStatusSelected(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}

      {deleteModal && (
        <Popup title={"Удалить способ оплаты"} closePopup={closeDeleteModal}>
          <p className="delete-text">Вы уверены что хотите способ оплаты?</p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}

      <Header title={"Способы оплаты"} />
      <div className="paymentMethods__top">
        <div className="paymentMethods__top-df">
          {profileData?.role === "superadmin" && (
            <div className="paymentMethods__top-btn">
              <Button
                text={"Создать способ оплаты"}
                type={"accept"}
                onClick={() => {
                  openCreateModal();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          )}
          <div className="paymentMethods-select">
            <CustomSelect
              options={currencies.map((currency) => {
                return {
                  value: currency.id,
                  label: currency.currency,
                };
              })}
              placeholder={"Выберите валюту"}
              selected={currencySelectTwo}
              id="currency"
              handleSelect={(e) => {
                dispatch(setPaymentsMethodsAdsSearch([]));
                setcurrencySelectTwo(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="users__top-search">
            <SearchComponent
              searchTerm={bankID}
              onSearch={handlebankID}
              placeholder={"Введите банк id"}
            />
          </div>
        </div>
        <div className="users__top-inner users__top-inner_ml">
          <div className="users__top-search">
            <SearchComponent
              searchTerm={search}
              onSearch={handleSearch}
              placeholder={"Введите payment id"}
            />
          </div>
          <div className="users__top-btn">
            <Button
              text={"Найти payment"}
              type={"accept"}
              onClick={() => {
                searchSendData();
              }}
              topPadding={10}
              bottomPadding={10}
            />
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Страна</th>
            <th>Банк</th>
            <th>Банк ID</th>
            <th>Активен</th>
            <th>Дата создания</th>
            <th>Валюта</th>
            <th>Код</th>
            <th>USDT Price</th>
          </tr>
        </thead>
        <tbody>{renderPaymentMethodsData()}</tbody>
      </table>
      <div className="loadMore">
        <p
          onClick={() => {
            loadMore();
          }}
        >
          Загрузить еще...
        </p>
      </div>
    </div>
  );
}
