import {
  setLoader,
  setProfileData,
  setGenerateFaKeyProfile,
  setProfileBilling,
  setSuperadminsData,
  setSuperadminsDataEmpty,
  setCryptoWalletBillingEmpty
} from "../../Store/Slice/unitradeSlice";
import {
  useGetProfileInfoMutation,
  useLazyGenerateFaKeyProfileQuery,
  useChangeFaKeyProfileMutation,
  useGetProfileBillingMutation,
  useGetSuperadminsMutation,
  useCreateSuperadminMutation,
  useSwitchSuperadminMutation,
} from "../../Store/Builder/unitrade.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { jwtDecode } from "jwt-decode";
import useOnboarding from "./onboarding.api";
import CryptoWalletsApi from "./cryptoWallets.api";
import { useEditSuperadminMutation } from "../../Store/Builder/unitrade.api";

const ApiProfile = () => {
  const dispatch = useDispatch();
  const onboarding = useOnboarding();
  const apiCryptoWallets = CryptoWalletsApi();
  const [apiGetProfile] = useGetProfileInfoMutation();
  const [apiGetProfileBilling] = useGetProfileBillingMutation();
  const [apiGenerateFaKey] = useLazyGenerateFaKeyProfileQuery();
  const [apiEditFaKey] = useChangeFaKeyProfileMutation();
  const [apiGetSuperadmins] = useGetSuperadminsMutation();
  const [apiCreateSuperadmin] = useCreateSuperadminMutation();
  const [apiSwitchSuperadmin] = useSwitchSuperadminMutation();
  const [apiEditSuperadmin] = useEditSuperadminMutation();

  const getData = () => {
    dispatch(setLoader(true));
    apiGetProfile()
      .unwrap()
      .then((res) => {
        localStorage.setItem("profileData", JSON.stringify(res));
        dispatch(setProfileData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getSuperadmins = (params) => {
    dispatch(setLoader(true));
    apiGetSuperadmins(params)
      .unwrap()
      .then((res) => {
        dispatch(setSuperadminsData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editSuperadmin = (body, closeEditModal) => {
    dispatch(setLoader(true));
    apiEditSuperadmin(body)
      .unwrap()
      .then(() => {
        getData()
        closeEditModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const switchSuperadmin = (body, closeSwitchAdminModal) => {
    dispatch(setLoader(true));
    apiSwitchSuperadmin(body)
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWalletBillingEmpty([]))
        const decodedToken = jwtDecode(res?.access_token);
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiration = decodedToken.exp - currentTime;
        localStorage.setItem("refresh_token_admin", res?.refresh_token);
        onboarding.setToken(res?.access_token, timeUntilExpiration);
        dispatch(setSuperadminsDataEmpty([]))
        getData()
        getProfileBilling()
        apiCryptoWallets.getCryptoWalletsBilling({
          wallet_id: body?.admin_id,
          limit: 100,
        });
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append("limit", 100);
          return params;
        };
        const formattedParams = formatParams();
        getSuperadmins(formattedParams)
        closeSwitchAdminModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getProfileBilling = () => {
    dispatch(setLoader(true));
    apiGetProfileBilling()
      .unwrap()
      .then((res) => {
        dispatch(setProfileBilling(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const generateFaKey = () => {
    dispatch(setLoader(true));
    apiGenerateFaKey()
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        dispatch(setGenerateFaKeyProfile(res));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editFaKeyUser = (data, closeModal) => {
    dispatch(setLoader(true));
    apiEditFaKey(data)
      .unwrap()
      .then((res) => {
        showToast("Новый Fa ключ сохранен в буфер обмена", "success");
        navigator.clipboard.writeText(data?.new_fa_key);
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createSuperadmin = (data, closeModal) => {
    dispatch(setLoader(true));
    apiCreateSuperadmin(data)
      .unwrap()
      .then((res) => {
        showToast("Суперпользователь создан успешно", "success");
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append("limit", 100);
          return params;
        };
        const formattedParams = formatParams();
        getSuperadmins(formattedParams)
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    generateFaKey,
    editFaKeyUser,
    getProfileBilling,
    getSuperadmins,
    createSuperadmin,
    switchSuperadmin,
    editSuperadmin
  };
};

export default ApiProfile;
