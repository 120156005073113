import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import showToast from "../../Global/Hooks/toast";

export default function PrivateRoute() {
  const auth = localStorage.getItem("unitrade_admin_auth");
  useEffect(() => {
    if (!auth) {
      // showToast("Please Login In", "error");
    }
  }, []);
  return auth ? <Outlet /> : <Navigate to="/login" />;
}
