import {useDispatch} from "react-redux";
import {
  useCreatePaymentMethodsMutation,
  useDeletePaymentMethodMutation, useEditPaymentMethodMutation,
  useGetPaymentMethodsMutation, useGetPaymentMethodsSearchMutation
} from "../../Store/Builder/unitrade.api";
import {
  setCreatePaymentMethod, setDeletePaymentMethod, setEditPaymentMethod,
  setLoader, setPaymentsMethodsAds, setPaymentsMethodsAdsSearch,
} from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";

const ApiPaymentMethods = () => {
  const dispatch = useDispatch();
  const [apiGetPaymentMethods] = useGetPaymentMethodsMutation();
  const [apiCreatePaymentMethod] = useCreatePaymentMethodsMutation();
  const [apiDeletePaymentMethod] = useDeletePaymentMethodMutation();
  const [apiEditPaymentMethod] = useEditPaymentMethodMutation();
  const [apiGetPaymentMethodSearch] = useGetPaymentMethodsSearchMutation()


  const getData = (params, action) => {
    dispatch(setLoader(true))
    apiGetPaymentMethods(params)
      .unwrap()
      .then((res) => {
        dispatch(setPaymentsMethodsAds(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getPaymentSearch = (body) => {
    dispatch(setLoader(true))
    apiGetPaymentMethodSearch(body)
      .unwrap()
      .then((res) => {
        let array = []
        array.push(res)
        dispatch(setPaymentsMethodsAdsSearch(array))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createPaymentMethod = (data, closeModal) => {
    dispatch(setLoader(true))
    apiCreatePaymentMethod(data)
      .unwrap()
      .then((res) => {
        dispatch(setCreatePaymentMethod(res))
        showToast("Способ оплаты успешно создан", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const deletePaymentMethod = (data, closeModal) => {
    dispatch(setLoader(true))
    apiDeletePaymentMethod(data)
      .unwrap()
      .then(() => {
        dispatch(setDeletePaymentMethod(data?.payment_id))
        showToast("Способ оплаты успешно удален", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const editPaymentMethod = (data, closeModal) => {
    dispatch(setLoader(true))
    apiEditPaymentMethod(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditPaymentMethod(res))
        showToast("Способ оплаты успешно изменен", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }


  return {
    getData,
    createPaymentMethod,
    deletePaymentMethod,
    editPaymentMethod,
    getPaymentSearch
  }
}

export default ApiPaymentMethods;
