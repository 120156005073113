import {
  setLoader,
  setServiceTraderCredentialsData,
  setServiceTraderWallets,
  setServiceTraderWalletDetails,
  setServiceTraderWalletBilling,
  setServiceTraderWalletBillingEmpty,
  setServiceTraderWalletStatistics,
  setServiceTraderData,
} from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";
import { useDispatch } from "react-redux";
import {
  useEditTraderCredentialsMutation,
  useGetServiceTraderCredentialsMutation,
  useEditTraderPasswordsMutation,
  useGetServiceTraderWalletsMutation,
  useCreateServiceTraderWalletMutation,
  useDeleteServiceTraderWalletMutation,
  useServiceTraderWalletDetailsMutation,
  useAddDepositServiceWalletMutation,
  useAddBalanceServiceWalletMutation,
  useAddFrozenServiceWalletMutation,
  useSubDepositServiceWalletMutation,
  useSubBalanceServiceWalletMutation,
  useSubFrozenServiceWalletMutation,
  useGetBillingServiceWalletMutation,
  useGetStatisticServiceWalletMutation,
  useGetServiceTraderDataMutation,
  useAddInputLimitServiceTraderMutation,
  useAddOutputLimitServiceTraderMutation,
  useSubInputLimitServiceTraderMutation,
  useSubOutputLimitServiceTraderMutation,
  useEditTraderServiceDataMutation
} from "../../Store/Builder/unitrade.api";

const ApiServiceTraderCredentials = () => {
  const dispatch = useDispatch();
  const [apiGetCredentials] = useGetServiceTraderCredentialsMutation();
  const [apiEditTraderCredentials] = useEditTraderCredentialsMutation();
  const [apiEditTraderPasswords] = useEditTraderPasswordsMutation();
  const [apiGetTraderWallets] = useGetServiceTraderWalletsMutation();
  const [apiCreateTraderWallet] = useCreateServiceTraderWalletMutation();
  const [apiDeleteTraderWallet] = useDeleteServiceTraderWalletMutation();
  const [apigetTraderWalletDetails] = useServiceTraderWalletDetailsMutation();
  const [apiAddDepositToWallet] = useAddDepositServiceWalletMutation();
  const [apiAddBalanceToWallet] = useAddBalanceServiceWalletMutation();
  const [apiAddFrozenToWallet] = useAddFrozenServiceWalletMutation();
  const [apiSubDepositToWallet] = useSubDepositServiceWalletMutation();
  const [apiSubBalanceToWallet] = useSubBalanceServiceWalletMutation();
  const [apiSubFrozenToWallet] = useSubFrozenServiceWalletMutation();
  const [apiGetBillingWallet] = useGetBillingServiceWalletMutation();
  const [apiGetStatisticWallet] = useGetStatisticServiceWalletMutation();
  const [apiGetServiceTraderData] = useGetServiceTraderDataMutation();
  const [apiAddInputLimitServiceTrader] =
    useAddInputLimitServiceTraderMutation();
  const [apiAddOutputLimitServiceTrader] =
    useAddOutputLimitServiceTraderMutation();
  const [apiSubInputLimitServiceTrader] =
    useSubInputLimitServiceTraderMutation();
  const [apiSubOutputLimitServiceTrader] =
    useSubOutputLimitServiceTraderMutation();
    const [apiEditServiceTraderData] = useEditTraderServiceDataMutation();

  const getData = (body) => {
    dispatch(setLoader(true));
    apiGetCredentials(body)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderCredentialsData(res));
        console.log(res);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const editData = (body, closeEditDataModal) => {
    dispatch(setLoader(true));
    apiEditTraderCredentials(body)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderCredentialsData(res));
        closeEditDataModal();
        dispatch(setLoader(false));
        showToast("Данные трейдера успешно изменены", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const editPassword = (body, closeEditPasswordModal) => {
    dispatch(setLoader(true));
    apiEditTraderPasswords(body)
      .unwrap()
      .then(() => {
        closeEditPasswordModal();
        dispatch(setLoader(false));
        showToast("Пароль трейдера успешно изменен", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const getWallets = (body) => {
    dispatch(setLoader(true));
    apiGetTraderWallets(body)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderWallets(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  const createWallet = (body, closeCreateWalletModal) => {
    dispatch(setLoader(true));
    apiCreateTraderWallet(body)
      .unwrap()
      .then(() => {
        getWallets({
          user_id: localStorage.getItem("user_id"),
        });
        closeCreateWalletModal();
        dispatch(setLoader(false));
        showToast("Кошелек успешно создан", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const deleteWallet = (body, closeDeleteWalletModal) => {
    dispatch(setLoader(true));
    apiDeleteTraderWallet(body)
      .unwrap()
      .then(() => {
        getWallets({
          user_id: localStorage.getItem("user_id"),
        });
        closeDeleteWalletModal();
        dispatch(setLoader(false));
        showToast("Кошелек успешно удален", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const getWalletDetails = (body) => {
    dispatch(setLoader(true));
    apigetTraderWalletDetails(body)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderWalletDetails(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const addDepositToWallet = (body, formattedParams) => {
    dispatch(setLoader(true));
    apiAddDepositToWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setServiceTraderWalletBillingEmpty([]));
        getWalletDetails({ wallet_id: body?.wallet_id });
        getBillingWallet(formattedParams);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const addBalanceToWallet = (body, formattedParams) => {
    dispatch(setLoader(true));
    apiAddBalanceToWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setServiceTraderWalletBillingEmpty([]));
        getBillingWallet(formattedParams);
        getWalletDetails({ wallet_id: body?.wallet_id });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const addFrozenToWallet = (body, formattedParams) => {
    dispatch(setLoader(true));
    apiAddFrozenToWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setServiceTraderWalletBillingEmpty([]));
        getBillingWallet(formattedParams);
        getWalletDetails({ wallet_id: body?.wallet_id });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const substractDepositFromWallet = (body, formattedParams) => {
    dispatch(setLoader(true));
    apiSubDepositToWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setServiceTraderWalletBillingEmpty([]));
        getBillingWallet(formattedParams);
        getWalletDetails({ wallet_id: body?.wallet_id });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const substractBalanceFromWallet = (body, formattedParams) => {
    dispatch(setLoader(true));
    apiSubBalanceToWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setServiceTraderWalletBillingEmpty([]));
        getBillingWallet(formattedParams);
        getWalletDetails({ wallet_id: body?.wallet_id });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const substractFrozenFromWallet = (body, formattedParams) => {
    dispatch(setLoader(true));
    apiSubFrozenToWallet(body)
      .unwrap()
      .then(() => {
        dispatch(setServiceTraderWalletBillingEmpty([]));
        getBillingWallet(formattedParams);
        getWalletDetails({ wallet_id: body?.wallet_id });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const getBillingWallet = (params) => {
    dispatch(setLoader(true));
    apiGetBillingWallet(params)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderWalletBilling(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const getStatisticWallet = (params) => {
    dispatch(setLoader(true));
    apiGetStatisticWallet(params)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderWalletStatistics(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const getServiceTraderData = (body) => {
    dispatch(setLoader(true));
    apiGetServiceTraderData(body)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const addInputLimitServiceTrader = (body, closeAddLimitModal) => {
    dispatch(setLoader(true));
    apiAddInputLimitServiceTrader(body)
      .unwrap()
      .then(() => {
        getServiceTraderData({
          service_trader_data_id: body?.service_trader_data_id,
        });
        closeAddLimitModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const addOutputLimitServiceTrader = (body, closeSubLimitModal) => {
    dispatch(setLoader(true));
    apiAddOutputLimitServiceTrader(body)
      .unwrap()
      .then(() => {
        getServiceTraderData({
          service_trader_data_id: body?.service_trader_data_id,
        });
        closeSubLimitModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const subInputLimitServiceTrader = (body, closeAddLimitOutputModal) => {
    dispatch(setLoader(true));
    apiSubInputLimitServiceTrader(body)
      .unwrap()
      .then(() => {
        getServiceTraderData({
          service_trader_data_id: body?.service_trader_data_id,
        });
        closeAddLimitOutputModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const subOututLimitServiceTrader = (body, closeSubLimitOutputModal) => {
    dispatch(setLoader(true));
    apiSubOutputLimitServiceTrader(body)
      .unwrap()
      .then(() => {
        getServiceTraderData({
          service_trader_data_id: body?.service_trader_data_id,
        });
        closeSubLimitOutputModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  const editServiceTraderData = (body, closeEditModal) => {
    dispatch(setLoader(true));
    apiEditServiceTraderData(body)
      .unwrap()
      .then((res) => {
        dispatch(setServiceTraderData(res));
        closeEditModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? err?.data?.message, "error");
      });
  };

  return {
    getData,
    editData,
    editPassword,
    getWallets,
    createWallet,
    deleteWallet,
    getWalletDetails,
    addDepositToWallet,
    addBalanceToWallet,
    addFrozenToWallet,
    substractDepositFromWallet,
    substractBalanceFromWallet,
    substractFrozenFromWallet,
    getBillingWallet,
    getStatisticWallet,
    getServiceTraderData,
    addInputLimitServiceTrader,
    addOutputLimitServiceTrader,
    subInputLimitServiceTrader,
    subOututLimitServiceTrader,
    editServiceTraderData,
  };
};

export default ApiServiceTraderCredentials;
