import {
  setDeleteMerchantWalletByWalletId,
  setEditMerchantWallet,
  setMerchantWalletBillingEmpty,
  setLoader,
  setMerchantWallet,
  setMerchantWalletBilling,
  setMerchantWalletByWalletId,
  setMerchantWallletStatistics,
} from "../../Store/Slice/unitradeSlice";
import {
  useCreateMerchantWalletsMutation,
  useDeleteMerchantWalletsMutation,
  useEditMerchantWalletsMutation,
  useGetMerchantWalletsByWalletIdMutation,
  useGetMerchantWalletsMutation,
  useLazyMerchantWalletsBillingQuery,
  useLazyGetMerchantWalletStatisticsQuery,
  useAddDepositMerchantWalletsMutation,
  useSubDepositMerchantWalletsMutation,
} from "../../Store/Builder/unitrade.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";

const ApiMerchantWallets = () => {
  const dispatch = useDispatch();
  const [apiGetMerchantWallets] = useGetMerchantWalletsMutation();
  const [apiCreateMerchantWallets] = useCreateMerchantWalletsMutation();
  const [apiEditedMerchantWallets] = useEditMerchantWalletsMutation();
  const [apiDeleteMerchantWallets] = useDeleteMerchantWalletsMutation();
  const [apiGedMerchantWalletsById] = useGetMerchantWalletsByWalletIdMutation();
  const [apiMerchantWalletsBillings] = useLazyMerchantWalletsBillingQuery();
  const [apiGetMerchantWalletsStatistics] = useLazyGetMerchantWalletStatisticsQuery();
  const [apiAddDepositMerchantWallets] = useAddDepositMerchantWalletsMutation();
  const [apiSubDepositMerchantWallets] = useSubDepositMerchantWalletsMutation();

  const getData = (params) => {
    dispatch(setLoader(true));
    apiGetMerchantWallets(params)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantWallet(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getMerchantWalletsByWalletId = (params) => {
    dispatch(setLoader(true));
    apiGedMerchantWalletsById(params)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantWalletByWalletId(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createMerchantWallets = (data, closeModal) => {
    dispatch(setLoader(true));
    apiCreateMerchantWallets(data)
      .unwrap()
      .then((res) => {
        getData({
          user_id: data?.user_id,
        });
        showToast("Кошелек успешно создан", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editedMerchantWallets = (data, closeModal) => {
    dispatch(setLoader(true));
    apiEditedMerchantWallets(data)
      .unwrap()
      .then((res) => {
        getMerchantWalletsByWalletId(res?.id);
        showToast("Кошелек успешно изменен", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addDepositMerchantWallets = (data, closeModal, formattedParams) => {
    dispatch(setLoader(true));
    apiAddDepositMerchantWallets(data)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantWalletBillingEmpty([]));
        getMerchantWalletsByWalletId(res?.id);
        getMerchantWalletsStatistics({ wallet_id: res?.id });
        getMerchantWalletsBilling(formattedParams);
        showToast("Депозит успешно добавлен", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const subDepositMerchantWallets = (data, closeModal, formattedParams) => {
    dispatch(setLoader(true));
    apiSubDepositMerchantWallets(data)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantWalletBillingEmpty([]));
        getMerchantWalletsByWalletId(res?.id);
        getMerchantWalletsStatistics({ wallet_id: res?.id });
        getMerchantWalletsBilling(formattedParams);
        showToast("Депозит успешно вычтен", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const deleteMerchantWalletByWalletId = (data, closeModal) => {
    dispatch(setLoader(true));
    apiDeleteMerchantWallets(data)
      .unwrap()
      .then(() => {
        dispatch(setDeleteMerchantWalletByWalletId(data));
        showToast("Кошелек успешно удален", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getMerchantWalletsBilling = (params) => {
    dispatch(setLoader(true));
    apiMerchantWalletsBillings(params)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantWalletBilling(res?.billings));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getMerchantWalletsStatistics = (body) => {
    dispatch(setLoader(true));
    apiGetMerchantWalletsStatistics(body)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantWallletStatistics(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    createMerchantWallets,
    editedMerchantWallets,
    deleteMerchantWalletByWalletId,
    getMerchantWalletsByWalletId,
    getMerchantWalletsBilling,
    getMerchantWalletsStatistics,
    addDepositMerchantWallets,
    subDepositMerchantWallets,
  };
};

export default ApiMerchantWallets;
