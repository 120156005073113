import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useCreateReportMutation, useGetReportURLMutation, useGetReportsMutation } from "../../Store/Builder/unitrade.api";
import { setLoader, setReports, setCreateReport, setReportURL } from "../../Store/Slice/unitradeSlice";

const ApiReports = () => {
  const dispatch = useDispatch();
  const [apiGetReports] = useGetReportsMutation();
  const [apiGetReportURL] = useGetReportURLMutation();
  const [apiCreateReport] = useCreateReportMutation();

  const getReports = (params) => {
    dispatch(setLoader(true));

    apiGetReports(params)
      .unwrap()
      .then((res) => {
        dispatch(setReports(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getReportURL = (body) => {
    dispatch(setLoader(true));

    apiGetReportURL(body)
      .unwrap()
      .then((res) => {
        dispatch(setReportURL(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createReport = (body, closeCreateModal) => {
    dispatch(setLoader(true));

    apiCreateReport(body)
      .unwrap()
      .then((res) => {
        dispatch(setCreateReport(res));
        dispatch(setLoader(false));
        closeCreateModal()
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getReports,
    createReport,
    getReportURL
  };
};

export default ApiReports;
