import Header from "../../Components/Header";
import { useEffect, useRef, useState, useMemo } from "react";
import ApiOrders from "../../Global/Api/orders.api";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelect } from "../../UI/Select";
import moment from "moment/moment";
import ApiCurrencies from "../../Global/Api/currencies.api";

import "./index.scss";
import { Link, NavLink } from "react-router-dom";
import { setOrdersEmpty } from "../../Store/Slice/unitradeSlice";
import Popup from "../../Components/Popup";
import Button from "../../UI/Button";
import SearchComponent from "../../Components/Search";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import Toggle from "rsuite/Toggle";
import "rsuite/Toggle/styles/index.css";
import {
  setBillingOrder,
  setBillingOrderHistory,
} from "../../Store/Slice/unitradeSlice";
import ApiUsers from "../../Global/Api/users.api";
import { useDebounce } from "../../Global/Hooks/useDebounce";
import parsePhoneNumberFromString from "libphonenumber-js";
import showToast from "../../Global/Hooks/toast";

export default function Orders() {
  const ordersApi = ApiOrders();
  const usersApi = ApiUsers();
  const currenciesApi = ApiCurrencies();
  const dispatch = useDispatch();
  const [limitCount, setLimitCount] = useState(100);
  const [languageState, setLanguageState] = useState(false);
  const [appealModal, setAppealModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [currencySelect, setCurrencySelect] = useState(null);
  const amount = useInputHandler("");
  const [infoOrder, setInfoOrder] = useState(null);
  const lastId = useRef(null);
  const lastDateTime = useRef(null);
  const [search, setSearch] = useState("");
  const [recalcModal, setRecalcModal] = useState(false);
  const firstLoad = useRef(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileAppeal, setSelectedFileAppeal] = useState([]);
  const [callbackModal, setCallbackModal] = useState(false);
  const [value, setValue] = useState(null);
  const [checked, setChecked] = useState(false);
  const [modalBillingOrder, setModalBillingOrder] = useState(false);
  const [checkedMobile, setCheckedMobile] = useState(false);
  const [usersSelect, setUsersSelect] = useState(null);
  const [usersSelectRedefine, setUsersSelectRedefine] = useState(null);
  const [usersMerchantSelect, setUsersMerchantSelect] = useState(null);
  const [bonusSelect, setBonusSelect] = useState(null);
  const [callbackFlagSelect, setCallbackFlagSelect] = useState(null);
  const [warningMessageSelect, setWarningMessageSelect] = useState(null);
  const [redefineModal, setRedefineModal] = useState(false);
  const [redefineTraderModal, setRedefineTraderModal] = useState(false);
  const [clientId, setClientId] = useState("");
  const [requisiteValue, setRequisiteValue] = useState("");
  const [requisiteAdValue, setRequisiteAdValue] = useState("");
  const [callbackSend, setCallbackSend] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [redefine, setRedefine] = useState(null);
  const [paginationOrder, setPaginationOrder] = useState("start");
  const [requisiteSelect, setRequisiteSelect] = useState(null);
  const [tabService, setTabService] = useState(false);
  const [tabId, setTabId] = useState(false);
  const [checkOrdersModal, setCheckOrdersModal] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [usersMerchantIdsSelect, setUsersMerchantIdsSelect] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const clearClients = () => {
    dispatch(setOrdersEmpty([]));
  };

  const debouncedSearchIdValue = useDebounce(clientId, 300, clearClients);
  const debouncedReqisiteValue = useDebounce(requisiteValue, 300, clearClients);
  const debouncedReqisiteAdValue = useDebounce(
    requisiteAdValue,
    300,
    clearClients
  );

  const requisiteData = [
    {
      value: "card",
      label: "По картам",
    },
    {
      value: "phone",
      label: "По СБП",
    },
    {
      value: "transfer",
      label: "По счетам",
    },
  ];

  const callbackFlagData = [
    {
      value: "not_sent",
      label: "без кб",
    },
    {
      value: "successfully",
      label: "с кб",
    },
    {
      value: "unsuccessfully",
      label: "ошибка с кб",
    },
  ];
  const warningMessageData = [
    {
      value: true,
      label: "Да",
    },
    {
      value: false,
      label: "Нет",
    },
  ];
  const bonusData = [
    {
      value: true,
      label: "Межбанк",
    },
    {
      value: false,
      label: "Не межбанк",
    },
  ];
  const [timeData, setTimeData] = useState([
    {
      value: "created",
      label: "Created",
    },
    {
      value: "updated",
      label: "Updated",
    },
    {
      value: "completed",
      label: "Completed",
    },
  ]);
  const [statusData, setStatusData] = useState([
    {
      value: "success",
      label: "Success",
    },
    {
      value: "cancel",
      label: "Cancel",
    },
    {
      value: "appeal",
      label: "Appeal",
    },
    {
      value: "pending",
      label: "Pending",
    },
  ]);
  const [statusSelect, setStatusSelect] = useState(null);
  const [timeSelect, setTimeSelect] = useState(null);
  const {
    orders,
    language,
    billingOrder,
    billingOrderHistory,
    fullTraders,
    usersMerchant,
    currencies,
  } = useSelector((state) => state.rootReducer.unitradeSlice);
  const [tab, setTab] = useState(false);

  const users = fullTraders.filter(
    (user) => user.type_active === "active" && !user?.is_service
  );

  const serviceUsers = fullTraders.filter(
    (user) => user.type_active === "active" && user?.is_service
  );

  useEffect(() => {
    const formattedParamsUsers = formatParamsUsers();
    const formattedParamsUsersMerchant = formatParamsUsersMerchant();
    usersApi.getFullTraders(formattedParamsUsers);
    usersApi.getData(formattedParamsUsersMerchant, "merchant");
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      ordersApi.getData(formattedParams);
    }
  }, [search]);

  useEffect(() => {
    dispatch(setOrdersEmpty([]));
    lastId.current = null;
    lastDateTime.current = null;
  }, [
    value,
    requisiteSelect,
    timeSelect,
    usersSelect,
    bonusSelect,
    statusSelect,
    usersMerchantSelect,
    warningMessageSelect,
    currencySelect,
    callbackFlagSelect,
    debouncedSearchIdValue,
    debouncedReqisiteValue,
    debouncedReqisiteAdValue,
    paginationOrder,
  ]);

  useEffect(() => {
    setCheckedOrders([]);
    const formattedParams = formatParams();
    ordersApi.getData(formattedParams);
    firstLoad.current = true;
  }, [
    tab,
    statusSelect,
    value,
    requisiteSelect,
    timeSelect,
    usersSelect,
    bonusSelect,
    usersMerchantSelect,
    warningMessageSelect,
    currencySelect,
    callbackFlagSelect,
    debouncedSearchIdValue,
    debouncedReqisiteValue,
    debouncedReqisiteAdValue,
    paginationOrder,
  ]);

  useEffect(() => {
    setLanguageState(localStorage.getItem("localization"));
  }, [localStorage.getItem("localization")]);

  const formatParamsUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    return params;
  };

  const formatParamsUsersMerchant = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "merchant_server");
    return params;
  };

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("order_type", !tab ? "buy" : "sell");
    params.append("pagination_order", paginationOrder);
    if (value !== null)
      params.append(
        "date_from",
        moment(value[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (value !== null)
      params.append(
        "date_to",
        moment(value[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (requisiteSelect !== null)
      params.append("requisite_type", requisiteSelect.value);
    params.append(
      "time_filer",
      timeSelect !== null ? timeSelect.value : "created"
    );
    if (statusSelect !== null)
      params.append("order_status", statusSelect.value);
    if (usersSelect !== null) params.append("trader_id", usersSelect.value);
    if (usersMerchantSelect !== null)
      params.append("merchant_id", usersMerchantSelect.value);
    if (warningMessageSelect !== null)
      params.append("warning_message", warningMessageSelect.value);
    if (bonusSelect !== null) params.append("bonus_order", bonusSelect.value);
    if (callbackFlagSelect !== null)
      params.append("callback_flag", callbackFlagSelect.value);
    if (currencySelect !== null)
      params.append("currency_id", currencySelect.value);
    if (lastId.current !== null) params.append("last_order_id", lastId.current);
    if (lastDateTime.current !== null)
      params.append("last_datetime", lastDateTime.current);
    if (clientId !== "")
      params.append("client_id", clientId.replace(/\s+/g, ""));
    if (requisiteValue !== "")
      params.append("requisite", requisiteValue.replace(/\s+/g, ""));
    if (requisiteAdValue !== "")
      params.append("ad_requisite", requisiteAdValue.replace(/\s+/g, ""));
    return params;
  };

  const loadMore = () => {
    if (timeSelect === null) {
      lastDateTime.current = orders[orders.length - 1]?.created_at;
    }
    if (timeSelect?.value === "created") {
      lastDateTime.current = orders[orders.length - 1]?.created_at;
    }
    if (timeSelect?.value === "updated") {
      lastDateTime.current = orders[orders.length - 1]?.updated_at;
    }
    if (timeSelect?.value === "completed") {
      lastDateTime.current = orders[orders.length - 1]?.completed_at;
    }
    const formattedParams = formatParams();
    ordersApi.getData(formattedParams);
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const handleRequisiteValue = (searchTerm) => {
    setRequisiteValue(searchTerm);
  };

  const handleRequisiteAdValue = (searchTerm) => {
    setRequisiteAdValue(searchTerm);
  };

  const searchSendData = () => {
    const body = {
      order_id: search,
    };
    ordersApi.getOrderSearch(body);
  };

  const openAppealModal = (order) => {
    setInfoOrder(order);
    setAppealModal(true);
  };

  const openModalBillingOrder = (order) => {
    setInfoOrder(order);
    ordersApi.getBillingOrder(order);
    setModalBillingOrder(true);
  };

  const closeModalBillingOrder = () => {
    dispatch(setBillingOrder(null));
    dispatch(setBillingOrderHistory([]));
    setModalBillingOrder(false);
  };

  const openCallbackModal = (order) => {
    setInfoOrder(order);
    setCallbackModal(true);
  };

  const openCancelModal = (order) => {
    setInfoOrder(order);
    setCancelModal(true);
  };

  const openRecalcModal = (order) => {
    setInfoOrder(order);
    setRecalcModal(true);
  };

  const openRedefineModal = (order) => {
    setInfoOrder(order);
    setRedefineModal(true);
  };

  const openRedefineTraderModal = (order) => {
    setInfoOrder(order);
    setRedefineTraderModal(true);
  };

  const closeRecalcModal = () => {
    setInfoOrder(null);
    setRecalcModal(false);
    amount.setChange("");
    localStorage.removeItem("order_id");
    setSelectedFile([]);
  };

  const closeCancelModal = () => {
    setInfoOrder(null);
    setCancelModal(false);
  };

  const closeCallbackModal = () => {
    setInfoOrder(null);
    setCallbackModal(false);
  };

  const closeAppealModal = () => {
    setInfoOrder(null);
    setAppealModal(false);
    setSelectedFileAppeal([]);
  };

  const closeRedefineModal = () => {
    setInfoOrder(null);
    setRedefineModal(false);
  };

  const closeRedefineTraderModal = () => {
    setInfoOrder(null);
    setRedefineTraderModal(false);
    setUsersSelectRedefine(null);
  };

  const appealSendData = () => {
    const formData = new FormData();
    selectedFileAppeal.forEach((file) => {
      formData.append("admin_receipts", file);
    });

    const order_id = infoOrder?.id;
    const callback_flag = callbackSend;
    ordersApi.setAppeal(
      { order_id, callback_flag, formData },
      closeAppealModal
    );
  };

  const redefineSendData = () => {
    const body = {
      order_id: infoOrder?.id,
    };
    ordersApi.redefineOrder(body, closeRedefineModal);
  };

  const redefineTraderSendData = () => {
    const body = {
      order_id: infoOrder?.id,
      trader_id: usersSelectRedefine.value,
    };
    ordersApi.redefineTraderOrder(body, closeRedefineTraderModal, formatParams);
  };

  const redefineServiceTraderSendData = () => {
    const body = {
      order_id: infoOrder?.id,
      service_trader_id: usersSelectRedefine.value,
    };
    ordersApi.redefineServiceTraderOrder(
      body,
      closeRedefineTraderModal,
      formatParams
    );
  };

  const callbackSendData = () => {
    const body = {
      order_id: infoOrder?.id,
    };
    ordersApi.sendCallback(body, closeCallbackModal);
  };

  const cancelSendData = () => {
    const body = {
      order_id: infoOrder?.id,
      amount: infoOrder?.order_amount,
    };
    ordersApi.setCancel(body, closeCancelModal);
  };

  const cancelPendingSendData = () => {
    const body = {
      order_id: infoOrder?.id,
    };
    ordersApi.setCancelPendingSell(body, closeCancelModal);
  };

  const recalcSendData = () => {
    const formData = new FormData();
    formData.append("amount", amount.value);
    formData.append("receipt", selectedFile);

    ordersApi.setRecalc(formData, closeRecalcModal);
  };

  const massRedefineTraderOrder = () => {
    const body = {
      trader_id: usersSelectRedefine.value,
      orders: checkedOrders,
    };

    ordersApi.massRedefineTraderOrder(
      body,
      closeRedefineTraderModal,
      formatParams,
      setCheckedOrders
    );
  };

  const getDeclension = (number, one, few, many) => {
    number = Math.abs(number) % 100;
    const num = number % 10;
    if (number > 10 && number < 20) {
      return many;
    }
    if (num > 1 && num < 5) {
      return few;
    }
    if (num == 1) {
      return one;
    }
    return many;
  };

  const getRemainingTime = (completionTime) => {
    // Check if completionTime is provided
    if (!completionTime) {
      return "Время завершения не указано";
    }

    // Convert completion time to a moment object
    const endTime = moment.utc(completionTime).local();

    // Get the current time
    const currentTime = moment();

    // Calculate the difference
    const duration = moment.duration(endTime.diff(currentTime));

    // Extract hours and minutes from the duration
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    // Get the correct declension for hours and minutes
    const hoursDeclension = getDeclension(hours, "час", "часа", "часов");
    const minutesDeclension = getDeclension(
      minutes,
      "минута",
      "минуты",
      "минут"
    );

    // Format the remaining time
    const remainingTime = `${hours} ${hoursDeclension}, ${minutes} ${minutesDeclension}`;

    return remainingTime;
  };

  const checkOrder = (orderId) => {
    if (checkedOrders.includes(orderId)) {
      setCheckedOrders(checkedOrders.filter((id) => id !== orderId));
    } else {
      setCheckedOrders([...checkedOrders, orderId]);
    }
  };

  const isDisabled = (id) => {
    return checkedOrders.length >= 20 && !checkedOrders.includes(id);
  };

  const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
      return ""; // Если входная строка пуста или не определена, возвращаем пустую строку
    }

    // Удаляем все символы, кроме цифр
    const cleanedNumber = creditCardNumber.replace(/\D/g, "");

    // Добавляем пробел после каждых 4 символов
    const formattedNumber = cleanedNumber.replace(/(.{4})/g, "$1 ");

    // Возвращаем отформатированный номер карты
    return formattedNumber.trim(); // Удаляем лишние пробелы в начале и конце строки
  };

  const formatPhoneNumber = (phoneNumber) => {
    try {
      const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
      if (phoneNumberObject) {
        const country = phoneNumberObject.country;
        if (country === "RU") {
          // Для российского номера используем кастомное форматирование
          return "+7" + phoneNumberObject.formatNational().replace(/^8/, "");
        } else {
          // Для других номеров используем международное форматирование с заменой пробелов на дефисы
          return phoneNumberObject.formatInternational().replace(/ /g, "-");
        }
      }
    } catch (error) {
      console.error("Error formatting phone number:", error);
    }
    return phoneNumber;
  };

  const renderOrders = () => {
    return orders?.map((order) => (
      <tr
        key={order?.id}
        className={`order__row ${
          order?.warning_message?.search("DANGER") === 0 ? "warning" : ""
        } 
        ${
          order?.order_stage === "pending"
            ? "order__row_pending"
            : order?.order_stage === "success" &&
              order?.callback_result === true
            ? "order__row_complete"
            : order?.order_stage === "success" &&
              order?.callback_result === false
            ? "order__row_success_false"
            : order?.order_stage === "success" &&
              order?.callback_result === null
            ? "order__row_appeal_false"
            : order?.order_stage === "cancel" && order?.callback_result === true
            ? "order__row_cancel"
            : order?.order_stage === "cancel" &&
              order?.callback_result === false
            ? "order__row_success_false"
            : order?.order_stage === "cancel" && order?.callback_result === null
            ? "order__row_appeal_false"
            : (order?.order_stage === "appeal" ||
                order?.order_stage === "appeal_success") &&
              order?.callback_flag === true
            ? "order__row_appeal"
            : (order?.order_stage === "appeal" ||
                order?.order_stage === "appeal_success") &&
              order?.callback_flag === false
            ? "order__row_appeal_false"
            : "order__row_appeal"
        }
        `}
      >
        {tab && (
          <td>
            {order?.type === "sell" && order?.order_stage === "pending" && (
              <input
                className="order__checkbox"
                type="checkbox"
                checked={checkedOrders.includes(order?.id)}
                onChange={() => checkOrder(order?.id)}
                disabled={isDisabled(order.id)}
              />
            )}
          </td>
        )}
        {order?.type === "sell" && (
          <td>
            {order?.warning_message ? order?.warning_message : "--- --- ---"}
          </td>
        )}
        <td>
            {order?.id}
        </td>
        <td>
          <p style={{ marginBottom: 10 }}>{order?.transaction_id}</p>
          <p>
            <Link
              onClick={() => {
                localStorage.setItem("user_id", order?.merchant_id);
              }}
              target="_blank"
              to={`/user/${order?.merchant_id}`}
            >
              {usersMerchant.find((user) => user.id === order.merchant_id)
                ?.notice || order.merchant_id}
              {/* {order?.merchant?.notice ? order?.merchant?.notice : order?.merchant_id} */}
            </Link>
          </p>
        </td>
        {/* <td>{order?.internal_id ? order?.internal_id : "--- --- ---"}</td>
        <td>{order?.external_id ? order?.external_id : "--- --- ---"}</td> */}
        <td>
          <p>
            <Link
              onClick={() => {
                localStorage.setItem("user_id", order?.trader_id);
              }}
              target="_blank"
              to={`/user/${order?.trader_id}`}
            >
              {fullTraders.find((user) => user.id === order.trader_id)
                ?.notice ||
                order?.trader?.notice ||
                order?.trader_id}
              {/* {order?.trader?.notice ? order?.trader?.notice : order?.trader_id} */}
            </Link>
          </p>
        </td>
        <td>
          <p>{order?.client_id}</p>
        </td>
        <td>
          <p style={{ marginBottom: 10 }}>{order?.type}</p>
          <p>{order?.order_category}</p>
        </td>
        {order?.type === "buy" && (
          <td className="min-w">
            <pre style={{ marginBottom: 10 }}>
              {order?.ad_data?.card_number !== null
                ? formatCreditCardNumber(order?.ad_data?.card_number)
                : order?.ad_data?.phone_number !== null
                ? formatPhoneNumber(order?.ad_data?.phone_number)
                : formatCreditCardNumber(order?.ad_data?.requisite)}
            </pre>
            <pre>{order?.ad_data?.payment_method?.bank_name}</pre>
          </td>
        )}
        {order?.type === "sell" && (
          <td className="min-w">
            <pre style={{ marginBottom: 10 }}>
              {order?.card_number !== null
                ? formatCreditCardNumber(order?.card_number)
                : order?.phone_number !== null
                ? formatPhoneNumber(order?.phone_number)
                : formatCreditCardNumber(order?.requisite)}
            </pre>
            <pre>{order?.payment_method?.bank_name}</pre>
          </td>
        )}
        <td>
          <p>{order?.order_amount}</p>
        </td>
        <td>
          <p style={{ marginBottom: 10 }}>{order?.order_stage}</p>
          <p>{order?.order_substage}</p>
        </td>
        <td>
          <p>
            {moment.utc(order?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
          </p>
        </td>
        <td>
          <p>
            {moment
              .utc(order?.updated_at)
              .local()
              .format("DD.MM.YY, HH:mm:ss") !== "Invalid date"
              ? moment
                  .utc(order?.updated_at)
                  .local()
                  .format("DD.MM.YY, HH:mm:ss")
              : "--- --- ---"}
          </p>
        </td>
        {/* <td>
          {moment.utc(order?.completed_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td> */}
        <td>
          <p>
            {order?.order_stage === "pending"
              ? getRemainingTime(moment.utc(order?.time_to_end).local())
              : "--- --- ---"}
          </p>
        </td>
        <td className="order__btn">
          <NavLink
            onClick={() => {
              localStorage.setItem("order_id", order?.id);
            }}
            to={`/order/${order?.id}`}
            target="_blank"
            className="user__btn"
          >
            <img src="/assets/icons/eye-icon.svg" alt="" />
          </NavLink>
        </td>
        <td
          onClick={() => {
            openModalBillingOrder(order);
          }}
          className="orderBilling__btn"
        >
          <img src="/assets/icons/billing.svg" alt="billing" />
        </td>
        {(order?.order_stage === "appeal" ||
          order?.order_stage === "appeal_success") && (
          <td
            onClick={() => {
              localStorage.setItem("order_id", order?.id);
              openRecalcModal(order);
            }}
            className="order__btn"
          >
            <img src="/assets/icons/calc-order.svg" alt="" />
          </td>
        )}
        {(order?.order_stage === "success" ||
          order?.order_stage === "cancel") && (
          <td
            onClick={() => {
              openCallbackModal(order);
            }}
            className="order__btn"
          >
            <img src="/assets/icons/callback.svg" alt="" />
          </td>
        )}
        {order?.order_stage === "pending" && order?.type !== "buy" && (
          <td
            onClick={() => {
              openRedefineModal(order);
            }}
            className="order__btn"
          >
            <img src="/assets/icons/ptichka.svg" alt="" />
          </td>
        )}
        {order?.order_stage === "pending" && order?.type !== "buy" && (
          <td
            onClick={() => {
              setRedefine("oneOrder");
              openRedefineTraderModal(order);
            }}
            className="order__btn"
          >
            <img src="/assets/icons/sendRedefine.svg" alt="" />
          </td>
        )}
        {order?.order_stage !== "appeal" &&
          order?.order_stage !== "appeal_success" && (
            <td
              onClick={() => {
                openAppealModal(order);
                setCallbackSend(true);
              }}
              className="order__btn order__btn_callback_send"
            >
              <img src="/assets/icons/alert-triangle.svg" alt="" />
            </td>
          )}
        {order?.order_stage !== "appeal" &&
          order?.order_stage !== "appeal_success" && (
            <td
              onClick={() => {
                openAppealModal(order);
                setCallbackSend(false);
              }}
              className="order__btn order__btn_callback_notsend"
            >
              <img src="/assets/icons/alert-triangle.svg" alt="" />
            </td>
          )}
        {(order?.order_stage === "appeal" ||
          order?.order_stage === "appeal_success") && (
          <td
            onClick={() => {
              openCancelModal(order);
            }}
            className="order__btn"
          >
            <img src="/assets/icons/x-circle.svg" alt="" />
          </td>
        )}
        {order?.order_stage === "pending" && order?.type === "sell" && (
          <td
            onClick={() => {
              openCancelModal(order);
            }}
            className="order__btn"
          >
            <img src="/assets/icons/x-circle.svg" alt="" />
          </td>
        )}
      </tr>
    ));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileChangeAppeal = (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 3) {
      showToast("Максимальное количество файлов - 3", "error");
      event.target.value = ""; // Сбросить выбранные файлы
      return;
    }

    setSelectedFileAppeal(files);
  };

  // const handleFileChangeAppeal = (event) => {
  //   const files = Array.from(event.target.files);
  //
  //   if (files.length > 3) {
  //     showToast("Максимальное количество файлов - 3" , "error")
  //     setSelectedFile([])
  //     return;
  //   }
  //
  //   setSelectedFileAppeal(files);
  // };

  const renderedRows = useMemo(() => {
    return billingOrderHistory?.map((item) => (
      <tr key={item?.id}>
        <td>
          {moment.utc(item?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
        <td>{item?.order_id}</td>
        <td>{item?.deposit}</td>
        <td>{item?.frozen_balance}</td>
        <td>{item?.currency_type}</td>
        <td>{item?.operation_type}</td>
      </tr>
    ));
  }, [billingOrderHistory]);

  const handleSearchId = (searchTerm) => {
    setClientId(searchTerm);
  };

  const handleChange = (e) => {
    // Извлечение значения из textarea
    const inputText = e.target.value;

    // Разрешенные символы: цифры, пробелы и переносы строк
    const validInput = inputText.replace(/[^\d\s\n]/g, "");

    // Разделение строки по пробелам или переносам строк и фильтрация пустых строк
    const numberArray = validInput
      .split(/[\s\n]+/)
      .filter(Boolean) // убирает пустые строки
      .map(Number); // преобразует строки в числа

    // Сохранение массива чисел в состоянии
    setNumbers(numberArray);
    setInputValue(validInput); // Обновление состояния inputValue для отображения только валидного ввода
  };

  const closeCheckOrdersModal = () => {
    setCheckOrdersModal(false);
    setInputValue("");
    setNumbers([]);
  };

  const searchIdsOrders = () => {
    const formatParamsOrdersIds = () => {
      const params = new URLSearchParams();

      // Проверяем, что numbers действительно массив
      if (Array.isArray(numbers)) {
        // Добавляем каждый элемент массива numbers как отдельный параметр
        numbers.forEach((number) => {
          params.append("order_ids[]", number);
        });
      } else {
        console.error("numbers is not an array");
      }

      return params;
    };

    const formattedParams = formatParamsOrdersIds();
    ordersApi.getIdsOrders(formattedParams, closeCheckOrdersModal);
  };

  const searchIdsTransactionOrders = () => {
    const formatParamsOrdersIds = () => {
      const params = new URLSearchParams();

      // Если выбранный ID пользователя не равен null, добавляем его в параметры
      if (usersMerchantIdsSelect !== null) {
        params.append("merchant_id", usersMerchantIdsSelect.value);
      }

      // Проверяем, что numbers является массивом
      if (Array.isArray(numbers)) {
        // Добавляем каждый элемент массива numbers как отдельный параметр
        numbers.forEach((number) => {
          params.append("transaction_ids[]", number);
        });
      } else {
        console.error("numbers is not an array");
      }

      return params;
    };

    const formattedParams = formatParamsOrdersIds();
    ordersApi.getIdsTransactionOrders(formattedParams, closeCheckOrdersModal);
  };

  return (
    <div className="orders container">
      {modalBillingOrder && (
        <Popup title={"Кошелек"} closePopup={closeModalBillingOrder}>
          <table>
            <thead>
              <tr>
                <th>Баланс</th>
                <th>Замороженный баланс</th>
                <th>Депозит</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{billingOrder?.balance}</td>
                <td>{billingOrder?.frozen_balance}</td>
                <td>{billingOrder?.deposit}</td>
              </tr>
            </tbody>
          </table>
          <h2 className="orderBilling__title">Биллинг</h2>
          <table>
            <thead>
              <tr>
                <th>Дата создания</th>
                <th>ID заявки</th>
                <th>Депозит</th>
                <th>Замороженный баланс</th>
                <th>Тип валюты</th>
                <th>Тип операции</th>
              </tr>
            </thead>
            <tbody>{renderedRows}</tbody>
          </table>
        </Popup>
      )}
      {appealModal && (
        <Popup
          title={`Подача апелляции ${
            callbackSend ? "c колбэком" : "без колбэка"
          }`}
          closePopup={closeAppealModal}
        >
          <p className="delete-text">
            Вы уверены что хотите открыть апелляцию?
          </p>
          <p className="recalc-text">Прикрепите чек</p>
          <input
            value={selectedFileAppeal?.name}
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleFileChangeAppeal}
            className="recalc-input"
            type="file"
            multiple
          />
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeAppealModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Открыть апелляцию"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={appealSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {recalcModal && (
        <Popup title={"Перерасчитать заявку"} closePopup={closeRecalcModal}>
          <p className="delete-text">
            Вы уверены что хотите перерасчитать заявку?
          </p>
          <div className="popup__input">
            <Input
              label={"Введите сумму"}
              placeholder="Введите сумму"
              type="text"
              id="amount"
              onlyNumber={true}
              {...amount}
            />
          </div>
          <p className="recalc-text">Прикрепите чек</p>
          <input
            accept="application/pdf"
            onChange={handleFileChange}
            className="recalc-input"
            type="file"
          />
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeRecalcModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Перерасчитать"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={recalcSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {callbackModal && (
        <Popup title={"Прокинуть callback"} closePopup={closeCallbackModal}>
          <p className="delete-text">
            Вы уверены что хотите прокинуть callback?
          </p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCallbackModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Отправить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={callbackSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {cancelModal && (
        <Popup title={"Отменить ордер"} closePopup={closeCancelModal}>
          <p className="delete-text">Вы уверены что хотите отменить заявку?</p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCancelModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Отменить заявку"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={() => {
                  if (infoOrder?.order_stage === "pending") {
                    cancelPendingSendData();
                  } else {
                    cancelSendData();
                  }
                }}
              />
            </div>
          </div>
        </Popup>
      )}
      {redefineModal && (
        <Popup title={"Перенаправить заявку"} closePopup={closeRedefineModal}>
          <p className="delete-text">
            Вы уверены что хотите перенаправить заявку?
          </p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeRedefineModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Перенаправить заявку"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={redefineSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {redefineTraderModal && (
        <Popup
          title={
            checkedOrders?.length > 0
              ? "Перенаправить заявки на трейдера"
              : "Перенаправить заявку на конкретного трейдера"
          }
          closePopup={closeRedefineTraderModal}
        >
          {checkedOrders?.length === 0 && (
            <div className="orders__tabs orders__tabs_service mt-10">
              <div
                onClick={() => {
                  if (tabService) {
                  }
                  setTabService(false);
                }}
                className={`orders__tab ${
                  !tabService ? "orders__tab_active" : ""
                }`}
              >
                <p>На трейдера</p>
              </div>
              <div
                onClick={() => {
                  if (!tabService) {
                  }
                  setTabService(true);
                }}
                className={`orders__tab ${
                  tabService ? "orders__tab_active" : ""
                }`}
              >
                <p>На сервисного трейдера</p>
              </div>
            </div>
          )}
          <div className="popup__select mt-10">
            <CustomSelect
              options={
                !tabService
                  ? users.map((user) => {
                      return {
                        value: user.id,
                        label: user.notice !== null ? user.notice : user.login,
                      };
                    })
                  : serviceUsers.map((user) => {
                      return {
                        value: user.id,
                        label: user.notice !== null ? user.notice : user.login,
                      };
                    })
              }
              placeholder={"Выберите трейдера"}
              selected={usersSelectRedefine}
              handleSelect={(e) => {
                setUsersSelectRedefine(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeRedefineTraderModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={
                  checkedOrders?.length > 0
                    ? "Перенаправить заявки"
                    : "Перенаправить заявку"
                }
                topPadding={10}
                disabled={usersSelectRedefine === null ? true : false}
                bottomPadding={10}
                type="accept"
                onClick={
                  redefine === "oneOrder" && !tabService
                    ? redefineTraderSendData
                    : redefine === "oneOrder" && tabService
                    ? redefineServiceTraderSendData
                    : massRedefineTraderOrder
                }
              />
            </div>
          </div>
        </Popup>
      )}
      {checkOrdersModal && (
        <Popup
          title={"Массовый поиск по id"}
          closePopup={closeCheckOrdersModal}
        >
          <div className="orders__tabs orders__tabs_service mt-10">
            <div
              onClick={() => {
                if (tabId) {
                }
                setTabId(false);
              }}
              className={`orders__tab ${!tabId ? "orders__tab_active" : ""}`}
            >
              <p>По ID</p>
            </div>
            <div
              onClick={() => {
                if (!tabId) {
                }
                setTabId(true);
              }}
              className={`orders__tab ${tabId ? "orders__tab_active" : ""}`}
            >
              <p>По Transaction ID</p>
            </div>
          </div>
          {tabId && (
            <div className="orders__select w-100">
              <CustomSelect
                options={usersMerchant?.map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
                placeholder={"Выберите мерчанта"}
                selected={usersMerchantIdsSelect}
                handleSelect={(e) => {
                  setUsersMerchantIdsSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          )}
          <div className="orders__modal-textarea">
            <textarea
              placeholder="Введите id заявок"
              value={inputValue}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCheckOrdersModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Поиск"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={() => {
                  if (!tabId) {
                    searchIdsOrders();
                  } else {
                    searchIdsTransactionOrders();
                  }
                }}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Заявки"} />
      <div className="orders__inner">
        <div className="orders__selects">
          <div className="popup-select orders__head-select">
            <CustomSelect
              options={currencies.map((currency) => {
                return {
                  value: currency.id,
                  label: currency.currency,
                };
              })}
              placeholder={"Выберите валюту"}
              selected={currencySelect}
              id="currency"
              handleSelect={(e) => {
                setCurrencySelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="users__top-btn">
            <Button
              text={"Массовый поиск заявок"}
              type={"primary"}
              onClick={() => {
                setCheckOrdersModal(true);
              }}
              topPadding={10}
              bottomPadding={10}
            />
          </div>
        </div>
        <div className="orders__selects">
          <div className="orders__input mb-10">
            <SearchComponent
              searchTerm={requisiteValue}
              onSearch={handleRequisiteValue}
              placeholder={"Реквизиты клиента"}
            />
          </div>
          <div className="orders__input mb-10">
            <SearchComponent
              searchTerm={requisiteAdValue}
              onSearch={handleRequisiteAdValue}
              placeholder={"Реквизиты объявления"}
            />
          </div>
          <div className="popup-select orders__head-select">
            <CustomSelect
              options={requisiteData}
              placeholder={"Выберите тип реквизитов"}
              selected={requisiteSelect}
              handleSelect={(e) => {
                setRequisiteSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
        </div>
        <div className="orders__selects">
          <div className="orders__select">
            <CustomSelect
              options={timeData}
              placeholder={"Выберите тип времени"}
              selected={timeSelect}
              handleSelect={(e) => {
                setTimeSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={fullTraders?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите трейдера"}
              selected={usersSelect}
              handleSelect={(e) => {
                setUsersSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={usersMerchant?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите мерчанта"}
              selected={usersMerchantSelect}
              handleSelect={(e) => {
                setUsersMerchantSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={warningMessageData}
              placeholder={"Warning message"}
              selected={warningMessageSelect}
              handleSelect={(e) => {
                setWarningMessageSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={callbackFlagData}
              placeholder={"Выберите тип кб"}
              selected={callbackFlagSelect}
              handleSelect={(e) => {
                setCallbackFlagSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={bonusData}
              placeholder={"Выберите тип межбанка"}
              selected={bonusSelect}
              handleSelect={(e) => {
                setBonusSelect(e ?? null);
                dispatch(setOrdersEmpty([]));
              }}
              isClearable={true}
            />
          </div>
        </div>
        <div className="orders__top">
          <div className="orders__tabs orders__tabs_mw">
            <div
              onClick={() => {
                if (tab) {
                  lastId.current = null;
                  lastDateTime.current = null;
                  dispatch(setOrdersEmpty([]));
                }
                setTab(false);
              }}
              className={`orders__tab ${!tab ? "orders__tab_active" : ""}`}
            >
              <p>
                {languageState === "false"
                  ? language?.ads?.sell_ru
                  : language?.ads?.sell_en}
              </p>
            </div>
            <div
              onClick={() => {
                if (!tab) {
                  lastId.current = null;
                  lastDateTime.current = null;
                  dispatch(setOrdersEmpty([]));
                }
                setTab(true);
              }}
              className={`orders__tab ${tab ? "orders__tab_active" : ""}`}
            >
              <p>
                {languageState === "false"
                  ? language?.ads?.buy_ru
                  : language?.ads?.buy_en}
              </p>
            </div>
            <div className="orders__select">
              <CustomSelect
                options={statusData}
                placeholder={"Выберите статус"}
                selected={statusSelect}
                handleSelect={(e) => {
                  setStatusSelect(e ?? null);
                  dispatch(setOrdersEmpty([]));
                }}
                isClearable={true}
              />
            </div>
            <div className="orders__input">
              <SearchComponent
                searchTerm={clientId}
                onSearch={handleSearchId}
                placeholder={"Поиск по ID клиента"}
              />
            </div>
            <DateRangePicker onChange={setValue} format="dd.MM.yyyy HH:mm" />
          </div>
          <div
            className={`users__top-inner ${
              checkedOrders?.length > 0
                ? "users__top-inner_lx"
                : "users__top-inner_ml"
            }`}
          >
            <div className="users__top-search">
              <SearchComponent
                searchTerm={search}
                onSearch={handleSearch}
                placeholder={"Введите id заявки"}
              />
            </div>
            <div className="users__top-btn">
              <Button
                text={"Найти заяку"}
                type={"accept"}
                onClick={() => {
                  searchSendData();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            {checkedOrders?.length > 0 && (
              <div className="users__top-btn">
                <Button
                  text={"Перенаправить заявки"}
                  type={"primary"}
                  onClick={() => {
                    setRedefine("massOrder");
                    openRedefineTraderModal();
                  }}
                  topPadding={10}
                  bottomPadding={10}
                />
              </div>
            )}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {tab && (
                <th>
                  <input
                    className="order__checkbox"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckedOrders(
                          orders
                            .filter((item) => item?.order_stage === "pending")
                            .slice(0, 20)
                            .map((item) => item.id)
                        );
                      } else {
                        setCheckedOrders([]);
                      }
                    }}
                    disabled={
                      statusSelect === null || statusSelect?.value === "pending"
                        ? false
                        : true
                    }
                  />
                </th>
              )}
              {tab && <th>Warning Message</th>}
              <th>ID</th>
              <th>Transaction ID / Merchant ID</th>
              {/* <th>Internal ID</th>
              <th>External ID</th> */}
              <th>Trader ID</th>
              <th>Client ID</th>
              <th>Type / Category</th>
              <th>Requisite</th>
              <th>Order Amount</th>
              {/* <th></th> */}
              <th>Status / Substage</th>
              <th>
                <div
                  className="order__th"
                  onClick={() => {
                    if (paginationOrder === "start") {
                      setPaginationOrder("end");
                    } else {
                      setPaginationOrder("start");
                    }
                    dispatch(setOrdersEmpty([]));
                  }}
                >
                  <p>Created At</p>
                  <span>
                    {paginationOrder === "start" ? (
                      <img src="/assets/icons/arrowDown.svg" alt="arrownDown" />
                    ) : (
                      <img
                        src="/assets/icons/arrowUpper.svg"
                        alt="arrowUpper"
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div
                  className="order__th"
                  onClick={() => {
                    if (paginationOrder === "start") {
                      setTimeSelect({
                        value: "updated",
                        label: "Updated",
                      });
                      setPaginationOrder("end");
                    } else {
                      setTimeSelect({
                        value: "updated",
                        label: "Updated",
                      });
                      setPaginationOrder("start");
                    }
                    dispatch(setOrdersEmpty([]));
                  }}
                >
                  <p>Updated At</p>
                  <span>
                    {paginationOrder === "start" ? (
                      <img src="/assets/icons/arrowDown.svg" alt="arrownDown" />
                    ) : (
                      <img
                        src="/assets/icons/arrowUpper.svg"
                        alt="arrowUpper"
                      />
                    )}
                  </span>
                </div>
              </th>
              {/* <th>Completed At</th> */}
              <th>Time to end</th>
            </tr>
          </thead>
          <tbody>{renderOrders()}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
