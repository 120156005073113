import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../Components/Header";
import Button from "../../UI/Button";
import ApiUsers from "../../Global/Api/users.api";
import Popup from "../../Components/Popup";
import { CustomSelect } from "../../UI/Select";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import "./index.scss";
import SearchComponent from "../../Components/Search";

export default function Users() {
  const usersApi = ApiUsers();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const [limitCount, setLimitCount] = useState(100);
  const [infoUser, setInfoUser] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [updateUuidModal, setUpdateUuidModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roleSelect, setRoleSelect] = useState(null);
  const [roleFilterSelect, setRoleFilterSelect] = useState(null);
  const [banedSelect, setBanedSelect] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const login = useInputHandler("");
  const channelId = useInputHandler("");
  const telegramId = useInputHandler("");
  const userUrl = useInputHandler("");
  const agentId = useInputHandler("");
  const notice = useInputHandler("");
  const mainMerchant = useInputHandler("");
  const ip = useInputHandler("");
  const sessionTimeout = useInputHandler("");
  const faPath = useInputHandler("");
  const agentFee = useInputHandler("");
  const lastCreated = useRef(null);
  const [activeSelect, setActiveSelect] = useState(null);
  const [statusSelect, setStatusSelect] = useState(null);
  const [agentSelect, setAgentSelect] = useState(null);
  const [showBannedUsers, setShowBannedUsers] = useState(false);
  const statusData = [
    {
      label: "Активен",
      value: "active",
    },
    {
      label: "Не активен",
      value: "not_active",
    },
    {
      label: "На паузе",
      value: "paused",
    },
  ];
  const activeData = [
    {
      label: "Активен",
      value: "active",
    },
    {
      label: "Не активен",
      value: "not_active",
    },
  ];
  const baned = [
    {
      label: "Заблокировать",
      value: true,
    },
    {
      label: "Разблокировать",
      value: false,
    },
  ];

  const { users, roles, usersAgent } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );
  const isButtonDisabled = !(roleSelect !== null);
  const firstLoad = useRef(false);

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      usersApi.getData(formattedParams);
    }
  }, [search]);

  useEffect(() => {
    const formattedParams = formatParams();
    if (roleFilterSelect !== null || statusSelect !== null) {
      usersApi.getData(formattedParams, "filter");
    } else {
      usersApi.getData(formattedParams);
      firstLoad.current = true;
    }
  }, [roleFilterSelect, statusSelect]);

  useEffect(() => {
    const formattedParamsUsersAgent = formatParamsUsersAgent();
    usersApi.getData(formattedParamsUsersAgent, "agent");
  }, []);

  const formatParamsUsersAgent = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "agent");
    return params;
  };

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    if (roleFilterSelect !== null)
      params.append("role", roleFilterSelect.value);
    if (statusSelect !== null) params.append("type_active", statusSelect.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const loadMore = () => {
    lastCreated.current = users[users.length - 1]?.created_at;
    const formattedParams = formatParams();
    usersApi.getData(formattedParams);
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const openDeleteModal = (user) => {
    setInfoUser(user);
    setDeleteModal(true);
  };

  const openUpdateUuidModal = (user) => {
    setInfoUser(user);
    setUpdateUuidModal(true);
  };

  const closeUpdateUuidModal = (user) => {
    setInfoUser(null);
    setUpdateUuidModal(false);
  };

  const openCreateModal = (user) => {
    setInfoUser(user);
    setCreateModal(true);
  };

  const openEditModal = (user) => {
    setInfoUser(user);
    setEditModal(true);
    login.setChange(user?.login === null ? "" : user?.login);
    notice.setChange(user?.notice === "" ? "" : user?.notice);
    channelId.setChange(user?.channel_id === null ? "" : user?.channel_id);
    telegramId.setChange(user?.telegram_id === null ? "" : user?.telegram_id);
    userUrl.setChange(user?.user_url === null ? "" : user?.user_url);
    sessionTimeout.setChange(
      user?.session_timeout === null ? "" : user?.session_timeout
    );
    mainMerchant.setChange(
      user?.main_merchant === null ? "" : user?.main_merchant
    );
    let selectAgent = usersAgent?.find((el) => el?.id === user?.agent_id);
    if (selectAgent !== undefined) {
      setAgentSelect({
        value: selectAgent?.id,
        label: selectAgent?.notice,
      });
    }
    setRoleSelect(roles?.filter((el) => el?.value === user?.role));
    setBanedSelect({
      value: user?.is_banned,
      label: user?.is_banned ? "Заблокировать" : "Разблокировать",
    });
    setActiveSelect({
      value: user?.type_active,
      label: user?.type_active === "active" ? "Активен" : "Не активен",
    });
    ip.setChange(user?.ip_list?.length > 0 ? user?.ip_list.join(", ") : "");
    agentFee.setChange(user?.agent_fee === null ? "" : user?.agent_fee);
  };

  const closeDeleteModal = () => {
    setInfoUser(null);
    setDeleteModal(false);
  };

  const closeEditModal = () => {
    setInfoUser(null);
    setEditModal(false);
    login.setChange("");
    channelId.setChange("");
    telegramId.setChange("");
    userUrl.setChange("");
    agentId.setChange("");
    setAgentSelect(null);
    mainMerchant.setChange("");
    setRoleSelect(null);
    ip.setChange("");
    setBanedSelect(null);
    setActiveSelect(null);
    notice.setChange("");
    agentFee.setChange("");
    sessionTimeout.setChange("");
  };

  const closeCreateModal = () => {
    setInfoUser(null);
    setCreateModal(false);
  };

  const deleteSendData = () => {
    const body = {
      user_id: infoUser?.id,
    };
    usersApi.deletedUser(body, closeDeleteModal);
  };

  const createSendData = () => {
    const body = {
      role: roleSelect?.value,
    };
    usersApi.createUser(body, closeCreateModal);
  };

  const searchSendData = () => {
    const body = {
      user_id: search,
    };
    usersApi.getSearchUser(body);
  };

  const editSendData = () => {
    let body;
    if (infoUser?.role === "merchant_server") {
      body = {
        id: infoUser?.id,
        login: login.value !== "" ? login.value : null,
        channel_id: channelId.value !== "" ? channelId.value : null,
        telegram_id: telegramId.value !== "" ? telegramId.value : null,
        user_url: userUrl.value !== "" ? userUrl.value : null,
        agent_id:
          agentSelect?.value !== null || agentSelect?.value !== undefined
            ? agentSelect?.value
            : null,
        main_merchant: mainMerchant.value !== "" ? mainMerchant.value : null,
        role: roleSelect?.value,
        notice: notice.value !== "" ? notice.value : "",
        ip_list:
          ip?.value !== ""
            ? ip.value?.split(/[\s,]+/).map((ip) => ip.trim())
            : [],
        is_banned: banedSelect.value,
        type_active: activeSelect.value,
        agent_fee: agentFee.value !== "" ? agentFee.value : null,
        session_timeout: sessionTimeout.value,
      };
    } else {
      body = {
        id: infoUser?.id,
        login: login.value !== "" ? login.value : null,
        channel_id: channelId.value !== "" ? channelId.value : null,
        telegram_id: telegramId.value !== "" ? telegramId.value : null,
        user_url: userUrl.value !== "" ? userUrl.value : null,
        agent_id:
          agentSelect?.value !== null || agentSelect?.value !== undefined
            ? agentSelect?.value
            : null,
        main_merchant: mainMerchant.value !== "" ? mainMerchant.value : null,
        role: roleSelect?.value,
        notice: notice.value !== "" ? notice.value : "",
        ip_list:
          ip?.value !== ""
            ? ip.value?.split(/[\s,]+/).map((ip) => ip.trim())
            : [],
        is_banned: banedSelect.value,
        type_active: activeSelect.value,
        agent_fee: agentFee.value !== "" ? agentFee.value : null,
      };
    }
    usersApi.editedUser(body, closeEditModal);
  };

  const updateUuidSendData = () => {
    const body = {
      id: infoUser?.id,
    };
    usersApi.editedUuidUser(body, closeUpdateUuidModal);
  };

  const renderUsersData = () => {
    return users
      ?.filter((user) =>
        showBannedUsers ? user?.is_banned === true : user?.is_banned === false
      )
      .map((user) => (
        <tr
          className={`${
            user?.is_banned === false && user?.type_active !== "active"
              ? "is_active"
              : user?.is_banned === true && user?.type_active !== "active"
              ? "is_banned"
              : user?.is_banned === true && user?.type_active === "active"
              ? "is_banned"
              : "transparent_trader"
          }`}
          key={user?.id}
        >
          <td className="popup__actions">
            <a
              onClick={() => {
                localStorage.setItem("user_id", user?.id);
              }}
              href={`/user/${user?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="user__btn"
            >
              <img src="/assets/icons/eye-icon.svg" alt="" />
            </a>
            <div
              onClick={() => {
                openEditModal(user);
              }}
              className="users__btn"
            >
              <img src="/assets/icons/edit-ad.svg" alt="" />
            </div>
            {profileData?.role === "superadmin" && (
              <div
              onClick={() => {
                openDeleteModal(user);
              }}
              className="users__btn"
            >
              <img src="/assets/icons/trash-ad.svg" alt="" />
            </div>
            )}
            {profileData?.role === "superadmin" && (
              <div className="users__btns">
              {user?.role === "merchant_server" && (
                <div
                  onClick={() => {
                    openUpdateUuidModal(user);
                  }}
                  className="users__btn"
                >
                  <img src="/assets/icons/update-uuid.svg" alt="" />
                </div>
              )}
            </div>
            )}
          </td>
          <td>{user?.notice}</td>
          <td>{user?.is_banned === true ? "Заблокирован" : "Разблокирован"}</td>
          <td>
            {user?.type_active === "active"
              ? "Активен"
              : user?.type_active === "paused"
              ? "На паузе"
              : "Неактивен"}
          </td>
          <td>{user?.login}</td>
          <td>
            {user?.session_timeout} {user?.session_timeout !== null && "секунд"}
          </td>
          <td>
            <div>{user?.is_service ? "service trader" : user?.role}</div>
          </td>
        </tr>
      ));
  };

  return (
    <div className="users container">
      {updateUuidModal && (
        <Popup
          title={"Обновить uuid пользователя"}
          closePopup={closeUpdateUuidModal}
        >
          <p className="delete-text">
            Вы уверены что хотите обновить uuid пользователя?
          </p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeUpdateUuidModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Обновить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={updateUuidSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {createModal && (
        <Popup title={"Создать пользователя"} closePopup={closeCreateModal}>
          <div className="popup-select">
            <p>Выберите роль пользователя</p>
            <CustomSelect
              options={roles}
              selected={roleSelect}
              handleSelect={(e) => {
                setRoleSelect(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={isButtonDisabled}
                bottomPadding={10}
                type="accept"
                onClick={createSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {editModal && (
        <Popup title={"Изменить пользователя"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Логин"}
                  placeholder="Введите логин"
                  type="text"
                  id="login"
                  {...login}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Notice"}
                  placeholder="Введите notice"
                  type="text"
                  id="notice"
                  {...notice}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Chanel id"}
                  placeholder="Введите id"
                  type="text"
                  id="channelId"
                  {...channelId}
                  onlyNumber={true}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Telegram id"}
                  placeholder="Введите id"
                  type="text"
                  id="telegramId"
                  {...telegramId}
                  onlyNumber={true}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"User url"}
                  placeholder="Введите url"
                  type="text"
                  id="userUrl"
                  {...userUrl}
                />
              </div>
              <div className="popup-select">
                {/*<Input*/}
                {/*  label={"Агент id"}*/}
                {/*  placeholder="Введите id"*/}
                {/*  type="text"*/}
                {/*  id="agentId"*/}
                {/*  {...agentId}*/}
                {/*/>*/}
                {/*<UsersSelect users={users} onChange={handleSelectChange} />*/}
                <p>agent Id</p>
                <CustomSelect
                  options={usersAgent?.map((user) => {
                    return {
                      value: user.id,
                      label: user.notice !== null ? user.notice : user.login,
                    };
                  })}
                  selected={agentSelect}
                  handleSelect={(e) => {
                    setAgentSelect(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            {/* <div className="popup__inputs"> */}
            {/* <div className="popup__input">
                <Input
                  label={"Main merchant"}
                  placeholder="Введите main merchant"
                  type="text"
                  id="mainMerchant"
                  {...mainMerchant}
                />
              </div> */}
            {/* <div className="popup-select">
                <p>Выберите роль пользователя</p>
                <CustomSelect
                  options={roles}
                  selected={roleSelect}
                  handleSelect={(e) => {
                    setRoleSelect(e ?? null)
                  }}
                />
              </div> */}
            {/* </div> */}
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Ставка агента"}
                  placeholder="Введите ставку"
                  type="text"
                  id="agentFee"
                  {...agentFee}
                  onlyNumber={true}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Список ip адресов"}
                  placeholder="Введите ip через запятую"
                  type="text"
                  id="ip"
                  {...ip}
                />
              </div>
            </div>
            {infoUser?.role === "merchant_server" && (
              <div className="popup__inputs">
                <div className="popup__input">
                  <Input
                    label={"Время сессии"}
                    placeholder="Введите время сессии"
                    type="text"
                    id="sessionTimeout"
                    {...sessionTimeout}
                    onlyNumber={true}
                  />
                </div>
              </div>
            )}
            <div className="popup__inputs">
              <div className="popup-select">
                <p>Заблокировать/Разблокировать</p>
                <CustomSelect
                  options={baned}
                  isClearable={false}
                  selected={banedSelect}
                  handleSelect={(e) => {
                    setBanedSelect(e ?? null);
                  }}
                />
              </div>
              <div className="popup-select">
                <p>Активен/Неактивен</p>
                <CustomSelect
                  options={activeData}
                  selected={activeSelect}
                  isClearable={false}
                  handleSelect={(e) => {
                    setActiveSelect(e ?? null);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {deleteModal && (
        <Popup title={"Удалить пользователя"} closePopup={closeDeleteModal}>
          <p className="delete-text">
            Вы уверены что хотите удалить пользователя?
          </p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Пользователи"} />
      <div className="users__inner">
        <div className="users__top">
          <div className="users__top-inner">
            <div className="users__top-select">
              <CustomSelect
                options={roles}
                placeholder={"Выберите роль"}
                selected={roleFilterSelect}
                handleSelect={(e) => {
                  setRoleFilterSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="users__top-select">
              <CustomSelect
                options={statusData}
                placeholder={"Выберите статус"}
                selected={statusSelect}
                handleSelect={(e) => {
                  setStatusSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            {profileData?.role === "superadmin" && (
              <div className="users__top-btn">
                <Button
                  text={"Создать пользователя"}
                  type={"accept"}
                  onClick={() => {
                    openCreateModal();
                  }}
                  topPadding={10}
                  bottomPadding={10}
                />
              </div>
            )}
          </div>
          <div className="users__top-inner users__top-inner_normal">
            <div className="users__top-search">
              <SearchComponent
                searchTerm={search}
                onSearch={handleSearch}
                placeholder={"Введите id пользователя"}
              />
            </div>
            <div className="users__top-btn">
              <Button
                text={"Найти пользователя"}
                type={"accept"}
                onClick={() => {
                  searchSendData();
                }}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="users__top-btn">
              <Button
                onClick={() => setShowBannedUsers(!showBannedUsers)}
                text={
                  showBannedUsers
                    ? "Показать разблокированных"
                    : "Показать заблокированных"
                }
              ></Button>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Действия</th>
              <th>Notice</th>
              <th>Блокировка</th>
              <th>Активность</th>
              <th>Логин</th>
              <th>Время сессии</th>
              <th>Роль</th>
            </tr>
          </thead>
          <tbody>{renderUsersData()}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
