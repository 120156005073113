import {useDispatch} from "react-redux";
import {
  useCreateBankBinsMutation,
  useDeleteBankBinMutation, useEditBankBinMutation,
  useGetBankBinsMutation, useGetBankBinsSearchMutation
} from "../../Store/Builder/unitrade.api";
import {
  setBankBins, setBankBinsSearch,
  setCreateBankBins,
  setDeleteBankBins,
  setEditBankBins,
  setLoader
} from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";

const ApiBankBins = () => {
  const dispatch = useDispatch();
  const [apiGetBankBins] = useGetBankBinsMutation();
  const [apiCreateBankBin] = useCreateBankBinsMutation();
  const [apiDeleteBankBin] = useDeleteBankBinMutation();
  const [apiEditBankBin] = useEditBankBinMutation();
  const [apiGetBankBinSearch] = useGetBankBinsSearchMutation()

  const getData = (params) => {
    dispatch(setLoader(true))
    apiGetBankBins(params)
      .unwrap()
      .then((res) => {
        dispatch(setBankBins(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getDataSearch = (body) => {
    dispatch(setLoader(true))
    apiGetBankBinSearch(body)
      .unwrap()
      .then((res) => {
        let array = []
        array.push(res)
        dispatch(setBankBinsSearch(array))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createBankBin = (data, closeModal) => {
    dispatch(setLoader(true))
    apiCreateBankBin(data)
      .unwrap()
      .then((res) => {
        dispatch(setCreateBankBins(res))
        showToast("Bank Bin created successfully", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  };

  const deleteBankBin = (data, closeModal) => {
    dispatch(setLoader(true))
    apiDeleteBankBin(data)
      .unwrap()
      .then((res) => {
        dispatch(setDeleteBankBins(data?.bin_id))
        showToast("Bank Bin deleted successfully", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  };

  const editBankBin = (data, closeModal) => {
    dispatch(setLoader(true))
    apiEditBankBin(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditBankBins(res))
        showToast("Bank Bin edited successfully", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  return {
    getData,
    createBankBin,
    deleteBankBin,
    editBankBin,
    getDataSearch
  }
}

export default ApiBankBins;
