import React, { useEffect, useState } from "react";
import "./index.scss";
import { useLocation } from "react-router-dom";

const icons = {
  email: "/assets/icons/email-icon.svg",
  password: {
    vision: "/assets/icons/eye-vision-icon.svg",
    noVision: "/assets/icons/eye-icon.svg",
  },
};

export default function Input({
  id,
  name,
  value,
  placeholder = "Placeholder",
  handleChange,
  text,
  type = "text",
  label = "Label",
  icon,
  toggleType,
  disabled,
  change = false,
  changeInfo,
  card = false,
  onlyNumber = false,
  defaultValue,
  maxLength = 999,
}) {
  let inputIcon = null;
  let passwordIcon = null;
  const location = useLocation();
  const [imagePath, setImagePath] = useState("./");
  const [currentValue, setCurrentValue] = useState("");
  const [showHelper, setShowHelper] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/settings/personal-info" ||
      location.pathname === "/settings/security" ||
      location.pathname === "/settings/providers" ||
      location.pathname === "/settings/locations" ||
      location.pathname === "/settings/payment-method" ||
      location.pathname === "/settings/faq"
    ) {
      setImagePath("../.");
    } else {
      setImagePath("");
    }
  }, [location]);

  if (
    (id === "password" || id === "password_confirm" || id === "password_old") &&
    toggleType
  ) {
    passwordIcon = icon ? icons.password.noVision : icons.password.vision;
  } else {
    inputIcon = icons[type];
  }

  function formatCreditCardNumber(input) {
    let cardNumber = input.replace(/\D/g, '');
    const maxLength = 16;
    if (cardNumber.length > maxLength) {
      cardNumber = cardNumber.slice(0, maxLength);
    }
    const formattedCardNumber = cardNumber.replace(/(\d{4})/g, '$1 ');
    return formattedCardNumber.trim();
  }

  return (
    <div className="input__group">
      <div className="input__inner">
        <label htmlFor={id}>{label}</label>
        {change && (
          <div onClick={changeInfo} className="input__upperBtn">
            Change
          </div>
        )}
      </div>
      <div className="input__fill">
        <input
          className="input__fill-input"
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={(event) => {
            if(card) {
              const formattedCardNumber = formatCreditCardNumber(event.target.value)
              event.target.value = formattedCardNumber
              handleChange(event);
              setCurrentValue(formattedCardNumber);
            }
            if (onlyNumber) {
              const inputValue = event.target.value;

              const numericValue = inputValue.replace(/[^\d.,]/g, "");

              setCurrentValue(numericValue);
              handleChange({ target: { value: numericValue } });
            } else {
              handleChange(event);
              setCurrentValue(event.target.value);
            }
          }}
          onFocus={() => {
            setShowHelper(true);
          }}
          onBlur={() => {
            setShowHelper(false);
          }}
          disabled={disabled}
        />
        {inputIcon && (
          <img
            className="input__fill-img"
            src={imagePath + inputIcon}
            alt="icon"
          />
        )}
        {/* {icon && <img className="input__fill-img" src={icon} alt="icon" />} */}
        {passwordIcon && (
          <img
            className="input__fill-img"
            src={imagePath + passwordIcon}
            alt="icon"
            onClick={toggleType}
          />
        )}
      </div>
    </div>
  );
}
