import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "../axios";
import PhoneInput from "react-phone-number-input";
import DatePicker from "rsuite/DatePicker";
import "rsuite/DateRangePicker/styles/index.css";
import "react-phone-number-input/style.css";
import {
  Select,
  Input,
  Flex,
  Button,
  message,
  Modal,
  Table,
  Upload,
  InputNumber,
} from "antd";
import {
  SearchOutlined,
  FundViewOutlined,
  FileProtectOutlined,
  FileAddOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import "./index.scss";

export default function Receipts() {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const { Dragger } = Upload;
  const formDataCheck = new FormData();
  const [fileList, setFileList] = useState([]);
  const propsCheck = {
    name: "receipt",
    multiple: false,
    accept: "application/pdf",
    fileList,
    beforeUpload(file) {
      setFileList([file]);
      return false;
    },
  };

  const props = {
    on: false,
    name: "receipt",
    multiple: false,
    accept: "application/pdf",
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append("receipt", file);
      setFileList(file);
      axios
        .post(
          `${process.env.REACT_APP_RECEIPT_URL}/api/v1/admin/receipt/analyze`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setAnalyzeData(response.data);
          message.success(`${file.name} file uploaded successfully.`);
          onSuccess(response.data);
        })
        .catch((error) => {
          message.error(`${file.name} file upload failed.`);
          onError(error);
        });
    },
  };
  const [loading, setLoading] = useState(false);
  const [analyzeModal, setAnalyzeModal] = useState(false);
  const [addedReceiptModal, setAddedReceiptModal] = useState(false);
  const [checkReceiptModal, setCheckReceiptModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [valuePhone, setValuePhone] = useState();
  const [valueDate, setValueDate] = useState(null);
  const [addReceipt, setAddReceipt] = useState(false);
  const [analyzeData, setAnalyzeData] = useState(null);
  const [errorCheckMessage, setErrorCheckMessage] = useState("");
  const [categoriesValues, setCategoriesValues] = useState({
    bank_name: "",
    example_type: "",
  });
  const [receiptValues, setReceiptValues] = useState({
    bank_name: "",
    type: null,
    order_amount: null,
    created_at: "",
    example_category_name: "",
    example_category_key: "",
    parsing_type: null,
    amount_key: "",
    date_key: "",
    card_number: "",
    phone_number: "",
    card_key: "",
    phone_key: "",
  });
  const [errors, setErrors] = useState({
    bank_name: false,
    example_type: false,
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Тип",
      dataIndex: "example_type",
      key: "example_type",
      render: (example_type) => (example_type === "sell" ? "Вывод" : "Ввод"),
    },
    {
      title: "Банк ID",
      dataIndex: "bank_id",
      key: "bank_id",
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Дата изменения",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) =>
        updated_at
          ? moment(updated_at).format("YYYY-MM-DD HH:mm:ss")
          : "--- --- ---",
    },
    {
      title: "Действия",
      key: "actions",
    },
  ];
  const [messageApi, contextHolder] = message.useMessage();

  const successNotice = () => {
    messageApi.open({
      type: "success",
      content: "SDK ключ пользователя успешно изменен!",
    });
  };

  const errorNotice = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const getCategories = () => {
    if (
      categoriesValues?.bank_name?.length >= 3 &&
      categoriesValues?.example_type !== ""
    ) {
      setLoading(true);
      const formattedParams = formatParamsCategories();
      axios
        .get(`/api/v1/admin/receipt/example_category`, {
          params: formattedParams,
        })
        .then((res) => {
          setCategories(res?.data);
          setLoading(false);
        })
        .catch((error) => {
          errorNotice(error?.response?.data?.message);
          setLoading(false);
        });
    } else {
      if (categoriesValues?.bank_name?.length < 3) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          bank_name: true,
        }));
      }
      if (categoriesValues?.example_type === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          example_type: true,
        }));
      }
    }
  };

  const formatParamsCategories = () => {
    return {
      bank_name: categoriesValues?.bank_name,
      example_type: categoriesValues?.example_type,
    };
  };

  const handleChange = (value) => {
    setCategoriesValues((prevValues) => ({
      ...prevValues,
      example_type: value,
    }));
  };

  const handleCancelanalyzeModal = () => {
    setAnalyzeModal(false);
    setFileList([]);
  };

  const handleCheckReceiptModal = () => {
    setCheckReceiptModal(false);
    setReceiptValues({
      bank_name: "",
      type: null,
      order_amount: null,
      created_at: "",
      example_category_name: "",
      example_category_key: "",
      parsing_type: null,
      amount_key: "",
      date_key: "",
      card_number: "",
      phone_number: "",
      card_key: "",
      phone_key: "",
    });
    setValueDate(null);
    setValuePhone();
    setFileList([]);
  };

  const fetchAddReceipt = () => {
    const formattedParams = formatParamsReceipt();
    const queryParams = new URLSearchParams(formattedParams).toString();

    const formDataCheck = new FormData();
    if (fileList.length > 0) {
      formDataCheck.append("receipt", fileList[0]);
    }

    axios
      .post(`/api/v1/admin/receipt/add?${queryParams}`, formDataCheck, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        successNotice("Чек успешно добавлен!");
        handleCheckReceiptModal();
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const formatParamsReceipt = () => {
    const params = {
      bank_name: receiptValues.bank_name,
      type: receiptValues.type,
      order_amount: receiptValues.order_amount,
      example_category_name: receiptValues.example_category_name,
      example_category_key: receiptValues.example_category_key,
      parsing_type: receiptValues.parsing_type,
      amount_key: receiptValues.amount_key,
      date_key: receiptValues.date_key,
      created_at: moment(valueDate?.toString()).format("YYYY-MM-DDTHH:mm"),
    };

    if (receiptValues.card_number) {
      params.card_number = receiptValues.card_number;
    }

    if (valuePhone) {
      params.phone_number = valuePhone;
    }

    if (receiptValues.card_key) {
      params.card_key = receiptValues.card_key;
    }

    if (receiptValues.phone_key) {
      params.phone_key = receiptValues.phone_key;
    }

    return params;
  };

  const fetchCheckReceipt = () => {
    const formattedParams = formatParamsReceipt();
    const queryParams = new URLSearchParams(formattedParams).toString();

    const formDataCheck = new FormData();
    if (fileList.length > 0) {
      formDataCheck.append("receipt", fileList[0]);
    }

    axios
      .post(`/api/v1/admin/receipt/check?${queryParams}`, formDataCheck, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res?.data?.message === null) {
          setAddReceipt(true);
          setErrorCheckMessage("");
        } else {
          setAddReceipt(false);
          setErrorCheckMessage(res?.data?.message);
        }
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className="receiptsPage">
      {contextHolder}
      {/* Анализировать чек */}
      <Modal
        title="Анализировать чек"
        open={analyzeModal}
        cancelText="Отмена"
        okText="Анализировать"
        onCancel={handleCancelanalyzeModal}
        footer={false}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Область для загрузки</p>
          <p className="ant-upload-hint">
            Нажмите или перетащите файл в эту область, чтобы загрузить
          </p>
        </Dragger>
        {analyzeData !== null && (
          <div style={{ marginTop: 16 }}>
            <Flex gap={12} align="center">
              <h4>Кол-во ключей:</h4>
              <p>{analyzeData?.count_keys}</p>
            </Flex>
            <Flex gap={12} align="center">
              <h4>len xref values:</h4>
              <p>{analyzeData?.len_xref_values}</p>
            </Flex>
            <Flex gap={12} align="center">
              <h4>PDF версия:</h4>
              <p>{analyzeData?.pdf_version}</p>
            </Flex>
            <h4 style={{ marginTop: 12 }}>Ключи метадаты:</h4>
            <ul>
              {analyzeData?.metadata_keys?.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
            <h4 style={{ marginTop: 12 }}>Текст:</h4>
            <ul>
              {analyzeData?.text?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </Modal>

      {/* Проверить чек */}
      <Modal
        title={`Проверить чек`}
        open={checkReceiptModal}
        onOk={fetchCheckReceipt}
        cancelText="Отмена"
        okText={`Проверить`}
        onCancel={handleCheckReceiptModal}
        footer={[
          <Button onClick={handleCheckReceiptModal}>Отмена</Button>,
          <Button type="primary" loading={loading} onClick={fetchCheckReceipt}>
            Проверить
          </Button>,
          <Button
            disabled={addReceipt === false ? true : false}
            type="primary"
            loading={loading}
            onClick={fetchAddReceipt}
          >
            Добавить чек
          </Button>,
        ]}
      >
        <Flex vertical gap={12}>
          <Select
            style={{ width: "100%" }}
            placeholder="Выберите тип"
            value={receiptValues.type}
            onChange={(value) => {
              setReceiptValues((prevValues) => ({
                ...prevValues,
                type: value,
              }));
            }}
            options={[
              {
                value: "buy",
                label: "Ввод",
              },
              {
                value: "sell",
                label: "Вывод",
              },
            ]}
          />
          <Input
            style={{ width: "100%" }}
            placeholder="Введите название банка"
            value={receiptValues.bank_name}
            onChange={(e) => {
              setReceiptValues((prevValues) => ({
                ...prevValues,
                bank_name: e.target.value,
              }));
            }}
          />
          <Flex gap={16}>
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Введите сумму"
              value={receiptValues.order_amount}
              onChange={(value) => {
                console.log(value);
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  order_amount: value,
                }));
              }}
            />
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ суммы"
              value={receiptValues.amount_key}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  amount_key: e.target.value,
                }));
              }}
            />
          </Flex>
          <Flex gap={16}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату и время создания"
              value={valueDate}
              onChange={setValueDate}
              format="dd.MM.yyyy HH:mm"
            />
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ даты"
              value={receiptValues.date_key}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  date_key: e.target.value,
                }));
              }}
            />
          </Flex>
          <Flex gap={16}>
            <Input
              style={{ width: "100%" }}
              placeholder="Введите название категории"
              value={receiptValues.example_category_name}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  example_category_name: e.target.value,
                }));
              }}
            />
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ категории"
              value={receiptValues.example_category_key}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  example_category_key: e.target.value,
                }));
              }}
            />
          </Flex>
          <Select
            style={{ width: "100%" }}
            placeholder="Выберите тип парсинга"
            value={receiptValues.parsing_type}
            onChange={(value) => {
              setReceiptValues((prevValues) => ({
                ...prevValues,
                parsing_type: value,
              }));
            }}
            options={[
              {
                value: "next",
                label: "Next",
              },
              {
                value: "split",
                label: "Split",
              },
            ]}
          />
          <Flex gap={16}>
            <Input
              style={{ width: "100%" }}
              placeholder="Введите номер карты"
              value={receiptValues.card_number}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  card_number: e.target.value,
                }));
              }}
            />
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ номера карты"
              value={receiptValues.card_key}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  card_key: e.target.value,
                }));
              }}
            />
          </Flex>
          <Flex gap={16}>
            <div className="phone__input">
              <PhoneInput
                placeholder="Введите номер телефона"
                value={valuePhone}
                onChange={setValuePhone}
                international
              />
            </div>
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ номера телефона"
              value={receiptValues.phone_key}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  phone_key: e.target.value,
                }));
              }}
            />
          </Flex>
          <Dragger {...propsCheck}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Область для загрузки</p>
            <p className="ant-upload-hint">
              Нажмите или перетащите файл в эту область, чтобы загрузить
            </p>
          </Dragger>
          {errorCheckMessage !== "" && (
            <p className="error_text_message">{errorCheckMessage}</p>
          )}
        </Flex>
      </Modal>
      <div className="receipts__wrapper">
        <Flex gap={16} justify="space-between">
          <Flex gap={16}>
            <Input
              status={errors.bank_name ? "error" : ""}
              style={{ width: "270px" }}
              placeholder="Введите название банка"
              onChange={(e) => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  bank_name: false,
                }));

                setCategoriesValues((prevValues) => ({
                  ...prevValues,
                  bank_name: e.target.value,
                }));
              }}
            />
            <Select
              status={errors.example_type ? "error" : ""}
              style={{ width: "270px" }}
              placeholder="Выберите тип"
              onChange={(value) => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  example_type: false,
                }));
                handleChange(value);
              }}
              options={[
                {
                  value: "buy",
                  label: "Ввод",
                },
                {
                  value: "sell",
                  label: "Вывод",
                },
              ]}
            />
            <Button
              onClick={getCategories}
              icon={<SearchOutlined />}
              type="primary"
            >
              Найти категории
            </Button>
          </Flex>
          <Flex gap={16}>
            <Button
              onClick={() => {
                setAnalyzeModal(true);
              }}
              type="primary"
              shape="round"
              icon={<FundViewOutlined />}
            >
              Анализировать чек
            </Button>
            {profileData?.role === "superadmin" && (
              <Button
                onClick={() => {
                  setCheckReceiptModal(true);
                }}
                type="primary"
                shape="round"
                icon={<FileProtectOutlined />}
              >
                Проверить чек
              </Button>
            )}
          </Flex>
        </Flex>
        <Table
          dataSource={categories}
          columns={columns}
          rowKey="id"
          pagination={false}
          loading={loading}
          expandable={{
            expandedRowRender: (record) => (
              <div
                style={{
                  paddingLeft: 24,
                }}
              >
                <h3>Ключевые слова:</h3>
                <ul
                  style={{
                    margin: 0,
                  }}
                >
                  {record?.key_words?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
      </div>
    </div>
  );
}
