import {
  setEditOrderAppeal,
  setLoader,
  setOrderData,
  setOrders,
  setOrdersSearch,
  setRejectOrders,
  setDeleteOrder,
  setOrderInfoData,
  setBillingOrder,
  setBillingOrderHistory,
  setOrdersEmpty,
  setUserUpdate,
  setRejectOrdersSearch,
} from "../../Store/Slice/unitradeSlice";
import {
  useGetOrderBillingDetailsMutation,
  useGetOrderDetailsMutation,
  useGetOrderSearchMutation,
  useGetOrdersMutation,
  useSetOrderAppealMutation,
  useSetOrderCancelMutation,
  useSendOrderCallbackMutation,
  useSetOrderRecalcMutation,
  useGetBillingOrderMutation,
  useRedefineOrderMutation,
  useRedefineTraderOrderMutation,
  useMassRedefineOrdersMutation,
  useGetRejectOrdersMutation,
  useGetRejectOrderSearchMutation,
  useRedefineServiceTraderOrderMutation,
  useCancelPendingOrderMutation,
  useGetIdsOrdersMutation,
  useGetIdsTransactionOrdersMutation,
} from "../../Store/Builder/unitrade.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useLocation } from "react-router-dom";

const ApiOrders = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [apiGetOrders] = useGetOrdersMutation();
  const [apiGetRejectOrders] = useGetRejectOrdersMutation();
  const [apiSetAppeal] = useSetOrderAppealMutation();
  const [apiSendCallback] = useSendOrderCallbackMutation();
  const [apiSetCancel] = useSetOrderCancelMutation();
  const [apiSetRecalc] = useSetOrderRecalcMutation();
  const [apiGetOrderBillingDetails] = useGetOrderBillingDetailsMutation();
  const [apiGetBillingOrder] = useGetBillingOrderMutation();
  const [apiGetOrderDetails] = useGetOrderDetailsMutation();
  const [apiGetOrderSearch] = useGetOrderSearchMutation();
  const [apiGetRejectOrderSearch] = useGetRejectOrderSearchMutation();
  const [apiRedefineOrder] = useRedefineOrderMutation();
  const [apiRedefineTraderOrder] = useRedefineTraderOrderMutation();
  const [apiRedefineServiceTraderOrder] =
    useRedefineServiceTraderOrderMutation();
  const [apiMassRedefineTraderOrder] = useMassRedefineOrdersMutation();
  const [apiCancelPendingOrder] = useCancelPendingOrderMutation();
  const [apiGetIdsOrders] = useGetIdsOrdersMutation();
  const [apiGetIdsTransactionOrders] = useGetIdsTransactionOrdersMutation();

  const getData = (params, action) => {
    dispatch(setLoader(true));
    apiGetOrders(params)
      .unwrap()
      .then((res) => {
        if (action !== "shaitan") {
          dispatch(setOrders(res));
        } else {
          if (res?.length > 0) {
            dispatch(setUserUpdate(res));
            console.log(res?.[0]?.trader?.notice, res?.length);
          }
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getRejectOrders = (params) => {
    dispatch(setLoader(true));
    apiGetRejectOrders(params)
      .unwrap()
      .then((res) => {
        dispatch(setRejectOrders(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getOrderSearch = (body) => {
    dispatch(setLoader(true));
    apiGetOrderSearch(body)
      .unwrap()
      .then((res) => {
        // let array = []
        // array.push(res)
        dispatch(setOrdersSearch(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getRejectOrderSearch = (body) => {
    dispatch(setLoader(true));
    apiGetRejectOrderSearch(body)
      .unwrap()
      .then((res) => {
        dispatch(setRejectOrdersSearch(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const setAppeal = (formData, closeAppealModal) => {
    dispatch(setLoader(true));
    apiSetAppeal(formData)
      .unwrap()
      .then((res) => {
        dispatch(setEditOrderAppeal(res));
        dispatch(setLoader(false));
        closeAppealModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const redefineOrder = (params, closeRedefineModal) => {
    dispatch(setLoader(true));
    apiRedefineOrder(params)
      .unwrap()
      .then((res) => {
        dispatch(setEditOrderAppeal(res));
        dispatch(setLoader(false));
        closeRedefineModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const redefineTraderOrder = (
    params,
    closeRedefineTraderModal,
    formatParams
  ) => {
    dispatch(setLoader(true));
    apiRedefineTraderOrder(params)
      .unwrap()
      .then(() => {
        dispatch(setOrdersEmpty([]));
        const formattedParams = formatParams();
        getData(formattedParams);
        dispatch(setLoader(false));
        closeRedefineTraderModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const redefineServiceTraderOrder = (
    params,
    closeRedefineTraderModal,
    formatParams
  ) => {
    dispatch(setLoader(true));
    apiRedefineServiceTraderOrder(params)
      .unwrap()
      .then(() => {
        dispatch(setOrdersEmpty([]));
        const formattedParams = formatParams();
        getData(formattedParams);
        dispatch(setLoader(false));
        closeRedefineTraderModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const redefineTraderOrderShaitan = (params, closeRedefineModal) => {
    dispatch(setLoader(true));
    apiRedefineTraderOrder(params)
      .unwrap()
      .then(() => {
        dispatch(setOrdersEmpty([]));
        closeRedefineModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const massRedefineTraderOrder = (
    body,
    closeRedefineTraderModal,
    formatParams,
    setCheckedOrders
  ) => {
    dispatch(setLoader(true));
    apiMassRedefineTraderOrder(body)
      .unwrap()
      .then((res) => {
        dispatch(setOrdersEmpty([]));
        const formattedParams = formatParams();
        getData(formattedParams);
        dispatch(setLoader(false));
        setCheckedOrders([]);
        closeRedefineTraderModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const sendCallback = (params, closeCallbackModal) => {
    dispatch(setLoader(true));
    apiSendCallback(params)
      .unwrap()
      .then(() => {
        dispatch(setLoader(false));
        if (location?.pathname !== "/callback") {
          closeCallbackModal();
        }
        showToast("Callback успешно отправлен", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const setCancel = (params, closeCancelModal) => {
    dispatch(setLoader(true));
    apiSetCancel(params)
      .unwrap()
      .then((res) => {
        dispatch(setDeleteOrder(res));
        dispatch(setLoader(false));
        closeCancelModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const setCancelPendingSell = (body, closeCancelModal) => {
    dispatch(setLoader(true));
    apiCancelPendingOrder(body)
      .unwrap()
      .then((res) => {
        dispatch(setDeleteOrder(res));
        dispatch(setLoader(false));
        closeCancelModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const setRecalc = (params, closeCancelModal) => {
    dispatch(setLoader(true));
    apiSetRecalc(params)
      .unwrap()
      .then((res) => {
        dispatch(setDeleteOrder(res));
        dispatch(setLoader(false));
        closeCancelModal();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getOrderBillingDetails = (body) => {
    dispatch(setLoader(true));
    apiGetOrderBillingDetails(body.order_id)
      .unwrap()
      .then((res) => {
        dispatch(setOrderData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getBillingOrder = (body) => {
    dispatch(setLoader(true));
    apiGetBillingOrder(body)
      .unwrap()
      .then((res) => {
        dispatch(setBillingOrder(res));
        dispatch(setBillingOrderHistory(res?.crypto_billings));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        if (err?.data?.code_error !== "cryptoWalletNotFound") {
          showToast(err?.data?.message ?? "Error", "error");
        }
      });
  };

  const getOrderDetails = (body) => {
    dispatch(setLoader(true));
    apiGetOrderDetails(body.order_id)
      .unwrap()
      .then((res) => {
        dispatch(setOrderInfoData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getIdsOrders = (params, closeCheckOrdersModal) => {
    dispatch(setLoader(true));
    apiGetIdsOrders(params)
      .unwrap()
      .then((res) => {
        dispatch(setOrdersEmpty([]));
        dispatch(setOrders(res));
        dispatch(setLoader(false));
        closeCheckOrdersModal()
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };
  
  const getIdsTransactionOrders = (params, closeCheckOrdersModal) => {
    dispatch(setLoader(true));
    apiGetIdsTransactionOrders(params)
      .unwrap()
      .then((res) => {
        dispatch(setOrdersEmpty([]));
        dispatch(setOrders(res));
        dispatch(setLoader(false));
        closeCheckOrdersModal()
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    setAppeal,
    getOrderBillingDetails,
    getOrderSearch,
    setCancel,
    sendCallback,
    getOrderDetails,
    setRecalc,
    getBillingOrder,
    redefineOrder,
    redefineTraderOrder,
    massRedefineTraderOrder,
    getRejectOrders,
    getRejectOrderSearch,
    redefineTraderOrderShaitan,
    redefineServiceTraderOrder,
    setCancelPendingSell,
    getIdsOrders,
    getIdsTransactionOrders
  };
};

export default ApiOrders;
