import {
    setLoader,
    setTransactions
  } from "../../Store/Slice/unitradeSlice";
  import {
    useGetTransactionsMutation,
  } from "../../Store/Builder/unitrade.api";
  import showToast from "../Hooks/toast";
import { useDispatch } from "react-redux";
  
  const ApiTransactions = () => {
    const dispatch = useDispatch();
    const [apiGetTransactions] = useGetTransactionsMutation();
  
    const getTransactions = (params) => {
      dispatch(setLoader(true));
      apiGetTransactions(params)
        .unwrap()
        .then((res) => {
          dispatch(setTransactions(res));
          dispatch(setLoader(false));
        })
        .catch((err) => {
          dispatch(setLoader(false));
          showToast(err?.data?.message ?? "Error", "error");
        });
    };
  
    return {
        getTransactions,
    };
  };
  
  export default ApiTransactions;
  