import axios from "axios";
import Cookies from 'js-cookie';
import { refreshAccessToken } from "./authUtils";

let isRefreshing = false;
let refreshSubscribers = [];

// Функция для подписки на обновление токена
function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

// Функция для оповещения всех подписчиков
function onRefreshed(newAccessToken) {
  refreshSubscribers.forEach(callback => callback(newAccessToken));
  refreshSubscribers = [];
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_RECEIPT_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('access_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Обрабатываем 403 и 401 статусы
    if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
      if (isRefreshing) {
        // Если обновление уже выполняется, добавляем запрос в очередь и возвращаем его после обновления токена
        return new Promise((resolve, reject) => {
          subscribeTokenRefresh((newAccessToken) => {
            if (newAccessToken) {
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              resolve(instance(originalRequest));
            } else {
              reject(error);
            }
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newAccessToken = await refreshAccessToken();

        // Обновляем все подписанные запросы с новым токеном
        onRefreshed(newAccessToken);

        // Повторяем оригинальный запрос с новым токеном
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return instance(originalRequest);
      } catch (refreshError) {
        // Если обновление токена не удалось, очищаем токены и перенаправляем пользователя на страницу логина
        console.error('Failed to refresh token:', refreshError);
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        window.location.href = "/receipt-service/login"; // Перенаправляем пользователя
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;  // Сбрасываем флаг после завершения обновления токена
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
