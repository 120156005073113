import "./index.scss";
import Header from "../../Components/Header";
import ApiSmsMessages from "../../Global/Api/smsMessages.api";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SearchComponent from "../../Components/Search";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import { setSmsMessageBilling, setSmsMessagesSearch } from "../../Store/Slice/unitradeSlice";
import { Link } from "react-router-dom";
import {useDebounce} from "../../Global/Hooks/useDebounce";
import ApiDevices from "../../Global/Api/devices.api";
import { CustomSelect } from "../../UI/Select";

export default function SmsMessages() {
  const dispatch = useDispatch();
  const smsMessagesApi = ApiSmsMessages();
  const useDevices = ApiDevices();
  const [limitCount, setLimitCount] = useState("100");
  const lastCreated = useRef(null);
  const [search, setSearch] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceSelect, setDeviceSelect] = useState(null);
  const firstLoad = useRef(false);
  const [billingModal, setBillingModal] = useState(false);
  const { smsMessages, smsMessageBilling, devices } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("pagination_order", "end");
    if (deviceSelect !== null) params.append('device_id', deviceSelect.id);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsDevices = () => {
    const params = new URLSearchParams();
    params.append('limit', 10000)
    return params
  }

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      smsMessagesApi.getData(formattedParams);
    }
  }, [search, deviceSelect]);

  useEffect(() => {
    const formattedParams = formatParams();
    const formattedParamsDevices = formatParamsDevices();
    smsMessagesApi.getData(formattedParams);
    useDevices.getDevices(formattedParamsDevices)
    firstLoad.current = true;
  }, []);

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const handleSearchDevice = (searchTerm) => {
    dispatch(setSmsMessagesSearch([]))
    lastCreated.current = null
    setDeviceName(searchTerm);
  };

  const searchSendData = () => {
    const body = {
      message_id: search,
    };
    smsMessagesApi.getSmsSearch(body);
  };

  const loadMore = () => {
    lastCreated.current = smsMessages[smsMessages.length - 1]?.created_at;
    const formattedParams = formatParams();
    smsMessagesApi.getData(formattedParams);
  };

  const renderSmsMessagesData = () => {
    return smsMessages.map((sms, index) => {
      return (
        <tr key={index}>
          <td>{moment.utc(sms?.created_at).local().format("DD.MM.YY, HH:mm:ss")}</td>
          <td>{sms?.balance ? sms?.balance : 0}</td>
          <td>{sms?.android_version}</td>
          <td>{sms?.device_name}</td>
          <td>{sms?.bank_title}</td>
          <td>{sms?.package}</td>
          <td>{sms?.message}</td>
          <td>{sms?.order_amount}</td>
          <td
            onClick={() => {
              openBillingModal(sms);
            }}
            className="smsMessage__billing"
          >
            <img src="/assets/icons/billing.svg" alt="billing" />
          </td>
        </tr>
      );
    });
  };

  const renderSmsMessageBilling = () => {
    return smsMessageBilling?.map((sms, index) => {
      return (
        <tr key={index}>
          <td>{moment.utc(sms?.created_at).local().format("DD.MM.YY, HH:mm:ss")}</td>
          <td>
            <Link
              className="smsMessage__link"
              onClick={() => {
                localStorage.setItem("order_id", sms?.order_id);
              }}
              target="_blank"
              to={`/order/${sms?.order_id}`}
            >
              {sms?.order_id}
            </Link>
          </td>
          <td>{sms?.order_amount}</td>
          <td>{sms?.order_stage}</td>
          <td>{sms?.order_substage}</td>
        </tr>
      );
    });
  };

  const openBillingModal = (item) => {
    smsMessagesApi.getSmsBilling(item?.id);
    setBillingModal(true);
  };

  const closeBillingModal = () => {
    setBillingModal(false);
    dispatch(setSmsMessageBilling([]));
  };

  return (
    <div className="smsMessages container">
      {billingModal && (
        <Popup title={"Биллинг"} closePopup={closeBillingModal}>
          <table>
            <thead>
              <tr>
                <th>Дата создания</th>
                <th>ID заявки</th>
                <th>Сумма заявки</th>
                <th>Статус заявки</th>
                <th>Ответственность</th>
              </tr>
            </thead>
            <tbody>{renderSmsMessageBilling()}</tbody>
          </table>
        </Popup>
      )}
      <Header title={"СМС сообщения"} />
      <div className="smsMessages__top-inner">
        <div className="smsMessages__top-wrapper">
        <div className="smsMessages__top-search">
          <SearchComponent
            searchTerm={search}
            onSearch={handleSearch}
            placeholder={"Введите id sms"}
          />
        </div>
        <div className="users__top-btn">
          <Button
            text={"Найти sms"}
            type={"accept"}
            onClick={() => {
              searchSendData();
            }}
            topPadding={10}
            bottomPadding={10}
          />
        </div>
        </div>
        <div className="users__top__select">
              <CustomSelect
                options={devices}
                placeholder={"Выберите девайс"}
                selected={deviceSelect}
                handleSelect={(e) => {
                  dispatch(setSmsMessagesSearch([]))
                  setDeviceSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Дата создания</th>
            <th>Баланс</th>
            <th>Версия андроида</th>
            <th>Название устройства</th>
            <th>Банк</th>
            <th>Package</th>
            <th>СМС</th>
            <th>Сумма заказа</th>
            <th>Биллинг</th>
          </tr>
        </thead>
        <tbody>{renderSmsMessagesData()}</tbody>
      </table>
      <div className="loadMore">
        <p
          onClick={() => {
            loadMore();
          }}
        >
          Загрузить еще...
        </p>
      </div>
    </div>
  );
}
