import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import SearchComponent from "../../Components/Search";
import { useDebounce } from "../../Global/Hooks/useDebounce";
import { setTransactionsPageEmpty } from "../../Store/Slice/unitradeSlice";
import { CustomSelect } from "../../UI/Select";

import "./index.scss";
import ApiUsers from "../../Global/Api/users.api";
import ApiTransactions from "../../Global/Api/transactions.api";

export default function Transactions() {
  const dispatch = useDispatch();
  const apiTransactions = ApiTransactions();
  const usersApi = ApiUsers();
  const [walletId, setWalletId] = useState("");
  const [usersSelect, setUsersSelect] = useState(null);
  const limitCount = 200;
  const lastCreated = useRef(null);
  const { transactions, fullTraders } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  const users = fullTraders.filter(
    (user) => user.type_active === "active" && !user?.is_service
  );

  const clearTransactions = () => {
    dispatch(setTransactionsPageEmpty([]));
  };

  const debouncedWalletIdValue = useDebounce(walletId, 300, clearTransactions);

  useEffect(() => {
    const formattedParamsUsers = formatParamsUsers();
    usersApi.getFullTraders(formattedParamsUsers);
  }, []);

  useEffect(() => {
    const formattedParams = formatParams();
    apiTransactions.getTransactions(formattedParams);
  }, [debouncedWalletIdValue, usersSelect]);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    if (walletId !== "") params.append("wallet_id", walletId);
    if (usersSelect !== null) params.append("wallet_id", usersSelect.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    return params;
  };

  const loadMore = () => {
    lastCreated.current = transactions[transactions.length - 1]?.created_at;
    const formattedParams = formatParams();
    apiTransactions.getTransactions(formattedParams);
  };

  const renderTransactions = () => {
    return transactions?.map((item) => (
      <tr key={item?.id}>
        <td>{item?.id}</td>
        <td>{item?.hash}</td>
        <td>
          <Link target="_blank" to={`/user/${item?.crypto_wallet_id}`}>
            {item?.crypto_wallet_id}
          </Link>
        </td>
        <td>{item?.system_wallet_id}</td>
        <td>{item?.usdt_price}</td>
        <td>{item?.amount_usdt}</td>
        <td>{item?.amount_fiat}</td>
        <td>{item?.currency?.currency}</td>
        <td>{item?.status}</td>
        <td>
          {moment.utc(item?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
      </tr>
    ));
  };

  const handleWalletId = (searchTerm) => {
    setWalletId(searchTerm);
  };

  return (
    <div className="transactions container">
      <Header title={"Транзакции"} />
      <div className="transactions__inner">
        <div className="transactions__top">
          <div className="orders__input">
            <SearchComponent
              searchTerm={walletId}
              onSearch={handleWalletId}
              placeholder={"Поиск по ID кошелька"}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={users?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите трейдера"}
              selected={usersSelect}
              handleSelect={(e) => {
                setUsersSelect(e ?? null);
                dispatch(setTransactionsPageEmpty([]));
              }}
              isClearable={true}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Hash</th>
              <th>Crypto Wallet</th>
              <th>System Wallet</th>
              <th>USDT Price</th>
              <th>Amount USDT</th>
              <th>Amount Fiat</th>
              <th>Currency</th>
              <th>Status</th>
              <th>Created At</th>
            </tr>
          </thead>
          {transactions?.length > 0 && <tbody>{renderTransactions()}</tbody>}
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
