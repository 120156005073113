import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import ApiDevices from "../../Global/Api/devices.api";
import { Link } from "react-router-dom";

import "./index.scss";

export default function Devices() {
  const { devices } = useSelector((state) => state.rootReducer.unitradeSlice);
  const limitCount = 100;
  const lastCreated = useRef(null);
  const useDevices = ApiDevices();

  useEffect(() => {
    const formattedParams = formatParams();
    useDevices.getDevices(formattedParams);
  }, []);

  const loadMore = () => {
    lastCreated.current = devices[devices.length - 1]?.created_at;
    const formattedParams = formatParams();
    useDevices.getDevices(formattedParams);
  };

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const renderDevices = () => {
    return devices?.map((item) => (
      <tr key={item?.id}>
        <td>{item?.id}</td>
        <td>{item?.device_type}</td>
        <td>{item?.name}</td>
        <td>{item?.android_version}</td>
        <td>{item?.device_key}</td>
        <td>{item?.sim_slot}</td>
        <td>{item?.phone_number}</td>
        <td>{!item?.is_deleted ? 'Не удален' : 'Удален'}</td>
        <td>
                <Link
                  onClick={() => {
                    localStorage.setItem("user_id", item?.user_id);
                  }}
                  target="_blank"
                  to={`/user/${item?.user_id}`}
                >
                  {item?.user_id}
                </Link>
              </td>
      </tr>
    ));
  };

  return (
    <div className="devices">
      <h2 className="">Устройства</h2>
      <div className="devices__table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Тип Устройства</th>
              <th>Имя</th>
              <th>Версия Андроида</th>
              <th>Ключ Устройства</th>
              <th>SIM SLOT</th>
              <th>Номер Телефона</th>
              <th>Статус</th>
              <th>ID Пользователя</th>
            </tr>
          </thead>
          <tbody>{renderDevices()}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
