import {
  setCreateUser,
  setDeleteUser,
  setEditUser,
  setLoader,
  setUserData,
  setUserFilter,
  setUserFilterMerchant,
  setUsers,
  setUserSearch,
  setGenerateFaKey,
  setServiceUsers,
  setFullTraders,
  setUsersByAgent,
  setAgentUsers,
} from "../../Store/Slice/unitradeSlice";
import {
  useCreateUserMutation,
  useDeletedUserMutation,
  useEditedUserMutation,
  useEditedUUIDUserMutation,
  useGetUserMutation,
  useGetUserSearchMutation,
  useGetUsersMutation,
  useLazyGenerateFaKeyQuery,
  useChangeFaKeyUserMutation,
  useGetFullTradersMutation,
  useGetUsersByAgentMutation,
} from "../../Store/Builder/unitrade.api";
import ApiOrders from "./orders.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useLocation, useParams } from "react-router-dom";
import ApiServiceTraderCredentials from "./serviceTraderCredentials.api";
import CryptoWalletsApi from "./cryptoWallets.api";
import ApiMerchantWallets from "./merchantWallets.api";

const ApiUsers = () => {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const merchantWalletApi = ApiMerchantWallets();
  const parameters = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const ordersApi = ApiOrders();
  const apiCryptoWallets = CryptoWalletsApi();
  const serviceCredentialsApi = ApiServiceTraderCredentials();
  const [apiGetUsers] = useGetUsersMutation();
  const [apiGetUser] = useGetUserMutation();
  const [apiCreateUser] = useCreateUserMutation();
  const [apiEditedUser] = useEditedUserMutation();
  const [apiDeletedUser] = useDeletedUserMutation();
  const [apiEditedUuidUser] = useEditedUUIDUserMutation();
  const [apiGetUserSearch] = useGetUserSearchMutation();
  const [apiGenerateFaKey] = useLazyGenerateFaKeyQuery();
  const [apiEditFaKey] = useChangeFaKeyUserMutation();
  const [apiGetFullTraders] = useGetFullTradersMutation();
  const [apiGetUsersByAgent] = useGetUsersByAgentMutation();

  const getSearchUser = (body) => {
    dispatch(setLoader(true));
    apiGetUserSearch(body)
      .unwrap()
      .then((res) => {
        let array = [];
        array.push(res);
        dispatch(setUserSearch(array));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getData = (params, action, requisiteSelect) => {
    dispatch(setLoader(true));

    apiGetUsers(params)
      .unwrap()
      .then((res) => {
        if (action === "filter") {
          dispatch(setUserFilter(res));
        }
        if (action === "shaitan") {
          for (let i = 0; i < res?.length; i++) {
            const formatParams = () => {
              const params = new URLSearchParams();
              params.append("limit", 50000);
              params.append("order_type", "sell");
              params.append("time_filer", "created");
              params.append("order_status", "pending");
              if (requisiteSelect !== null)
                params.append("requisite_type", requisiteSelect.value);
              params.append("trader_id", res[i]?.id);
              return params;
            };
            const formattedParams = formatParams();
            ordersApi.getData(formattedParams, "shaitan");
          }
        }
        if (action === "merchant") {
          dispatch(setUserFilterMerchant(res));
        } else if (action === "agent") {
          dispatch(setAgentUsers(res));
        } else if (action === "service") {
          dispatch(setServiceUsers(res));
        } else {
          dispatch(setUsers(res));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        // showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getFullTraders = (params) => {
    dispatch(setLoader(true));
    apiGetFullTraders(params)
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        dispatch(setFullTraders(res));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const generateFaKey = () => {
    dispatch(setLoader(true));
    apiGenerateFaKey()
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        dispatch(setGenerateFaKey(res));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editFaKeyUser = (data, closeModal) => {
    dispatch(setLoader(true));
    apiEditFaKey(data)
      .unwrap()
      .then((res) => {
        showToast("Новый Fa ключ сохранен в буфер обмена", "success");
        navigator.clipboard.writeText(data?.new_fa_key);
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getDataUser = (params, setIsOnline) => {
    dispatch(setLoader(true));
    apiGetUser(params)
      .unwrap()
      .then((res) => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append("limit", 200);
          params.append("wallet_id", res?.id);
          return params;
        };
        if (parameters?.id === res?.id && res?.is_service) {
          serviceCredentialsApi.getData({
            trader_id: parameters?.id,
          });
          serviceCredentialsApi.getWallets({
            user_id: parameters?.id,
          });
          const formatParamsUsersMerchant = () => {
            const params = new URLSearchParams();
            params.append("limit", 10000);
            params.append("role", "merchant_server");
            return params;
          };
          const formattedParamsUsersMerchant = formatParamsUsersMerchant();
          getData(formattedParamsUsersMerchant, "merchant");
        }
        if ((res?.role !== "merchant_server" && !res?.is_service) && profileData?.role === 'superadmin') {
          apiCryptoWallets.getCryptoWalletsStatistics({
            wallet_id: parameters?.id,
          });
          apiCryptoWallets.getData(
            {
              wallet_id: parameters?.id,
            },
            setIsOnline
          );
          const formattedParams = formatParams();
          apiCryptoWallets.getCryptoWalletsBilling(formattedParams);
        }
        if (res?.role === "merchant_server" && profileData?.role === 'superadmin') {
          const body = {
            user_id: parameters?.id,
          };
          merchantWalletApi.getData(body);
        }
        if (res?.role === "agent" && profileData?.role === 'superadmin') {
          const formatParamsAgent = () => {
            const params = new URLSearchParams();
            params.append("limit", 200);
            params.append("agent_id", parameters?.id);
            params.append("pagination_order", "end");
            return params;
          };
          const formattedParamsAgent = formatParamsAgent();
          getUsersByAgent(formattedParamsAgent);
        }
        dispatch(setUserData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createUser = (data, closeModal) => {
    dispatch(setLoader(true));
    apiCreateUser(data)
      .unwrap()
      .then((res) => {
        dispatch(setCreateUser(res[0]));
        showToast("Пользователь успешно создан", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editedUser = (data, closeModal) => {
    dispatch(setLoader(true));
    apiEditedUser(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditUser(res));
        showToast("Пользователь успешно изменен", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editedUuidUser = (data, closeModal) => {
    dispatch(setLoader(true));
    apiEditedUuidUser(data)
      .unwrap()
      .then((res) => {
        showToast("Новый uuid сохранен в буфер обмена", "success");
        navigator.clipboard.writeText(res?.cache_merchant_uuid);
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const deletedUser = (data, closeModal) => {
    dispatch(setLoader(true));
    apiDeletedUser(data)
      .unwrap()
      .then(() => {
        dispatch(setDeleteUser(data?.user_id));
        showToast("Пользователь успешно удален", "success");
        closeModal();
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getUsersByAgent = (params) => {
    dispatch(setLoader(true));
    apiGetUsersByAgent(params)
      .unwrap()
      .then((data) => {
        dispatch(setUsersByAgent(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    getDataUser,
    createUser,
    editedUser,
    deletedUser,
    editedUuidUser,
    getSearchUser,
    generateFaKey,
    editFaKeyUser,
    getFullTraders,
    getUsersByAgent,
  };
};

export default ApiUsers;
