import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useOnboarding from "../../Global/Api/onboarding.api";
import { setNavBar } from "../../Store/Slice/unitradeSlice";

import "./index.scss";

export default function NavBar({}) {
  const dispatch = useDispatch();
  const onboarding = useOnboarding();
  const location = useLocation();
  const [showDrop, setShowDrop] = useState(false);
  const [languageState, setLanguageState] = useState(false);
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const { navBar, language } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  useEffect(() => {
    setLanguageState(localStorage.getItem("localization"));
  }, [localStorage.getItem("localization")]);

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (
        e.target.closest(".nav__drop") === null &&
        e.target.closest(".nav__profile") === null
      ) {
        setShowDrop(false);
      }
    });
  }, [location]);

  return (
    <div className={`nav ${navBar ? "nav_active" : ""}`}>
      <div className="nav__top">
        <Link to={"/"} className="nav__logo">
          {/*<img src={`${"/assets/images/nav-logo.svg"}`} alt="techsbook" />*/}
          UnitPay
        </Link>
        {window.innerWidth <= 500 && (
          <div
            onClick={() => {
              dispatch(setNavBar(!navBar));
              document.querySelector(".nav__wrapper-right").style.display =
                "unset";
            }}
            className={`header__btn ${navBar ? "header__btn_active" : ""} ${
              navBar && window.innerWidth <= 500 ? "header__btn_mobile" : ""
            }`}
          >
            <div className="header__btn-line"></div>
            <div className="header__btn-line header__btn-line_small"></div>
            <div className="header__btn-line"></div>
          </div>
        )}
        <div className="nav__items">
          {(profileData?.role === "superadmin" ||
            profileData?.role === "arbitr") && (
            <NavLink
              to="/"
              className={`nav__item ${
                location.pathname === "/" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Валюты</p>
              </div>
            </NavLink>
          )}
          {(profileData?.role === "superadmin" ||
            profileData?.role === "arbitr") && (
            <NavLink
              to="/users"
              className={`nav__item ${
                location.pathname === "/users" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/users"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Пользователи</p>
              </div>
            </NavLink>
          )}
          {(profileData?.role === "superadmin" ||
            profileData?.role === "arbitr") && (
            <NavLink
              to="/payment-methods"
              className={`nav__item ${
                location.pathname === "/payment-methods"
                  ? "nav__item_active"
                  : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/payment-methods"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Способы оплаты</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/bank-bins"
              className={`nav__item ${
                location.pathname === "/bank-bins" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/bank-bins"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Bank Bins</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/sms-messages"
              className={`nav__item ${
                location.pathname === "/sms-messages" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/sms-messages"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">СМС сообщения</p>
              </div>
            </NavLink>
          )}
          <NavLink
            to="/sms-history"
            className={`nav__item ${
              location.pathname === "/sms-history" ? "nav__item_active" : ""
            }`}
          >
            <div className="nav__item-left">
              <span>
                <img
                  src={`${
                    location.pathname === "/sms-history"
                      ? "/assets/icons/sell-white.svg"
                      : "/assets/icons/sell.svg"
                  }`}
                  alt="sell"
                />
              </span>
              <p className="nav__item-text">СМС история</p>
            </div>
          </NavLink>
          <NavLink
            to="/announcements"
            className={`nav__item ${
              location.pathname === "/announcements" ? "nav__item_active" : ""
            }`}
          >
            <div className="nav__item-left">
              <span>
                <img
                  src={`${
                    location.pathname === "/announcements"
                      ? "/assets/icons/sell-white.svg"
                      : "/assets/icons/sell.svg"
                  }`}
                  alt="sell"
                />
              </span>
              <p className="nav__item-text">Объявления</p>
            </div>
          </NavLink>
          <NavLink
            to="/orders"
            className={`nav__item ${
              location.pathname === "/orders" ? "nav__item_active" : ""
            }`}
          >
            <div className="nav__item-left">
              <span>
                <img
                  src={`${
                    location.pathname === "/orders"
                      ? "/assets/icons/sell-white.svg"
                      : "/assets/icons/sell.svg"
                  }`}
                  alt="sell"
                />
              </span>
              <p className="nav__item-text">Заявки</p>
            </div>
          </NavLink>
          <NavLink
            to="/reject-orders"
            className={`nav__item ${
              location.pathname === "/reject-orders" ? "nav__item_active" : ""
            }`}
          >
            <div className="nav__item-left">
              <span>
                <img
                  src={`${
                    location.pathname === "/reject-orders"
                      ? "/assets/icons/sell-white.svg"
                      : "/assets/icons/sell.svg"
                  }`}
                  alt="sell"
                />
              </span>
              <p className="nav__item-text">Отклоненные Заявки</p>
            </div>
          </NavLink>
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/transactions"
              className={`nav__item ${
                location.pathname === "/transactions" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/transactions"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Транзакции</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/profile"
              className={`nav__item ${
                location.pathname === "/profile" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/profile"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Профиль</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/statistics"
              className={`nav__item ${
                location.pathname === "/statistics" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/statistics"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Статистика</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/kpi"
              className={`nav__item ${
                location.pathname === "/kpi" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/kpi"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">KPI</p>
              </div>
            </NavLink>
          )}
          {(profileData?.role === "superadmin" ||
            profileData?.role === "arbitr") && (
            <NavLink
              to="/links-merchant-trader"
              className={`nav__item ${
                location.pathname === "/links-merchant-trader"
                  ? "nav__item_active"
                  : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/links-merchant-trader"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Merchant Links</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/reports"
              className={`nav__item ${
                location.pathname === "/reports" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/reports"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Репорты</p>
              </div>
            </NavLink>
          )}
          {profileData?.role === "superadmin" && (
            <NavLink
              to="/devices"
              className={`nav__item ${
                location.pathname === "/devices" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/devices"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Устройства</p>
              </div>
            </NavLink>
          )}
          {(profileData?.role === "superadmin" ||
            profileData?.role === "arbitr") && (
            <NavLink
              to="/clients"
              className={`nav__item ${
                location.pathname === "/clients" ? "nav__item_active" : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      location.pathname === "/clients"
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Клиенты</p>
              </div>
            </NavLink>
          )}
          {(profileData?.role === "superadmin" || profileData?.role === "arbitr") && (
            <NavLink
              to="/receipt-service/login"
              className={`nav__item ${
                (location.pathname === "/receipt-service/login" ||
                location.pathname === "/receipt-service/receipts" ||
                location.pathname === "/receipt-service/users")
                  ? "nav__item_active active"
                  : ""
              }`}
            >
              <div className="nav__item-left">
                <span>
                  <img
                    src={`${
                      (location.pathname === "/receipt-service/login" ||
                      location.pathname === "/receipt-service/receipts" ||
                      location.pathname === "/receipt-service/users")
                        ? "/assets/icons/sell-white.svg"
                        : "/assets/icons/sell.svg"
                    }`}
                    alt="sell"
                  />
                </span>
                <p className="nav__item-text">Сервис чеков</p>
              </div>
            </NavLink>
          )}
          {/* <div className="bb"></div> */}
        </div>
      </div>
      <div className={`nav__drop ${showDrop ? "nav__drop_active" : ""}`}>
        <NavLink
          to="/login"
          onClick={() => {
            onboarding.logoutHandle({});
          }}
          className="nav__drop-item"
        >
          <span>
            <img src={`${"/assets/icons/logout.svg"}`} alt="logout" />
          </span>
          <p className="nav__drop-text">
            {languageState === "false"
              ? language?.nav_bar?.logout_ru
              : language?.nav_bar?.logout_en}
          </p>
        </NavLink>
      </div>
      <div
        onClick={() => {
          setShowDrop(!showDrop);
        }}
        className="nav__profile"
      >
        <div className="nav__profile-left">
          <div
            className={`nav__profile-avatar
            `}
          >
            <img
              src={`${
                profileData?.image
                  ? profileData?.image
                  : "/assets/icons/user-example.svg"
              }`}
              alt="avatar"
            />
          </div>
          <p className="nav__profile-name">
            {profileData?.telegram_id ?? profileData?.role}
          </p>
        </div>
        <span
          className={`nav__arrow-profile ${
            showDrop ? "nav__arrow-profile_active" : ""
          }`}
        >
          <img src="/assets/icons/nav-arrow.svg" alt="arrows" />
        </span>
      </div>
    </div>
  );
}
