import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import { CustomSelect } from "../../UI/Select";
import ApiUsers from "../../Global/Api/users.api";
import ApiMerchantLinks from "../../Global/Api/merchantLinks.api";
import Popup from "../../Components/Popup";
import Button from "../../UI/Button";
import { Link, NavLink } from "react-router-dom";
import {
  setLinkMerchantsEmpty,
  setLinkTradersEmpty,
} from "../../Store/Slice/unitradeSlice";

import "./index.scss";

export default function MerchantLinks() {
  const usersApi = ApiUsers();
  const merchantLinksApi = ApiMerchantLinks();
  const dispatch = useDispatch();
  const [usersSelect, setUsersSelect] = useState(null);
  const [usersMerchantSelect, setUsersMerchantSelect] = useState(null);
  const [usersSelectCreate, setUsersSelectCreate] = useState(null);
  const [usersSelectCreateService, setUsersSelectCreateService] =
    useState(null);
  const lastDateTime = useRef(null);
  const limitCount = 100;
  const [usersMerchantSelectCreate, setUsersMerchantSelectCreate] =
    useState(null);
  const [
    usersMerchantSelectCreateService,
    setUsersMerchantSelectCreateService,
  ] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [createServiceModal, setCreateServiceModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [buySelect, setBuySelect] = useState(null);
  const [sellSelect, setSellSelect] = useState(null);
  const [buySelectC2C, setBuySelectC2C] = useState(null);
  const [sellSelectC2C, setSellSelectC2C] = useState(null);
  const [buySelectBonus, setBuySelectBonus] = useState(null);
  const [sellSelectBonus, setSellSelectBonus] = useState(null);

  const buyData = [
    {
      label: "Включен",
      value: true,
    },
    {
      label: "Отключен",
      value: false,
    },
  ];
  const sellData = [
    {
      label: "Включен",
      value: true,
    },
    {
      label: "Отключен",
      value: false,
    },
  ];
  const {
    users,
    usersMerchant,
    serviceUsers,
    linkMerchant,
    linkTrader,
    linkTraders,
    linkMerchants,
  } = useSelector((state) => state.rootReducer.unitradeSlice);

  useEffect(() => {
    const formattedParamsUsers = formatParamsUsers();
    const formattedParamsServiceUsers = formatParamsServiceUsers();
    const formattedParamsUsersMerchant = formatParamsUsersMerchant();
    usersApi.getData(formattedParamsUsers);
    usersApi.getData(formattedParamsServiceUsers, "service");
    usersApi.getData(formattedParamsUsersMerchant, "merchant");
  }, []);

  useEffect(() => {
    if (usersSelect !== null) {
      merchantLinksApi.getMerchants({
        trader_id: usersSelect?.value,
        limit: limitCount,
      });
    }
  }, [usersSelect]);

  useEffect(() => {
    if (usersMerchantSelect !== null) {
      merchantLinksApi.getTraders({
        merchant_id: usersMerchantSelect?.value,
        limit: limitCount,
      });
    }
  }, [usersMerchantSelect]);

  const formatParamsUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    return params;
  };

  const formatParamsServiceUsers = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "service_trader");
    return params;
  };

  const formatParamsUsersMerchant = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    params.append("role", "merchant_server");
    return params;
  };

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const openCreateServiceModal = () => {
    setCreateServiceModal(true);
  };

  const closeCreateModal = () => {
    setUsersMerchantSelectCreate(null);
    setUsersSelectCreate(null);
    setCreateModal(false);
  };

  const closeCreateServiceModal = () => {
    setUsersMerchantSelectCreateService(null);
    setUsersSelectCreateService(null);
    setCreateServiceModal(false);
  };

  const createSendData = () => {
    merchantLinksApi.createLink({
      merchant_id: usersMerchantSelectCreate.value,
      trader_id: usersSelectCreate.value,
    });
    closeCreateModal();
  };

  const deleteSendData = () => {
    merchantLinksApi.deleteLink({
      merchant_id: linkMerchant?.merchant?.id,
      trader_id: userInfo?.trader?.id,
    });
    closeDeleteModal();
  };

  const openDeleteModal = (item) => {
    setUserInfo(item);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setUserInfo(null);
    setDeleteModal(false);
  };

  const openEditModal = (item) => {
    setEditModal(true);
    setUserInfo(item);
    setBuySelect(buyData?.filter((el) => el?.value === item?.is_buy_p2p));
    setSellSelect(sellData?.filter((el) => el?.value === item?.is_sell_p2p));
    setBuySelectC2C(buyData?.filter((el) => el?.value === item?.is_buy_c2c));
    setSellSelectC2C(sellData?.filter((el) => el?.value === item?.is_sell_c2c));
    setBuySelectBonus(
      buyData?.filter((el) => el?.value === item?.is_buy_bonus)
    );
    setSellSelectBonus(
      sellData?.filter((el) => el?.value === item?.is_sell_bonus)
    );
  };

  const closeEditModal = () => {
    setEditModal(false);
    setUserInfo(null);
    setSellSelect(null);
    setBuySelect(null);
    setBuySelectC2C(null);
    setSellSelectC2C(null);
    setBuySelectBonus(null);
    setSellSelectBonus(null);
  };

  const renderTraders = () => {
    return linkTraders?.map((item) => (
      <tr key={item?.trader?.id}>
        <td>
          <Link target="_blank" to={`/user/${item?.trader?.id}`}>
            {item?.trader?.notice ?? item?.trader?.id}
          </Link>
        </td>
        <td>{item?.trader?.login ?? "--- --- ---"}</td>
        <td>{item?.trader?.telegram_id ?? "--- --- ---"}</td>
        <td>
          {item?.trader?.type_active === "active"
            ? "Активен"
            : item?.trader?.type_active === "paused"
            ? "На паузе"
            : "Неактивен"}
        </td>
        <td>{item?.trader?.is_banned ? "Забанен" : "Разбанен"}</td>
        <td>{item?.is_buy_p2p ? "Включены" : "Отключены"}</td>
        <td>{item?.is_sell_p2p ? "Включены" : "Отключены"}</td>
        <td>{item?.is_buy_c2c ? "Включены" : "Отключены"}</td>
        <td>{item?.is_sell_c2c ? "Включены" : "Отключены"}</td>
        <td>{item?.is_buy_bonus ? "Включены" : "Отключены"}</td>
        <td>{item?.is_sell_bonus ? "Включены" : "Отключены"}</td>
        {item?.trader?.is_service && (
          <td>
            <NavLink
              target="_blank"
              to={`/bank-links/${item?.trader?.id}`}
              className="users__btn"
            >
              <img src="/assets/icons/link.svg" alt="" />
            </NavLink>
          </td>
        )}
        <td>
          <div
            onClick={() => {
              openEditModal(item);
            }}
            className="users__btn"
          >
            <img src="/assets/icons/edit-ad.svg" alt="" />
          </div>
        </td>
        <td>
          <div
            onClick={() => {
              openDeleteModal(item);
            }}
            className="users__btn"
          >
            <img src="/assets/icons/trash-ad.svg" alt="" />
          </div>
        </td>
      </tr>
    ));
  };

  const renderMerchants = () => {
    return linkMerchants?.map((item) => (
      <tr key={item?.merchant?.id}>
        <td>
          <Link target="_blank" to={`/user/${item?.merchant.id}`}>
            {item?.merchant.notice ?? item?.merchant.id}
          </Link>
        </td>
        <td>{item?.merchant.login ?? "--- --- ---"}</td>
        <td>{item?.merchant.telegram_id ?? "--- --- ---"}</td>
        <td>
          {item?.merchant.type_active === "active"
            ? "Активен"
            : item?.merchant.type_active === "paused"
            ? "На паузе"
            : "Неактивен"}
        </td>
        <td>{item.merchant?.is_banned ? "Забанен" : "Разбанен"}</td>
      </tr>
    ));
  };

  const loadMoreTraders = () => {
    lastDateTime.current = linkTraders[linkTraders?.length - 1]?.created_at;
    merchantLinksApi.getTraders({
      merchant_id: usersMerchantSelect?.value,
      limit: limitCount,
      last_created_at: lastDateTime.current,
    });
  };

  const loadMoreMerchants = () => {
    lastDateTime.current = linkMerchants[linkMerchants?.length - 1]?.created_at;
    merchantLinksApi.getMerchants({
      trader_id: usersSelect?.value,
      limit: limitCount,
      last_created_at: lastDateTime.current,
    });
  };

  const editSendData = () => {
    merchantLinksApi.editTraficByTrader({
      link_id: userInfo?.id,
      is_buy_p2p:
        buySelect.value === undefined ? buySelect[0].value : buySelect.value,
      is_sell_p2p:
        sellSelect.value === undefined ? sellSelect[0].value : sellSelect.value,
      is_buy_c2c:
        buySelectC2C.value === undefined
          ? buySelectC2C[0].value
          : buySelectC2C.value,
      is_sell_c2c:
        sellSelectC2C.value === undefined
          ? sellSelectC2C[0].value
          : sellSelectC2C.value,
      is_buy_bonus:
        buySelectBonus.value === undefined
          ? buySelectBonus[0].value
          : buySelectBonus.value,
      is_sell_bonus:
        sellSelectBonus.value === undefined
          ? sellSelectBonus[0].value
          : sellSelectBonus.value,
    });
    closeEditModal();
  };

  const createServiceSendData = () => {
    merchantLinksApi.createServiceLink(
      {
        merchant_id: usersMerchantSelectCreateService.value,
        trader_id: usersSelectCreateService.value,
      },
      closeCreateServiceModal
    );
  };

  return (
    <div className="merchantLinks container">
      {createModal && (
        <Popup
          title={"Привязать трейдера к мерчанту"}
          closePopup={closeCreateModal}
        >
          <div className="orders__select">
            <CustomSelect
              options={usersMerchant?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите мерчанта"}
              selected={usersMerchantSelectCreate}
              handleSelect={(e) => {
                setUsersMerchantSelectCreate(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={users
                ?.filter(item => item?.role === "trader" && !item?.is_service)
                .map(user => ({
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                }))}
              placeholder={"Выберите трейдера"}
              selected={usersSelectCreate}
              handleSelect={(e) => {
                setUsersSelectCreate(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {createServiceModal && (
        <Popup
          title={"Привязать сервисного трейдера к мерчанту"}
          closePopup={closeCreateServiceModal}
        >
          <div className="orders__select">
            <CustomSelect
              options={usersMerchant?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите мерчанта"}
              selected={usersMerchantSelectCreateService}
              handleSelect={(e) => {
                setUsersMerchantSelectCreateService(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="orders__select">
            <CustomSelect
              options={serviceUsers?.map((user) => {
                return {
                  value: user.id,
                  label: user.notice !== null ? user.notice : user.login,
                };
              })}
              placeholder={"Выберите трейдера"}
              selected={usersSelectCreateService}
              handleSelect={(e) => {
                setUsersSelectCreateService(e ?? null);
              }}
              isClearable={true}
            />
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateServiceModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createServiceSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {deleteModal && (
        <Popup title={"Удалить пользователя"} closePopup={closeDeleteModal}>
          <p className="delete-text">
            Вы уверены что хотите удалить пользователя?
          </p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {editModal && (
        <Popup title={"Настройки трафика трейдера"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup-select mb-10">
              <p>Ввод P2P</p>
              <CustomSelect
                options={buyData}
                selected={buySelect}
                handleSelect={(e) => {
                  setBuySelect(e ?? null);
                }}
              />
            </div>
            <div className="popup-select">
              <p>Вывод P2P</p>
              <CustomSelect
                options={sellData}
                selected={sellSelect}
                handleSelect={(e) => {
                  setSellSelect(e ?? null);
                }}
              />
            </div>
            <div className="popup-select">
              <p>Ввод C2C</p>
              <CustomSelect
                options={sellData}
                selected={buySelectC2C}
                handleSelect={(e) => {
                  setBuySelectC2C(e ?? null);
                }}
              />
            </div>
            <div className="popup-select">
              <p>Вывод C2C</p>
              <CustomSelect
                options={sellData}
                selected={sellSelectC2C}
                handleSelect={(e) => {
                  setSellSelectC2C(e ?? null);
                }}
              />
            </div>
            <div className="popup-select">
              <p>Ввод бонус</p>
              <CustomSelect
                options={sellData}
                selected={buySelectBonus}
                handleSelect={(e) => {
                  setBuySelectBonus(e ?? null);
                }}
              />
            </div>
            <div className="popup-select">
              <p>Вывод бонус</p>
              <CustomSelect
                options={sellData}
                selected={sellSelectBonus}
                handleSelect={(e) => {
                  setSellSelectBonus(e ?? null);
                }}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Merchant Links"} />
      <div className="merchantLinks__inner">
        <div className="merchantLinks__top">
          <div className="orders__selects">
            <div className="orders__select">
              <CustomSelect
                options={usersMerchant?.map((user) => {
                  return {
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  };
                })}
                placeholder={"Выберите мерчанта"}
                selected={usersMerchantSelect}
                handleSelect={(e) => {
                  dispatch(setLinkTradersEmpty([]));
                  setUsersMerchantSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
            <div className="orders__select">
              <CustomSelect
                options={users
                  ?.filter(
                    (item) =>
                      item?.role === "trader" || item?.role === "service_trader"
                  )
                  ?.map((user) => ({
                    value: user.id,
                    label: user.notice !== null ? user.notice : user.login,
                  }))}
                placeholder={"Выберите трейдера"}
                selected={usersSelect}
                handleSelect={(e) => {
                  dispatch(setLinkMerchantsEmpty([]));
                  setUsersSelect(e ?? null);
                }}
                isClearable={true}
              />
            </div>
          </div>
          <div className="orders__btn">
            <Button
              text={"Привязать трейдера"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={openCreateModal}
            />
          </div>
          <div className="orders__btn w-l">
            <Button
              text={"Привязать сервисного трейдера"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={openCreateServiceModal}
            />
          </div>
        </div>
        {usersMerchantSelect !== null && (
          <div className="merchantLinks__box">
            <h2>
              Трейдеры мерчанта:{" "}
              {linkMerchant?.merchant?.notice ?? linkMerchant?.merchant?.id}
            </h2>
            <div className="merchantLinks__table">
              <table>
                <thead>
                  <tr>
                    <th>Имя</th>
                    <th>Логин</th>
                    <th>Телеграмм ID</th>
                    <th>Активность</th>
                    <th>Блокировка</th>
                    <th>Ввод P2P</th>
                    <th>Вывод P2P</th>
                    <th>Ввод C2C</th>
                    <th>Вывод C2C</th>
                    <th>Ввод бонус</th>
                    <th>Вывод бонус</th>
                  </tr>
                </thead>
                <tbody>{renderTraders()}</tbody>
              </table>
            </div>
            <div className="loadMore">
              <p
                onClick={() => {
                  loadMoreTraders();
                }}
              >
                Загрузить еще...
              </p>
            </div>
          </div>
        )}
        {usersSelect !== null && (
          <div className="merchantLinks__box">
            <h2>
              Мерчанты трейдера:{" "}
              {linkTrader?.trader?.notice ?? linkTrader?.trader?.id}
            </h2>
            <div className="merchantLinks__table">
              <table>
                <thead>
                  <tr>
                    <th>Имя</th>
                    <th>Логин</th>
                    <th>Телеграмм ID</th>
                    <th>Активность</th>
                    <th>Блокировка</th>
                  </tr>
                </thead>
                <tbody>{renderMerchants()}</tbody>
              </table>
            </div>
            <div className="loadMore">
              <p
                onClick={() => {
                  loadMoreMerchants();
                }}
              >
                Загрузить еще...
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
