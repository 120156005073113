import {
  setLoader,
  setLinkMerchant,
  setLinkTraders,
  setLinkTrader,
  setLinkMerchants,
  setDelTraders,
  setLinkTradersEmpty,
  setLinkMerchantsEmpty,
  setTraficByTrader
} from "../../Store/Slice/unitradeSlice";
import {
  useCreateLinkMerchantTraderMutation,
  useLinksTradersMutation,
  useLinksMerchantsMutation,
  useDeleteLinkMerchantTraderMutation,
  useEditTraficByTraderMutation,
  useCreateServiceLinkMerchantTraderMutation
} from "../../Store/Builder/unitrade.api";
import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";

const ApiMerchantLinks = () => {
  const dispatch = useDispatch();
  const [apiCreateLink] = useCreateLinkMerchantTraderMutation();
  const [apiCreateServiceLink] = useCreateServiceLinkMerchantTraderMutation();
  const [apiGetLinksTraders] = useLinksTradersMutation();
  const [apiGetLinksMerchants] = useLinksMerchantsMutation();
  const [apiDeleteLinkTrader] = useDeleteLinkMerchantTraderMutation();
  const [apiEditTraficByTrader] = useEditTraficByTraderMutation();

  const getTraders = (params, action) => {
    dispatch(setLoader(true));
    apiGetLinksTraders(params)
      .unwrap()
      .then((res) => {
        dispatch(setLinkMerchant(res));
        if (action === "create") {
          dispatch(setLinkTradersEmpty(res?.traders));
        } else {
          dispatch(setLinkTraders(res?.traders));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getMerchants = (params, action) => {
    dispatch(setLoader(true));
    apiGetLinksMerchants(params)
      .unwrap()
      .then((res) => {
        dispatch(setLinkTrader(res));
        if (action === "create") {
          dispatch(setLinkMerchantsEmpty(res?.merchants));
        } else {
          dispatch(setLinkMerchants(res?.merchants));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createLink = (body) => {
    dispatch(setLoader(true));
    apiCreateLink(body)
      .unwrap()
      .then((res) => {
        getTraders({ merchant_id: body?.merchant_id, limit: 100 }, "create");
        getMerchants({ trader_id: body?.trader_id, limit: 100 }, "create");
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createServiceLink = (body, closeCreateServiceModal) => {
      dispatch(setLoader(true));
      apiCreateServiceLink(body)
      .unwrap()
      .then(() => {
        getTraders({ merchant_id: body?.merchant_id, limit: 100 }, "create");
        getMerchants({ trader_id: body?.trader_id, limit: 100 }, "create");
        closeCreateServiceModal()
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };


  const deleteLink = (body) => {
    dispatch(setLoader(true));
    apiDeleteLinkTrader(body)
      .unwrap()
      .then(() => {
        dispatch(setDelTraders(body?.trader_id));
        dispatch(setLoader(false));
        showToast("Трейдер успешно удален", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editTraficByTrader = (body) => {
    dispatch(setLoader(true));
    apiEditTraficByTrader(body)
      .unwrap()
      .then((res) => {
        dispatch(setTraficByTrader(res));
        dispatch(setLoader(false));
        showToast("Настройки успешно изменены", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };


  return {
    getTraders,
    getMerchants,
    createLink,
    deleteLink,
    editTraficByTrader,
    createServiceLink
  };
};

export default ApiMerchantLinks;
