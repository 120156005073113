import React, { useEffect, useState } from "react";
import CryptoWalletsApi from "../../Global/Api/cryptoWallets.api";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelect } from "../../UI/Select";
import { setCryptoWalletBillingEmpty } from "../../Store/Slice/unitradeSlice";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Button from "../../UI/Button";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import ApiProfile from "../../Global/Api/profile.api";
import ApiUsers from "../../Global/Api/users.api";

import "./index.scss";

export default function ProfileInfo() {
  const dispatch = useDispatch();
  const apiProfile = ApiProfile();
  const userApi = ApiUsers();
  const apiCryptoWallets = CryptoWalletsApi();
  let url = window.location.href;
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [generateFaModal, setGenerateFaModal] = useState(false);
  const newFaKey = useInputHandler("");
  const repeatNewFaKey = useInputHandler("");
  const ownerFaKey = useInputHandler("");
  const limitCount = 100;
  let bodyFilter;
  let data;

  const typeData = [
    {
      value: "web3",
      label: "WEB3",
    },
    {
      value: "order",
      label: "Order",
    },
    {
      value: "fee",
      label: "Fee",
    },
  ];

  const { cryptoWallet, cryptoWalletBilling, faKeyDataProfile } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  const getWalletData = () => {
    const body = {
      wallet_id: url.split("/").pop(),
    };

    apiCryptoWallets.getData(body);
  };

  useEffect(() => {
    getWalletData();
    apiCryptoWallets.getCryptoWalletsBilling({
        wallet_id: url.split("/").pop(),
        limit: limitCount,
      });
  }, []);

  useEffect(() => {
    if (faKeyDataProfile !== null) {
      newFaKey.setChange(faKeyDataProfile?.fa_key);
      repeatNewFaKey.setChange(faKeyDataProfile?.fa_key);
    }
  }, [faKeyDataProfile]);

  const renderBilling = () => {
    return cryptoWalletBilling?.map((billing) => (
      <tr key={billing?.id}>
        <td>
          <NavLink
            onClick={() => {
              localStorage.setItem("order_id", billing?.order_id);
            }}
            to={`/order/${billing?.order_id}`}
            className="user__btn"
          >
            {billing?.order_id}
          </NavLink>
        </td>
        <td>{billing?.currency_type}</td>
        <td>{billing?.operation_type}</td>
        <td>{billing?.transaction_type}</td>
        <td>{billing?.balance}</td>
        <td>{billing?.frozen_balance}</td>
        <td>{billing?.deposit}</td>
        <td>
          {moment.utc(billing?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
      </tr>
    ));
  };

  const loadMore = () => {
    if (selectedTransaction !== null) {
      data = {
        wallet_id: url.split("/").pop(),
        limit: limitCount,
        transaction_type: selectedTransaction.value,
        last_created_at:
          cryptoWalletBilling[cryptoWalletBilling?.length - 1]?.created_at,
      };
    } else {
      data = {
        wallet_id: url.split("/").pop(),
        limit: limitCount,
        last_created_at:
          cryptoWalletBilling[cryptoWalletBilling?.length - 1]?.created_at,
      };
    }
    apiCryptoWallets.getCryptoWalletsBilling(data);
  };

  const openGenerateFaKeyModal = () => {
    setGenerateFaModal(true);
  };

  const closeGenerateFaKeyModal = () => {
    setGenerateFaModal(false);
    newFaKey.setChange("");
    repeatNewFaKey.setChange("");
    ownerFaKey.setChange("");
  };

  const generateKey = () => {
    apiProfile.generateFaKey();
  };

  const sendDataGenerate = () => {
    const body = {
      id: url.split("/").pop(),
      new_fa_key: newFaKey.value,
      new_fa_key_again: repeatNewFaKey.value,
      owner_password: ownerFaKey.value,
    };
    userApi.editFaKeyUser(body, closeGenerateFaKeyModal);
  };

  return (
    <div className="profileInfo">
      {generateFaModal && (
        <Popup title={"Изменить 2fa ключ"} closePopup={closeGenerateFaKeyModal}>
          <div className="popup__form">
            <div>
              <Button
                text={"Сгенерировать новый fa ключ"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={generateKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Новый fa ключ"}
                placeholder=""
                type="text"
                id="newFaKey"
                {...newFaKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Повторите новый fa ключ"}
                type="text"
                placeholder=""
                id="repeatNewFaKey"
                {...repeatNewFaKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Введите свой fa ключ"}
                type="text"
                placeholder=""
                onlyNumber={true}
                maxLength={6}
                id="ownerFaKey"
                {...ownerFaKey}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeGenerateFaKeyModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={
                  ownerFaKey.value?.length !== 6 ||
                  newFaKey.value === "" ||
                  repeatNewFaKey.value === ""
                    ? true
                    : false
                }
                bottomPadding={10}
                type="accept"
                onClick={sendDataGenerate}
              />
            </div>
          </div>
        </Popup>
      )}
      <div className="profileInfo__table">
        <div className="profileInfo__head">
          <h2>Информация о кошельке</h2>
          <div className="editFa__btn">
            <Button
              text={"Изменить fa ключ"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={openGenerateFaKeyModal}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Крипто адрес</th>
              <th>Тип валюты</th>
              <th>Баланс</th>
              <th>Депозит</th>
              <th>Замороженный баланс</th>
              <th>Активность</th>
              <th>QR код</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{cryptoWallet?.crypto_address}</td>
              <td>{cryptoWallet?.currency_type}</td>
              <td>{cryptoWallet?.balance}</td>
              <td>{cryptoWallet?.deposit}</td>
              <td>{cryptoWallet?.frozen_balance}</td>
              <td>{cryptoWallet?.is_wallet ? "Активен" : "Не активен"}</td>
              <td className="qr_code">
                <img src={cryptoWallet?.qr_code_path} alt="" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="cryptoWalletBilling">
        <h2 className="crypto-wallets__title">
          Billings
          <div className="crypto-wallets__select">
            <CustomSelect
              options={typeData}
              placeholder={"Выберите тип транзакции"}
              selected={selectedTransaction}
              handleSelect={(e) => {
                dispatch(setCryptoWalletBillingEmpty([]));
                setSelectedTransaction(e ?? null);
                if (e === null) {
                  bodyFilter = {
                    wallet_id: url.split("/").pop(),
                    limit: limitCount,
                  };
                } else {
                  bodyFilter = {
                    wallet_id: url.split("/").pop(),
                    transaction_type: e.value,
                    limit: limitCount,
                  };
                }
                apiCryptoWallets.getCryptoWalletsBilling(bodyFilter);
              }}
              isClearable={true}
            />
          </div>
        </h2>
        <table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Currency Type</th>
              <th>Operation Type</th>
              <th>Transaction Type</th>
              <th>Balance</th>
              <th>Frozen Balance</th>
              <th>Deposit</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>{renderBilling()}</tbody>
        </table>
        <div className="loadMore">
          <p
            onClick={() => {
              loadMore();
            }}
          >
            Загрузить еще...
          </p>
        </div>
      </div>
    </div>
  );
}
