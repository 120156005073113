import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import {
  useGetAvailableBanksMutation,
  useLinkAvailableBankMutation,
  useDeleteAvailableBankMutation,
  useEditAvailableBankMutation,
} from "../../Store/Builder/unitrade.api";
import { setLoader, setAvailableBanks } from "../../Store/Slice/unitradeSlice";

const ApiBankLinks = () => {
  const dispatch = useDispatch();
  const [apiGetAvailableBanks] = useGetAvailableBanksMutation();
  const [apiLinkAvailableBank] = useLinkAvailableBankMutation();
  const [apiDeleteAvailableBank] = useDeleteAvailableBankMutation();
  const [apiEditAvailableBank] = useEditAvailableBankMutation();

  const getAvailableBanks = (params) => {
    dispatch(setLoader(true));
    apiGetAvailableBanks(params)
      .unwrap()
      .then((res) => {
        dispatch(setAvailableBanks(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const linkAvailableBank = (body, formatParams, closeCreateModal) => {
    dispatch(setLoader(true));
    apiLinkAvailableBank(body)
      .unwrap()
      .then(() => {
        const formattedParams = formatParams();
        getAvailableBanks(formattedParams);
        closeCreateModal();
        showToast("Платежный метод успешно привязан", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const deleteAvailableBank = (body, formatParams, closeDeleteModal) => {
    dispatch(setLoader(true));
    apiDeleteAvailableBank(body)
      .unwrap()
      .then(() => {
        const formattedParams = formatParams();
        getAvailableBanks(formattedParams);
        closeDeleteModal();
        showToast("Платежный метод успешно отвязан", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const editeAvailableBank = (body, formatParams, closeEditModal) => {
    dispatch(setLoader(true));
    apiEditAvailableBank(body)
      .unwrap()
      .then(() => {
        const formattedParams = formatParams();
        getAvailableBanks(formattedParams);
        closeEditModal();
        showToast("Настройки трафика успешно изменены", "success");
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getAvailableBanks,
    linkAvailableBank,
    deleteAvailableBank,
    editeAvailableBank,
  };
};

export default ApiBankLinks;
