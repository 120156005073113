import {useDispatch} from "react-redux";
import {useGetAdsMutation, useGetAnnouncementsSearchMutation} from "../../Store/Builder/unitrade.api";
import {setLoader, setSellAds, setSellAdsSearch} from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";

const useGetAds = () => {
  const dispatch = useDispatch();
  const [getAds] = useGetAdsMutation();
  const [getAdSearch] = useGetAnnouncementsSearchMutation()
  const get = (params) => {
    dispatch(setLoader(true))
    getAds(params)
      .unwrap()
      .then((res) => {
        dispatch(setSellAds(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getSearch = (body) => {
    dispatch(setLoader(true))
    getAdSearch(body)
      .unwrap()
      .then((res) => {
        let array = []
        array.push(res)
        dispatch(setSellAdsSearch(array))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
    getSearch
  };
};

export default useGetAds;


