import {setLoader, setPaymentsMethods, setSmsMessages, setSmsMessagesSearch, setSmsMessageBilling} from "../../Store/Slice/unitradeSlice";
import showToast from "../Hooks/toast";
import {useDispatch} from "react-redux";
import {
  useGetPaymentMethodsMutation,
  useGetSmsMessagesMutation,
  useGetSmsMessagesSearchMutation,
  useLazyGetSmsBillingQuery
} from "../../Store/Builder/unitrade.api";

const ApiSmsMessages = () => {
  const dispatch = useDispatch();
  const [apiGetSmsMessages] = useGetSmsMessagesMutation();
  const [apiGetSmsMessagesSearch] = useGetSmsMessagesSearchMutation();
  const [apiGetSmsBilling] = useLazyGetSmsBillingQuery();

  const getData = (params) => {
    dispatch(setLoader(true))
    apiGetSmsMessages(params)
      .unwrap()
      .then((res) => {
        dispatch(setSmsMessages(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getSmsBilling = (params) => {
    dispatch(setLoader(true))
    apiGetSmsBilling(params)
      .unwrap()
      .then((res) => {
        dispatch(setSmsMessageBilling(res?.billings))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getSmsSearch = (body) => {
    dispatch(setLoader(true))
    apiGetSmsMessagesSearch(body)
      .unwrap()
      .then((res) => {
        let array = [];
        array.push(res)
        dispatch(setSmsMessagesSearch(array))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getData,
    getSmsSearch,
    getSmsBilling
  }
}

export default ApiSmsMessages;
