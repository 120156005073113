import { useDispatch } from "react-redux";
import showToast from "../Hooks/toast";
import { useNavigate } from "react-router-dom";
import {
  useLazyLoginQuery,
  useLazyLogoutQuery,
  useGetProfileInfoMutation,
} from "../../Store/Builder/unitrade.api";
import { jwtDecode } from "jwt-decode";
import ApiProfile from "./profile.api";
import { useEffect } from "react";
import { setLoader, setProfileData } from "../../Store/Slice/unitradeSlice";

const useOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLazyLoginQuery();
  const [logout] = useLazyLogoutQuery();
  const [apiGetProfile] = useGetProfileInfoMutation();
  let refreshTimeoutId = null;

  const refreshToken = async (expiration) => {
    const timeoutTrigger = expiration * 1000 - 10000; // Обновляем токен за 10 секунд до истечения

    refreshTimeoutId = setTimeout(async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/refresh-logout/refresh-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              refresh_token: localStorage.getItem("refresh_token_admin"),
            }),
          }
        );

        if (response.ok) {
          const newTokens = await response.json();
          const decodedToken = jwtDecode(newTokens?.access_token);
          const currentTime = Math.floor(Date.now() / 1000);
          const timeUntilExpiration = decodedToken.exp - currentTime;
          setToken(newTokens?.access_token, timeUntilExpiration);
        } else {
          throw new Error("Failed to refresh token");
        }
      } catch (error) {
        localStorage.removeItem("refresh_token_admin");
        localStorage.removeItem("unitrade_admin_auth");
        localStorage.removeItem("token_expiration");
        localStorage.removeItem("profileData");
        navigate("/login");
      }
    }, timeoutTrigger);
  };

  const abortRefreshToken = () => {
    if (refreshTimeoutId) {
      clearTimeout(refreshTimeoutId);
    }
  };

  const setToken = (token, tokenExpiration) => {
    localStorage.setItem("unitrade_admin_auth", token);
    localStorage.setItem(
      "token_expiration",
      Date.now() / 1000 + tokenExpiration
    ); // Сохраняем время истечения токена
    refreshToken(tokenExpiration);
  };

  const restoreToken = () => {
    const token = localStorage.getItem("unitrade_admin_auth");
    const tokenExpiration = localStorage.getItem("token_expiration");
    if (token && tokenExpiration) {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiration = tokenExpiration - currentTime;
      if (timeUntilExpiration > 0) {
        refreshToken(timeUntilExpiration);
      } else {
        localStorage.removeItem('refresh_token_admin')
        localStorage.removeItem('unitrade_admin_auth')
        localStorage.removeItem("token_expiration");
        localStorage.removeItem("profileData");
        navigate("/login"); // Если токен истек, перенаправляем на страницу входа
      }
    }
  };

  useEffect(() => {
    restoreToken(); // Восстанавливаем таймер при загрузке страницы
  }, []);

  const getData = () => {
    dispatch(setLoader(true));
    apiGetProfile()
      .unwrap()
      .then((res) => {
        localStorage.setItem("profileData", JSON.stringify(res));
        dispatch(setProfileData(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const loginHandle = (data) => {
    login(data)
      .unwrap()
      .then((res) => {
        const decodedToken = jwtDecode(res?.access_token);
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiration = decodedToken.exp - currentTime;

        localStorage.setItem("refresh_token_admin", res?.refresh_token);
        setToken(res?.access_token, timeUntilExpiration);
        getData();
        showToast("Вход выполнен успешно", "success");
        navigate("/");
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const logoutHandle = (data) => {
    logout(data)
      .unwrap()
      .then(() => {
        localStorage.removeItem("unitrade_admin_auth");
        localStorage.removeItem("refresh_token_admin");
        localStorage.removeItem("token_expiration");
        localStorage.removeItem("profileData");
        abortRefreshToken();
        navigate("/login");
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    loginHandle,
    logoutHandle,
    setToken,
  };
};

export default useOnboarding;
