import "./index.scss";
import Header from "../../Components/Header";
import ApiSmsMessages from "../../Global/Api/smsMessages.api";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiSmsHistory from "../../Global/Api/smsHistory.api";
import moment from "moment";
import SearchComponent from "../../Components/Search";
import Button from "../../UI/Button";
import { setSmsHistorySearch } from "../../Store/Slice/unitradeSlice";
import { useDebounce } from "../../Global/Hooks/useDebounce";
import ApiDevices from "../../Global/Api/devices.api";
import { CustomSelect } from "../../UI/Select";
import { Link } from "react-router-dom";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";

export default function SmsHistory() {
  const dispatch = useDispatch();
  const smsHistoryApi = ApiSmsHistory();
  const useDevices = ApiDevices();
  const [limitCount, setLimitCount] = useState("100");
  const lastCreated = useRef(null);
  const [search, setSearch] = useState("");
  const firstLoad = useRef(false);
  const [value, setValue] = useState(null);
  const [deviceName, setDeviceName] = useState("");
  const [deviceSelect, setDeviceSelect] = useState(null);
  const [statusSelect, setStatusSelect] = useState(null);
  const statusData = [
    {
      label: 'failed',
      value: 'failed'
    },
    {
      label: 'success',
      value: 'success'
    }
  ]
  const { smsHistory, devices } = useSelector(
    (state) => state.rootReducer.unitradeSlice
  );

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("pagination_order", "end");
    if (deviceSelect !== null) params.append("device_id", deviceSelect.id);
    if (statusSelect !== null) params.append("sms_types", statusSelect.value);
    if (value !== null)
      params.append(
        "date_from",
        moment(value[0].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (value !== null)
      params.append(
        "date_to",
        moment(value[1].toString()).format("YYYY-MM-DDTHH:mm")
      );
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsDevices = () => {
    const params = new URLSearchParams();
    params.append("limit", 10000);
    return params;
  };

  useEffect(() => {
    const formattedParams = formatParams();
    if (search === "" && firstLoad.current === true) {
      smsHistoryApi.getData(formattedParams);
    }
  }, [search, deviceSelect, statusSelect, value]);

  useEffect(() => {
    const formattedParams = formatParams();
    const formattedParamsDevices = formatParamsDevices();
    smsHistoryApi.getData(formattedParams);
    useDevices.getDevices(formattedParamsDevices);
    firstLoad.current = true;
  }, []);

  const loadMore = () => {
    lastCreated.current = smsHistory[smsHistory.length - 1]?.created_at;
    const formattedParams = formatParams();
    smsHistoryApi.getData(formattedParams);
  };

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const handleSearchDevice = (searchTerm) => {
    dispatch(setSmsHistorySearch([]));
    lastCreated.current = null;
    setDeviceName(searchTerm);
  };

  const searchSendData = () => {
    const body = {
      sms_history_id: search,
    };
    smsHistoryApi.getSmsSearch(body);
  };

  const renderSmsHistoryData = () => {
    return smsHistory.map((sms, index) => {
      return (
        <tr key={index}>
          <td>{sms?.id}</td>
          <td>
            <p className="time_span">
              {moment.utc(sms?.created_at).local().format("DD-MM-YYYY")}
            </p>
            <p>{moment.utc(sms?.created_at).local().format("HH:mm:ss")}</p>
          </td>
          <td>{sms?.device_name}</td>
          <td>
            <Link
              onClick={() => {
                localStorage.setItem("deviceId", sms?.device_id);
              }}
              target="_blank"
              to={`/devices/${sms?.device_id}`}
            >
              {sms?.device_id}
            </Link>
          </td>
          <td>{sms?.bank_title !== null ? sms?.bank_title : "--- ---"}</td>
          <td>{sms?.package !== null ? sms?.package : "--- ---"}</td>
          <td>{sms?.message}</td>
          <td>{sms?.type_history}</td>
        </tr>
      );
    });
  };

  return (
    <div className="smsMessages container">
      <Header title={"СМС история"} />
      <div className="smsMessages__top-inner">
        <div className="smsMessages__top-wrapper">
          <div className="smsMessages__top-search">
            <SearchComponent
              searchTerm={search}
              onSearch={handleSearch}
              placeholder={"Введите id sms"}
            />
          </div>
          <div className="users__top-btn">
            <Button
              text={"Найти sms"}
              type={"accept"}
              onClick={() => {
                searchSendData();
              }}
              topPadding={10}
              bottomPadding={10}
            />
          </div>
        </div>
        <DateRangePicker 
        onChange={(e) => {
          dispatch(setSmsHistorySearch([]));
          setValue(e)
        }} 
        format="dd.MM.yyyy HH:mm" />
        <div className="users__top__select">
          <CustomSelect
            options={statusData}
            placeholder={"Выберите статус"}
            selected={statusSelect}
            handleSelect={(e) => {
              dispatch(setSmsHistorySearch([]));
              setStatusSelect(e ?? null);
            }}
            isClearable={true}
          />
        </div>
        <div className="users__top__select">
          <CustomSelect
            options={devices}
            placeholder={"Выберите девайс"}
            selected={deviceSelect}
            handleSelect={(e) => {
              dispatch(setSmsHistorySearch([]));
              setDeviceSelect(e ?? null);
            }}
            isClearable={true}
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Дата создания</th>
            <th>Название устройства</th>
            <th>ID устройста</th>
            <th>Банк</th>
            <th>Пакет</th>
            <th>СМС</th>
            <th>Type history</th>
          </tr>
        </thead>
        <tbody>{renderSmsHistoryData()}</tbody>
      </table>
      <div className="loadMore">
        <p
          onClick={() => {
            loadMore();
          }}
        >
          Загрузить еще...
        </p>
      </div>
    </div>
  );
}
